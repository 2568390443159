export const locale = {
  lang: 'es',
  data: {
    TESTLANG: 'es_base',
    LNG_ITEMS: 'Productos',
    LNG_ITEM: 'Producto',
    LNG_TIENDAS: 'Puntos de Venta',
    LNG_TIENDA: 'Punto de Venta',
    LNG_PRICES_DECISIONS_FILTER_CATEGORYS: 'Subcategorías',
    GENERAL_INFO: 'Información',
    GENERAL_DOWNLOAD: 'Descargar',
    GENERIC_CLEAN: 'Limpiar búsqueda',
    GENERIC_SELECTED_OPTION: 'Opción seleccionada',
    GENERIC_PUBLISH_CONTENT: 'Desea publicar los siguientes {{val}} registros seleccionados?',
    GENERIC_CANCEL_CONTENT: 'Desea cancelar los siguientes {{val}} registros seleccionados?',
    GENERIC_DOWNLOAD_CONTENT: 'Desea descargar los siguientes {{val}} registros seleccionados?',
    GENERIC_UNPUBLISH: 'Errores de publicación masiva',
    GENERIC_WITHOUT_PERMISSION: 'Sin Permiso para realizar esta acción',
    GENERIC_ACCESS_PERMISSION: 'Sin permisos de acceso a esta pantalla',
    GENERIC_ACTION_ROW_CONTENT_FAILED: 'La acción no puede llevarse a cabo correctamente',
    GENERIC_CANCEL: 'Cancelar',
    GENERIC_ACCEPT: 'Aceptar',
    GENERIC_CLOSE: 'Cerrar',
    GENERIC_OK: 'Ok',
    GENERIC_CONTINUE: 'Continuar',
    GENERIC_WARNING: 'Aviso',
    GENERIC_REPORT: 'Reporte Completo',
    GENERIC_ADD: 'Agregar',
    GENERIC_FILTER: 'Filtrar',
    GENERIC_FULLSCREEN_TTIP: 'Expandir',
    GENERIC_EXITFULLSCREEN_TTIP: 'Reducir',
    GENERIC_EXPORT_TTIP: 'Exportar',
    GENERIC_CLONE: 'Clonar',
    GENERIC_EDIT: 'Editar',
    GENERIC_DELETE: 'Eliminar',
    GENERIC_VIEW: 'Ver',
    GENERIC_CONFIRM: 'Confirmar',
    GENERIC_RESET: 'Reiniciar',
    GENERIC_RESET_ALT: 'Resetear',
    GENERIC_SAVE: 'Guardar',
    GENERIC_SAVE_OK: 'Guardado exitosamente.',
    GENERIC_SAVE_AND_GENERATE: 'Guardar y Generar',
    GENERIC_GENERATE: 'Generar',
    GENERIC_NONE: 'Ninguno',
    GENERIC_DOWNLOAD_EXCEL: 'download.xlsx',
    GENERIC_ITEMS_NOT_FOUND: 'No se encontraron resultados',
    GENERIC_ITEMS_NOT_ITEMS: 'No hay más ítems.',
    GENERIC_NOTICE: 'Aviso',
    GENERIC_SOME: '(Varios)',
    GENERIC_YES: 'Sí',
    GENERIC_NO: 'No',
    GENERIC_ALL: 'Todos',
    GENERIC_ALL_PAGE: 'Toda la página',
    GENERIC_DESELECT: 'Deseleccionar',
    GENERIC_ALL_F: 'Todas',
    GENERIC_DAY1: 'Lunes',
    GENERIC_DAY2: 'Martes',
    GENERIC_DAY3: 'Miercoles',
    GENERIC_DAY4: 'Jueves',
    GENERIC_DAY5: 'Viernes',
    GENERIC_DAY6: 'Sabado',
    GENERIC_DAY7: 'Domingo',
    GENERAL_NAME_DUPLICATE: 'Este nombre ya existe',
    GENERIC_DELETE_LOGIC: 'Inactivar registro',
    GENERIC_DELETE_LOGIC_SINGLE_CONTENT: 'Seguro que desea inactivar {{val}}? solo podrá ser eliminado si no se encuentra en uso',
    GENERIC_DELETE_MULTIPLE: 'Inactivar registros',
    GENERIC_SUPPORT_CHAT: 'Chatear con Soporte Técnico',
    GENERIC_DELETE_LOGIC_MULTIPLE_CONTENT:
      'Seguro que desea inactivar {{val}} registros? solo podrán ser eliminados si no se encuentran en uso',
    GENERIC_ERRORS: {
      REQUIRED: 'Este campo es requerido',
      EMAIL: 'Debe ser un email válido',
      MIN: 'El valor debe ser mayor que {{val}}',
      MAX: 'El valor debe ser menor que {{val}}',
      MINLENGTH: 'Mínimo {{val}} caracteres',
      MAXLENGTH: 'Máximo {{val}} caracteres',
      PATTERN: 'Formato inválido',
      MATDATEPICKERMIN: 'La fecha debe ser posterior a {{val}}',
      MATDATEPICKERMAX: 'La fecha debe ser anterior a {{val}}',
      CODEEXISTS: 'El código ya existe',
    },
    LINEAR_SPACE_TYPES: {
      Sales: 'Ventas',
    },
    LNG_PRODUCTS_TYPES: {
      Retail: 'Retail',
      Ingredient: 'Ingrediente',
      Combo: 'Combo',
      Recipe: 'Receta',
      Supply: 'Insumo',
      Item: 'Retail',
    },
    LNG_STORES_TYPES: {
      Store: 'Punto de venta',
      DistributionCenter: 'Centro de distribución ',
      StoreAndDistributionCenter: 'Punto de venta y Centro de distribución',
    },
    LNG_STRATEGIES_VARIABLES: {
      Sales: 'Ventas',
      SalesUN: 'Unidades Vendidas',
      Deliveries: 'Entregas',
      DeliveriesUN: 'Entregas en UN',
      Margin: 'Margen',
      MarginWithVendors: 'Margen por acuerdos',
      MarketSales: 'Ventas Mercado',
      MarketUN: 'Unidades Mercado',
    },
    LNG_STRATEGIES_EVENTS: {
      First: 'Primer ingreso',
      CreationDate: 'Fecha de alta',
    },
    STATUS_CONNECTION: {
      NO_CONNECTION: 'Sin Conexión.',
      BAD_CONNECTION: 'La Conexión es pobre.',
      ACCEPT: 'OK',
    },
    ABM_STATUS: {
      NEW: 'Nuevo',
      ENDORSED: 'Endorsado',
      INFORMED: 'Informado',
      DELETED: 'Eliminado',
      MODIFIED: 'Modificado',
      CURRENT: 'Publicado',
      CANCELLED: 'Cancelado',
    },
    PUBLISH_STATUS: {
      PROCESSING: 'Publicando',
      PROCESSING_TOOLTIP: 'Refrescar resultados de búsqueda al ser notificado sobre el éxito de la publicación',
      ROW_DISABLED_BY_PROCESSING: 'No se puede realizar la acción porque el registro está siendo procesado',
      DELETING: 'Eliminando',
      DELETING_TOOLTIP: 'Resultados de búsqueda serán actualizados al ser notificado el éxito de la eliminación',
      ABM_WARNING_DELETING: 'Registro en proceso de eliminación.',
    },
    LNG_ADMINISTRATION_MASTER_SUPPLIER_searchStores: 'Buscar Puntos de Venta',
    LNG_ADMINISTRATION_MASTER_STORES_newStore: 'Nuevo punto de venta',
    LNG_ADMINISTRATION_MASTER_STORES_tooltipDeleteStore: 'Borrar puntos de venta seleccionadas',
    LNG_ADMINISTRATION_MASTER_STORES_storeType: 'Tipo de Punto de Venta',
    LNG_MASTER_STORETYPES_Store: 'Punto de Venta',
    LNG_MASTER_STORETYPES_DistributionCenter: 'Centro de distribución ',
    LNG_MASTER_STORETYPES_StoreAndDistributionCenter: 'Punto de Venta y Centro de distribución',
    LNG_PRICES_SETUP_PRICERULE_storeScope: 'Alcance Puntos de Venta',
    LNG_PRICES_SETUP_PRICERULE_step3storeScope: 'Paso 3 - Alcance Puntos de Venta',
    'LNG_PRICE-RULES-TYPES_Margin': 'Margen',
    'LNG_PRICE-RULES-TYPES_Competitive': 'Competencia',
    'LNG_PRICE-RULES-TYPES_ParentItem': 'Parentesco de Productos',
    'LNG_PRICE-RULES-TYPES_ParentZone': 'Parentesco de Puntos de Venta',
    LNG_SET_EFFECTIVE_DATES: 'Configurar Fechas de Vigencia',
    LNG_SET_EFFECTIVE_DATE: 'Configurar Fecha de Vigencia',
    LNG_PREVIEW: 'Vista Previa',
    TIME_UNITS_SHORT: {
      seconds: 'Seg.',
      minutes: 'Min.',
      hours: 'Horas',
      days: 'Días',
    },
    GLOBAL: {
      DATES: 'Fechas',
      brand: 'Marca',
      brands: 'Bandera',
      manufacturer: 'Proveedor',
      internalCode: 'Código Interno',
      externalCode: 'Código Externo',
      Vendor: 'Proveedor',
      Imprent: 'Imprenta',
      Region: 'Región',
      Market: 'Market',
      Format: 'Tipo de Operación Propia',
      Country: 'País',
      State: 'Provincia',
      Department: 'Localidad',
      NAME: 'Nombre',
      QUANTITY: 'Cantidad',
      City: 'Ciudad',
      ZipCode: 'Código postal',
      Subcategoría: 'Subcategoría',
      OperationType: 'Tipo de Operación Competidores',
      UnCheckedSuggestion: 'Sugerencia no verificada',
      PriceRaised: 'Precios que aumentaron',
      NoSuggestions: 'Sin sugerencias',
      NotCheckedSimulation: 'Simulación no verificada',
      SuggestionAccepted: 'Sugerencia aceptada',
      ManualPriceEdited: 'Precio editado manualmente',
      SimulationAcepted: 'Simulación aceptada',
      Regular: 'Regular',
      Competition: 'Competencia',
      Current: 'Actual',
      New: 'Nuevo',
      Endorsed: 'Endosado',
      Informed: 'Informado',
      Historic: 'Histórico',
      Future: 'Futuro',
      Checked: 'Comprobada',
      Unchecked: 'Sin revisar',
      PriceZone: 'Zona de Precios',
      Search: 'Buscar',
      Type: 'Tipo',
      WITHOUT_PERMISSIONS: 'Sin Permisos',
      PLACEHOLDER_START_DATE: 'Fecha inicio',
      PLACEHOLDER_END_DATE: 'Fecha fin',
      MAX_DIFF_DATE: 'El rango de fechas no debe ser mayor a ',
      SECOND_DATE_LOWER_THAN_FIRST: 'La fecha de inicio es mayor a la fecha de fin ',
      DATE: 'Fecha',
      PDV: 'Puntos de Venta',
      WITHOUT_CHANGES: 'Sin Cambios',
      WITHOUT_INVALID_MAIN_DATA: 'Datos Principales Inválidos',
    },
    TYPE_ACTION: {
      approved: 'Aprobar',
      informToERP: 'Publicar Decisiones',
      TOOLTIP: {
        approved: 'Aprobar Cambios',
        informToERP: 'Publicar Decisiones',
      },
    },
    NOTIFICATIONS: {
      TITLE: 'Notificaciones',
      NO_NEW_ITEMS: 'No hay notificaciones nuevas.',
      NO_OLD_ITEMS: 'No hay notificaciones sin leer.',
      MARK_AS_READ: 'Marcar como leída',
      MARK_ALL_AS_READ: 'Marcar todas como leídas',
      VIEW_LAST: 'Ver últimas ya leídas',
      CLOSE_LAST: 'Cerrar últimas ya leídas',
      NOT_ENABLED: 'Las notificaciones de su navegador se encuentran deshabilitadas.',
      ENABLE_NOTIFICATIONS: 'Debe activar las notificaciones de su navegador. Click para más info.',
      ENABLE_NOTIFICATIONS_TUTORIAL: 'Tutorial de activación',
      DOWNLOAD: 'Descargar Archivo',
      TOGO: 'Ir',
    },
    INSIGHTS: {
      TITLE: 'Insights',
      GO_TO_INSIGHTS: 'Ir a la pantalla de Insights',
    },
    GLOBAL_CONFIG: {
      TITLE: 'Configuración',
      FONT_SIZE: 'Tamaño de fuente',
      SMALL: 'Chica',
      NORMAL: 'Normal',
      BIG: 'Grande',
      THEME: 'Tema',
      LIGTH: 'Claro',
      DARK: 'Oscuro',
      DATE_FORMAT: 'Formato de fecha',
      RESET_WARNING: 'Para aplicar los cambios es necesario reiniciar la aplicación',
    },
    HELPS: {
      PRICE: {
        SOON: 'Próximamente',
        FAQS: {
          ROUTE: 'Precios',
          TITLE: 'Preguntas Frecuentes',
          QUESTION1:
            '¿Qué pasa si tengo una regla de margen y una regla de competencia para el mismo producto y en el mismo punto de venta? ¿Que va a hacer Prisma?',
          ANSWER1:
            'En Prisma, las reglas más específicas siempre tendrán prioridad por sobre las otras. Es decir, si existe una regla de margen para la subcategoría Electrodomésticos en Buenos Aires, y a su vez existe una regla de competencia para la subcategoría Electrodomésticos, para un punto de venta específico de la zona buenos Aires, entonces la regla de competencia tendrá prioridad en ese punto de venta en particular, mientras los restantes puntos de venta de Buenos Aires se regirán por la regla de margen.',
          ANSWER1_1:
            'Si existe una regla de competencia y otra de margen tienen un alcance de productos y de punto de venta igual. Entonces la regla de competencia siempre tendrá prioridad. Es decir, Prisma intentará cumplir con el posicionamiento competitivo y de no ser posible entonces se generarán sugerencias a partir de la regla de margen.',
          QUESTION2: '¿Cómo funciona la regla de margen zonal? ¿En qué casos la voy a usar?',
          QUESTION3: '¿Cual es la diferencia entre reglas de margen o markup? ¿Cuál debo usar?',
          ANSWER3:
            'El Margen de Utilidad y el Mark Up son dos maneras de observar la misma situación: cuánto me quiero ganar. Son exactamente lo mismo. Su diferencia está en la forma de obtenerlos: el Mark Up se calcula en base al costo, mientras que el Margen de Utilidad se calcula sobre el precio de venta.',
          ANSWER3_1:
            'Por ejemplo, un producto o servicio que tenga como costo final un monto de $100,00 y al que se le quiera ganar un Mark Up de un 20%, tendrá como precio final un monto de $120.00 y un Margen de Utilidad de un 16.66%.',
          ANSWER3_2: 'Mark Up = 20/Costo = 20/100 = 0.2 x 100 = 20%',
          ANSWER3_3: 'Margen de Utilidad = 20/Precio = 20/120 = 0.16 x 100 = 16.16%',
          QUESTION4: '¿Qué tipo de costo utiliza Prisma para calcular el margen?',
          ANSWER4:
            'En Prisma recomendamos trabajar con el Costo de Reposición, es decir, el precio que le tenemos que pagar al proveedor para reponer una mercadería o materia prima. Este costo puede diferir del costo contable.',
          QUESTION5:
            'Si tengo el producto "Café Chico", que es padre del producto "Café Mediano" en una regla configurada para que el producto hijo cueste un 20% más que el producto padre. ¿Puedo tener otra regla, al mismo tiempo, donde "Café Chico" sea padre de "Café Grande" y el producto hijo ("Café Grande") cueste 40% más que el Padre ("Café Chico")?',
          ANSWER5:
            'Se puede, Prisma permite crear varias reglas de parentesco de productos donde definas al mismo ítem como padre. En este caso crearás una regla de parentesco productos que el ítem Café Chico sea padre de café mediano con una diferencia de 20 % y luego crearás otra regla de parentesco donde el producto Café Chico sea padre de Café grande con una diferencia de 40%.',
          QUESTION6:
            'Creé una regla donde el producto "A" es hijo del producto  "B". "A" cambia su precio cada vez que cambia el precio "B". Quiero crear otra regla donde el producto "C" sea también padre de "A"?  Entonces "A" cambiará de precio cada vez que cambie el precio de "B" o "C".',
          ANSWER6:
            'Las reglas de parentesco de productos no pueden compartir Hijos. De esta manera si defines que un producto será hijo, solamente puede tener un solo padre.',
          QUESTION7: '¿Creé reglas de parentesco y no veo las sugerencias de precios, como hago para generarlas?',
          ANSWER7:
            'Las reglas de parentesco no generan sugerencias. Las únicas reglas que generan sugerencias de precios son Reglas de Margen y Reglas de competencia.',
          QUESTION8: 'Creé una regla de competencia / margen para un producto y Prisma no sugiere ningún precio, ¿Qué debo hacer?',
          ANSWER8:
            'Si prisma no generó ninguna sugerencia, significa que tus precios cumplen con los posicionamientos y lineamientos definidos. Por ende, no necesita generar ninguna sugerencia.',
          ANSWER8_1:
            'Para el caso de las reglas de competencia, recuerda que Prisma se alimenta de los precios de tus competidores. Debes chequear que tengas los precios de competidores actualizados.',
          QUESTION9:
            'Cuando estoy creando una regla de competencia hay puntos de venta que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER9:
            'Si no aparecen listados, es porque tienes puntos de venta que están mal etiquetados o no contiene los atributos correctos, esto lo puedes chequear desde Menú Configuración > Maestros > Puntos de Venta',
          QUESTION10:
            'Cuando estoy creando una regla de competencia hay productos que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER10:
            'Si no aparecen listados todos los productos, debes chequear que los mismos estén bien categorizados o tengan los atributos correctos, eso lo puedes chequear desde el módulo de Ítem Master.',
          QUESTION11:
            'Cuando estoy creando una regla de margen hay puntos de venta que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER11:
            'Si no aparecen listados, es porque tienes puntos de venta que están mal etiquetados o no contiene los atributos correctos, esto lo puedes chequear desde Menú Configuración > Maestros > Puntos de Venta',
          QUESTION12: 'Cuando estoy creando una regla de margen hay productos que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER12:
            'Si no aparecen listados todos los productos, debes chequear que los mismos estén bien categorizados o tengan los atributos correctos, eso lo puedes chequear desde el módulo de Ítem Master.',
          QUESTION13:
            'Cuando estoy creando una regla de parentesco hay puntos de venta que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER13:
            'Si no aparecen listados, es porque tienes puntos de venta que están mal etiquetados o no contiene los atributos correctos, esto lo puedes chequear desde Menú Configuración > Maestros > Puntos de Venta',
          QUESTION14:
            'Cuando estoy creando una regla de parentesco hay productos que no aparecen listados para seleccionarlos en el alcance.',
          ANSWER14:
            'Si no aparecen listados todos los productos, debes chequear que los mismos estén bien categorizados o tengan los atributos correctos, eso lo puedes chequear desde el módulo de Ítem Master.',
        },
      },
    },
    GENERIC_COMP: {
      SCOPE: {
        CLIPBOARD_STORE_TITLE: 'Pegar puntos de venta desde portapapeles',
        CLIPBOARD_PRODUCT_TITLE: 'Pegar productos desde portapapeles',
        STRATEGY_ATTR_TITLE: 'Selección por atributo',
        STRATEGY_ATTR_MSG: 'Se seleccionan atributos para definir el alcance de productos',
        STRATEGY_PROD_TITLE: 'Selección por producto',
        STRATEGY_PROD_MSG: 'Se deberán elegir explícitamente los productos uno a uno',
        STRATEGY_ATTR_TITLE_STORE: 'Selección por atributo',
        STRATEGY_ATTR_MSG_STORE: 'Se seleccionan atributos para definir el alcance de puntos de venta',
        STRATEGY_STORE_TITLE_STORE: 'Selección por puntos de venta',
        STRATEGY_STORE_MSG_STORE: 'Se deberán elegir explícitamente los puntos de venta uno a uno',
        TAG_SELECTOR: 'Selección de Etiquetas',
        ADVANCED_TAG_SELECTOR: '(Selección Avanzada de Etiquetas)',
        ITEM_LIST_WITH_ACTIONS: {
          TABLE: {
            ADD: 'Agregar Forecast',
            NAME: 'Nombre',
            CODE: 'Código',
            FORECAST: 'Forecast',
            MODAL: {
              TITLE: 'Asignar Forecast',
              INPUT: 'Forecast',
            },
            DELETE_FORECAST_TITLE: 'Confirmar eliminación de Forecast',
            DELETE_FORECAST_CONTENT: '¿Está seguro que desea eliminar el forecast de {{val}} {{val2}}?',
          },
        },
      },
      LOOKUP: {
        Search: 'Buscar...',
        clearAll: 'Deseleccionar todos los elementos',
        clear: 'Deseleccionar el elemento',
        selectAll: 'Seleccionar todos los elementos',
        selectAllPage: 'Seleccionar todos los elementos de la página actual',
        selectAllChilds: 'Seleccionar todos los elementos hijos',
        onlySelected: 'Ver solo elementos seleccionados',
        allItemsSelected: 'Todos los elementos seleccionados.',
        noneItemsSelected: 'Ningún elemento seleccionado.',
        selectedItemsLabel: 'Elementos Seleccionados ({{val}}):',
        Included: 'Incluido',
        Excluded: 'Excluido',
        includedAll: 'Incluir todos los elementos',
        excludedAll: 'Excluir todos los elementos',
        includedclearAll: 'Deseleccionar todos los elementos incluidos',
        excludedclearAll: 'Deseleccionar todos los elementos excluido',
        itemsNotFound: 'No se encontraron resultados',
        parent_item_active: 'Este producto es hijo en otras reglas, no puede ser usado como padre',
        parent_item_message:
          'Un producto padre no puede ser hijo en otra regla. Se muestran en gris los productos que son hijos en otras reglas',
        parent_store_active: 'Este punto de venta es hijo en otras reglas, no puede ser usado como padre',
        parent_store_message:
          'Un punto de venta padre no puede ser hijo en otra regla. Se muestran en gris los puntos de venta que son hijos en otras reglas',
      },
      CAT_SELECT_V2: {
        LEVEL2: 'Unidad de Negocios',
        LEVEL3: 'Departamento',
        LEVEL4: 'Categoría',
        LEVEL5: 'Subcategoría',
      },
      WEEKDAY_COMPONENT_DAYS: {
        '0': 'Do',
        '1': 'Lu',
        '2': 'Ma',
        '3': 'Mi',
        '4': 'Ju',
        '5': 'Vi',
        '6': 'Sá',
      },
      IMPORTERV2: {
        DEFAULT_TITLE: 'Importador General',
        SELECT_TYPE_PLACEHOLDER: 'Seleccione un Importador',
        DEFAULT_DESCRIPTION:
          'En esta pantalla podrás importar información subiendo un archivo. El formato del mismo se puede descargar haciendo click en el botón de descarga de template. Una vez subido el archivo recibirás instrucciones de cómo continuar.',
        DOWNLOAD_TEMPLATE: 'Descargar Template',
        AssortmentMarketData_alternativeDescription:
          'Una vez finalizada la importación, es necesario asignar los nuevos productos de mercado a una categoría de Prisma desde la siguiente pantalla: <br><b>Surtido > Configuración > Productos de Mercado</b>',
        TITLES: {
          FERRYES_AND_COMISIONS: 'Importador Fletes y Comisiones',
          COST_AND_TAXES: 'Importador Costos e Impuestos',
          VENDOR_COST: 'Costos por Proveedor',
          STORE_TAXES: 'Impuestos por Puntos de Venta',
          NEW_PRICES: 'Nuevos Precios',
          COMPETITOR_PRICES: 'Precios de Competidores',
          ITEMS: 'Ítems',
          IMPORT_VENDORS: 'Solucionar conflictos de Proveedores',
          IMPORT_PRICES_TITLE: 'Precios',
          MARKET_INFORMATION: 'Información de Mercado',
          MARGIN_WITH_VENDOR: 'Margen por Acuerdos',
          STORE_ASSORTMENT: 'Surtido por Punto de Venta',
          STORE_COSTS: 'Costos por Punto de Venta',
          STORE_PRICES: 'Precios por Punto de Venta',
          STORE_PRICE_OUTPUT: 'Publicación de precios',
          COMPETITORS: 'Competidores',
          STORE_PRICE_OUTPUT_PREVIEW: 'Publicación de precios - Preview',
          CustomItems: 'Productos Regionales',
          REGULATED_PRICES: 'Precios Regulados',
          VENDOR_COSTS: 'Costos por Proveedor',
          CustomItemsDelete: 'Borrado de productos regionales',
          CustomItemsUpdatePrice: 'Actualización de precios de productos regionales',
          CustomItemsUpdateCosts: 'Actualización de costos de productos regionales',
          VendorCosts: 'Costos por Proveedor',
          DailySales: 'Ventas Diarias',
          AssortmentMarketData: 'Información de Mercado',
          Categories: 'Categorías',
          Margin2: 'Margen por Acuerdos',
          MonthlyInventory: 'Inventario Mensual',
          StoreAssortment: 'Surtido de Tiendas',
          Stores: 'Tiendas',
          MonthlySales: 'Ventas Mensuales',
          ItemMatchings: 'Equivalencias de Item',
          SpacesAvailability: 'Espacios Disponibles',
          None: 'Sin selección',
          CategoriesV2: 'Categorías V2',
        },
        RESPONSE_OK: 'La importación se envió a la cola de proceso, para realizar el seguimiento de la misma haga click aquí:',
        RESPONSE_OK_TITLE: 'Importación en curso',
        TYPES: {
          ALL: 'Todos',
          Items: 'Ítems',
          RegulatedPrices: 'Precios regulados',
          PriceRegulated: 'Precios regulados',
          ActionExceptionalValue: 'Excepciones de Precio Fijo',
          ActionExceptionalMixedValue: 'Excepciones de Precio Fijo Múltiples',
          StoreCosts: 'Costos por Punto de Venta',
          StorePrices: 'Precios por Punto de Venta',
          StorePriceOutput: 'Publicación de Precios',
          CustomItems: 'Productos Regionales',
          CustomItemsDelete: 'Borrado de productos regionales',
          CustomItemsUpdatePrice: 'Precios de productos regionales',
          CustomItemsUpdateCosts: 'Costos de productos regionales',
          StorePriceOutputPreview: 'Publicación de Precios - Preview',
          ItemCodes: 'Códigos de Ítems',
          UnitsOfMeasures: 'Unidades de Medida',
          Competitors: 'Competidores',
          CompetitorPrices: 'Precios de Competidores',
          CompetitorsPrices: 'Precios de Competidores',
          SalesTickets: 'Tickets de Ventas',
          Vendors: 'Proveedores',
          VendorItems: 'Items por proveedor',
          DailyInventory: 'Inventario Diario',
          DailySales: 'Ventas Diarias',
          AssortmentOutputPreview: 'Publicación de Surtido - Preview',
          AssortmentOutput: 'Publicación de Surtido',
          VendorCosts: 'Costos por Proveedor',
          VENDOR_COSTS: 'Costos por Proveedor',
          StoreCostOutput: 'Salida de Costos',
          AssortmentMarketData: 'Información de Mercado',
          Categories: 'Categorías',
          Margin2: 'Margen por Acuerdos',
          ItemTags: 'Etiquetas de artículos',
          MonthlyInventory: 'Inventario Mensual',
          StoreAssortment: 'Surtido de Tiendas',
          Stores: 'Tiendas',
          MonthlySales: 'Ventas Mensuales',
          None: 'Sin selección',
          DailyMissionReport: 'Misiones Diarias',
          DailyMissionReport_alt: 'Reporte Diario',
          ItemMatchings: 'Equivalencias de Item',
          SpacesAvailability: 'Espacios Disponibles',
          PlanogramPopMaterialMarketplaces: 'Material Rentado',
          MarketItemToRegister: 'Productos de Mercado',
          CategoryTree: 'Árbol de Categorías',
          PromiotonPeriod: 'Períodos Promocionales',
          PromiotionPeriod: 'Períodos Promocionales',
          Promotion: ' Promociones',
          PromotionPeriod: 'Períodos Promocionales',
          CategoriesV2: 'Categorías V2',
          PromotionReport: 'Reporte de Promociones',
          PrintingList: 'Reporte de Imprenta',
          LogisticsList: 'Reporte de Logística',
          TagValues: 'Valores de etiqueta',
          BranchesYpfTiendas: 'Sucursales',
          DeliveryStoresYpfTienda: 'Lugares de entrega',
          ItemsYpfTiendas: 'Artículos',
          PlanogramStructure: 'Estructura de Planograma',
          TakeAPayBPromotion: 'Promocion lleva A paga B',
          DiscountPromotion: 'Promocion de descuento',
          FixedPricePromotion: 'Promocion de precio fijo',
          Planogram: 'Planogramas',
          PopMaterial: 'Materiales POP',
          PlanogramPopMaterial: 'Planogramas materiales',
          PlanogramStore: 'Planogramas tiendas',
          PromotionReferencePrice: 'Precios de referencia',
          PromotionSpace: 'Espacios',
          PromotionMaterial: 'Materiales',
          PromotionSpaceStore: 'Espacios tiendas',
          PromotionSpaceMaterial: 'Espacios materiales',
          PromotionMaterialStore: 'Materiales tiendas',
        },
        STATUS: {
          Pending: 'Pendiente',
          InProgress: 'En progreso',
          Succeeded: 'Exitoso',
          SucceededWithWarnings: 'Exitoso con Alertas',
          Failed: 'Con Fallos',
          Cancelled: 'Cancelado',
          NoDataProcessed: 'Sin datos procesados',
        },
        STATUS_MSG: {
          information: 'Ok',
          warning: 'Warnings',
          error: 'Error',
        },
        SOURCE: {
          WebApi: 'Web Api',
          Sftp: 'SFTP',
          ExternalPull: 'Datos Externos',
        },
      },
      INTERFACES_LOG: {
        DEFAULT_TITLE: 'Log de Interfaces',
        LIST_COL_TYPE: 'Entidad',
        ENTITY: 'Entidad',
        LIST_COL_SOURCE: 'Origen',
        LIST_COL_ID: 'Id',
        LIST_COL_CREATED: 'Creado',
        LIST_COL_STARTED: 'Comenzado',
        LIST_COL_FINISHED: 'Finalizado',
        LIST_COL_DURATION: 'Duración',
        LIST_COL_STATUS: 'Estado',
        LIST_COL_USERNAME: 'Nombre Usuario',
        LIST_COL_ORIGINAL_FILE_NAME: 'Nombre de Archivo',
        MORE_DETAIL: 'Más Detalles',
        RETRY: 'Intentar Nuevamente',
        RETRY_PERMISSION: 'No se disponen de los permisos necesarios para volver a intentar el proceso de interfaces con fallos.',
        RETRY_OK: 'La interface con fallos se está ejecutando nuevamente.',
        LIST_COL_RECORDS: 'Registros',
        LIST_COL_BATCHES: 'Lotes',
        LIST_COL_BATCHES_PROGRESS: 'Progreso',
        LIST_COL_BATCHES_NUM: 'Lote Nro.',
        LIST_COL_MESSAGES: 'Mensajes',
        LIST_COL_LINE: 'Línea',
        LIST_COL_BATCHES_COMPLETED: 'Lotes Completados',
        DATE: 'Fecha',
        IMPORTER: 'Importador',
        EXPORT_MSG: 'Exportar Mensajes',
        EXPORT_MSG_SHRT: 'Exportar',
        SIMPLE_VIEW: 'Vista Simple',
        ADVANCED_VIEW: 'Vista Avanzada',
      },
    },
    PRICE_RULES: {
      COMPETENCE: {
        POSITION_AGAINST: {
          Minimum: 'Mínimo',
          Average: 'Promedio',
          Maximum: 'Máximo',
          Mode: 'Moda',
        },
        POSITION_TREND: {
          HighestMode: 'Moda que más se repite',
          MinimumPrice: 'Precio mínimo',
          AveragePrice: 'Precio promedio',
          MaximumPrice: 'Precio máximo',
        },
        RULE_TYPE: {
          Guideline: 'Guideline',
          Margin: 'Margen',
          Competitive: 'Competencia',
          ParentItem: 'Parentesco de Productos',
          ParentZone: 'Parentesco de Puntos de Venta',
          BasePriceList: 'BasePriceList',
        },
      },
    },
    NAV: {
      HOME: {
        TITLE: 'Inicio',
        DASHBOARD: 'Dashboard',
      },
      DASHBOARD: {
        TITLE: 'Dashboard',
      },
      DATA_ENTRY_ADAPTATIONS: {
        TITLE: 'Entrada de Datos',
      },
      PRICES: {
        TITLE: 'Precios',
        SETUP: {
          TITLE: 'Configuración',
          PRICEZONES: 'Zona de Precios',
          COMPETITORS: 'Competidores',
          PRICESRULES: 'Reglas de Precios',
          BRANDS: 'Banderas',
          GUIDELINESANDSTRATEGIES: 'Lineamientos y Estrategias',
          SENSITIVITIES: 'Sensibilidades',
          ROUNDING: 'Reglas de Redondeo',
          STRATEGYANDRULES: 'Estrategias y Reglas',
        },
        DECISIONS: {
          TITLE: 'Decisiones',
          DECISIONS: 'Decisiones',
          INFORM_ERP: 'Publicar Decisiones',
          PRICE_ELASTICITY: 'Elasticidad de Precios',
          PRICE_DECISIONS: 'Decisiones de Precios',
          DECISIONSSUMMARY: 'Resumen de Decisiones',
        },
        REPORTS: {
          TITLE: 'Reportes',
          PRICE_CHANGES: 'Auditoría de Precios',
          COMPETITIVENESS_INDEX: 'Índice de competitividad',
          PRICE_REPORTS: 'Reporte de Precios',
          SUGGESTION_REPORTS: 'Reporte de Sugerencias',
          COST_REPORTS: 'Reporte de Costos',
          YPF_REPORTS: 'Reportes Ypf',
          PRICES_MAP: 'Mapa de Precios',
          PRICE_INCONSISTENCY_REPORT: 'Inconsistencias de Precios',
          DAILY_MARGIN: 'Márgenes diarios',
          COMPETITOR_PRICES: 'Precios de Competidores',
        },
        ADMIN: {
          TITLE: 'Administración',
          TAXES: {
            TITLE: 'Impuestos',
            CO2: 'Impuesto CO2',
            ICL: 'Impuesto ICL',
            IIBB: 'Impuesto IIBB',
          },
          IMPORTERS: {
            TITLE: 'Importadores',
            IMPORTPARITY: 'Import Parity',
            IMPORTFLETESCOMISIONES: 'Fletes y Comisiones',
            IMPORTVENDORCOSTS: 'Costos por Proveedor',
            IMPORTSTORETAXES: 'Impuestos por Punto de Venta',
            IMPORTSTORECOSTS: 'Costos por Punto de Venta',
            IMPORTNEWPRICES: 'Nuevos Precios',
            REGULATEDPRICES: 'Precios Regulados',
            COMPETITORSPRICES: 'Precios de Competidores',
            IMPORTCOSTSANDTAXES: 'Costos e Impuestos',
          },
        },
      },
      ADMIN: {
        TITLE: 'Administración',
        MASTER: {
          TITLE: 'Maestros',
          STORES: 'Puntos de Venta',
          STORES_V2: 'Puntos de Venta',
          SUPPLIER: 'Proveedores',
          SUPPLIER_V2: 'Proveedores ',
          TAG: 'Etiqueta',
          TAGS: 'Etiquetas',
          INSIGHTS: 'Insights',
          EQUIPMENTS: 'Tipos de Equipamiento',
          DYNAMIC_ENTITIES: 'Entidades Dinámicas',
          GENERAL_IMPORTER: 'Importador General',
          INTERFACES_LOG: 'Log de Interfaces',
        },
        SECURITY: {
          TITLE: 'Seguridad',
          USERS: 'Usuarios',
          ROLES: 'Roles',
        },
      },
      MYSTERYSHOPPER: {
        TITLE: 'Mystery Shopper',
        MISSIONS: {
          TITLE: 'Misiones',
        },
        USER_ASSIGNMENT: {
          TITLE: 'Asignación de Usuarios',
        },
      },
      ITEMMASTER: {
        TITLE: 'Ítem Master',
      },
      ASSORTMENT: {
        TITLE: 'Surtidos',
        SETUP: {
          TITLE: 'Configuración',
          CLUSTERING: 'Clusterización',
          CATEGORY_SETUP: 'Configuración de Categorías',
          TREEOFCATEGORYS: 'Árbol de Categorías',
          ROLES: 'Roles',
          STRATEGIES: 'Estrategias',
          SEGMENTASSIGNMENTS: 'Asignación de Segmentos',
          ROLESANDSTRATEGIESASSIGNMENT: 'Configuración de Categorías',
          MARKET_INFORMATION: 'Productos de Mercado',
        },
        DECISIONS: {
          TITLE: 'Decisiones',
          ASSORTMENTASIGNATION: 'Decisiones de Surtido',
          INFORM_ERP: 'Publicar Decisiones',
          SHOW_COLUMNS_TITLE: 'Seleccionar Columnas',
          DINAMIC_COLUMNS_TITLE_ORDER: 'Ordenar',
          DINAMIC_COLUMNS_SUB_TITLE_ORDER: 'Seleccione el orden dentro de cada grupo de columnas',
          MAX_COLUMNS_SELECTED: 'Cantidad máxima de columnas seleccionadas ({{val}})',
          MAX_COLUMNS_EXCEED: 'Cantidad máxima excedida, se seleccionaron los primeros {{val}} resultados',
          WITHOUT_CLUSTERS_FOR_LEVEL: 'Sin clusters asignados para este nivel de categoría',
          DINAMIC_COLUMNS_BTN_CANCEL: 'Cancelar',
          DINAMIC_COLUMNS_BTN_APPLY: 'Aplicar',
          DINAMIC_COLUMNS_TITLE: 'Columnas',
          DINAMIC_COLUMNS_SUB_TITLE: 'Seleccionar las columnas que quieras ver en la tabla',
          TAGS_IN_USE: 'Solo es posible gestionar etiquetas que se encuentren aplicadas a los productos de este cluster',
          DINAMIC_COLUMNS_GROUP: {
            defaultHeader: 'General',
            productHeader: 'Datos del Producto',
            ticketsHeader: 'Tickets',
            performanceHeader: 'Performance',
            stocksHeader: 'Stock',
            exhibitionHeader: 'Exhibición',
            marketHeader: 'Mercado',
            paretoHeader: 'Pareto',
            clasificationHeader: 'Clasificación',
            TGENERAL: 'Total General',
            TGENERAL_RECALC: 'Pareto Recalculado',
          },
          DINAMIC_COLUMNS: {
            itemCode: 'Código',
            itemName: 'Producto',
            isNew: 'Nuevo',
            status: 'Estado',
            segmento: 'Segmento',
            tag: 'Etiqueta',
            itemBrand: 'Marca',
            itemManufacturer: 'Fabricante',
            pareto: 'Pareto',
            sales: 'Ventas $',
            salesShare: 'Ventas %',
            salesRanking: 'Ventas $ Rank',
            soldUnits: 'Ventas UN',
            soldUnitsShared: '% Ventas UN',
            soldUnitsRanked: 'Ventas UN Rank',
            margin: 'Margen $',
            marginPercentage: 'Margen %',
            marginWithVendor: 'Margen con acuerdos',
            presence: 'Presencia',
            discounts: 'Descuentos $',
            price: 'Precio',
            tickets: 'Tickets',
            ticketsTotal: 'Tickets Total',
            ticketsShare: 'Tickets %',
            stockCost: 'Stock $',
            stockCostShare: '% Stock $',
            stockCostCurrent: 'Stock $ Actual',
            stockUnits: 'Stock UN',
            stockUnitsShare: '% Stock UN',
            stockUnitsCurrent: 'Stock UN Actual',
            wasteUnits: 'Merma UN',
            availability: 'Disponibilidad',
            outOfStock: 'Quiebres',
            daysInStock: 'Días Stock',
            daysInStockCurrent: 'Días Stock Actual',
            gmroi: 'GMROI',
            planograms: 'Planogramas',
            popMaterial: 'Material POP',
            facings: 'Frentes',
            linearSpace: 'Espacio Lineal (mt)',
            gmros: 'GMROS',
            marketSales: 'Ventas $ Mercado',
            marketUnits: 'Ventas UN Mercado',
            marketPrice: 'Precio Mercado',
            marketSalesShare: '% Ventas $ Mercado',
            marketSalesRanking: 'Ventas $ Mercado Rank',
            marketUnitsShare: '% Ventas UN Mercado',
            marketUnitsRanking: 'Ventas UN Mercado Rank',
            salesShareGap: 'Ventas % GAP',
            unitsShareGap: 'Ventas UN GAP',
            weightedIndex: 'IP %',
            weightedIndexPareto: 'Pareto',
            IP: 'IP %',
            UN: 'UN',
            suggestionRoleName: 'Nombre Sugerencia',
            suggestionRoleAction: 'Descripcion Sugerencia',
          },
        },
        REPORTS: {
          TITLE: 'Reportes',
          CLUSTERANALYSIS: 'Análisis de Cluster',
          REPORT_DECISIONS: 'Reporte de Decisiones',
        },
        ADMINISTRATION: {
          TITLE: 'Administración',
          IMPORTERS: {
            TITLE: 'Importadores',
            MARKET_INFORMATION: 'Información de Mercado',
            MARGIN_WITH_VENDOR: 'Margen por Acuerdos',
          },
        },
      },
      PROMOTIONS: {
        TITLE: 'Promociones',
        CAMPAIGN: {
          TITLE: 'Campañas',
        },
        ACTIONS: {
          TITLE: 'Acciones',
        },
      },
      PROMOTIONSV2: {
        TITLE: 'Promociones',
        CONFIGURATIONS: {
          TITLE: 'Configuración',
          RULES: {
            TITLE: 'Reglas de promociones',
          },
          REFERENCE_PRICES: {
            TITLE: 'Precios de referencia',
          },
          PROMOTIONAL_PROCESS: {
            TITLE: 'Procesos promocionales',
          },
          MESSAGE_STRUCTURES: {
            TITLE: 'Estructura de mensajes',
          },
        },
        CAMPAIGN: {
          TITLE: 'Campañas ',
        },
        CALENDAR: {
          TITLE: 'Calendario',
        },
        PERIODS: {
          TITLE: 'Períodos Promocionales',
        },
        PROMOTIONAL_PERIOD: {
          TITLE: 'Períodos Promocionales',
        },
        ACTIONS: {
          TITLE: 'Acciones Promocionales',
        },
        PRODUCTIONLIST: {
          TITLE: 'Lista de Producción',
        },
        PRODUCTION_REPORTS: {
          TITLE: 'Reportes operativos',
        },
        PROMOTION_OUTPUT: {
          TITLE: 'Datos para POS',
        },
        LOGISTICS_REPORT: {
          TITLE: 'Reporte de logística',
        },
        AVAILABLE_SPACES: {
          TITLE: 'Material promocional',
        },
        PROMOTION_REPORT: {
          TITLE: 'Reporte de Promociones',
        },
        MARKETPLACE: {
          TITLE: 'Marketplace',
        },
        REPORTS: {
          TITLE: 'Reportes',
        },
      },
      SPACES: {
        REPORTS_TITLE: 'Reportes',
        DECISIONS_TITLE: 'Decisiones',
        ADMIN_TITLE: 'Administración',
        MATERIAL_PROM_TITLE: 'Material Promocional',
        CONFIG_TITLE: 'Configuración',
        INCONSISTENCIES: {
          TITLE: 'Inconsistencias',
        },
        FLOORPLANS: {
          TITLE: 'Layouts',
        },
        SPACES_TEMPLATES: {
          TITLE: 'Templates',
        },
        TITLE: 'Espacios',
        TITLEV2: 'Espacios',
        PLANOGRAMS: {
          TITLE: 'Planogramas',
        },
        STRUCTURE: {
          TITLE: 'Estructuras',
        },
        GENERIC_SPACE: {
          TITLE: 'Espacio Genérico',
        },
        POPMATERIAL: {
          TITLE: 'Material POP',
        },
      },
      REPORTS: {
        TITLE: 'Reportes',
        LIST: {
          TITLE: 'Reportes Generales',
        },
      },
      SUPPLY: {
        TITLE: 'Abastecimiento',
        SETUP: {
          TITLE: 'Configuración',
          ORDER_SCHEDULE: 'Agenda de Pedidos',
          GENERAL: 'General',
          HOLIDAYS: 'Feriados',
          CAPACITIES: 'Capacidades',
        },
        DECISIONS: {
          TITLE: 'Decisiones',
          ORDER_CONTROL: 'Pedidos',
        },
        ADMINISTRATION: {
          TITLE: 'Administración',
          IMPORTERS: 'Importadores',
        },
      },
    },
    TEST_COMPONENT: {
      TEST_A: 'Frase de testing A',
      TEST_B: 'Frase de testing B',
      ANIDADA: {
        TEST_C: 'Frase de testing C',
      },
      TEST_D: 'Esta frase tiene {{val}} valor.',
      TEST_E: 'Esta frase tiene {{val}} valores, no {{val2}} ni {{val3}}.',
      TEST_F: 'Esta otra frase tiene {{val}} valor.',
      TEST_G: 'Esta frase tiene {{val}}, {{val2}} o {{val3}} valores.',
      TEST_H: 'Frase con {{val}} anidados',
      TEST_HB: 'VALORES MÚLTIPLES',
      TEST_I: 'Esta frase tiene {{val}} {{val2}}',
      TEST_I_sinfular: 'valor',
      TEST_I_plural: 'valores',
      TEST_J_ENUM: {
        '1': 'Asignado',
        '2': 'No Asignado',
      },
    },
    DATA_ENTRY_ADAPTATIONS: {
      TITLE: 'Administración de Entrada de Datos',
      NEW_ITEM_BTN: 'Nueva Entrada de Datos',
      TABLE: {
        TYPE: 'Tipo de Entrada',
        NAME: 'Nombre',
      },

      EDIT: {
        TRANSFORMATION_TYPES: {
          Constant: 'Constante',
          Negative: 'Negativo',
          ToPercentage: 'A Porcentaje',
          Trim: 'Recortar Espacios',
          TrimStart: 'Recortar al Inicio',
          TrimEnd: 'Recortar al Final',
          ToBoolean: 'A Booleano',
          ConcatHeadersValues: 'Concatenar Valores',
          SubString: 'Subcadena',
          PadLeft: 'Rellenar a Izquierda',
          PadRight: 'Rellenar a Derecha',
        },
        UPLOADED_FILE: 'Archivo Cargado',
        STANDARD_LOAD: 'Carga Estándar',
        ONLY_FIRST_FIVE: 'Solo se muestran los primeros 5 registros',
        CONFIGURE_TRANSFORMATION: 'Configurar Transformación',
        SOURCE_COLUMN: 'Columna Origen',
        TARGET_COLUMN: 'Columna Destino',
        TRANSFORMATION: 'Transformación',
        ADD_TRANSFORMATION: 'Agregar Transformación',
        VALUE: 'Valor',
        CHARACTER: 'Caracter',
        TOTAL_WIDTH: 'Ancho Total',
        PADDING_CHAR: 'Caracter de Relleno',
        INDEX: 'Índice',
        LENGTH: 'Longitud',
        VALUE_FOR_TRUE: 'Valor para True',
        TYPE_ENTRY: 'Tipo de Entrada',
        NAME_INPUT: 'Nombre',
        TAB1: 'Data General',
        NEW_DATA_ENTRY: 'Nueva Entrada de Datos',
        NO_MAPPING_SUGGESTION: 'No hay una sugerencia de columna de origen para la columna destino',
      },
      COMP: {
        FILE_UPLOAD: {
          TITLE: 'Arrastrar archivo aquí o hacer click para seleccionar',
          SIZE_ERROR: 'El archivo excede el tamaño máximo permitido',
          TYPE_ERROR: 'Tipo de archivo no permitido',
          ALL_FILES: 'Todos los archivos',
          ACCEPTED_TYPES: 'Tipos de archivo aceptados: {{val}}',
        },
      },
    },
    HOME: {
      ROUTE: 'Inicio',
      TITLE: 'Bienvenido',
      PRICING_MODULE: 'Precios',
      ASSORTMENT_MODULE: 'Surtidos',
      SMART_SUPPLY_MODULE: 'Abastecimiento',
      PROMOTIONS_MODULE: 'Promociones',
      ITEM_MASTER_MODULE: 'Ítem Master',
      SPACE_MODULE: 'Espacios',
      QUICK_ACCESS: 'Acceso Rápido',
      PRICING_MODULE_RULES: 'Precios - Reglas de Precios',
      PRICING_MODULE_DESICIONS: 'Precios - Decisiones de Precios',
      ASSORTMENT_MODULE_ROL: 'Surtidos - Roles',
      ASSORTMENT_MODULE_STRATEGIES: 'Surtidos - Estrategias',
      ASSORTMENT_MODULE_DECISIONS: 'Surtidos - Decisiones de Surtido',
      ITEM_MASTER_MODULE_ABM: 'Ítem Master - ABM',
      HELPS: 'Ayuda',
      HELP_1: '¿Cómo crear una Regla de Margen?',
      ARTICLE_BLOG_TITLE: 'Percepciones de precios, elecciones del consumidor y el Efecto Compromiso',
      ARTICLE_BLOG_TITLE2: 'Cómo extraer mayor valor de los Datos de tu cadena de retail',
      READ_MORE_BTN: 'Leer Más',
      PRISMA_PRICES: 'Prisma Precios',
      COMPETITORS_PRICES: 'Precios de Competidores',
      ARTICLE2_BLOG_TITLE: '¿Su estrategia de precios no funciona? Estas podrían ser las 4 razones',
      ARTICLE3_BLOG_TITLE: '“Canastas de Alimentos” : listas para comprar en un solo click',
      PRISMA_SPACES: 'Prisma Espacios',
      PRISMA_SPACES_SUB: 'Para minoristas que desean rentabilizar sus planogramas',
    },
    PR: {
      STP: {
        COMP: {
          LIST: {
            TITLE: 'Competidores',
            DELETE_TTIP: 'Borrar competidores seleccionados',
            DELETE: 'Eliminar',
            FILTER_TTIP: 'Filtrar',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Solo Tabla',
            VIEW_MAP: 'Solo mapa',
            VIEW_TABLE_MAP: 'Mapa y tabla',
            NAME: 'Nombre',
            CODE: 'Código',
            BRAND: 'Bandera',
            MARKER: 'Marker',
            EDIT_ROW: 'Editar',
            EDIT_ROW_DISABLED_TOOLTIP: 'Editar competidores seleccionados',
            DELETE_ROW: 'Borrar',
          },
          ROUTE: 'Precios / Configuración',
          TITLE: 'Competidores',
          NEW_COMP_BTN: 'Nuevo Competidor',
          IMPORT_BTN: 'Importar',
          IMPORT_OP1_314: 'Precios de la 314',
          IMPORT_OP2_COMP: 'Precios de Competencia',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          VIEW_TTIP: 'Vista',
          VIEW_TABLE: 'Solo Tabla',
          VIEW_MAP: 'Solo mapa',
          VIEW_TABLE_MAP: 'Mapa y tabla',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar competidores seleccionados',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          CODE: 'Código',
          BRAND: 'Bandera',
          MARKER: 'Marker',
          TYPE: 'Tipo',
          CHANNEL: 'Canal',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          PRICEZONE1: 'Zona de Precio 1',
          PRICEZONE2: 'Zona de Precio 2',
          PRICEZONE3: 'Zona de Precio 3',
          NEW: 'Nuevos',
          INCOMPLETES: 'Incompletos',
          TITLE_FILTERS: 'Filtros',
          MARKER_FILTER: 'Marker',
          OP1_ALL: 'Todos',
          OP2_MARKER: 'Marker',
          OP3_NO_MARKER: 'No Marker',
          BRAND_FILTER: 'Bandera',
          OPERATION_TYPE_FILTER: 'Tipo de Operación',
          PROVINCES_FILTER: 'Provincia',
          LOCATION_FILTER: 'Localidad',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          MODAL_314: 'Se importaron correctamente los datos de la 314',
          TITLE_FORM: 'Nuevo Competidor',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          CODE_INPUT: 'Código',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'El campo debe ser mayor a 0',
          NAME_INPUT: 'Nombre',
          BRAND_INPUT: 'Bandera',
          MARKER_CHECK: 'Marker',
          MARKER_CHECK_TTP: 'Marcar como Marker a un competidor relevante',
          ADRESS_INPUT: 'Dirección',
          MAP_DATA: 'Datos Mapa',
          LATITUDE_INPUT: 'Latitud',
          VALIDATION_LATITUDE1: 'Latitud debe ser menor o igual a 90',
          VALIDATION_LATITUDE2: 'Latitud debe ser mayor o igual a -90',
          LONGITUDE_INPUT: 'Longitud',
          VALIDATION_LONGITUDE1: 'Longitud debe ser menor o igual a 180',
          VALIDATION_LONGITUDE2: 'Longitud debe ser mayor o igual a -180',
          CLASIFICATION_TAB: 'Clasificación',
          Nuevo: 'Nuevo',
          Incompleto: 'Incompleto',
        },
        SENSITIVITIES: {
          NEW_SENSITIVITIE: 'Nueva sensibilidad',
          SENSITIVITIE: 'Sensibilidad',
          NAME: 'Nombre',
          COD: 'Código',
          SURVEYFREQUENCY: 'Frecuencia de Relevamiento',
          EXPIRATIONDAYS: 'Expiración',
          VALIDITYINDAYS: 'Días de Vigencia',
          VALIDATION_1: 'El campo debe ser mayor a 0',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          DAYSTORELIEVE: 'Días a Relevar',
          _weekday: {
            Monday: 'Lunes',
            Tuesday: 'Martes',
            Wednesday: 'Miércoles',
            Thursday: 'Jueves',
            Friday: 'Viernes',
            Saturday: 'Sábado',
            Sunday: 'Domingo',
          },
          _frecuency: {
            Daily: 'Diario',
            Weekly: 'Semanal',
            Biweekly: 'Quincenal',
            Monthly: 'Mensual',
            Biannual: 'Semestral',
            Annual: 'Anual',
            BiMonthly: 'Bimestral',
            ThreeMonth: 'Trimestral',
            Never: 'Nunca',
          },
        },
        ROUND: {
          ROUTE: 'Precios / Configuración',
          TITLE: 'Reglas de Redondeo',
          NEW_ROUNDING_BTN: 'Nuevo Redondeo',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar redondeos seleccionados',
          DELETE: 'Eliminar',
          PRICE_TYPE: 'Tipo de Precio',
          SINCE: 'Desde',
          TO: 'Hasta',
          DIRECTION: 'Dirección',
          TO_TOP_OP1: 'Hacia Arriba',
          NEAREST_OP2: 'Más Cercano',
          DOWN: 'Hacia Abajo',
          ROUNDING_TYPE: 'Tipo de Redondeo',
          WITHOUT_TERMINATIONS: 'Sin Terminaciones',
          TOLERANCE: 'Tolerancia %',
          SKUS: '# Productos',
          SKUS_TO_EXCLUDE: '# Productos a Excluir',
          USERS: '# Usuarios',
          DEFINE_ROUNDING_STP1: 'Definir Redondeo',
          PRODUCT_SCOPE_STP2: 'Alcance Productos',
          STORE_SCOPE_STP3: 'Alcance Puntos de Venta',
          STP1: 'Paso 1 - Definir Redondeo',
          TITLE_FORM_NEW: 'Nuevo Redondeo',
          TITLE_FORM_EDIT: 'Editar Redondeo',
          PRICE_TYPE_INPUT: 'Tipo de Precio Inicial',
          SINCE_INPUT: 'Desde',
          TO_INPUT: 'Hasta',
          TOLERANCE_INPUT: 'Tolerancia %',
          DIRECTION_INPUT: 'Dirección',
          ROUNDING_TYPE_INPUT: 'Tipo de Redondeo',
          ROUNDING_UNITS_OP1: 'Unidades de Redondeo',
          TERMINATIONS_OP2: 'Terminaciones',
          ROUNDING_UNITS_INPUT: 'Unidades de Redondeo',
          TERMINATIONS_INPUT: 'Terminaciones',
          NEXT_BTN: 'Siguiente',
          NEW_TERMINATION: 'Nueva Terminación',
          TERMINATION_MODAL_INPUT: 'Terminación',
          NO_TERMINATION: 'Ninguna terminación agregada',
          ADDED_TERMINATION: 'Terminación agregada',
          ADDED_TERMINATIONS: 'Terminaciones agregadas',
          ADD_BTN: 'Agregar',
          STP2: 'Paso 2 - Alcance Productos',
          ADD_ATTRIBUTE_BTN: '+',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Paso 3 - Alcance Puntos de Venta',
          SAVE_BTN: 'Guardar',
          NONE: 'Ningún',
          ALL: 'Todos',
          EXCLUDED: 'excluido',
          SEARCH: 'Buscar',
          milesimas: 'Milésimas ',
          centesimas: 'Centésimas ',
          decimas: 'Décimas ',
          unidades: 'Unidades ',
          decenas: 'Decenas ',
          centenas: 'Centenas ',
          miles: 'Miles ',
          decenas_de_miles: 'Decenas de miles',
          Nearest: 'Al más Cercano',
          Down: 'Hacia Abajo',
          Up: 'Hacia Arriba',
        },
        RULES: {
          DELETE_MODAL: {
            TITLE_ONE: 'Desea eliminar a {{val}}?',
            CONTENT:
              'Las sugerencias pendientes de aprobación serán descartadas.\\n Las sugerencias aprobadas o modificadas manualmente se mantendrán.',
            TITLE_MULTIPLE: 'Desea eliminar las reglas seleccionados ({{val}})?',
          },
          PARENT_STORE_TITLE: 'Parentesco de {{val}}',
          PARENT_ITEM_TITLE: 'Parentesco de Productos',
          COMPETENCE_TITLE: 'Competencia',
          MARGIN_TITLE: 'Margen',
          SELECT_ALL_SUBCATEGORY: 'Seleccionar todas las Subcategorías',
          SELECT_ALL_CATEGORY: 'Seleccionar todas las Categorías',
          ROUTE: 'Precios / Configuración',
          TITLE: 'Reglas de Precios',
          SUGGESTION_BTN: 'Generar Sugerencia',
          GENERATE_SUGGESTIONS_TOOLTIP: 'Para poder generar sugerencias debe seleccionar una categoría (solo una) o ítems del listado.',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED: 'Para poder generar sugerencias debe seleccionar solo una categoría',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED_MODAL_DESCRIPTION:
            'Esta puede ser aplicada en el filtro avanzado o desde esta misma ventana. Una vez aplicada esta selección el listado será filtrado con las reglas alcanzadas y podrá hacer uso del botón Generar Sugerencia (color verde) para realizar el pedido de la misma.',
          GENERATE_SUGGESTIONS_SELECTION: 'Las sugerencias serán generadas respecto de la siguiente categoría seleccionada: ',
          SUGGESTION_OP1_MARGIN: 'Margen',
          SUGGESTION_OP2_COMPETITION: 'Competencia',
          NEW_RULE_BTN: 'Nueva Regla',
          NEW_RULE_TITLE_MODAL: 'Nueva Regla',
          RULE_TYPE_INPUT: 'Tipo de Regla',
          ADDITIONAL_FILTERS: 'Filtros adicionales',
          LEGEND_COMP:
            'Las reglas de posicionamiento competitivo generan sugerencias de precios en base al cambio de un precio del mercado.',
          LEGEND_MARG: 'Las reglas de margen generan sugerencias de precios en base al cambio de costos de proveedores.',
          LEGEND_P_STORES: 'Las reglas de Parentesco de {{val}} son evaluadas cada vez que se cambia un precio de una {{val2}} padre.',
          STORES_VAL: 'Puntos de Venta',
          LEGEND_P_ITEMS: 'Las reglas de Parentesco de Productos son evaluadas cada vez que se cambia un precio de un producto padre.',
          CANCEL_BTN: 'Cancelar',
          CREATE_BTN: 'Crear',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar reglas seleccionadas',
          DELETE: 'Eliminar',
          CLEAN_FILTERS: 'Limpiar Filtros',
          SHOW_RULES: 'Elija algún tipo de criterio para mostrar las Reglas asociadas',
          NAME: 'Nombre',
          TYPE: 'Tipo',
          STATE: 'Provincia',
          COUNTRY: 'País',
          ZONE: 'Zona',
          STORES: '# Puntos de Venta',
          ITEMS: '# Productos',
          MARGIN: 'Margen %',
          IPC: 'IPC %',
          LAST_MODIFICATION: 'Últ Modf.',
          USER: 'Usuario',
          TYPE_RULES: 'Reglas de precio',
          SUGGESTIONS_GENERATED_SNACKBAR:
            'Se realizó la solicitud para generar sugerencias. Recibirá una notificación cuando el proceso haya finalizado.',
          INCLUDED_ITEMS: 'Productos Incluidos',
          ACCEPT_BTN: 'Aceptar',
          INCLUDED_STORES: 'Puntos de Venta Incluidos',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTER: 'Categoría',
          SEARCH_CATEGORIES_FILTER: 'Buscar Categorías',
          SUBCATEGORY_FILTER: 'Subcategoría',
          SEARCH_SUBCATEGORIES_FILTER: 'Buscar Subcategorías',
          RULE_TYPE_FILTER: 'Tipo de Regla',
          USER_FILTER: 'Usuario',
          SEARCH_USER_FILTER: 'Buscar Usuario',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          DEFINE_RULE_STP1: 'Definir Regla',
          PRODUCT_SCOPE_STP2: 'Alcance Productos',
          STORE_SCOPE_STP3: 'Alcance Puntos de Venta',
          STP3_STORE: 'Paso 3 - Alcance Productos',
          STP2_STORE: 'Paso 2 - Alcance Puntos de Venta',
          STP4_COMPETENCE: 'Paso 5 - Límites por Competidor',
          STP5_COMPETENCE: 'Paso 6 - Límites de Margen y Variación',
          STP5_COMPETENCE_ALT: 'Paso 6 - Límites de Margen y Variación',
          STP3_MARGIN: 'Paso 4 - Límites por Competidor',
          STP4_MARGIN: 'Paso 5 - Límites por Margen',
          STP4_MARGIN_ALT: 'Paso 4 - Límites por Margen',
          STORE_SCOPE_STP5: 'Límites por Competidor',
          STORE_SCOPE_STP6: 'Límites de Margen y Variación',
          TITLE_FORM_NEW: 'Nueva Regla de Margen o Markup',
          TITLE_FORM_EDIT: 'Ver Regla de Margen o Markup',
          STP1: 'Paso 1 - Definir Regla',
          NAME_INPUT: 'Nombre',
          QUERY: 'Descripción / Código',
          POSITION_TYPE_INPUT: 'Tipo de regla',
          INDICATE_MARGIN_INPUT: 'Indicar margen',
          VALIDATION_MARGIN: 'El campo debe ser menor a 100',
          NEXT_BTN: 'Siguiente',
          STP2: 'Paso 2 - Alcance Productos',
          ADD_ATTRIBUTE_BTN: '+',
          SEARCH: 'Buscar',
          SEE_PROD: 'Ver Productos',
          CODE: 'Código',
          BRAND: 'Marca',
          MANUFACTURER: 'Fabricante',
          PRICE: 'Precio $',
          COST: 'Costo $',
          PRICE_CHIP: 'Precio: $',
          COST_CHIP: 'Costo: $',
          REFRESH_PRODUCTS: 'Actualizar Productos',
          REFRESH_STORES: 'Actualizar Puntos de Venta',
          SEE_STORE: 'Ver Puntos de Venta',
          SELECT_PROD: 'Productos Seleccionados ',
          SELECT_STORE: 'Puntos de Venta Seleccionados ',
          FILTER_PROD: 'Productos Filtrados ',
          FILTER_STORE: 'Puntos de Venta Filtrados ',
          PRICE_TABLE: 'Precio: $',
          COST_TABLE: 'Costo: $',
          ADD_PROD: 'Agregue Productos al listado',
          CHANGE_FORM: 'Cambiar Filtros',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Paso 3 - Alcance Puntos de Venta',
          SAVE_BTN: 'Guardar',
          EXIT_BTN: 'Salir',
          SKIP_BTN: 'Omitir',
          NONE: 'Ningún',
          ALL: 'Todos',
          EXCLUDED: 'excluido',
          COMPETITORS_SCOPE_STP2: 'Alcance Competidores',
          PARENT_PRODUCT_SCOPE_STP1: 'Definir Regla',
          PARENT_PRODUCT_SCOPE_STP2: 'Alcance Productos',
          PARENT_PRODUCT_SCOPE_STP3: 'Alcance Puntos de Venta',
          PARENT_PRODUCT_SCOPE_STP4: 'Configuración de regla de parentesco de ítems',
          PARENT_PRODUCT_SCOPE_STP1_TITLE: 'Paso 1 - Definir Regla',
          PARENT_PRODUCT_SCOPE_STP2_TITLE: 'Paso 2 - Alcance Productos',
          PARENT_PRODUCT_SCOPE_STP3_TITLE: 'Paso 3 - Alcance Puntos de Venta',
          PARENT_PRODUCT_SCOPE_STP4_TITLE: 'Paso 4 - Resumen',
          PARENT_STORE_SCOPE_STP1: 'Definir Regla',
          PARENT_STORE_SCOPE_STP2: 'Alcance de Puntos de Venta',
          PARENT_STORE_SCOPE_STP3: 'Alcance de productos',
          PARENT_STORE_SCOPE_STP1_TITLE: 'Paso 1 - Definir Regla',
          PARENT_STORE_SCOPE_STP2_TITLE: 'Paso 2 - Alcance Puntos de Venta',
          PARENT_STORE_SCOPE_STP3_TITLE: 'Paso 3 - Alcance Productos',
          STORE_SCOPE_STP4: 'Alcance Puntos de Venta',
          COMPETITION_TITLE_FORM_NEW: 'Nueva Regla de Competencia',
          COMPETITION_TITLE_FORM_EDIT: 'Ver Regla de Competencia',
          POSITIONING_INPUT: 'Posicionamiento',
          PERCENT_MARGIN_OBJETIVE: '%',
          ONLY_ALERT:
            'Solo Alertar: Generará una alerta en la pantalla de decisión de precios pero no generará ninguna sugerencia de cambio.',
          DO_NOTHING: 'No hacer nada',
          IN_CASE: 'En caso de no encontrar precios de Competidores:',
          PRICE_SUGGESTION_STEP5: 'El precio sugerido debe estar:',
          SELECTION_COMPETITORS_STEP5: 'Selección de competidores para el cálculo de límites:',
          RADIO_MIN_STEP5: 'Como Mínimo un',
          RADIO_MAX_STEP5: 'Como Máximo un',
          RADIO_AVG_STEP5: 'Entre el',
          MORE: 'más',
          AND_THE: 'y el',
          COMPETITORS_STEP5: 'que los competidores a seleccionar',
          COMPETITORS_STEP5_SECONDVALUE_CHEAP: 'más barato',
          COMPETITORS_STEP5_SECONDVALUE_EXPENSIVE: 'más caro',
          COMPETITORS_AVG_STEP5: 'respecto a los competidores a seleccionar en este paso.',
          SUGESSTION_LOW_STEP5: 'Sugerir debajo del Mínimo',
          SUGESSTION_HIGH_STEP5: 'Sugerir sobre el Máximo',
          TITLE_COMPETITORS_STEP6:
            'Se pueden definir lineamientos de margen y variación específicos para esta regla. Si desea usar los lineamientos de la categoría, guarde los cambios directamente omitiendo este paso.',
          TITLE_COMPETITORS_STEP6_NOTICE: 'Si se configura este límite se anulará la configuración de límites de la categoría',
          LINEAL_MARGIN_COMPETITORS_STEP6: 'Lineamientos de Margen',
          MIN_MARGIN_INPUT_STEP6: 'Margen Min %',
          MAX_MARGIN_INPUT_STEP6: 'Margen Max %',
          MIN_MARGIN_INPUT_STEP6_ALT: 'Margen Min ',
          MAX_MARGIN_INPUT_STEP6_ALT: 'Margen Max ',
          VARIATION_MARGIN_INPUT_STEP6: 'Variación Significativa Margen $',
          MIN_SUGGESTION_MARGIN_INPUT_STEP6: 'Sugerir debajo del Mínimo',
          MAX_SUGGESTION_MARGIN_INPUT_STEP6: 'Sugerir sobre el Máximo',
          VARIATION_PRICE_COMPETITORS_STEP6: 'Variación de Precios',
          MIN_VARIATION_PRICE_INPUT_STEP6: 'Máxima Baja Porcentual',
          MAX_VARIATION_PRICE_INPUT_STEP6: 'Máxima Suba Porcentual',
          MIN_VARIATION_PRICE_INPUT_STEP6_ALT: 'Máxima Baja',
          MAX_VARIATION_PRICE_INPUT_STEP6_ALT: 'Máxima Suba',
          MIN_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Sugerir debajo del Mínimo',
          MAX_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Sugerir sobre el Máximo',
          MARGIN_OBJETIVE: 'Definir Margen Objetivo %',
          MARGIN_OBJETIVE_CATEGORY: 'Margen Objetivo por Subcategorías',
          MARGIN_OBJETIVE_CATEGORY_HELP: 'Tener presente que el Margen Objetivo % debe estar completo en Lineamientos y Estrategias.',
          POSITION_AGAINST_INPUT: 'Posicionarse Contra',
          POSITION_TREND_INPUT: 'Si hay más de una moda usar',
          SURVEY_SOURCES_INPUT: 'Fuentes de relevamiento',
          STP2_COMP: 'Paso 2 - Alcance Competidores',
          STP2_COMP_BUTTON_TITLE: 'Selecciona la manera en que desea compararse contra sus competidores.',
          MAINMARKET_TITLE: 'Competidor Principal',
          MAINMARKET_MSG: 'Buscará el precio del Competidor Principal de cada Punto de Venta.',
          USE_TRADE_AREA_TITLE: 'Trade Area',
          USE_TRADE_AREA_MSG: 'Buscará el precio de los Competidores asociados al Trade Area de cada Punto de Venta.',
          SELECT_COMPETITORS_TITLE: 'Selección de Competidores',
          SELECT_COMPETITORS_MSG: 'Buscará a los competidores que cumplan con los criterios a definir a continuación.',
          TRADE_AREA_CHECK: 'Sólo trade area',
          MARKERS_CHECK: 'Sólo seleccionar Markers',
          STP3_COMP: 'Paso 3 - Alcance Productos',
          STP4_COMP: 'Paso 4 - Alcance Puntos de Venta',
          STORE_PARENT_TITLE_FORM_NEW: 'Nueva Regla Parentesco de Puntos de Venta',
          STORE_PARENT_TITLE_FORM_EDIT: 'Ver Regla Parentesco de Puntos de Venta',
          STORE_PARENT_INPUT: 'Punto de Venta Padre',
          STORE_PARENT_TTIP:
            'Solo aparecerán en el listado de puntos de venta padres, aquellos puntos de venta que no hayan sido asignadas como puntos de venta hijas en otras reglas',
          SEARCH_PARENT: 'Buscar Padre',
          RELATIONSHIP_TYPE_INPUT: 'Posicionamiento de items hijos',
          DIFFERENCE_IN_INPUT: 'Condición',
          relationshipType_Price: 'Evaluar precio',
          relationshipType_Margin: 'Evaluar margen',
          relationshipType_DeltaPrice: 'Clonar valores de ítem padre',
          EVALUATE_PARENT_ITEM_PRICE: 'Igualar precio de item padre',
          EVALUATE_PARENT_ITEM_MARGIN: 'Evaluar margen de item padre',
          DIFFERENCE_IN_OPTION: 'Diferencia en',
          DIFFERENCE_INPUT: 'Valor',
          DIFFERENCE_INPUT_NOT_ZERO:
            'El valor debe ser distinto de 0 (Cero). En caso de querer replicar el valor del ítem padre, debe seleccionar "Igualar margen/precio de ítem padre" en el campo Condición.',
          TEXT_P_STORES: 'Las {{val}} hijas tendrán {{val2}} {{val3}} padre',
          CATEGORIES_INPUT: 'Categorías*',
          CATEGORIES_INPUT_EXC: 'Categorías Excluidas*',
          CATEGORIES_TTIP: 'Deberá al menos seleccionar una categoría, para seleccionar las subcategorías',
          SEARCH_CATEGORIES: 'Buscar Categorías',
          SUBCATEGORIES_INPUT: 'Subcategorías*',
          SUBCATEGORIES_INPUT_EXC: 'Subcategorías Excluidas*',
          SUBCATEGORIES_TTIP: 'Deberá al menos seleccionar una subcategoría por categoría seleccionada',
          SEARCH_SUBCATEGORIES: 'Buscar Subcategorías',
          SELECT_ALL: 'Seleccionar Todos',
          VALIDATION_CATEGORY: 'Debe seleccionar al menos una categoría',
          VALIDATION_SUBCATEGORY: 'Debe seleccionar al menos una opción por cada categoría seleccionada',
          GET_OUT_BTN: 'Salir',
          SAME_PRICE: 'el mismo precio que ',
          SAME_MARGIN: 'el mismo margen que ',
          HIGHER: 'Superior',
          LOWER: 'Inferior',
          UNDER: 'debajo',
          ABOVE: 'por encima',
          MAYOR: 'mayor',
          MINOR: 'menor',
          TEXT_P_STORES_TS: 'un Precio {{val}} en {{val2}} a la',
          ITEM_PARENT_TITLE_FORM_NEW: 'Nueva Regla Parentesco de Productos',
          ITEM_PARENT_TITLE_FORM_EDIT: 'Ver Regla Parentesco de Productos',
          PARENT_RULE_GENERATE_SUGGESTION_TITLE: 'Generación de Sugerencias',
          PARENT_RULE_ONSAVE_GENERATE_SUGGESTION_CONTENT: 'La regla se ejecutará inmediatamente',
          ITEM_PARENT_INPUT: 'Producto Padre',
          ITEM_PARENT_TTIP:
            ' Solo aparecerán en el listado de productos padres, aquellos productos que no hayan sido asignados como productos hijos en otras reglas',
          TEXT_P_COMPETENCE: 'Posicionarse un {{val}} ',
          TEXT_P_COMPETENCE_TS: '{{val}} {{val2}} del {{val3}} ({{val4}}) de mis competidores',
          TEXT_P_COMPETENCE_TRADEAREA: 'dentro del {{val}} de cada Punto de Venta',
          TEXT_P_COMPETENCE_MARKERS: 'principales',
          TEXT_P_COMPETENCE_COMPETITORS_SELECTED: 'seleccionados',
          TEXT_P_COMPETENCE_STP3: 'dentro de {{val}} de cada Punto de Venta',
          TEXT_P_COMPETENCE_STP4: ', para los artículos seleccionados',
          TEXT_P_COMPETENCE_STP4_ALL: ', para todos los artículos ',
          TEXT_P_ACTUAL_MARGIN: '{{val}} {{val2}}',
          TEXT_P_MARGIN: 'Posicionarse con un {{val}} {{val2}}',
          TEXT_P_MARGIN_TS_1: 'para los artículos seleccionados',
          TEXT_P_MARGIN_TS_2: 'en las regiones requeridas ',
          TEXT_P_MARGIN_POSITION: '{{val}} de {{val2}}',
          TEXT_P_MARGIN_POSITION_0: 'Margen',
          TEXT_P_MARGIN_POSITION_1: 'Margen',
          TEXT_P_MARGIN_POSITION_2: 'Mantener margen actual en %',
          TEXT_P_MARGIN_POSITION_4: 'Mantener margen actual en $',
          TEXT_P_MARGIN_POSITION_5: 'Margen objetivo por Zona',
          TEXT_P_MARGIN_POSITION_6: 'Markup',
          TEXT_P_MARGIN_POSITION_7: 'Margen de la Categoría',
          MARGIN_POSITION_TYPE_0: 'Margen en %',
          MARGIN_POSITION_TYPE_1: 'Margen en $',
          MARGIN_POSITION_TYPE_2: 'Mantener margen actual en %',
          MARGIN_POSITION_TYPE_4: 'Mantener margen actual en $',
          MARGIN_POSITION_TYPE_5: 'Margen objetivo por Zona',
          MARGIN_POSITION_TYPE_6: 'Markup en %',
          MARGIN_POSITION_TYPE_7: 'Margen de la Categoría',
          LEGEND_RULE_TITLE: 'REGLA: ',
          TEXT_P_ITEMS: 'Los Productos Hijos tendrán {{val}} {{val2}}',
          TEXT_P_ITEMS_VARIANT: 'Los Productos Hijos tendrán la misma Variación de Precio que {{val}}',
          TEXT_P_ITEMS_TS: 'un Precio {{val}} en {{val2}} a ',
          TEXT_P_ITEMS_TS_MARGEN: 'un Margen de {{val}} a ',
          TEXT_P_STORE: 'Los Puntos de Venta Hijos tendrán {{val}} {{val2}}',
          TEXT_P_STORE_VARIANT: 'Los Puntos de Venta Hijos tendrán la misma Variación de Precio que {{val}}',
          SEARCH_RESULT_CHILD_STORE: '{{val}} Puntos de Venta van a ser afectados por esta Regla.',
          TEXT_P_STORE_TS: 'un Precio {{val}} en {{val2}} a ',
          TEXT_P_STORE_TS_MARGEN: 'un Margen {{val}} en {{val2}} a ',
          MARK_AS_ZONE: 'Marcar como Zona',
          MARK_AS_ZONE_TTIP: 'Mismo precio para todas las zonas',
          STRATEGY: 'A continuación elegir la estrategia de selección de los productos hijos',
          STRATEGY_ATTR_TITLE: 'Selección por atributo (Dinámico)',
          STRATEGY_ATTR_MSG:
            'Se seleccionan atributos para definir el alcance de productos, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
          STRATEGY_STORE_ATTR_MSG: 'Se seleccionan atributos para definir el alcance de Puntos de Venta',
          STRATEGY_PROD_TITLE: 'Selección por producto',
          STRATEGY_STORE_TITLE: 'Selección por Puntos de Venta',
          STRATEGY_PROD_MSG: 'Se deberán elegir explícitamente los productos uno a uno',
          STRATEGY_STORE_MSG: 'Se deberán elegir explícitamente los puntos de venta una a una',
          STP3_PARENT_ITEM: 'Paso 3 - Alcance Puntos de Venta',
          GENERIC_MSG_ERRROR_EXECSUGGESTION: 'No se han podido generar sugerencias.',
          GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'No hay nuevas sugerencias.',
          NO_DIFF: 'Sin Diferencia',
          LEVEL_SELECT: 'Nivel de Árbol de Categorías',
          RULE_NAME: 'Nombre de la regla',
          RULE_CONDITION: 'Condición',
          RULE_CLONE_PARENT_ITEMS: 'Los ítems hijos clonarán cualquier modificación de precio o margen del ítem padre.',
          RULE_SAME_PRICE_PARENT_ITEM: 'Los ítems hijos mantendrán el mismo {{val}} que el ítem padre.',
          RULE_PRICE_POSITION: 'Los ítems hijos se posicionarán con un {{val}} de {{val2}} con respecto al ítem padre.',
          RULE_PRICE: 'precio',
          RULE_MARGIN: 'margen',
          RULE_ITEM_PARENT_SELECTED: 'Item padre seleccionado',
          RULE_ITEM_CHILD_SELECTED: 'Item hijos seleccionados',
          RULE_PRICE_UPDATE_ON_CHILD:
            'Cualquier modificación de precio sobre el ítem padre se replicará en los ítem hijos de acuerdo a lo configurado.',
          RULE_POINT_OF_SALE_SELECTED: 'Puntos de venta seleccionados',
          RULE_REMEMBER_PARENT_ITEM_INDEPENDENT:
            'Recuerde que al ser una regla de parentesco de ítems, el ítem padre es independiente al punto de venta. ',
          RULE_REMEMBER_CHECK_ASSORTMENT:
            'Recuerde revisar el surtido de los puntos de venta para verificar que los ítems hijos estén incluidos en él.',
        },
        BRAND: {
          ROUTE: 'Precios / Configuración',
          TITLE: 'Banderas',
          NEW_BRAND_BTN: 'Nueva Bandera',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          DELETE_TTIP: 'Borrar banderas seleccionadas',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          LOGO: 'Logo',
          COLOR: 'Color',
          TITLE_FORM_NEW: 'Nueva Bandera',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          NAME_INPUT: 'Nombre',
          PICK_COLOR: 'Selecciona un color',
          CLEAN_COLOR_TTIP: 'Limpiar color',
          IS_ASIGNED: 'Esta bandera se encuentra asignada a puntos de venta o competidores por lo que no puede ser eliminada',
          OWN_BRAND: 'Marca Propia',
        },
        GUI_AND_STRA: {
          ROUTE: 'Precios / Configuración',
          TITLE: 'Lineamientos y Estrategias',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORIES: 'Categorías',
          RULES: 'Reglas',
          RULES_TTIP: 'Cantidad de reglas de precios',
          TARGET_MARGIN: 'Margen Objetivo %',
          TARGET_MARGIN_TTIP: 'Margen Objetivo Porcentual',
          MIN_MARGIN: 'Mg Min %',
          MIN_MARGIN_TTIP: 'Margen Mínimo Porcentual',
          MAX_MARGIN: 'Mg Max %',
          MAX_MARGIN_TTIP: 'Margen Máximo Porcentual',
          ALLOW_MIN_VIOLATION: 'Sug. Debajo Mg Min',
          ALLOW_MIN_VIOLATION_TTIP: 'Sugerencia Debajo del Mínimo',
          ALLOW_MAX_VIOLATION: 'Sug. Sobre Mg Max',
          ALLOW_MAX_VIOLATION_TTIP: 'Sugerencia Sobre el Máximo',
          SIGNIFICANT_VAR_MARGIN: 'Var Significativa Mg $',
          SIGNIFICANT_VAR_MARGIN_TTIP: 'Variación Significativa de Margen en Pesos',
          MIN_VARIATION: 'Var Min %',
          MIN_VARIATION_TTIP: 'Variación Mínima Porcentual',
          MAX_VARIATION: 'Var Max %',
          MAX_VARIATION_TTIP: 'Variación Máxima Porcentual',
          ALLOW_MIN_VARIATION: 'Sug. Debajo Var Min',
          ALLOW_MIN_VARIATION_TTIP: 'Sugerencia Debajo del Mínimo',
          ALLOW_MAX_VARIATION: 'Sug. Sobre Var Max',
          ALLOW_MAX_VARIATION_TTIP: 'Sugerencia Sobre el Máximo',
          SIGNIFICANT_VARIATION: 'Var Significativa $',
          SIGNIFICANT_VARIATION_TTIP: 'Variación Significativa en Pesos',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          MARGIN_GUIDELINES: 'Lineamientos de Margen',
          TARGET_MARGIN_INPUT: 'Margen Objetivo %',
          MIN_MARGIN_INPUT: 'Margen Min %',
          MAX_MARGIN_INPUT: 'Margen Max %',
          SIGNIFICANT_VAR_MARGIN_INPUT: 'Variación Significativa de Margen $',
          ALLOW_MIN_VIOLATION_CHECK: 'Sugerir Debajo del Mínimo',
          ALLOW_MIN_VIOLATION_CHECK_TTIP:
            'Si este casillero está tildado, Prisma va a sugerir por debajo de los lineamientos mínimos, y mostrará un cartel de warning al lado de la sugerencia, caso contrario te llevará la sugerencia al valor mínimo ingresado.',
          ALLOW_MAX_VIOLATION_CHECK: 'Sugerir Sobre el Máximo',
          ALLOW_MAX_VIOLATION_CHECK_TTIP:
            'Si este casillero está tildado, Prisma va a sugerir por encima de los lineamientos máximos, y mostrará un cartel de warning al lado de la sugerencia, caso contrario te llevará la sugerencia al valor máximo',
          VARIATION_PRICE: 'Variación de Precios',
          MIN_MARGIN_V_INPUT: 'Máxima Baja %',
          MAX_MARGIN_V_INPUT: 'Máxima Suba %',
          SIGNIFICANT_VAR_MARGIN_V_INPUT: 'Variación Significativa de Margen $',
          ALLOW_MIN_VIOLATION_V_CHECK: 'Sugerir Debajo del Mínimo',
          ALLOW_MIN_VIOLATION_V_CHECK_TTIP:
            ' Si este casillero está tildado, Prisma va a sugerir variaciones de precios por debajo del mínimo establecido, y mostrará un cartel de warning al lado de la sugerencia, caso contrario te llevará la sugerencia al valor de la variación mínima',
          ALLOW_MAX_VIOLATION_V_CHECK: 'Sugerir Sobre el Máximo',
          ALLOW_MAX_VIOLATION_V_CHECK_TTIP:
            ' Si este casillero está tildado, Prisma va a sugerir variaciones de precios por debajo del máximo establecido, y mostrará un cartel de warning al lado de la sugerencia, caso contrario te llevará la sugerencia al valor de la variación máxima',
          TITLE_MODAL: 'Impactar cambios en los siguientes niveles del árbol de categorías',
          SUBTITLE_MODAL:
            'Desea establecer estos mismos lineamientos para los siguientes niveles del árbol de categorías, hasta nivel subcategoría inclusive?',
          REFUSE_BTN: 'Rechazar',
          ACCEPT_BTN: 'Aceptar',
        },
      },
      REPORTS: {
        ROUTE: 'Precios / Reportes',
        SUGGESTIONREPORTS: {
          ROUTE: 'Precios / Reportes',
          GENERATE: 'Generar reporte',
          TITLE: 'Reporte de Sugerencias',
          GENERATE_TITLE: 'Generar Reporte de Sugerencias',
          INPROCESS: 'Generando reporte',
          DAY: 'Día',
          INTRODUCTION: 'Genera reporte con los detalle de todas las sugerencias del día indicado',
        },
        COSTREPORTS: {
          ROUTE: 'Precios / Reportes',
          GENERATE: 'Generar reporte',
          TITLE: 'Reporte de Costos',
          GENERATE_TITLE: 'Generar Reporte de Costos',
          INPROCESS: 'Generando reporte',
          DAY: 'Día',
          INTRODUCTION:
            'Genera reporte con los detalle de todas los costos filtrados. Desde aquí se podrá seleccionar un rango de fechas no mayor a 30 días para la descarga de costos.',
        },
        YPF: {
          ROUTE: 'Precios / Reportes',
          TITLE: 'Reportes YPF',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          COMPETITIVE_POSITION: 'Posicionamiento Competitivo',
          EXPORT_TTIP: 'Exportar',
          APP_TTIP: 'Compartir a la App',
          DASHBOARD_TTIP: 'Ir al dashboard',
          COMPETITOR: 'Competidor',
          N2: 'N2',
          N3: 'N3',
          G2: 'G2',
          G3: 'G3',
          CHEAPER: 'Más Barato',
          EQUEAL_PRICE: 'Igual Precio',
          MORE_EXPENSIVE: 'Más Caros',
          PROVINCE_FILTER: 'Provincia',
          FILTER: 'Filtro',
          LOCATION_FILTER: 'Localidad',
          BRANDS_FILTER: 'Banderas',
          APPLY_BTN: 'APLICAR FILTROS',
          COMPETITION_PRICES: 'Precios de la Competencia',
          NS: 'NS',
          INF: 'INF',
          UD: 'UD',
          D500: 'D500',
          INF_D: 'INF D',
          INF_NS: 'INF vs NS',
          INFD_D500: 'INF D vs D500',
          RETAIL_MARGIN: 'Márgenes Retail',
          PRODUCTS_FILTER: 'Productos',
        },
        DAILYMARGIN: {
          LIST: {
            TITLE: 'Reportes Márgenes Diarios',
            ROUTE: '/prices/reports/daily-margin',
            DELETE: 'Eliminar',
            DELETE_TTIP: 'Eliminar',
            FILTER_TTIP: 'Filtrar',
            EXPORT: 'Descargar',
            EXPORT_TTIP: 'Exportar reporte en formato excel',
            STORECODE: 'Código de punto de venta',
            STORENAME: 'Punto de venta',
            ITEMCODE: 'Código de producto',
            ITEMNAME: 'Producto',
            PRICE: 'Precio',
            PRICEWOTAXES: 'Precio sin impuestos',
            COST: 'Costo',
            MARGIN: 'Margen',
            MAXMARGINAMOUNT: 'Máximo margen',
            COMPETITOR: 'Competidor',
            COMPETITORSPRICE: 'Precio del competidor',
            MARKETAVERAGEPRICE: 'Precio Promedio',
            CURRENTDATE: 'Fecha actual',
            MARGINVSMAXMARGIN: 'Margen vs Margen Máximo',
            COMPETITIVEINDEX: 'Competitividad',
            MARKETCOMPETITIVEINDEX: 'Competitividad de mercado',
          },
        },
        PRICE_INCONSISTENCY: {
          ROUTE: 'Precios / Reportes',
          TITLE: 'Inconsistencias de Precios',
          CODE: 'Código',
          NAME: 'Nombre',
          CURRENT_PRICE: 'Precio Actual',
          MAXIMUM_PRICE: 'Precio Máximo',
          STORE: 'Punto de Venta',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTER: 'Categoría',
          SEARCH_CATEGORY_FILTER: 'Buscar Categorías',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
        },
        PRICES_MAP: {
          ROUTE: 'Precios / Reportes',
          TITLE: 'Mapa de Precios',
          CHOOSE_PRODUCT: 'Elija algún producto para poder mostrar el mapa',
          CHOOSE_PRODUCT_AND_ZONE: 'Elija algún producto y zona para poder mostrar el mapa',
          FILTER_TTIP: 'Filtrar',
          PRODUCT_FILTER: 'Producto',
          MARKER_FILTER: 'Marker',
          OP1_ALL: 'Todos',
          OP2_MARKER: 'Marker',
          OP3_NO_MARKER: 'No Maker',
          NAME: 'Nombre',
          CODE: 'Código',
          PRICE: 'Precio',
          BRAND: 'Bandera',
          MARKER: 'Marker',
          Nuevo: 'Nuevo',
          SOURCE: 'Fuente',
          SOURCE_TTIP: 'Fuente de relevamiento',
          VALIDFROM: 'Fecha de Relev.',
          Incompleto: 'Incompleto',
          OWN_BRAND: 'Marca Propia',
          CHART_TITLE: 'Histórico de Precios',
          CHART_TITLE_PRICE: 'Precio',
          CHART_TITLE_VENTAS: 'Ventas',
          REFERENCE_STORE: 'PDV de Referencia',
        },
        COMPETITOR_PRICES: {
          ROUTE: 'Precios / Reportes',
          TITLE: 'Precios de Competidores',
          DOWLOAD_REPORT: 'Descargar Reporte',
          SINCE_INPUT: 'Desde',
          TO_INPUT: 'Hasta',
        },
        PRICES_AUDIT: {
          PRICE_CHANGES: 'Auditoría de Precios',
          PROD_FILTERS: 'Filtros Producto',
          FUELS: 'Combustibles',
          BUSINESS_UNIT: 'Unidad de Negocios',
          DEP: 'Departamento',
          CAT: 'Categoría',
          SUBCAT: 'Subcategoría',
          PROD: 'Producto',
          POS_FILTERS: 'Filtros PDV',
          REGION: 'Región',
          OPERATION_TYPE_FILTER: 'Tipo de Operación',
          STORE_TYPE_INPUT: 'Punto de Venta',
          DATE_FILTER: 'Filtros Fecha',
          INITIAL_DATE: 'Fecha Desde',
          END_DATE: 'Fecha Hasta',
          USER: 'Usuario',
          REPORT: 'Generar Reporte',
          REPORT_UPDATE: 'Actualizar Reporte',
          CODE: 'Código',
          STORES: 'Puntos de Venta',
          NAME: 'Nombre',
          PREVIUS_PRICE_INPUT: '$ Precio Anterior',
          MARGIN_PRICE_INPUT: '% Precio Anterior',
          NEW_PRICE_INPUT: '$ Precio Nuevo',
          AUDIT_PRICE_CHANGE: 'Cambio de Precio',
          MARGIN_NEW: '% Margin Nuevo',
          INFORM: 'Informado el',
          INFORM_BY: 'Informado by',
          SUBTITLE: 'Precios / Reportes',
        },
        PRICE_REPORT: {
          TITLE: 'Pricing/Reports',
          WeightedIndex: 'Índice P',
          SalesUnits: 'Ventas U',
          Sales: 'Ventas $',
          Margin: 'Margen $',
          PercentageMargin: 'Margen %',
          Gmroi: 'GMROI',
          COMPETITIVENESS_INDEX: 'Índice de competitividad',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Mes Anterior',
          SUGGESTIONS_ACCEPTED: '% Sugerencias Aceptadas',
          MarginDif: 'Dif. Margen vs Mes Anterior',
          PercentageMarginDif: 'P. Porcentuales vs Mes Anterior',
          DEC_TYPE: 'Tipos de Decisiones',
          INDEX_COMP: 'Índice de Competitividad (IC%)',
          CATEGORY: 'Categoría',
          CHEAPER: 'Más Barato',
          SAME: 'Iguales',
          MORE_EXPENSIVE: 'Más Caros',
          PRICES_TYPES: 'Tipos de Precios',
          PERIOD: 'Periodo',
          CATEGORYS: 'Categorías',
          EVOL_GRAPH: 'Gráfico Evolutivo',
          VARIABLE: 'Variable',
          VARIABLES: 'Variables',
          MAP: 'Mapa',
          PRODUCT_PERFORMANCE: 'Rendimiento de Productos',
          PRODUCT: 'Producto',
          VOLUME: 'Volumen',
        },
      },
      DECISIONS: {
        SUMMARY: {
          ROUTE: 'Precios / Decisiones',
          TITLE: 'Resumen de Decisiones',
          REVISE_BTN: 'Revisar todo',
          UPDATE_STATISTICS: 'Actualizar Estadísticas',
          LASTUPDATEDON: 'Última actualización:',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          REVIEW_PENDING: 'Sin Revisar',
          CATEGORY: 'Categoría',
          REVIEW_PENDING_TTIP: 'Sugerencias Pendientes de Revisión',
          DECISIONS: 'Revisadas',
          DECISIONS_TTIP: 'Decisiones Tomadas',
          ADVANCED: 'Avance %',
          ADVANCED_TTIP: 'Tiene en cuenta solo las sugerencias',
          SEND: 'Enviar',
          SUGGESTED_IMPACT: 'Imp. Sug. $',
          SUGGESTED_IMPACT_TTIP: 'Impacto Sugerido',
          DECISIONS_IMPACT: 'Imp. Decis. $',
          DECISIONS_IMPACT_TTIP: 'Impacto Decisiones',
          REVIEW_IMPACT: 'Imp. Rev. $',
          REVIEW_IMPACT_TTIP: 'Imp. Revisión. $',
          PENDING_APPROVAL: 'Sin Aprobar',
          PENDING_APPROVAL_TTIP: 'Decisiones Pendientes de Aprobación',
          APPROVED: 'Aprobadas',
          APPROVED_TTIP: 'Decisiones Aprobadas',
          TITLE_FILTERS: 'Filtros',
          PROVINCES_FILTER: 'Provincia',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          REJECTED_TTIP: 'Rechazados',
          REJECTED: 'Rechazadas',
          APPROVE: 'Aprobar',
          APPROVE_ALL: 'Aprobar Todo',
          REVIEW: 'Revisar',
          CONFIRM_SEND: 'Confirma el envío?',
          CONFIRM_SEND_ALL: 'Confirma el envío de todos los ítems?',
          CONFIRM_APPROVE: 'Confirma la aprobación?',
          CONFIRM_APPROVE_ALL: 'Confirma la aprobación de todos los ítems?',
          CONFIRM_REVIEW: 'Confirma la revisión de todos los ítems?',
        },
        ERP: {
          ROUTE: 'Precios / Decisiones',
          TITLE: 'Publicar Decisiones',
          DOWLOAD_BTN: 'Descargar Info',
          PREVIEW_BTN: 'Vista Previa',
          INFORM_BTN: 'Publicar',
          LAST_PRICES: 'Últimos Precios Publicados',
          GENERATION_DATE: 'Fecha de Generación',
          GENERATION_TIME: 'Hora de Generación',
          USER: 'Usuario',
          FILE: 'Archivo',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          PREVIEW_PROCCESING: 'El proceso ha comenzado. Recibirás una notificación cuando finalice.',
          PREVIEW_WITHOUT_PROCCESING: 'No hay nuevos datos para procesar.',
        },
        PRICE_DECISIONS: {
          NO_GROUPID: 'Elija una categoría para mostrar el listado',
          APPLY_FILTERS: 'Filtros aplicables',
          STRATEGY_DIALOG: {
            UNIT: 'Unidad',
            Price: '$',
            Margin: '%',
            UsdxM3: 'USD * M3',
          },
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          SUGGESTED_IMPACT_TITLE: 'Impacto Sugerido $',
          CHANGES_TITLE: '# Cambios',
          VS_SUGGESTED_IMPACT_TITLE: 'Vs Impacto Sugerido $',
          CHANGES_PRICE_TITLE: 'Cambio de Precios %',
          NEW_IMPACT_TITLE: 'Impacto Nuevo $',
          SUGGESTIONS_ACCEPTED_DESCRIPTION: 'Sugerencias Aceptadas',
          WEIGHTED_SALE_DESCRIPTION: 'Ponderado por Venta',
          PERCENT_CHANGE: 'Cambio %',
          FILTER_CHIP: 'Filtrar precios por',
          CODE_ITEM: 'Código de producto',
          CODE_STORE: 'Código de punto de venta',
          PARENT_RULE: 'Regla de parentesco',
          FILTER_CHIP_PARENT: 'Filtrar precios por la regla de parentesco',
          FILTER_CHIP_PARENT_ITEM: 'Producto Padre:',
          FILTER_CHIP_PARENT_STORE: 'Punto de Venta Padre:',
          TABLE_TITLE: 'Análisis de la Sugerencia',
          INIT_PRICE_IMPORT_TITLE: 'Precio inicializado por importación',
          OBSERVATIONS: 'Observaciones',
          REASON: 'Razón',
          STEP_BY_STEP: 'Paso a paso',
          SEE_MORE_TABLE: 'Ver Más...',
          PRICE_TABLE: 'Precio',
          MARGIN_TABLE: 'Margen',
          CI_TABLE: 'Costos más Impuestos',
          ORIGINAL_TABLE: 'Original $',
          ACTUAL_TABLE: 'Actual $',
          SUGGESTED_TABLE: 'Sugerido $',
          DECISION_TABLE: 'Decisión $',
          ROUTE: 'Precios / Decisiones',
          TITLE: 'Decisiones de Precios',
          FILTER_TTIP: 'Filtrar',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          KEEP_PRICES: 'Ignorar Sugerencias',
          KEEP_PRICES_TTIP: 'Ignorar Sugerencias de los precios actuales seleccionados',
          ACCEPT_SUGGESTIONS: 'Aceptar Sugerencias',
          ACCEPT_SUGGESTIONS_TTIP: 'Aceptar sugerencias seleccionadas',
          ENDORSE: 'Aprobar Cambios',
          ENDORSE_TTIP: 'Aprobar los cambios seleccionados',
          REJECT_ENDORSE: 'Rechazar Cambios',
          REJECT_ENDORSE_TTIP: 'Rechazar los cambios seleccionados',
          REVERT_PRICE: 'Revertir Precios',
          REVERT_PRICE_TTIP: 'Revertir los precios seleccionados',
          MARK_REVIEWED: 'Solicitar Aprobación',
          MARK_REVIEWED_TTIP: 'Solicitar Aprobación de las decisiones seleccionadas',
          EDIT_PRICES: 'Modificar Precios',
          EDIT_PRICES_TTIP: 'Modificar precios manualmente',
          EFFECTIVE_DATE: 'Fechas de Vigencia',
          EFFECTIVE_DATE_TTIP: 'Configurar Fechas de Vigencia para productos seleccionados',
          CLEAN_FILTERS: 'Limpiar Filtros',
          PRODUCT: 'Producto',
          PRICE_TYPE: 'Tipo de Precio',
          CURRENT_PRICE_TTIP: 'Precio Actual',
          VALID_PRICE_TTIP: 'Precio Vigente',
          NOT_VALID_PRICE_TTIP: 'Precio No Vigente',
          SUGGESTION_PRICE_TTIP: 'Precio Sugerido',
          CHECK_TTIP: 'Revisada',
          PRICE: 'Precio Actual $',
          COMPETITION_PRICE: 'Precio de Competencia $',
          SUGGESTION: 'Precio Sugerido $',
          DECISIONS: 'Decisión $',
          IMPACT: 'Impacto $',
          NEWVALIDATEDATE: 'Fecha de Vigencia',
          ACCEPT_SUGGESTION: 'Aceptar sugerencia',
          KEEP_PRICE: 'Mantener el precio actual',
          EDIT_MANUALLY: 'Cambiar manualmente',
          TEXT_SNACKBAR: 'Se modificaron {{val}} registros',
          TEXT_SNACKBAR_MAX_BULK_LIMIT: 'No es posible realizar acciones masivas para más de {{val}} ítems.',
          EFFECTIVE_DATE_MODAL: 'Fecha de vigencia',
          EFFECTIVE_DATE_INPUT: 'Fecha de inicio de vigencia',
          TO_DATE_INPUT: 'Fecha de finalización de vigencia',
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceptar',
          TEXT_SNACKBAR2: 'Se modificaron {{val}} registros',
          TITLE_FILTERS: 'Filtros',
          PROVINCE_FILTERS: 'Provincia',
          CITY_FILTERS: 'Ciudad',
          PRICE_ZONE1_FILTERS: 'Zona de precios 1',
          PRICE_ZONE2_FILTERS: 'Zona de precios 2',
          PRICE_ZONE3_FILTERS: 'Zona de precios 3',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          EDIT_PRICE_MODAL: 'Modificar precios',
          STRATEGY_INPUT: 'Estrategia',
          ROUNDING_CHECK: 'Utilizar redondeos cargados',
          VALUE_INPUT: 'Valor',
          TAB1: 'Decisión',
          TAB2: 'Competencia',
          TAB3: 'Costos y Márgenes',
          TAB4: 'Auditoría',
          TAB5: 'Relación de puntos de venta',
          TAB6: 'Receta',
          TAB7: 'Producto en Combo',
          TAB8: 'Combo',
          TAB_ZONES: 'Relación de puntos de venta',
          TAB_ZONES_TITLE: 'Relación de puntos de venta',
          CODE_INPUT: 'Código',
          UM_BASE_INPUT: 'UM Base',
          BRAND_INPUT: 'Bandera',
          NAME_INPUT: 'Nombre',
          WITHOUT_SUGGESTION: 'Sin Sugerencia',
          REVIEW_RULE_BTN: 'Revisar Regla',
          DISABLED_BY_CHILD:
            'No se pueden realizar acciones. Este producto dependen de las decisiones tomadas en el padre de la regla de parentesco',
          SAME_PRICE_WARNING: 'El ítem seleccionado ya tiene como precio: $',
          SUGGESTION_ACCEPTED_BTN: 'Sugerencia Aceptada',
          SUGGESTION_ACCEPTED_OP1: 'Mantener el Precio Original',
          SUGGESTION_ACCEPTED_OP2: 'Cambiar manualmente',
          SUGGESTION_WITHOUT_REVIEW: 'Sugerencia sin Revisar',
          SUGGESTION_ACCEPTED: 'Sugerencia Aceptada',
          SUGGESTION_IGNORED: 'Sugerencia Ignorada',
          SUGGESTION_ACCEPT: 'Aceptar Sugerencia',
          SUGGESTION_IGNORE: 'Ignorar Sugerencia',
          SUGGESTION_REVIEWED: 'Revisado',
          SUGGESTION_ENDOECE: 'Aprobar Cambios',
          SUGGESTION_ENDOECE_REJECTED: 'Rechazar Cambios',
          SUGGESTION_REJECTED: 'Cambios rechazados',
          SUGGESTION_ALREADY_ENDOECE: 'Aprobado',
          SUGGESTION_CHANGE_MANUALY: 'Cambiar manualmente',
          SUGGESTION_CHANGED_MANUALY: 'Cambiado manualmente',
          SUGGESTION_MARK_AS_REVIEW: 'Solicitar Aprobación',
          REVERT_NEW_PRICE: 'Revertir Precio',
          NO_DECISIONS_BTN: 'Sin Decisiones',
          NO_DECISIONS_OP1: 'Confirmar el precio actual',
          NO_DECISIONS_OP2: 'Cambiar manualmente',
          EDIT_MANUALLY_BTN: 'Editado Manualmente',
          EDIT_MANUALLY_OP1: 'Cambiar manualmente',
          EDIT_MANUALLY_OP2: 'Mantener el Actual',
          ORIGINAL_PRICE_INPUT: 'Precio Original',
          PREVIUS_PRICE_INPUT: 'Precio Anterior',
          COST_INPUT: 'Costo',
          MARGIN_INPUT: 'Margen $',
          PERCENTAGE_MARGIN_INPUT: 'Margen %',
          LEGEND_COMP_MAP: 'Filtre para poder ver el mapa e indicadores con los competidores de la manera en que lo desee',
          COMPETITOR_INPUT: 'Competidor',
          SOURCE_INPUT: 'Fuente',
          MARKERS_CHECK: 'Solo Markers',
          MARKERS_TTIP: 'Solo Competidores Relevantes',
          BRAND: 'Bandera',
          CODE_NAME: 'Cód | Nombre',
          TYPE: 'Tipo',
          SURVEY_DATE: 'Fe.',
          SURVEY: 'Fuente',
          COMPETENCE_TAB_KPIS_TITLE_1: 'Precio Comp. Actual',
          COMPETENCE_TAB_KPIS_TITLE_2: 'IC% Actual',
          COMPETENCE_TAB_KPIS_TITLE_3: 'IC% Sugerido',
          COMPETENCE_TAB_KPIS_TITLE_4: 'Precio Sugerido',
          COMPETENCE_TAB_KPIS_DESC_3: 'Vs. IC Actual',
          COMPETENCE_TAB_KPIS_DESC_4: 'Vs. Precio Actual',
          COMPETENCE_KPIS_TITLE_TRADE: 'Trade Area',
          COMPETENCE_KPIS_TITLE_COMPETITOR: 'Competidor principal',
          COMPETENCE_KPIS_TITLE_RULE: 'Regla',
          TITLE_MAP_SECTION: 'Mapa de la competencia',
          TITLE_TABLE: 'Trade Area',
          TITLE_KPIS: 'Análisis de la Competencia',
          NEW: 'Estado',
          NEW_CHIP: 'NUEVO',
          INVALID_CHIP: 'Inválido',
          OLD_CHIP: 'Viejo',
          SUGGESTION_COLUMN: 'Sugerido',
          MARK: 'Marker',
          OWNER: 'Competidor Principal',
          PVC: 'PVP',
          MARGIN: 'Margen',
          MARGIN_USD: 'Margen USDxM3',
          PERCENTAGE_MARGIN: 'Margen %',
          COST_TAXES: 'Costos e Impuestos',
          CURRENT: 'Actual',
          PREVIUS: 'Anterior',
          VAR: 'Variación %',
          LAST_VAR: 'Últ. Var.',
          EVENT: 'Evento',
          DATE: 'Fecha',
          PREVIUS_PRICE: 'Precio anterior $',
          DECISIONS_PRICE: 'Decisión de precio $',
          COST: 'Costo $',
          USER: 'Usuario',
          CHILDREN_STORE: 'Punto de Venta Hijo',
          CODE: 'Código',
          RECIPE: 'Receta',
          NAME: 'Nombre',
          CURRENT_PRICE: 'Actual $',
          SUGGESTION_RECIPE: 'Sugerencia $',
          DECISION: 'Decisión $',
          SEE_RECIPE_BTN: 'Ver Receta',
          INGREDIENTS: 'Ingredientes',
          ORIGIN_COST: 'Costo Original $',
          QUANTITY: 'Cantidad',
          CURRENT_COST: 'Costo Actual $',
          COMBO_PRODUCT: 'Producto de Combo',
          COMBOS: 'Combos',
          PARTICIPATION: 'Participación %',
          SEE_COMBO_BTN: 'Ver Combo',
          PARTICIPATION_TTIP: 'Participación de ventas del producto en este combo.',
          LEGEND_COMBO: 'Los siguientes Combos pueden estar afectados de manera directa o indirecta por la decisión de precios tomada.',
          COMBO: 'Combo',
          SUGGESTION_COMBO: 'Sugerencia $',
          COMBOS_COMPONENTS: 'Componentes del Combo',
          BLOCK: 'Bloque',
          TOOLTIP_PARENT: 'Producto Padre',
          TOOLTIP_PARENT_OPEN: 'ver Productos hijos',
          TOOLTIP_PARENT_OPENED: 'cerrar Productos hijos',
          TOOLTIP_CHILD: 'Producto Hijo',
          TOOLTIP_PRICE_VARIATION: 'Los productos hijos tendrán la misma Variación de precio que el producto padre',
          TOOLTIP_SUPERIOR_PRICE: 'Los Productos Hijos tendrán un Precio Superior en {{val}} que el producto padre',
          TOOLTIP_INFERIOR_PRICE: 'Los Productos Hijos tendrán un Precio Inferior en {{val}} que el producto padre',
          TOOLTIP_SAME_PRICE: 'Los Productos Hijos tendrán el mismo Precio que el producto padre',
          TOOLTIP_SUPERIOR_MARGIN: 'Los Productos Hijos tendrán un Margen Superior en {{val}} que el producto padre',
          TOOLTIP_INFERIOR_MARGIN: 'Los Productos Hijos tendrán un Margen Inferior en {{val}} que el producto padre',
          TOOLTIP_SAME_MARGIN: 'Los Productos Hijos tendrán el mismo Margen que el producto padre',
          TOOLTIP_COMBO: 'Combo',
          TOOLTIP_RECIPE: 'Receta',
          TOOLTIP_STORE: 'Parentesco de Puntos de Venta',
          TOOLTIP_COMBO_ITEM: 'Participa de un Combo',
          TOOLTIP_MAX_PRICE: 'El precio se encuentra por encima del precio regulado',
          TOOLTIP_IS_PARENT: 'Item padre en regla de parentesco. La variación de su precio afecta a sus ítems hijos.',
          RULE_TYPE_TITLE: 'Tipo de Regla',
          COMBO_ANALYST: 'Analista',
          COMBO_DECISIONTYPE: 'Tipo de decisión',
          COMBO_APPROVER: 'Aprobador',
          COMBO_SITUATION: 'Situación',
          COMBO_RULETYPE: 'Tipo de Regla',
          AUDIT_PRICE_CHANGE: 'Cambio de Precio',
          SHOW_KPIS: 'Mostrar KPIs',
          HIDE_KPIS: 'Ocultar KPIs',
          RULE_TYPE: {
            Guideline: 'Lineamiento',
            Margin: 'Margen',
            Competitive: 'Competencia',
            ParentItem: 'Parentesco de Productos',
            ParentZone: 'Parentesco de Puntos de Venta',
            BasePriceList: 'BasePriceList',
          },
          ADVANCED_FILTER: {
            NONE: 'Todos',
            SEE_CHILDS_MSG:
              'Al habilitar esta opción podrá buscar y ver los productos que son hijos de otros productos respecto de la sugerencia de precios realizada en las reglas de parentesco de puntos de venta y/o productos',
            SEE_CHILDS: 'Ver Productos hijos',
            SEE_FIXED_PRICES_MSG:
              'Al habilitar esta opción podrá buscar y ver únicamente los productos que tengan precios fijos. No se pueden tomar decisiones de sugerencias sobre ellos.',
            SEE_FIXED_PRICES: 'Ver Precios Fijos',
            TITLE_FILTERS: 'Filtros',
            STATE_FILTERS: 'Estado',
            ACTION_TYPE_FILTERS: 'Situación',
            PRICE_TYPE_FILTERS: 'Tipo de Precio',
            TYPE_FILTERS: 'Tipo de Producto',
            CATEGORY_INPUT: 'Categoría',
            SEARCH_CATEGORY: 'Buscar Categorías...',
            SUBCATEGORIES_INPUT: 'Subcategorías',
            SEARCH_SUBCATEGORIES: 'Buscar Subcategorías',
            TitleStoreTags: 'Filtros para Puntos de Venta',
            TitleItemTags: 'Filtros para Productos',
            UnCheckedSuggestion: 'Sugerencia sin revisar',
            PriceRaised: 'Suba de precios',
            NoSuggestions: 'Sin sugerencias',
            NotCheckedSimulation: 'Simulación sin revisar',
            SuggestionAccepted: 'Sugerencia aceptada',
            ManualPriceEdited: 'Editados manualmente',
            SimulationAcepted: 'Simulación aceptada',
            AboutToExpire: 'Precios por vencer',
            OldPrices: 'Precios Antiguos',
            Regular: 'Regular',
            Competition: 'Competencia',
            Current: 'Vigente',
            Todos: 'Todos',
            Checked: 'Revisados',
            Unchecked: 'Sin revisar',
            ApprovalPending: 'Aprobación solicitada',
            'ApprovalPending-Aproval': 'Sin Aprobar',
            Rejected: 'Rechazados',
            Approved: 'Aprobados',
            Error: 'Error en cálculo de sugerencia',
            SuggestionIgnored: 'Sugerencias ignoradas',
            PriceDrop: 'Baja de precios',
            SharpPriceDrop: 'Fuertes Bajas',
            SharpPriceRaised: 'Fuertes Subas',
            DepressedMargins: 'Márgenes Deprimidos',
            HighMargins: 'Márgenes Altos',
            Margin: 'Margen',
            Competitive: 'Competencia',
            AUDIT_PRICE_CHANGE: 'Cambio de precio',
          },
          TAB: {
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED_FORCED:
              'El precio de {{val}} se cambió a {{val3}} porque se aplicaron los límites de Precios Máximos Regulados.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_FORCED:
              'El precio de {{val}} se encuentra dentro de los límites de precios máximos regulados {{val3}}',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_HIGHER_NOTFORCED:
              'El precio de {{val}} supera los límites de Precios Máximos Regulados {{val3}} pero no se hicieron correcciones.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_MINOR_NOTFORCED:
              'El precio de {{val}} se encuentra dentro los límites de Precios Máximos Regulados {{val3}} no se hicieron correcciones.',
            STEP_LINK_COMPETITIVE_RULE: 'Regla de competencia ',
            STEP_TEXT_COMPETENCE_RULE_below: 'por debajo del promedio ',
            STEP_TEXT_COMPETENCE_RULE_above: 'por arriba del promedio ',
            STEP_TEXT_COMPETENCE_RULE_equal: 'igual al promedio ',
            STEP_TEXT_COMPETENCE_RULE_PRE: 'Se aplicó una ',
            STEP_TEXT_COMPETENCE_RULE_POST: 'para posicionar el precio un {{val2}}{{val}} {{val4}}, quedando en {{val3}}',
            STEP_TEXT_COMPETENCE_RULE: 'Se aplicó {{val}}: "{{val2}}" y generó un precio sugerido de {{val3}}',
            STEP_TEXT_MARGIN_RULE: 'Se aplicó {{val}}: "{{val2}}" y generó un precio sugerido de {{val3}}',
            STEP_TEXT_COMPETENCE_RULE_NOTAPPLIED: 'No se aplicó ninguna regla de precio',
            STEP_TEXT_MARGIN_RULE_NOTAPPLIED: 'No se aplicó ninguna regla de precio',
            STEP_TEXT_MARGIN_SUGGESTION_TRIGGER:
              'PRISMA detectó que tu precio actual de {{val}} no cumple con el margen objetivo de {{val2}}',
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER:
              'PRISMA detectó que tu precio actual de {{val}} no cumple con tu posicionamiento competitivo definido en: {{val2}} considerando el precio de referencia de mercado: {{val3}}',
            STEP_TEXT_SUGGESTION_ERROR:
              'PRISMA no pudo calcular el precio sugerido para este ítem y store (precio sugerido {{val}}),  por favor revise los costos e impuestos del mismo o comuníquese con soporte',
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER_SHORT:
              'PRISMA detectó que tu precio actual de {{val}} no cumple con tu posicionamiento competitivo.',
            STEP_TEXT_RELATED_PRODUCTS_RULE_SHORT:
              'Se aplicó la regla de parentesco de productos: "{{val}}" y generó un precio de {{val2}}',
            STEP_TEXT_RELATED_STORES_RULE_SHORT:
              'Se aplicó la regla de parentesco de puntos de venta: "{{val}}" y generó un precio de {{val2}}',
            STEP_TEXT_COMPETENCE_LIMITS_APPLIED:
              'La sugerencia de {{val}} se cambió a {{val2}} porque se aplicaron los límites de competencia',
            STEP_TEXT_COMPETENCE_LIMITS_NOTAPPLIED: 'Se verificaron las pautas de competencia. No se hicieron correcciones.',
            STEP_TEXT_COMPETENCE_RULE_MARGIN_FALLBACK:
              'Prisma no pudo encontrar el precio de referencia del mercado. La sugerencia se creó utilizando la reserva de margen de regla. {{val}}',
            STEP_TEXT_COMPETENCE_CATEGORY_MARGIN_FALLBACK:
              'Prisma no pudo encontrar el precio de referencia del mercado. La sugerencia se creó utilizando la reserva de margen de categoría. {{val}}',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED:
              'La sugerencia de {{val}} (variación: {{val2}}) se cambió a {{val3}} ya que la {{val5}} admitida para esta regla es de {{val4}}.',
            STEP_TEXT_VARIATION_GUIDELINES_NOTAPPLIED: 'Se verificaron las pautas de variación de precios. No se hicieron correcciones.',
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED:
              'La sugerencia de {{val}} (margen: {{val2}}) fue cambiada a {{val4}} ya que {{val3}} es el margen {{val5}} de la categoría.',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED_RULE:
              'La sugerencia de {{val}} (variación de precios: {{val2}}) fue cambiada a {{val4}} ya que {{val3}} es la variación {{val5}} de la regla.',
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED_RULE:
              'La sugerencia de {{val}} (margen: {{val2}}) fue cambiada a {{val4}} ya que {{val3}} es el margen {{val5}} de la regla.',
            STEP_TEXT_MARGIN_GUIDELINES_NOTAPPLIED: 'Se verificaron las pautas de margen. No se hicieron correcciones.',
            STEP_TEXT_ROUNDING_APPLIED: 'La sugerencia fue redondeada a {{val}}',
            STEP_TEXT_ROUNDING_NOTAPPLIED: 'No se aplicó redondeo',
            STEP_TEXT_SUMMARY_ACCEPTED: 'Tomaste una decisión y aceptaste la sugerencia de {{val}}',
            STEP_TEXT_SUMMARY_CHANGED: 'Tomaste una decisión y cambiaste el precio a {{val}}',
            STEP_TEXT_SUMMARY_IGNORED: 'Tomaste una decisión e ignoraste el precio {{val}}',
            STEP_TEXT_SUMMARY_WITHOUT_SUGGESTION: 'Tomaste una decisión y cambiaste el precio de {{val}} a {{val2}}',
            STEP_LINK_APPLIED_RULE: 'revisar regla...',
            STEP_LINK_COMPETITIVE_SUGGESTION_TRIGGER: 'ver datos...',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED: 'revisar lineamientos...',
            STEP_LINK_MARGIN_GUIDELINES_APPLIED: 'revisar lineamientos...',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED_RULE: 'revisar regla...',
            STEP_LINK_MARGIN_GUIDELINES_APPLIED_RULE: 'revisar regla...',
            STEP_LINK_ROUNDING_APPLIED: 'ver regla...',
            STEP_LINK_REGULATEDPRICE_GUIDELINES_APPLIED: 'ver configuración',
            STEP_LINK_REGULATEDPRICE_GUIDELINES_NOTAPPLIED: 'ver configuración',
            STEP_LINK_RELATED_PRODUCTS_RULE: 'ver regla de parentesco...',
            STEP_LINK_RELATED_STORES_RULE: 'ver regla de parentesco...',
            STEP_COMPETENCE_RULE: 'Regla de competencia',
            STEP_MARGIN_RULE: 'Regla de margen',
            SUGGESTION_TITLE: 'Decisión de Precio',
            NOT_STEPS: 'No se encontraron pasos',
            AUX_MAX_MARGIN: 'máximo',
            AUX_MIN_MARGIN: 'mínimo',
            AUX_MAX_VARIATION: 'suba máxima',
            AUX_MIN_VARIATION: 'baja mínima',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED:
              'El precio de {{val}} se cambió a {{val3}} porque se aplicaron los límites de Precios Máximos Regulados.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED:
              'El precio de {{val}} supera los límites de Precios Máximos Regulados {{val3}} pero no se hicieron correcciones.',
            STEP_TEXT_APPLY_PRODUCTS_RULE:
              'Este item fue identificado como principal en ciertas reglas de parentesco de items. En relación con este elemento, se aplicaron las siguientes reglas y se generó una decisión sobre los items hijos: {{val}}',
            STEP_TEXT_APPLY_STORES_RULE:
              'Esta tienda fue identificada como una principal en ciertas reglas de parentesco de puntos de venta. En relación con este item, se aplicaron las siguientes reglas y se generó una decisión sobre las tiendas hijas: {{val}}',
          },
          TAB_ZONES_COL_ZONE: 'Reglas (Parentesco de PDV)',
          TAB_ZONES_COL_RULE: 'Regla',
          TAB_ZONES_COL_ACTUAL: 'Actual',
          TAB_ZONES_COL_SUGGEST: 'Sugerido',
          TAB_ZONES_COL_DIFF: 'diff %',
          TAB_ZONES_COL_DECISION: 'Decisión',
          TAB_ZONES_COL_IMPACT: 'Impacto',
          WARNING_CHILD_WHITH_WARNING: 'Tiene Hijos con Advertencias',
          WARNINGS_PRICES_LIST: {
            TEST: 'test de warning / valor: {{val}}',
            MIN_MARGIN: 'El margen de {{val}} está por debajo del margen mínimo de {{val2}}.',
            MAX_MARGIN: 'El margen de {{val}} está por arriba del margen máximo de {{val2}}',
            EQUAL_MARGIN: 'El margen de {{val}} es igual al margen máximo de {{val2}}',
            VAR_BAJA: 'El precio bajó un {{val}}. La baja máxima según lineamiento es del {{val2}}',
            VAR_SUBA: 'El precio subió un {{val}}.  La suba máxima según lineamiento es del {{val2}}',
            SUGGESTION_ERROR: 'Precio sugerido inválido.',
          },
          STRATEGY_OP3: 'Llevar a un precio fijo',
          STRATEGY_OP2: 'Llevar a margen objetivo',
          STRATEGY_OP1: 'Aplicar variación de precio',
          SUGGESTED_IMPACT_TTIP: 'No incluye productos hijos',
          NEW_IMPACT_TTIP: 'Incluye Productos Hijos',
        },
      },
      TAXES: {
        IIBB: {
          ROUTE: 'Precios / Administración / Impuestos',
          TITLE: 'Ingresos Brutos',
          JURISDICTION: 'Jurisdicción',
          PERCENTAGE_RATE: 'Tasa porcentual',
          SAVE_BTN: 'Guardar',
          PERCENTAGE_RATE_INPUT: 'Tasa porcentual',
          VALIDATION_1: 'El campo no puede ser mayor a 100',
          VALIDATION_2: 'El campo debe ser mayor a 0',
          VALIDATION_3: 'El campo es obligatorio',
        },
        CO2: {
          ROUTE: 'Precios / Administración / Impuestos',
          TITLE: 'Impuestos CO2',
          YES: 'Si',
          NOT: 'No',
          EXCEMPT_ZONE: 'Zona Exenta',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'EURO',
          SAVE_BTN: 'Guardar',
          NS_INPUT: 'NS',
          VALIDATION1: 'El campo no debe ser superior a 100',
          VALIDATION2: 'El campo debe ser mayor a 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'EURO',
          TITLE_FORM: 'Impuestos CO2 - Zona {{val}} exenta',
        },
        ICL: {
          ROUTE: 'Precios / Administración / Impuestos',
          TITLE: 'Impuestos ICL',
          YES: 'Si',
          NOT: 'No',
          EXCEMPT_ZONE: 'Zona Exenta',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'EURO',
          SAVE_BTN: 'Guardar',
          NS_INPUT: 'NS',
          VALIDATION1: 'El campo no debe ser superior a 100',
          VALIDATION2: 'El campo debe ser mayor a 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'EURO',
          TITLE_FORM: 'Impuestos ICL - Zona {{val}} exenta',
        },
      },
      IMPORTERS: {
        PARITY: {
          ROUTE: 'Precios / Administración / Importadores',
          TITLE: 'Import Parity',
          IP_BTN: 'Cargar IP Diario',
          DAY: 'Día',
          TRANSFER_PRICING: 'Precios de transferencia',
          IMPORT_TTIP: 'Importar datos',
          IP_DAILY: 'Ponderación IP diario',
          IP_NS: 'IP NS',
          IP_IN: 'IP IN',
          IP_GO2: 'IP GO2',
          IP_GO3: 'IP GO3',
          TC: 'TC',
          TERMINAL: 'Terminal',
          DESCRIPTION: 'Descripción',
          NS: 'NS',
          IN: 'IN',
          GO2: 'GO2',
          GO3: 'GO3',
          TITLE_FORM: 'Import Parity - Coeficientes',
          SAVE_BTN: 'Guardar',
          DATE_COEFFICIENTS_INPUT: 'Cambiar fecha de coeficientes IP',
          VALIDATION1: 'El campo debe ser mayor a 0',
          TC_INPUT: 'TC',
          NS_INPUT: 'NS',
          IN_INPUT: 'IN',
          GO2_INPUT: 'GO grado 2',
          GO3_INPUT: 'GO grado 3',
        },
        FERRYES_AND_COMISIONS: {
          TITLE: 'Fletes y Comisiones',
        },
        COST_AND_TAXES: {
          TITLE: 'Costos e Impuestos',
        },
        VENDOR_COST: {
          TITLE: 'Costos por Proveedor',
        },
        STORE_TAXES: {
          TITLE: 'Impuestos por Puntos de Venta',
        },
        NEW_PRICES: {
          TITLE: 'Nuevos Precios',
        },
        COMPETITOR_PRICES: {
          TITLE: 'Precios de Competidores',
        },
      },
    },
    ASSORT: {
      STP: {
        CLUSTERING: {
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Clusterizaciones',
          SUBTITLE:
            'La clusterización permite clasificar estratégicamente los puntos de venta según un conjunto de variables. Puede ser una única clusterización para todas las categorías o varias clasterizaciones según la categoría. De esta manera se agiliza la toma de decisiones del negocio y se estandariza la oferta de productos. Desde esta pantalla podrás crear y modificar clusterizaciones.',
          NEW_CLUSTERIZATION_BTN: 'Nueva Clusterización',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar clusterizaciones seleccionadas',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          OBSERVATION: 'Observación',
          EDIT_TITLE_FORM: 'Editar Clusterización',
          NEW_TITLE_FORM: 'Nueva Clusterización',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          NAME_INPUT: 'Nombre',
          OBSERVATION_INPUT: 'Observación',
          CLUSTER_NAME_TTIP: 'Click aquí para ver puntos de venta y clusters asociados',
        },
        CLUSTER_ASSIGN: {
          REDO_CHANGE_CLUSTERS: 'Regresar cambios de clusters seleccionados',
          REDO_CHANGE_CLUSTERS_DESC: '¿Desea filtrar por el cluster seleccionado? esto borrará los cambios no guardados',
          ROUTE: 'Clusterización / Asignación de Cluster',
          SUBTITLE:
            'En esta pantalla podrás crear y asignar un clúster a cada punto de venta. O trabajar los puntos de venta como agentes libres sin asignar un clúster. En ese caso se trabaja de manera independiente como un clúster con un sólo punto de venta.',
          SAVE_BTN: 'Guardar',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          STORES: 'Puntos de Venta',
          CODE: 'Código',
          TYPE: 'Tipo',
          PROVINCE: 'Provincia',
          STATE: 'Estado',
          CLUSTER: 'Cluster',
          NEW_CLUSTER: 'Nuevo Cluster',
          NO_CLUSTER_ASSIGNED: 'Ningún Cluster Asignado',
          NEW_CLUSTER_BTN: 'NUEVO CLUSTER',
          NEW_CLUSTER_TITLE: 'Nuevo Cluster',
          NEW_CLUSTER_SUBTITLE:
            'El clúster es un grupo de puntos de venta, con características específicas o similar comportamiento de compra, a los que se le asigna junto con la categoría un rol y una estrategia. A su vez, simplifica la asignación y administración de surtidos.',
          NAME_INPUT: 'Nombre',
          OBSERVATION_INPUT: 'Observación',
          CANCEL_BTN: 'Cancelar',
          ADD_BTN: 'Agregar',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTERS: 'Estado',
          TYPE_FILTERS: 'Tipo',
          SEARCH_TYPE: 'Buscar Tipo...',
          PROVINCE_FILTERS: 'Provincia',
          CLUSTER_FILTERS: 'Cluster',
          SEARCH_CLUSTER: 'Buscar Cluster...',
          CLEAN_BTN: 'Limpiar',
          APPLY_BTN: 'Aplicar',
          Todos: 'Todos',
          Checked: 'Activo',
          Unchecked: 'Inactivo',
          FREE_AGENTS: 'Agente Libre',
        },
        CATEGORY_SETUP: {
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Configuración de Categorías',
          SUBTITLE:
            'En esta pantalla podrás asignar una clusterización y la segmentación principal de productos para cada nivel de categoría, definido previamente en árbol de categorías; a su vez podrás asignar un rol y una estrategia diferenciada para cada clúster, y ver la información respecto a la distribución y asignación de los segmentos.',
          LIST: {
            CATEGORIES: 'Categorías',
            CLUSTERIZATION: 'Clusterización',
            ROL: 'Rol',
            STRATEGY: 'Estrategia',
            SEGMENTATION: 'Segmentación',
            SEGMENT_QUANTITY: '# de Segmentos',
            SEGMENT_QUANTITY_TTIP: 'Cantidad de Segmentos',
            CLASSIFIELDS: 'Clasificados %',
            CLASSIFIELDS_TTIP: 'Porcentaje de productos dentro de la categoría clasificados con un segmento.',
            SWITCH_ACTIVE: 'Mostrar Categorías sin Asignación',
            SWITCH_INACTIVE: 'Mostrar Categorías con Asignación',
            CLUSTERIZATION_OR_SEGMENT_DISABLE: 'Categoría no editable por tener ítems asociados.',
            FILTER_TTIP: 'Filtrar',
            NOTFOUNDCATEGORYTREE:
              'No se encontraron Categorías sin asignar con los filtros seleccionados, contactar a soporte para habilitarlas',
            WITHOUTCLUSTERIZATION: 'Sin Clusterizaciones',
            WITHOUTSEGMENT: 'Sin Segmentos',
            WITHOUTSTRATEGY: 'Sin Estrategias',
            WITHOUTROL: 'Sin Clusters',
            WITHOUTCLUSTER: 'Sin Clusters',
          },
        },
        ROLES_STRATEGIES_ASSIGN: {
          SUBTITLE:
            'En esta pantalla podrás asignar una clusterización para cada nivel de categoría, definido previamente en árbol de categorías. A su vez, para cada clúster o punto de venta de la clusterización seleccionada, podrás asignar un rol y una estrategia diferenciada.',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORIES: 'Categorías',
          CLUSTERING: 'Clusterización',
          ROL: 'Rol',
          STRATEGY: 'Estrategia',
          UNALLOCATED_TTIP: 'Sin Asignar',
          SEARCH_CLUSTER: 'Buscar Cluster...',
          SEARCH_ROL: 'Buscar Rol...',
          WITHOUT_CLUSTERS: 'Función habilitada si existen clusters asignados',
          SEARCH_STRATEGY: 'Buscar Estrategia...',
          CHANGE_CLUSTERING_TITLE: 'Estás seguro de cambiar de clusterización?',
          CHANGE_CLUSTERING_SUBTITLE:
            'Esto reiniciará el listado de roles y estrategias asignados actualmente a todos los clusters de esta Categoría',
          CANCEL_BTN: 'Cancelar',
          CHANGE_BTN: 'Cambiar',
          ACCEPT_BTN: 'Aceptar',
          CHANGE_RS_TITLE: 'Modificar Rol / Estrategia',
          CHANGE_RS_SUBTITLE: 'Desea asignar: {{val}} a todos los cluster de esta categoría ?',
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Configuración de Categorías',
        },
        ROLES: {
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Roles',
          SUBTITLE: 'En esta pantalla podrás crear y modificar los distintos roles que se asignarán a las categorías.',
          NEW_ROLE_BTN: 'Nuevo Rol',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar roles seleccionados',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          OBSERVATION: 'Observación',
          DELISTING_RULE: 'Regla para Desactivar',
          LISTING_RULE: 'Regla para Activar',
          OTHERS_RULES: 'Otras Reglas',
          FRECUENCY: 'Frecuencia',
          DEFINE_ROL_STP1: 'Definir Rol',
          DELISTING_RULES_STEP2: 'Reglas para Desactivar',
          LISTING_RULES_STEP3: 'Reglas para Activar',
          OTHERS_RULES_STEP4: 'Reglas Adicionales',
          FRECUENCY_STEP5: 'Frecuencia de Análisis',
          TITLE_FORM_NEW: 'Nuevo Rol',
          TITLE2_FORM_NEW: 'Nuevo Rol /',
          STP1: 'Paso 1 - Definir Rol',
          LEGEND_ROL:
            'El Rol de la categoría definirá cuán exigentes son los criterios para sugerir activar o desactivar un producto para un cluster determinado. El rol se asigna a la categoría y combinado con la estrategia definen los criterios y las variables que se evaluarán en las sugerencias.',
          NAME_INPUT: 'Nombre',
          OBSERVATION_INPUT: 'Observación',
          NEXT_BTN: 'Siguiente',
          STP2: 'Paso 2 - Regla para Desactivar',
          LEGEND_DELISTING_RULE: 'En este paso podrás definir por qué criterios analizarás los productos para desactivar.',
          RULE_DELISTING_RULE: 'Sugerir desactivar productos cuyo ',
          RULE_LISTING_RULE: 'Sugerir activar productos cuyo ',
          RULE_DELISTING_RULE_ALT: 'Sugerir desactivar productos ',
          RULE_LISTING_RULE_ALT: 'Sugerir activar productos ',
          RULE_HIGH_AI: 'Sugerir activar productos usando IA',
          MINIMUM_AVAILABILITY: 'Disponibilidad mínima de ',
          PRESENCE_IN_STORES: 'Presencia en puntos de venta mínima de ',
          SUPPLIERS_W_SKU: 'Proveedores con una participación mínima de ',
          NEW_DATES: 'Cuando ingresen datos nuevos',
          OPPORTUNITIES_IA: 'Cuando la IA encuentre oportunidades',
          AND: 'Y',
          THE: 'el',
          THEFREQ: 'el',
          AUXS: {
            _variableSource: {
              _DEMOMercado: 'Mercado',
              ParetoCluster: 'Pareto del Cluster',
              ParetoGeneral: 'Pareto General',
              ParetoMarket: 'Pareto de Mercado',
              TotalMarket: 'Total de Mercado',
              ParetoReference: 'Pareto de Referencia',
            },
            _variableSource_phrase: {
              ParetoCluster: 'del Pareto del Cluster',
              ParetoGeneral: 'del Pareto General',
              ParetoMarket: 'del Pareto de Mercado',
              TotalMarket: 'del Total de Mercado',
              ParetoReference: 'del Pareto de Referencia',
            },
            _variable: {
              WeightedIndex: 'Índice Ponderado',
              SalesUnits: 'Ventas Un.',
              Sales: 'Ventas $',
              Margin: 'Margen $',
              MarginWithVendor: 'Margen por acuerdo',
              PercentageMargin: 'Margen %',
              Gmroi: 'GMROI',
              Presence: 'Presencia',
              MarketSales: 'Mercado $',
              MarketUnits: 'Mercado Un.',
            },
            _variable_phrase: {
              WeightedIndex: 'de Ventas en Índice Ponderado',
              SalesUnits: 'de Ventas en UN',
              Sales: 'de Ventas en $',
              Margin: 'de Margen en $',
              MarginWithVendor: 'de Margen por acuerdo',
              PercentageMargin: 'de Margen en %',
              Gmroi: 'de GMROI',
              Presence: 'Presencia',
              MarketSales: 'de Ventas de Mercado en $',
              MarketUnits: 'de Ventas de Mercado en Un.',
            },
            _method: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Share %',
              CumulativeShare: 'Bottom %',
            },
            _methodTop: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Share %',
              CumulativeShare: 'Top %',
            },
            _method_phrase: {
              AbsoluteValue: 'Valor Absoluto',
              TotalShare: 'Share %',
            },
            _condition: {
              GreaterThan: 'Es Mayor',
              LowerThan: 'Es Menor',
              Equals: 'Es Igual',
            },
            _condition_phrase: {
              GreaterThan: 'sea mayor que',
              LowerThan: 'sea menor que',
              Equals: 'sea igual a',
            },
            _conditionTopBottom: {
              LowerThan: 'Top',
              GreaterThan: 'Bottom',
            },
            _conditionTopBottom_phrase: {
              LowerThan: 'es Top',
              GreaterThan: 'es Bottom',
            },
            _combinationMethod: {
              And: 'Y',
              Or: 'O',
            },
            _combinationMethod_phrase: {
              And: 'Y el',
              Or: 'O el',
            },
            _weekday: {
              Monday: 'Lunes',
              Tuesday: 'Martes',
              Wednesday: 'Miércoles',
              Thursday: 'Jueves',
              Friday: 'Viernes',
              Saturday: 'Sábado',
              Sunday: 'Domingo',
            },
            _frecuency: {
              Biweekly: 'Quincenal',
              Monthly: 'Mensual',
              BiMonthly: 'Bimestral',
              ThreeMonth: 'Trimestral',
              FourMonth: 'Cuatrimestral',
              SixMonth: 'Semestral',
              Annually: 'Anual',
            },
            _frecuency_phrase: {
              Biweekly: 'Quincenalmente',
              Monthly: 'Mensualmente',
              BiMonthly: 'Bimestralmente',
              ThreeMonth: 'Trimestralmente',
              FourMonth: 'Cuatrimestralmente',
              SixMonth: 'Semestralmente',
              Annually: 'Anualmente',
            },
          },
          COMBINATION: 'REGLA',
          DELETE_COMBINATION_TTIP: 'Eliminar Regla',
          CANCEL_BTN: 'Cancelar',
          CONFIRM_BTN: 'Confirmar eliminación',
          VARIABLE_SOURCE_INPUT: 'Fuente',
          VARIABLE_INPUT: 'Variable',
          METHOD_INPUT: 'Método',
          CONDITION_INPUT: 'Condición',
          VALUE_INPUT: 'Valor',
          LOGIC_INPUT: 'Lógica',
          REFERENCE_CLUSTER: 'Cluster de Referencia',
          ADD_COMBINATION_BTN: 'Agregar Regla',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Paso 3 - Regla para Activar',
          LEGEND_LISTING_RULE: 'En este paso podrás definir por qué criterios analizarás los productos para activar.',
          STP4: 'Paso 4 - Otras Reglas',
          LEGEND_OTHERS_RULES: 'En este paso podés definir otro tipo de reglas o controles que quieras sumar al Rol.',
          CHECK_AVAILABILITY: 'Verificar disponibilidad para desactivar',
          CHECK_AVAILABILITY_TEXT:
            'Sugiere desactivar sólo cuando los productos hayan tenido una disponibilidad mayor a la especificada. Tienes que tener habilitados datos de stock.',
          MINIMUM_AVAILABILITY_INPUT: 'Disp. Mínima',
          MINIMUM_AVAILABILITY_TTIP: 'Disponibilidad Mínima',
          CHECK_PRESENCE: 'Verificar presencia para desactivar',
          CHECK_PRESENCE_TEXT:
            'Sugiere desactivar sólo cuando un producto ha tenido ventas en un porcentaje específico de los {{val}}%  del clúster',
          MINIMUM_PRESENCE_INPUT: 'Pres. Mínima',
          MINIMUM_PRESENCE_TTIP: 'Presencia Mínima',
          CHECK_SUPPLIER: 'Verificar proveedores con productos sueltos',
          CHECK_SUPPLIER_TEXT:
            'Revisa proveedores marginales que tengan una participación menor a la indicada en el mix general. Prisma te alertará para desactivar productos, optimizando tu cadena de abastecimiento.',
          MINIMUM_PART_INPUT: 'Part. Mínima',
          MINIMUM_PART_TTIP: 'Participación Mínima',
          SUGGEST_HIGH: 'Sugerir altas con inteligencia artificial',
          SUGGEST_HIGH_TEXT:
            'La inteligencia artificial de Prisma te sugerirá productos que en función de sus características y performance podrían funcionar en el {{val}} / clúster.',
          COMING_SOON: 'Próximamente',
          STP5: 'Paso 5 - Frecuencia de Análisis',
          LEGEND_CATEGORY_ANALYSIS: 'En este paso podrás definir cada cuánto se realizará el análisis de las categorías con este rol.',
          FRECUENCY_INPUT: 'Frecuencia',
          WEEKDAY_INPUT: 'Día de la Semana',
          GENERATE_SUGGESTIONS_IA: 'Generar sugerencias de activar cuando la IA encuentre oportunidades.',
          GENERATE_SUGGESTIONS_IA_TEXT:
            'Cada vez que la inteligencia artificial encuentre una oportunidad de mejora se generarán sugerencias para activar productos más allá de la frecuencia y las reglas definidas.',
          GENERATE_SUGGESTIONS: 'Generar sugerencias de activar cada vez que ingresen nuevos datos de mercado.',
          GENERATE_SUGGESTIONS_TEXT: 'Genera sugerencias de activar cada vez que ingresen datos de nuevos productos en el mercado.',
          SAVE_BTN: 'Guardar',
          NEW_COMBINATION: 'Nueva Regla',
          ADD_BTN: 'Agregar',
          REPEATED_VARIABLE_AND_SOURCE: 'Variable y Fuente no pueden estar duplicados para más de una regla.',
          SUGGESTION_ROLE_NAME: 'Sugerencia del rol',
          SUGGESTION_ROLE_DESCRIPTION: 'Descripcion de sugerencia',
        },
        STRATEGIES: {
          DELETE_TITLE: 'Eliminar estrategia',
          DELETE_TITLE_MULTIPLE: 'Eliminar estrategias',
          DELETE_CONTENT: '¿Se eliminará {{val}} de estrategias?',
          DELETE_CONTENT_MULTIPLE: '¿Se eliminarán {{val}} registros de estrategias?',
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Estrategias',
          SUBTITLE: 'En esta pantalla podrás crear y modificar las distintas estrategias que se asignan a las categorías.',
          NEW_STRATEGY_BTN: 'Nueva Estrategia',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar estrategias seleccionadas',
          DELETE: 'Eliminar',
          STRATEGY: 'Estrategia',
          OBSERVATION: 'Observación',
          VARIABLE: 'Variable',
          NEWS: 'Nuevos',
          TYPOLOGIES: 'Tipologías',
          PERIOD: 'Período',
          DEFINE_STRATEGY_STP1: 'Definir Estrategia',
          VARIABLES_SELECTION_STP2: 'Selección de las Variables',
          OTHER_CONFIGURATIONS_STP3: 'Otras Configuraciones',
          ANALYSIS_PERIOD_STP4: 'Período de Análisis',
          TITLE_FORM_NEW: 'Nueva Estrategia',
          TITLE_FORM_EDIT: 'Editar Estrategia',
          STP1: 'Paso 1 - Definir Estrategia',
          LEGEND_STRATEGY:
            'La estrategia te ayudará a definir en base a qué variable o combinación de variables se realizará el análisis de Pareto.',
          NAME_INPUT: 'Nombre',
          OBSERVATION_INPUT: 'Observación',
          NEXT_BTN: 'Siguiente',
          STP2: 'Paso 2 - Selección de las Variables',
          LEGEND_SELECTION_VARIABLES: 'En este paso podrás definir por qué criterios se va a analizar y optimizar el surtido.',
          VARIABLE_INPUT: 'Variable',
          COMBINATION_PERCENTAGE_INPUT: 'de combinación',
          VALIADATION: 'El valor ingresado debe ser mayor a 0 o igual a 100%',
          DELETE_VARIABLE_TTIP: 'Eliminar',
          VALIADATION2: 'La suma de todas las variables debe ser 100%',
          ADD_COMBINATION_BTN: 'Agregar Combinación',
          PREVIUS_BTN: 'Anterior',
          STP3: 'Paso 3 - Otras configuraciones',
          LEGEND_OTHER_CONFIGURATIONS: 'En este paso podrás ingresar configuraciones adicionales que mejorarán la precisión del análisis.',
          OTHER_CONFIGURATIONS_TEXT: 'Un producto se considera nuevo por',
          DAYS_INPUT: 'Días',
          DAY_INPUT: 'Día',
          SINCE_TEXT: 'considerado nuevo desde su',
          SINCE: 'desde su',
          EVENT_INPUT: 'Evento',
          TYPOLOGIES_TEXT: 'TIPOLOGÍAS (cortes de Pareto):',
          DEFINE_PARETO_TEXT:
            ' Define cuales son los cortes para cada producto dentro del Pareto de su categoría para clasificarlos de forma automática por la variable seleccionada.',
          HIGH_ROTATION: 'ALTA ROTACIÓN',
          HIGH_ROTATION_TEXT: ': Son aquellos productos que su índice acumulado va del 0 al',
          VALIDATION_HIGH_ROTATION: 'Debe ser menor a 100% y menor a Baja Rotación',
          LOW_ROTATION: 'BAJA ROTACIÓN',
          LOW_ROTATION_TEXT: ': Son aquellos productos que su índice acumulado va del {{val}}% al',
          VALIDATION_LOW_ROTATION: 'Debe ser menor a 100% y mayor a Alta Rotación',
          LONG_TAIL: 'LONG TAIL',
          LONG_TAIL_TEXT: ': Son aquellos productos que su índice acumulado va del {{val}}% al 100%. Son el último {{val2}}% del Pareto.',
          WITHOUT_ROTATION: 'SIN ROTACIÓN',
          WITHOUT_ROTATION_TEXT: ': Son aquellos productos que no presentan ventas en el período de análisis seleccionado.',
          NEWS1: 'PRODUCTOS NUEVOS',
          NEWS_TEXT: 'Son aquellos productos que tienen menos de {{val}} días desde su {{val2}}',
          STP4: 'Paso 4 - Período de Análisis',
          DEFINE_PERIOD_TEXT:
            'En este paso podrás definir el período en meses que se tomará para realizar el análisis. Para normalizar y poder comparar siempre verás valores promedio expresados a 30 días en reportes y pareto.',
          LATEST_INPUT: 'Últimos',
          MONTHS: 'Meses',
          JANUARY: 'ENE',
          FEBRUARY: 'FEB',
          MARCH: 'MAR',
          APRIL: 'ABR',
          MAY: 'MAY',
          JUNE: 'JUN',
          JULY: 'JUL',
          AUGUST: 'AGO',
          SEPTEMBER: 'SEP',
          OCTOBER: 'OCT',
          NOVEMBER: 'NOV',
          DECEMBER: 'DIC',
          LEGEND_MOTHS:
            'Si excluyes un mes de la lista no se tendrá en cuenta como parte del promedio. Si la cantidad de meses seleccionados fuera menor que el período seleccionado, se realizará el promedio con la cantidad de meses que haya disponibles.',
          SAVE_BTN: 'Guardar',
          FROM_DATE_OF: 'de Fecha de',
          LAST_MONTH_: 'Último Mes',
          LASTS_: 'Últimos',
          MONTHS_: 'Meses',
          EXCEPT_FOR: 'Excepto a',
          MONTHS_VALIDATION: 'Los meses de análisis no pueden ser mayores a los meses habilitados',
        },
        SEG_ASSIGNMENT: {
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Asignación de Segmentos',
          SUBTITLE:
            'En esta pantalla podrás asignar para cada nivel de categoría, definido previamente en árbol de categorías, la segmentación principal de productos que se va a utilizar para realizar el análisis y optimización del surtido.',
          EXPORT_TTIP: 'Exportar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          CATEGORY: 'Categoría',
          SEGMENTATION: 'Segmentación',
          NOT_ASSIGNED_TTIP: 'Sin Asignar',
          SEARCH_SEGMENTATION: 'Buscar Segmentación...',
          SEGMENT_QUANTITY: '# Cant. de Segm.',
          SEGMENT_QUANTITY_TTIP: 'Cantidad de Segmentos',
          CLASSIFIELDS: 'Clasificados %',
          CLASSIFIELDS_TTIP: 'Porcentaje de productos dentro de la categoría clasificados con un segmento.',
        },
        CATEGORIES: {
          ROUTE: 'Surtidos / Configuración',
          TITLE: 'Árbol de Categorías',
          SUBTITLE: 'Aquí podrás visualizar la jerarquía de categorías cargadas en Prisma.',
          SAVE_BTN: 'Guardar',
          PRINT_TTIP: 'Imprimir',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          SECTOR: 'Sector',
          CATEGORY: 'Categoría',
          SUBCATEGORY: 'Subcategoría',
          ELEMENTS: 'Elementos',
          SELECTED_CATEGORIES_TTIP: 'Categorías Seleccionadas: ',
          MODAL_TTILE: 'Desea guardar los cambios?',
          MODAL_SUBTTILE: 'Al guardar se pisaran los cambios anteriores',
          CLOSE_BTN: 'Cerrar',
          UNDO_BTN: 'Deshacer Cambios',
          ACCEPT_BTN: 'Aceptar',
        },
        HELP: {
          VARIABLE_SOURCE_INPUT:
            'Indica si la sugerencia se va a generar en base al pareto del clúster que se está analizando, al pareto de todos los clusters o con información del mercado.',
          VARIABLE_INPUT: 'Indica con qué variable se va a construir el pareto para evaluar la regla.',
          CONDITION_INPUT: 'Indica la condición numérica para generar la regla. Puede ser mayor, menor o igual.',
          METHOD_INPUT:
            'Permite definir que tipo de corte se va a utilizar para la regla. Puede ser un valor absoluto, un porcentaje de participación o un acumulado del porcentaje de participación.',
          VALUE_INPUT: 'Valor numérico necesario para completar la condición de la regla.',
          FRECUENCY_INPUT: 'Indica la repetición del análisis de la categoría a lo largo del tiempo.',
          WEEKDAY_INPUT: 'Días de la semana en el que habría que realizar el análisis de la categoría.',
          VARIABLE_S_INPUT: 'Indica con qué variable se va a analizar el surtido.',
          COMBINATION_PERCENTAGE_INPUT:
            'Indica el porcentaje de la variable que se tendrá en cuenta para el análisis. Si es una sóla variable el valor debe ser 100%. Si son más variables de análisis, la suma de los porcentajes de análisis de las mismas debe ser de 100%.',
        },
        MARKET_INFO: {
          TITLE: 'Productos de Mercado',
          CATEGORY_ASSIGN: 'Asignar Categoría',
          CATEGORY_ASSIGN_TOOLTIP: 'Asignar Categoría',
          CATEGORY_ASSIGN_DISABLED_TOOLTIP: 'No tienes permisos',
          CATEGORY_ASSIGN_MSG: 'Por favor, seleccione una Categoría para asignar a los productos elegidos.',
          marketItemName: 'Nombre',
          marketItemCode: 'Código de Barras',
          marketCategory: 'Categoría de Mercado',
          prismaCategory: 'Categoría',
          marketItemBrand: 'Marca',
          marketItemManufacturer: 'Fabricante',
          matching: 'Matching',
          CREATION_DATE: 'Fecha de Creación',
          ITEMS_NOT_MATCH: 'Ver productos sin matching',
          MARKET_CATEGORIES: 'Categorías de Mercado',
          MARKET_CATEGORY: 'Categoría de Mercado',
          PRODUCT: 'Producto',
          PRODUCTS: 'Productos',
          itemMarketDataType: {
            ownItems: 'Productos Propios',
            marketItems: 'Productos de Mercado',
            all: 'Todos',
          },
        },
      },
      DECISIONS: {
        ASSORT_ASSIGNATION: {
          ROUTE: 'Surtidos / Decisiones',
          TITLE: 'Decisiones de Surtido',
          SUBTITLE:
            'En esta pantalla podrás ver cómo está compuesto en cada clúster el mix de surtido por categoría. A su vez, tendrás un resumen de sugerencias e impactos de las mismas. Usando las palancas podrás activar o desactivar categorías, segmentos o mix de surtido.',
          SELECT_CATEGORY: 'Selecciona la categoría que desea trabajar.',
          LAST_SALE: 'Último mes de ventas:',
          NO_DATA: 'Sin datos',
          LAST_STOCK: 'Último mes de stock:',
          LAST_MARGIN2: 'Último mes de Margen por Acuerdos:',
          LAST_MARKET_INFO: 'Último mes de Información de Mercado:',
          SHOW_CLUSTER_ASSORTMENT: 'Elija una Categoría para mostrar el surtido de cada cluster',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          PRINT_TTIP: 'Imprimir',
          CLUSTER: 'Cluster',
          CATEGORY: 'Categoría',
          SEGMENT: 'Segmento',
          NO_SEGMENT: 'Se debe tener un segmento para poder activarlo',
          IE: 'IE %',
          LISTINGS: '# Activos',
          SKUS: '# Productos',
          TOTAL: '# Total',
          INACTIVE: '# A Desactivar',
          ACTIVE: '# A Activar',
          IMPACT: 'Impacto $',
          VIEW_PARETOC_TTIP: 'Click aquí para ver pareto de "categoría" en "clúster"',
          VIEW_PARETOT_TTIP: 'Click aquí para ver pareto de "categoría" total compañía',
          IMPACT_TTIP: 'Impacto en $ Ventas si se activa o desactivan las sugerencias',
          DELIST_TTIP: 'Cantidad de Sugerencias a Desactivar',
          LIST_TTIP: 'Cantidad de Sugerencias a Activar',
          TOTAL_TTIP: 'Total de productos dentro del segmento',
          LISTINGS_TTIP: 'Total de productos activos en el clúster',
          PERCENTAGE_TTIP: 'Porcentaje de productos activos',
          ERROR: 'Error en la asignación',
          CORRECT: 'Asignación guardada correctamente',
          ACCEPT_BTN: 'Aceptar',
          ENABLE_ASSIGNMENT_TITLE: 'Confirma la habilitación de la asignación?',
          DISABLE_ASSIGNMENT_TITLE: 'Confirma la deshabilitación de la asignación?',
          ACTION_ASSIGNMENT_SUBTITLE: 'Esta acción modificará el surtido de todos los productos dentro del Cluster.',
          CANCEL_BTN: 'Cancelar',
          EDIT_BTN: 'Modificar',
          CHECK: 'No volver a preguntar.',
          TITLE_FILTERS: 'Filtros',
          CATEGORY_FILTERS: 'Categoría',
          CATEGORY_FILTERS_TTIP: 'Debes seleccionar al menos una categoría para poder filtrar por cluster',
          SEARCH_CATEGORY: 'Buscar Categorías...',
          COUNT_FILTRED: 'Cantidad filtrada',
          TOTAL_COUNT: 'Cantidad total',
          CLUSTER_FILTERS: 'Cluster',
          WITHOUT_NEWS: 'Sin productos nuevos',
          TOTALITEMS: 'Productos totales',
          TOTALSALES: 'Ventas totales',
          MSG_WITHOUT_NEWS:
            'Se mostrarán los productos que no se encuentren en estado nuevo, manteniendo la posición que ocupan en el pareto',
          WITHOUT_INACTIVES: 'Sin productos inactivos',
          MSG_WITHOUT_INACTIVES: 'No se mostrarán los productos inactivos para los clusters seleccionados',
          RECALC_PARETO: 'Recalcular pareto',
          SHOW_ONLY_SUGGESTIONS: 'Mostrar Solo Sugerencias',
          MSG_RECALC_PARETO: 'Se actualizará el pareto en base a los productos filtrados',
          ANALYST_FILTERS: 'Analista',
          APPROVER_FILTERS: 'Aprobador',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          UNASSIGNEDSEGMENTS: 'Mostrar segmentos sin<br>Asignar',
          UNASSIGNEDCLUSTERS: 'Mostrar Clusters sin Asignar',
          EXPORT_TTIP: 'Exportar',
        },
        ASSORT_ASSIGNATION_ITEM: {
          OBSERVATION: 'Observaciones',
          ACTIVE_IN: 'Activo en',
          ITEM_TAGS: 'Filtros para Productos',
          STORE_TAGS: 'Filtros para Tiendas',
          BEHAVIOR_FOR_CATEGORY: 'Este ítem solo puede operarse desde el pareto del cluster general.',
          SUGGESTION_MODAL_MSG:
            'Se tomará el pedido para generar las sugerencias para este cluster, será notificado una vez se haya completado el proceso, puede tomar unos minutos',
          NOT_ASSORTMENT_BASED: 'Este Ítem no está basado en surtido. Debe modificarlo desde ítem master primero.',
          BLOCKED_FOR_SALES: 'Este ítem está bloqueado para la venta. Debe modificarlo desde ítem master primero.',
          BLOCKED_FOR_MARKET: 'Este ítem está bloqueado. Producto de Mercado, debe gestionarse desde las sugerencias por cluster.',
          ROUTE: 'Asignación de Surtido /',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          ORDER_TTIP: 'Ordenar',
          EXITFULLSCREEN_TTIP: 'Reducir',
          PRINT_TTIP: 'Imprimir',
          ACTIVATE: 'Activar',
          ACTIVATE_TTIP: 'Activar los ítems seleccionados',
          INACTIVATE: 'Desactivar',
          INACTIVATE_TTIP: 'Desactivar los ítems seleccionados',
          PRODUCT: 'Producto',
          BRAND: 'Marca',
          STATE: 'Estado',
          ACTIVE: 'ACTIVO',
          INACTIVE: 'INACTIVO',
          INACTIVE_MARKET_PROD: 'Producto de Mercado, deben generarse sugerencias para accionar',
          SEGMENT: 'Segmento',
          CIA: '# UN Cía.',
          MARKET: '# UN Mercado.',
          PRICE: 'Precio $',
          MARGIN: 'Margen %',
          CORRECT: 'Asignación guardada correctamente',
          REPORT_OK:
            'Generando reporte de Configuraciones Excepcionales de Surtido, Se le notificará cuando el reporte del ítem este listo',
          ACCEPT_BTN: 'Aceptar',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTERS: 'Estado',
          ALL_OP1: 'Todos',
          ACTIVE_OP2: 'Activo',
          INACTIVE_OP3: 'Inactivo',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          LISTING: 'Activar',
          DELISTING: 'Desactivar',
          ACCEPT_SUGGESTION: 'Aceptar Sugerencia',
          REJECT_SUGGESTION: 'Rechazar Sugerencia',
          ACCEPT_SUGGESTIONS: 'Aceptar Sugerencias',
          REJECT_SUGGESTIONS: 'Ignorar Sugerencias',
          REVERT_SUGGESTION: 'Revertir Cambios',
          REVERT_SUGGESTION_TO_INACTIVE: 'Revertir Cambios (Desactivar)',
          REVERT_SUGGESTION_TO_ACTIVE: 'Revertir Cambios (Activar)',
          COLUMN_DISPLAY_TTIP: 'Visualización de Columnas',
          REPORTS: 'Reportes',
          SUGGESTION: 'Generar Sugerencias',
          PRODUCTS: 'Productos',
          SALES: 'Ventas',
          IMPACT: 'Impacto en Ventas',
          DAYS: 'Días',
          COST: 'Costo $',
          ORDERS: '# Órdenes',
          DAYS_STOCK: 'Días de Stock',
          IN_STOCK: 'In-Stock',
          CLUSTER_SUGGESTION_ADD_TTIP: 'INACTIVO <br> Se sugiere Activar este producto en este clúster según la regla generada:',
          CLUSTER_SUGGESTION_REMOVE_TTIP: 'ACTIVO <br> Se sugiere Desactivar este producto en este clúster según la regla generada:',
          CLUSTER_SUGGESTION_ADD_DEMO_TTIP: 'Sugerir activar productos con share de ventas dentro del top 80% del mercado',
          CLUSTER_SUGGESTION_REMOVE_DEMO_TTIP: 'Sugerir desactivar productos en bottom 5% de ventas y disponibilidad superior al 80%',
          ACTIVE_NEW: 'A Activar',
          INACTIVE_NEW: 'A Desactivar',
          ASSORTMENT_FOR_CATEGORY__BY_CLUSTER: 'Manejo por Cluster',
          ASSORTMENT_FOR_CATEGORY__BY_CATEGORY: 'Manejo por Total General',
          LISTING_ALL_CLUSTERS: 'Activar en todos los Clusters',
          DELISTING_ALL_CLUSTERS: 'Desactivar en todos los Clusters',
          STATUS_PDV: 'Solo Surtido de Puntos de Venta',
          STATUS_COMBO: 'Parte de Receta o Combo',
          CHANGE_GENERAL_CONFIRM_DIALOG_TITLE: 'Confirmación de Asignación de surtido',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY: 'Quiere manejar este Ítem de Manera General?',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY_ALT:
            'Se cambiará la asignación de surtido a "General". Se perderán las asignaciones individuales de cada cluster.',
          WHITH_FILTERS: 'Con Filtros',
          WHITH_NO_FILTERS: 'Sin Filtros',
          setOrderBydefault: 'Ordenar por Pareto',
          setOrderBysalesShare: 'Ordenar por Share de Ventas',
          setOrderBysalesShareGap: 'Ordenar por Share GAP',
          EXIST_EXCEPTION_STORE_ASSORTMENT_TOOLTIP:
            'Existen Configuraciones Excepcionales de Surtido para este ítem.\nHaga click aquí para descargar el reporte',
          STORE_CLUSTER_COUNT_TOOLTIP_ACTIVE: 'El producto se encuentra Activo para la venta en {{val}} de {{val2}} PDV.',
          STORE_CLUSTER_COUNT_TOOLTIP_INACTIVE: 'El producto se encuentra Inactivo para la venta en {{val}} de {{val2}} PDV.',
        },
        ASSORT_CLUSTER_REPORTS: {
          GENERAL_REPORT: 'Reporte General',
          REPORT_DECISIONS: 'Reportes de Decisiones',
          CLUSTER_REPORT: 'Reporte por Cluster',
          PREFIX_REPORT: 'Reporte por',
        },
        ERP: {
          ROUTE: 'Surtidos / Decisiones',
          TITLE: 'Publicar Decisiones',
          DOWLOAD_BTN: 'Descargar Info',
          PREVIEW_BTN: 'Vista Previa',
          INFORM_BTN: 'Publicar',
          LAST_PRICES: 'Últimos Surtidos Publicados',
          GENERATION_DATE: 'Fecha de Generación',
          GENERATION_TIME: 'Hora de Generación',
          USER: 'Usuario',
          FILE: 'Archivo',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          PREVIEW_PROCCESING: 'El proceso ha comenzado. Recibirás una notificación cuando finalice.',
          PREVIEW_WITHOUT_PROCCESING: 'No hay nuevos datos para procesar.',
          REPORT_ASSORTMENT_DECISIONS: 'Decisiones de Surtido',
          REPORT_MARKET_ITEM: 'Productos de Mercado',
        },
      },
      REPORTS: {
        TITLE_DECISIONS: 'Reportes de Decisiones',
        CLUSTER_ANALYSIS: {
          ROUTE: 'Surtidos / Reportes',
          TITLE: 'Análisis de Cluster',
          SUBTITLE:
            'Prisma utiliza algoritmos de inteligencia artificial que permiten detectar patrones de compra de los consumidores y de esta manera poder validar la clusterización realizada. En esta pantalla podrás analizar la consistencia de las clusterizaciones previamente definidas y cambiar un punto de venta de un clúster en caso de ser necesario. Al aceptar una sugerencia de cambio, Prisma va a producir en forma automática la reasignación de surtido y el reporte que deberá ser publicado o puntos de venta para que el cambio sea correctamente implementado.',
          DISPERSION_TITLE: 'Dispersión de {{val}} por Cluster',
          EXPORT_TTIP: 'Exportar',
          APP_TTIP: 'Compartir a la App',
          DASHBOARD_TTIP: 'Ir al dashboard',
          NUMBER_OF_STORES: 'Cantidad de Puntos de Venta',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Mes Anterior',
          CLUSTER_QUANTITY: 'Cantidad de Cluster',
        },
      },
      ADMIN: {
        IMPORTERS: {
          MARKET_INFO: {
            TITLE: 'Información de Mercado',
          },
        },
      },
      ABM_MODAL: {
        ROLE_SELECTION: 'Selección de Rol',
        NEW_ROL_NAME: 'Datos del Rol',
        RULES_TO_DELIST: 'Reglas para Desactivar',
        RULES_TO_LIST: 'Reglas para Activar',
        MARKET_LIST: 'Alta de Mercado',
        VIEW: 'Ver',
        RULES_SELECT_LEGEND1:
          'Se tomará el pedido para generar las sugerencias para esta categoría, será notificado una vez se haya completado el proceso, puede tomar unos minutos.',
        RULES_SELECT_LEGEND2:
          'El rol se asigna a la categoría y combinado con la estrategia definen los criterios y las variables que se evaluarán en las sugerencias.',
        RULES_SELECT_LEGEND3: ' Las sugerencias solo se van a generar para los cluster visibles.',
        ASSIGN_ROLE: 'Asignar Rol',
        ROLES_TYPES_TITLES: {
          NORMAL: 'Rol por defecto de la categoría:',
          OTHER: 'Asignar Rol Creado previamente',
          NEW: 'Crear y asignar un nuevo Rol',
          TEST: 'Asignar Reglas de prueba',
          MARKET: 'Alta de productos de Mercado',
        },
        ROLES_TYPES_DESCRIPTION: {
          NORMAL: 'Se generarán las sugerencias de alta y baja de acuerdo al Rol ya seleccionado para esta categoría.',
          OTHER: 'Se generarán las sugerencias de alta y baja de acuerdo a la selección de un Rol dado de alta previamente.',
          NEW: 'Permite Crear y Asignar un nuevo rol para esta categoría.',
          TEST: ' Se asignarán y generarán reglas de Alta y de Baja de acuerdo a los seteos predefinidos de manera personalizada para esta categoría.',
          MARKET: 'Permite crear productos que no tienes en ItemMaster',
        },
        GENERATE_SUGGESTIONS_OK: 'Sugerencia asignada con éxito',
      },
    },
    IMV2: {
      LIST: {
        TITLE: 'Ítem Master',
        NEW_ITEM: 'Nuevo Ítem',
        ROUTE: 'item-master/list',
        DELETE: 'Inactivar',
        DELETE_TTIP: 'Desactiva el registro',
        UNDELETE: 'Activar',
        UNDELETE_TTIP: 'Activar el registro',
        DELETE_ROWS: 'Inactivar registros',
        DELETE_ROW_CONTENT: 'Seguro que desea inactivar {{val}}? solo podrá ser eliminado si no se encuentra en uso',
        DELETE_ROWS_CONTENT: 'Seguro que desea inactivar {{val}} productos? solo podrán ser eliminados si no se encuentran en uso',
        UNDELETE_ROW: 'Activar Registro',
        UNDELETE_ROWS: 'Activar Registros',
        UNDELETE_ROW_CONTENT: 'Seguro que desea activar {{val}}? al ser activado será iniciado con el estado nuevo',
        UNDELETE_ROWS_CONTENT: 'Seguro que desea activar {{val}} productos? al ser activados los mismos iniciarán con el estado nuevo',
        ACTION_ROW_CONTENT_SUCCESS: 'Acción completada correctamente',
        ENTITIES: {
          REPORT: 'Reporte completo aquí',
          APPLY: 'Debido a encontrarse en uso en las siguientes entidades:',
          ITEM_NOT_FOUND: 'Producto/s no encontrados',
          USEDINCOMBOS: 'Combos: {{val}}',
          USEDINMISSIONS: 'Misiones: {{val}}',
          USEDINPARENTRULES: 'Reglas de precios: {{val}}',
          USEDINPLANOGRAMS: 'Planogramas: {{val}}',
          USEDINPROMOTIONS: 'Promociones: {{val}}',
          USEDINRECIPES: 'Recetas: {{val}}',
          IMPEDIMENT: {
            USEDINPARENTRULES:
              'Producto padre no puede quedar vacío. Borrar regla y crear una nueva con otro producto padre si se requiere.',
            USEDINMISSIONS: 'Producto regalo no puede quedar vacío. Asignar otro producto regalo primero.',
            USEDINPLANOGRAMS: 'No puede eliminarse un producto presente en un planograma. Quitar del planograma primero.',
            USEDINRECIPES: 'No se puede borrar un producto que es parte de una receta. Quitar de la receta primero.',
            USEDINCOMBOS: 'No puede eliminarse el último producto de un bloque. Agregar otro producto al bloque o eliminar el bloque.',
          },
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: 'El inconveniente fue producido por ',
          TYPE: 'Tipo: {{val}}',
          KEYWORD: 'Palabra clave: {{val}}',
          VALUES: 'Valores: {{val}}',
          WARNING: 'Alerta',
          ERROR: 'Error',
          ENTITYNOTFOUND: 'Entidad no encontrada',
        },
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Maximizar',
        EXPORT_TTIP: 'Exportar',
        NAME: 'Nombre',
        CODE: 'Código',
        INTERNAL_CODE: 'Código Interno',
        CODE_EXIST: 'El código {{val}} ya existe',
        TYPE: 'Tipo',
        CATEGORY: 'Categoría',
        SUBCATEGORY: 'Subcategoría',
        BRAND: 'Marca',
        EDIT_ROW: 'Editar registro',
        VIEW_ROW: 'Ver registro',
        EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
        COPY_ROW: 'Copiar registro',
        DISABLED_CLONE_TOOLTIP: 'Solo se puede clonar registros en estado publicado',
        DISABLED_CLONE_TOOLTIP_OR_NEW: 'Solo se puede clonar registros en estado publicado o nuevo',
        DELETE_ROW: 'Inactivar registro',
        TYPE_FILTERS: 'Tipos de ítems',
        SEE_DELETED_FILTERS: 'Ver Inactivos',
        SEE_ITEMS_CREATED_WHIT_MARKET_RULE: 'Ver Ítems creados en base a Información de Mercado',
        SHORT_NAME: 'Descripción Corta',
        STATUS: 'Estado',
        STATUS_NEW: 'Nuevo',
        STATUS_MODIFIED: 'Modificado',
        STATUS_CURRENT: 'Publicado',
        EAN: 'Código Interno',
        ITEM_CREATION_ON: 'Fecha de creación',
        ITEM_MODIFY_ON: 'Fecha de modificación',
        ASSORTMENTBEHAVIORS: 'Surtido',
        STORES_FILTER: 'Puntos de Venta',
        CLONE_ITEM_TITLE: 'Clonar Ítem',
        CLONE_ITEM_CONTENT:
          'Definir nombre y código interno al nuevo ítem. Se clonaran de {{val}} ({{val2}}) todos los puntos ya existentes, teniendo la posibilidad de edición.',
        ITEM_TAGS: 'Filtros para Productos',
        ITEM_FILTER: 'Producto',
        ACTION_ROW_CONTENT_FAILED: 'La acción no puede llevarse a cabo correctamente',
      },
      EDIT: {
        ITEM: {
          SAVE: 'Guardar',
          PUBLISH: 'Publicar',
          WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
          WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
          NOVALID_PUBLISH_ADVICE: 'El siguiente tab no es válido:',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          CLASSIFICATION_TAB: 'Clasificación',
          UNITS_OF_MEASURE_TAB: 'Unidades de Medida',
          ASSORTMENT_TAB: 'Surtido',
          WARNING_SEGMENT: 'Es necesario completar el campo Segmento en Tab de Surtido, para poder publicar los cambios.',
          VENDORS_TAB: 'Proveedores, Cost. e Imp.',
          PRICES_TAB: 'Precios',
          INGREDIENTS_TAB: 'Ingredientes',
          COMPONENTS_TAB: 'Componentes',
          CODES_TAB: 'Códigos',
          CODES_MATCHING: 'Equivalencias',
          DELIVERY_TAB: 'Canales de venta',
          LOADING: 'Cargando...',
          NEW_ITEM_BTN: 'Nuevo Ítem',
          PUBLISH_CONFIRM:
            'El proceso de publicación incluye la creación del Surtido, Precios, Costos y otros registros relacionados al Producto.<br><br><b>Estás seguro que deseas Publicar?</b>',
          PUBLISH_CONFIRM_TITLE: 'Confirmación de Publicación',
          PUBLISHING_WARNING: 'Ítem en proceso de publicación, vuelva a intentar en unos minutos.',
          PUBLISH_LONG_TIME:
            'La publicación de este Producto puede demorar algunos minutos, cuando finalice serás avisado con una notificación.',
          PUBLISH_InvalidCombo: 'El combo es inválido, verifique en la solapa correspondiente al producto.',
          PUBLISH_InvalidRecipe: 'La receta es inválida, verifique en la solapa correspondiente al producto',
          PUBLISH_Vendors: 'Existe un error en los proveedores asociados, verifique la solapa correspondiente a proveedores.',
        },
        MAIN_DATA: {
          NAME: 'Nombre',
          SHORT_NAME: 'Descripción Corta',
          CREATION_DATE: 'Fecha de Creación',
          INTERNAL_CODE: 'Código Interno',
          VALIDATION_CODE2: 'El código {{val}} ya existe.',
          BAR_CODE: 'Código de barras',
          REGIONAL_ITEM: 'Producto regional',
          REGIONAL_ITEM_CHANGE: 'Cambiar a Producto estándar',
          REGIONAL_ITEM_DISABLED: 'No tiene permisos para cambiar a Producto estándar',
          REGIONAL_ITEM_MODAL_TITLE: 'Cambiar estado de producto regional',
          REGIONAL_ITEM_MODAL_CONTENT:
            'Seguro que desea modificar el producto para dejar de ser del tipo Producto Regional? Esta operación es irreversible. Solo por medio de interfaces pueden crearse productos del tipo Regional y no pueden modificarse hacia este estado otro tipo de productos.',
        },
        UNITS_OF_MEASURE: {
          NAME: 'Nombre',
          DUP_CODE_AND_CONVERSION: 'No pueden existir 2 unidades de medida con exacto nombre y factor de conversión.',
          ONLY_SINGLE_SELLING_UNIT_UOM: 'No pueden existir 2 unidades de medida de venta.',
          UNIT_NAME: 'Nombre de la unidad',
          CONVERSION: 'Factor de Conversión',
          UNIT_BASE: 'Unidad Base',
          SALES_UNITS: 'Unidad de Venta',
          SOME_SELLING_UNIT: 'Debe existir al menos una unidad de venta.',
          INITIAL_PRICE: 'Precio Inicial',
          INITIAL_PRICE_TIP: 'Este precio se va a aplicar a todas las tiendas donde el producto esté activo',
          FRONT_PHOTO: 'Foto del Ítem de Frente',
          SIDE_PHOTO: 'Foto del Ítem del Lateral',
          PHOTO_ABOVE: 'Foto del Ítem de Arriba',
          EXHIBITION_UNITS: 'Unidad de Exhibición',
          FACINGS_LIMIT: 'Límites de frentes',
          MIN_FACES: 'Mínimo de frentes',
          MAX_FACES: 'Máximo de frentes',
          MAG_HEIGHT_WITH_MAG: 'Alto (cm.)',
          MAG_WIDTH_WITH_MAG: 'Ancho (cm.)',
          MAG_DEPTH_WITH_MAG: 'Profundidad (cm.)',
          STACKABLE_SWITCH: 'Apilable',
          OVERTHROW_SWITCH: 'Tumbable',
          ROTATE_SWITCH: 'Rotable',
          MAX_STACKS: 'Máximo de pilas',
          NEW_UNIT_BTN: 'Nueva Unidad',
          REGULATED_PRICE_MAX: 'Precio Máximo',
          REGULATED_COST_MAX: 'Costo Máximo',
          REGULATED_PRICE_TIP: 'Define el valor máximo para el precio (Precio Regulado)',
          REGULATED_COST_TIP: 'Define el valor máximo para el costo (Costo Regulado)',
          FORCE_REGULATED_PRICE_MAX: 'Forzar Precio Máximo',
          FORCE_REGULATED_COST_MAX: 'Forzar Costo Máximo',
          REGULATED_PRICE_MARGIN: 'Margen',
          REGULATED_PRICE_MARGIN_TIP: 'Define el Margen de Precio (Margen para Precio Regulado)',
          TOOLTIP_SINGLE_SELLING_UNIT_UOM: 'Solo se permite una unidad de venta.',
        },
        RECIPE: {
          COMPONENT: 'Componente',
          TYPE: 'Tipo',
          NO_UOM: 'Ítem sin Unidad de medida',
          UOM: 'Unidad de medida',
          QUANTITY: 'Cantidad',
          WASTE_INPUT: 'Desperdicio %',
          COST: 'Costo',
          TAX: 'Impuesto',
          TOTAL_COST: 'Costo Total Aproximado:',
          ADD_COMPONENT: 'Agregar Componente',
        },
        COMBO: {
          NAME: 'Nombre',
          QUANTITY: 'Cantidad',
          NO_UOM: 'Ítem sin Unidad de medida',
          UOM: 'Unidad de medida',
          MAX_COST_TIP: 'Se tiene en cuenta el componente con mayor costo',
          TOTAL_COST: 'Costo Total Aproximado:',
          ADD_BLOCK: 'Agregar bloque',
          ADD_COMPONENT: 'Agregar Componente',
          COST: 'Costo',
          TAX: 'Impuesto',
        },
        CODES: {
          HELP_BOX: 'En esta sección se pueden agregar códigos de barra adicionales u otros códigos externos.',
          CODE_TYPE: 'Tipo de código',
          CODE: 'Código',
          VALIDATION_CODE2: 'El código {{val}} ya existe.',
          VALIDATION_CODE_DUPLICATE: 'Hay códigos duplicados',
          INVALID_CODE: 'Formato de código inválido.',
          UNITS_OF_MEASURE_BUY: 'Unidad de Medida',
          ADD_CODE: 'Agregar Código',
          NEW_CODE: 'Nuevo Código',
          PRIMARY_CODE_DELETE_WARNING: 'No es posible eliminar un Código Primario.',
        },
        MATCHINGS: {
          ADD_MATCHING: 'Agregar equivalencia',
          ITEM_CODE_TYPE: 'Tipo de código del ítem',
          ITEM_CODE: 'Código del ítem',
          ITEM_NAME: 'Nombre del ítem',
          DUPLICATED_FLAGS: 'Combinación Código de ítem-Bandera duplicada',
          INVALID_CODE: 'Código incorrecto',
          NAME_REQUIRED: 'Nombre requerido',
          FLAG_REQUIRED: 'Bandera requerida',
          MATCHING_OF: 'Equivalencia de',
          CONVERTION_RATE: 'Factor de conversión',
          CONVERTION_RATE_REQUIRED: 'Factor de conversión requerido',
        },
        ASSORTMENT: {
          NOT_PARAMETERIZED: 'Para habilitar este botón se debe completar la parametrización de la categoría en Surtido.',
          NOT_ACCESS_PERMISSION: 'No tienes permisos para completar esta acción',
          NOTIFY_USER: 'NOTIFICAR AL USUARIO',
          NOTIFIED_USER: 'Usuario notificado',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_BTN: 'Carga de surtido excepcional',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Podrá cargar excepciones de surtido por medio del importador o consultar el estado de surtido referente a este producto',
          BTN1_TITLE: 'Basado en el surtido',
          BTN1_MSG:
            'Toma en cuenta la segmentación principal definida para la categoría, pudiendo hacer excepciones mediante un importador.',
          BTN2_TITLE: 'Activo en todos los Pts. de Venta',
          BTN2_MSG: 'El producto va a estar activo en todos los puntos de venta.',
          BTN3_TITLE: 'Solo si es parte de Receta o Combo',
          BTN3_MSG: 'El producto puede  participar de una receta o de un combo.',
          BTN4_TITLE: 'Solo Surtido de Pts. de Venta',
          BTN4_MSG: 'El producto va a estar activo en determinados Puntos de Venta',
          ASSORTMENT_SEGMENT_SETUP_TITLE: 'Definir segmento',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_1: 'Definir segmento para la categoría {{val}} .',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_2: 'Este cambio impactará en la pantalla de asignación de segmentos en el módulo de Surtidos.',
          SEGMENT: 'Segmento',
          DEFINE: 'Definir',
          CANCEL: 'Cancelar',
          IMPORT_TITLE: 'Importar Puntos de Venta',
          ASSORTMENTBEHAVIORS_BASEDONASSORTMENT: 'Basado en el surtido',
          ASSORTMENTBEHAVIORS_ACTIVEINALLSTORES: 'Activo en todos los puntos de venta',
          ASSORTMENTBEHAVIORS_ONLYIFPARTOFRECIPEORCOMBO: 'Solo si es parte de Receta o Combo',
          ASSORTMENTBEHAVIORS_ONLYSTOREASSORTMENT: 'Solo surtido de puntos de venta',
          INIT_ASSORMENT: 'Configuración de surtido',
          CHOOSE_OPT_ASSORMENT: 'Elegir comportamiento de surtido',
          BLOCKED_FOR_PURCHASE: 'Bloqueo de compra',
          BLOCKED_FOR_SALES: 'Bloqueo de venta',
          CLONE_EXCEPTION_STORE_ASSORTMENT: 'Clonar Excepciones de Surtido',
          CLONE_EXCEPTION_STORE_ASSORTMENT_MSG:
            'Al habilitar esta opción se clonaran las excepciones de surtido de este registro. Al menos debe existir una excepción de surtido que no esté bloqueada para la venta',
          DOWLOAD_EXCEL: 'Descargar excel',
          VIEW_ASSORTMENT_STATUS: 'Reporte de Surtido',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'Se requiere guardar los cambios realizados',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Se detectaron cambios que afectan al estado del Surtido, para poder acceder debe guardar los cambios realizados',
          IMPORT_ITEMS: 'Surtido por punto de venta',
          IMPORT_EXCEL_ITEMS_FIXED_PRICE: 'Importar Precios Fijos',
          IMPORT_EXCEL_ITEMS_DISCOUNT: 'Importar Descuentos',
          IMPORT_EXCEL_ITEMS: 'Importar excepciones',
          DELETE_EXCEL_ITEMS: 'Eliminar excepciones',
          DELETE_EXCEL_ITEMS_MESSAGE: 'Se eliminarán todas las excepciones cargadas.',
          IMPORT_EXCEL_ITEMS_WARNING:
            'Para poder importar excepciones de surtido y consultar el estado del mismo, es necesario al menos tener los datos principales guardados. ',
          CATEGORY_CONFIG_WARNING:
            'Para utilizar comportamiento basado en surtido, se requiere que la categoría de este ítem tenga asignadas  Segmentación y Clusterización.',
          CONFIG_CATEGORY: 'Asignar Segmentación y Clusterización a Categoría',
          CONFIG_TAG: 'Asignar valores a la etiqueta',
          CONFIG_TAG_CONTENT: 'El segmento que pertenece a la categoría seleccionada no tiene valores',
          DEFINE_SEGMENT: 'DEFINIR SEGMENTO',
          CATEGORY_WARNING:
            'La categoría  {{val}} no tiene una segmentación principal definida, elija una opción para poder avanzar con la inicialización de surtido.',
          CATEGORY_SELECT_WARNING:
            'Se necesita seleccionar una categoría en este producto para poder avanzar con la inicialización de surtido.',
          NAME: 'Nombre',
          CODE: 'Código',
          ACTIVE: 'Activo',
          BLOCQUED_SELL: 'Bloqueado para la venta',
          BLOCQUED_BUY: 'Bloqueado para la compra',
          OBSERVATIONS: 'Observaciones',
        },
        PRICES: {
          SUGGESTED_PRICE_INPUT: 'Precio Fijo a nivel Nacional',
          DINAMIC_PRICE_INPUT: 'Descuento % sobre componentes',
          PRICE_TYPE_INPUT: 'Tipo de Precio',
          DISCOUNT_INPUT: 'Descuento',
          BTN3_TITLE: 'En base al precio de sus componentes',
          BTN3_MSG: 'Cada vez que cambies el precio de un componente, el precio del combo puede cambiar.',
          PERMANENT_PRICE_INPUT: 'Precio Fijo',
          BTN1_TITLE: 'Precio Nacional',
          BTN1_MSG: 'Toma en cuenta el precio único inicial definido para cada una de las unidades de medida de venta',
          BTN2_TITLE: 'Clonar Precio',
          BTN2_MSG: 'Clonar el precio de un producto existente',
          INIT_PRICE: 'Inicialización de Precios',
          CHOOSE_INIT_PRICE: 'Elegir una opción de inicialización de precios',
          REVIEW_CHANGES: 'Para consultar cambios de precios dirigirse a ',
          PRICE_DECISIONS: 'Decisiones de Precios',
          NO_PRICE_TIP:
            'En caso de no encontrar precio en algún punto de venta del producto clonado, se utilizará el precio inicial de la unidad de medida de venta.',
          INITIAL_PRICE: 'Precio Inicial',
          SELL_UOM: 'Unidad de medida de venta',
          IVA: 'IVA',
          VIEW_ASOCIATED_RULES: 'VER REGLAS ASOCIADAS',
          CONFIRM_CLONE_VALIDATION_MSG:
            'El producto del cual deseas clonar precios no tiene las mismas unidades de medida. Se recomienda elegir un producto con las mismas unidades de medida de venta.',
          CONFIRM_CLONE_VALIDATION_TITLE: 'Confirmar selección',
          CONFIRM_CLONE_VALIDATION_OK: 'Continuar con selección actual',
          CONFIRM_CLONE_VALIDATION_CANCEL: 'Elegir nuevo producto',
          VIEW_ASOCIATED_RULES_TITLE: 'Sin reglas asociadas',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'No se puede consultar un producto que no fue publicada',
        },
        VENDORS: {
          TITLE: 'Proveedores, Costos e Impuestos',
          SUBTITLE:
            'Definir un proveedor/es para este producto. Si elije con una segmentación, todos los puntos de venta deberán tener un valor asignado y de esta manera podran cargar costos e impuestos específicos para cada valor del segmento.',
          VENDOR: 'Proveedor',
          PRIMARY_VENDOR_WARNING: 'Al menos debe existir un Proveedor primario.',
          PRIMARY_VENDOR_WARNING_ONLY_ONE: 'No puede haber más de un Proveedor primario.',
          PRIMARY_VENDOR_WARNING_UOM: 'No puede existir el mismo Proveedor con la misma unidad de medida de compra.',
          PRIMARY_VENDOR_WARNING_DUP: 'No es posible dar de alta un proveedor más de una vez.',
          REGIONAL_PRODUCT_WITH_ONE_VENDOR: 'No se puede tener más de un proveedor',
          PRIMARY_VENDOR_DELETE_WARNING: 'No es posible eliminar un Proveedor primario.',
          INTERNAL_CODE: 'Código Interno del Proveedor',
          SEGMENT: 'Segmento',
          SEGMENTATION: 'Segmentación',
          UOM_BUY: 'Unidad de medida de compra',
          MIN_ORDER: 'Orden Mínima',
          MAX_ORDER: 'Orden Máxima',
          NEW_VENDOR: 'Asociar Proveedor',
          UNIQUE_COST: 'Costo único',
          UNIQUE_COST_NATIONAL: 'Costo nacional',
          SEGMENT_COSTS: 'Segmentar Costos',
          PRIMARY_VENDOR: 'Proveedor primario',
          SECONDARYY_VENDOR: 'Proveedor secundario',
          INDICATE_TEXT: 'indicar los valores de costos e impuestos correspondientes a:',
        },
      },
    },
    ITEM_MASTER: {
      TITLE: 'Ítem Master',
      NEW_ITEM_BTN: 'Nuevo Ítem',
      DUPLICATED_ITEM: 'Ítem duplicado',
      FILTER_TTIP: 'Filtrar',
      FULLSCREEN_TTIP: 'Expandir',
      EXITFULLSCREEN_TTIP: 'Reducir',
      EXPORT_TTIP: 'Exportar',
      IMPORT_TTIP: 'Importar',
      PRINT_TTIP: 'Imprimir',
      DELETE_TTIP: 'Borrar ítems seleccionados',
      DELETE: 'Eliminar',
      RECOVER: 'Recuperar',
      RECOVER_TTIP: 'Activar ítems seleccionados',
      NAME: 'Nombre',
      CODE: 'Código',
      TYPE: 'Tipo',
      CATEGORY: 'Categoría',
      SUBCATEGORY: 'Subcategoría',
      BRAND: 'Marca',
      CREATION_DATE: 'Fecha de Creación',
      RECOVER_ITEMS_TITLE: 'Desea recuperar los siguientes ítems eliminados?',
      RECOVER_ITEMS_SUBTITLE: 'Estos ítems desaparecerán del listado de eliminados.',
      CANCEL_BTN: 'Cancelar',
      CONFIRM_BTN: 'Confirmar',
      NEW_ITEM_MODAL: 'Nuevo Ítem',
      ITEM_TYPE_INPUT: 'Tipo de Ítem',
      LEGEND_RETAIL:
        'Los ítems Retail son productos regulares que se venden en los puntos de venta sin sufrir transformaciones o combinaciones con otros ítems.',
      LEGEND_INGREDIENT: 'Estos ítems no pueden venderse de forma individual sino que forma parte de una receta.',
      LEGEND_RECIPES: 'Es un ítem que se compone de uno o varios ingredientes.',
      LEGEND_SUPPLY: 'Estos ítems no pueden venderse de forma individual sino que forma parte de una receta.',
      CREATE_BTN: 'Crear',
      TITLE_FILTERS: 'Filtros',
      SEE_DELETED_FILTERS: 'Ver Eliminados',
      TYPE_FILTERS: 'Tipo',
      CLEAN_FILTER_BTN: 'Limpiar',
      APPLY_FILTER_BTN: 'Aplicar',
      DEFINE_ITEM_STP1: 'Definir Ítem',
      CLASSIFICATION_STP2: 'Clasificación',
      LOGISTICS_DATA_STP3: 'Datos Logísticos',
      INTEGRATION_STP3: 'Integración',
      PURCHASE_DATA_STP4: 'Datos de Compra',
      SALES_DATA_STP5: 'Datos de Venta',
      EDIT_TITLE_FORM: 'Editar',
      NEW_TITLE_FORM: 'Nuevo',
      INGREDIENT_TITLE: 'Ingrediente',
      SUPPLY_TITLE: 'Insumo',
      RETAIL_TITLE: 'Retail',
      RECIPE_TITLE: 'Ítem - Receta',
      COMBO_TITLE: 'Ítem - Combo',
      ITEM: 'Ítem',
      STP1: 'Paso 1 - Definir Ítem',
      INTERNAL_CODE_INPUT: 'Código Interno',
      ERROR: 'incorrecto',
      EAN_INPUT: 'Código EAN',
      TYPE_INPUT: 'Tipo',
      NAME_INPUT: 'Nombre',
      SHORT_DESCRIPTION_INPUT: 'Descripción corta',
      SHORT_DESCRIPTION_VALIDATION: 'Caracteres ingresados:',
      CREATION_DATE_INPUT: 'Fecha de Creación',
      NEXT_BTN: 'Siguiente',
      STP2: 'Paso 2 - Clasificación del Ítem',
      CATEGORY_INPUT: 'Categoría',
      SEARCH_CATEGORY: 'Buscar Categoría...',
      SUBCATEGORY_INPUT: 'Subcategoría',
      SEARCH_SUBCATEGORY: 'Buscar Subcategoría...',
      BRAND_INPUT: 'Marca',
      SEARCH_BRAND: 'Buscar Marca...',
      ADD_BRAND_BTN: 'Agregar Marca',
      PREVIUS_BTN: 'Anterior',
      STP3: 'Paso 3 - Datos Logísticos',
      UNIT_NAME: 'Nombre Unidad',
      UNIT_NAME_INPUT: 'Nombre de la Unidad',
      DELETE_UM: 'Eliminar Unidad de Medida',
      CODE_INPUT: 'Código',
      REQUIRED: 'Campo obligatorio',
      CODE_VALIDATION: 'Código debe ser Único',
      UNIT_REPEATED: 'Unidad Repetida',
      CODE_REPEATED: 'Código repetido',
      CREATE_UNIT: 'Crear nueva Unidad',
      CONVERSION_FACTOR_INPUT: 'Factor de Conversión',
      UNIT_BASE_CHECK: 'Es Unidad Base',
      NEW_UNIT_BTN: 'Nueva Unidad',
      UNIT_ALREADY_EXIST: 'Unidad ya existente',
      STP4: 'Paso 4 - Datos de Compra',
      STP5: 'Paso 5 - Datos de Compra',
      PURCHASE_UNIT_INPUT: 'Unidad de Compra',
      QUANTITY_UMB_INPUT: 'Cantidad x UMB',
      SUPPLIER_INPUT: 'Proveedores',
      SEARCH_SUPPLIER: 'Buscar Proveedores...',
      COST_INPUT: 'Costo',
      PURCHASE_IVA_INPUT: 'Iva Compra',
      SALE_IVA_INPUT: 'Iva Venta',
      SAVE_BTN: 'Guardar',
      STP_4_2: 'Paso 4 - Integración',
      UNITS_OF_MEASURE_INPUT: 'Unidad de Medida',
      WASTE_INPUT: 'Desperdicio %',
      STP5_R: 'Paso 5 - Datos de Venta',
      SALES_UNITS: 'UNIDAD DE VENTA',
      SALES_UNITS_DELETE_TTIP: 'Eliminar Unidad de Venta',
      SALES_UNITS_INPUT: 'Unidad de Venta',
      SALES_UNITS_VALIDATION: 'Unidad ya seleccionada',
      HIGH_INPUT: 'Alto',
      WIDTH_INPUT: 'Ancho',
      DEPTH_INPUT: 'Profundidad',
      MAX_STACKABLE: 'Máxima cantidad de apilados',
      OVERTHROW_SWITCH: 'Tumbable',
      ROTATE_SWITCH: 'Rotable',
      ROTATEY_SWITCH: 'Girable',
      EXHIBITION_UNITS_INPUT: 'Unidad de Exhibición',
      STACKABLE_SWITCH: 'Apilable',
      FRONT_PHOTO: 'Cargar foto del Ítem de Frente',
      SIDE_PHOTO: 'Cargar foto del Ítem del Lateral',
      PHOTO_ABOVE: 'Cargar foto del Ítem de Arriba',
      COMPOSITION_STP3: 'Composición',
      STP3_RECIPES: 'Paso 3 - Composición de la Receta',
      INGREDIENT: 'Ingrediente',
      ITEM_TITLE: 'Ítem',
      INGREDIENT_DELETED_TTIP: 'Eliminar el {{val}}° ingrediente',
      ITEM_DELETED_TTIP: 'Eliminar el {{val}}° ítem',
      INGREDIENT_INPUT: 'Ingrediente',
      ITEM_INPUT: 'Ítem',
      SEARCH_INGREDIENT: 'Buscar Ingredientes...',
      QUANTITY_INPUT: 'Cantidad',
      NEW_INGREDIENT_BTN: 'Nuevo Ingrediente',
      DEFINE_COMBO_STP1: 'Definir Combo',
      COMBO_ARMING_STP3: 'Armado del Combo',
      PRICE_STP4: 'Precio',
      STP1_COMBO: 'Paso 1 - Definir Combo',
      STP3_COMBO: 'Paso 3 - Armado del Combo',
      BLOCK_NAME_INPUT: 'Nombre del Bloque',
      BLOCK_DELETED_TTIP: 'Eliminar Bloque',
      ITEMS_INPUT: 'Ítems',
      SEARCH_ITEMS: 'Buscar Ítems...',
      ADD_BLOCK_BTN: 'Agregar Bloque',
      STP4_COMBO: 'Paso 4 - Definición de tipo de precio',
      PRICE_TYPE_INPUT: 'Tipo de Precio',
      DISCOUNT_INPUT: 'Descuento',
      FIXED_PRICE_MESSAGE: 'El precio fijo a nivel Nacional es el mismo precio para todos los puntos de venta',
      DISCOUNT_MESSAGE:
        'Toma en cuenta el precio del componente más barato de cada bloque y sólo los ítems que están activos en surtido para cada Punto de Venta.',
      SUGGESTED_PRICE_INPUT: 'Precio Fijo a nivel Nacional',
      PERMANENT_PRICE_INPUT: 'Precio Fijo',
      EXCLUDED_RULES_CHECK: 'Este combo quedará excluido de las reglas de margen y competencia',
      EXCLUDED_RULES_CHECK_ALT: 'Este combo quedará incluido de las reglas de margen y competencia',
      DINAMIC_PRICE_INPUT: 'Descuento % sobre componentes',
      PRICE_STRATEGY: '¿Cómo vas a definir el precio del combo?',
      INIT_PRICE: '¿Cómo vas a definir su precio inicial?',
      INIT_PRICE_EDIT: '¿Desea cambiar el precio actual?',
      PRICE_STRATEGY_COMPONENT_TITLE: 'En base al precio de sus componentes',
      PRICE_STRATEGY_COMPONENT_MSG: 'Cada vez que cambies el precio de un componente, el precio del combo puede cambiar.',
      PRICE_STRATEGY_RULE_TITLE: 'En base a reglas de competencia o parentesco',
      PRICE_STRATEGY_RULE_MSG:
        'Cuando cambie el precio de un competidor o cuando cambie el precio de un producto padre, Prisma sugerirá un cambio de precios.',
      SUGGESTED_PRICE_TEXT:
        ': Mínimo precio de los productos en todos los bloques - Mismo precio a todos los puntos de venta, partiendo o no del sugerido.',
      DINAMIC_PRICE_TEXT:
        ': Precio Actual de cada punto de venta (según sus productos ) + descuento. No se verá afectado por las reglas de parentesco.',
      DINAMIC_PRICE_TTIP: 'Precio Dinamico: Precio actual de cada puntos de venta (según sus productos) + descuento',
      NEW_UNIT: 'Nueva Unidad',
      NAME_INNPUT: 'Nombre',
      ADD_BTN: 'Agregar',
      NEW_INGREDIENT: 'Nuevo Ingrediente',
      FREE_STORES: 'Puntos de Venta sin costo ni precio',
      FREE_STORES_SUBTITLE: 'Se generaron costos y precios para todos los puntos de venta',
      ACCEPT_BTN: 'Aceptar',
      ERROR1:
        'No se generó costo ni precio para estos puntos de venta, porque alguno de los productos que comprende algunos de los combos, no tiene precio.',
      ERROR2: 'No se generó costo ni precio para estos puntos de venta, porque alguno de sus ingredientes, no tiene costo.',
      NEW_BRAND: 'Nueva Marca',
      NEW_SUPPLIER: 'Nuevo Proveedor',
      INFO_PERMISSION: 'El usuario actual tiene permiso solo para editar atributos, cualquier otro cambio no será realizado',
      Retail: 'Retail',
      Ingrediente: 'Ingrediente',
      Receta: 'Receta',
      Combo: 'Combo',
      Supply: 'Insumo',
      Insumo: 'Insumo',
    },
    SUPPLY: {
      SETUP: {
        ORDER_SCHEDULE: {
          TITLE: 'Agenda de Pedidos',
          ROUTE: 'Abastecimiento / Configuración',
          DEFINITION: 'Definición de Centro / Proveedor',
          SCOPE_PDV: 'Alcance de Puntos de Venta',
          FREQUENCY: 'Frecuencia',
          NEW_ORDER_BTN: 'NUEVA AGENDA',
          STORES_FILTER: 'Puntos de Venta',
          SUPPLY_FILTER: 'Proveedor',
          TYPE_ALL: 'Todos',
          TYPE_SUPPLIER: 'Proveedor',
          TYPE_DISTRIBUTION_CENTER: 'Centro de Distribución',
          TITLE_SCHEDULE_TO_LEAD: 'Agenda a cargar',
          TITLE_FRECUENCY: 'Frecuencia',
          TITLE_INCLUDES: 'Incluye',
          TITLE_LEAD_TIME: 'Lead Time (días)',
          TITLE_OVERLAPP: 'Agendas Solapadas',
          TTIP_OVERLAPP: 'Ver agendas solapadas',
          BTN_OVERLAPP_MODAL_CLOSE: 'Cerrar',
          PH_CATEGORYS: 'Categorías',
          PH_CATEGORYS_EXC: 'Categorías excluidas',
          FORM: {
            EDIT: 'Editar Agenda',
            NEW: 'Nueva Agenda',
            STEP: 'Paso',
            NEXT_BTN: 'Siguiente',
            PREVIUS_BTN: 'Anterior',
            SAVE_BTN: 'Guardar',
            SCOPE_PDV: {
              CODE_SEARCH: 'Buscar por código',
              TITLE: 'Selección Avanzada',
              DESC: 'Aplicar filtros a tu búsqueda para poder llegar a el resultado deseado. Está opción anulará la selección por filtros.',
              CLIPBOARD_TITLE: 'Pegar puntos de venta desde portapapeles',
            },
            FRECUENCY: {
              HOLIDAY_RECEPTION: 'En caso que el día de "Recepción de pedido"/"Entrega de mercadería" sea feriado/no laborable',
              NEXT_BUSINESS_DAY: 'El pedido se recibe próximo día hábil',
              NEXT_DAY_STIPULATED: 'El pedido se recibe próximo día estipulado en Agenda.',
            },
          },
          TYPEOFDELIVER: {
            DELIVERY: 'Entrega de productos',
            RECEIPT: 'Recepción de pedidos',
          },
          FREQUENCYTIME: {
            DAILY: 'Diaria',
            WEEKLY: 'Semanal',
            MONTHLY: 'Mensual',
          },
          HOLIDAYSHANDLING: {
            WEEKLY_DAYS_NO_HOLIDAYS: 'Sólo días de la semana excepto feriados',
            ALL_DAYS_NO_HOLIDAYS_NO_SUNDAYS: 'Todos los días excepto feriados y domingo',
            ALL_DAYS_NO_HOLIDAYS: 'Todos los días excepto feriados',
            ALL_DAYS: 'Todos los días',
          },
        },
        ORDER_SCHEDULE_LIST: {
          TYPE_FILTER: 'Tipos',
          STORES_FILTER: 'Puntos de venta',
          NAME: 'Proveedor / CD',
          NAME_TTIP: 'Proveedor o Centro de Distribución',
          CATEGORY: 'Categorías',
          STORES: 'Pts. de Venta',
          STORES_TTIP: 'Puntos de Venta',
          FREQUENCY: 'Frecuencia',
          MONDAY_CHAR: 'L',
          TUESDAY_CHAR: 'M',
          WEDNESDAY_CHAR: 'M',
          THURSDAY_CHAR: 'J',
          FRIDAY_CHAR: 'V',
          SATURDAY_CHAR: 'S',
          SUNDAY_CHAR: 'D',
        },
      },
      DECISIONS: {
        ORDER_CONTROL: {
          TITLE: 'Control de Órdenes',
          ROUTE: 'Abastecimiento / Decisiones',
          INFORM_BTN: 'Informar',
          NEW_ORDER_BTN: 'Nueva Orden',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXPORT_TTIP: 'Exportar',
          STATUS_FILTER: 'Estado',
          TYPE_FILTER: 'Tipo de movimiento',
          TYPE_ALL: 'Todos',
          TYPE_AUTOMATIC: 'Automático',
          TYPE_MANUAL: 'Manual',
          ORIGIN_FILTER: 'Origen',
          DESTINATION_FILTER: 'Destino',
          CREATION_DATE_FILTER: 'Fecha de Pedido',
          PLANNING_DATE_FILTER: 'Fecha Entrega Objetivo',
          DELIVERY_DATE_FILTER: 'Fecha de Entrega',
          NRO: 'Nro.',
          TYPE: 'Tipo',
          TYPE_TOOLTIP: 'Tipo de movimiento',
          ORIGIN: 'Origen',
          DESTINATION: 'Destino',
          CREATION_DATE: 'Fecha de Pedido',
          CREATION_DATE_TTIP: 'Fecha de Pedido',
          PLANNING_DATE: 'Fecha Ent. Objetivo',
          PLANNING_DATE_TTIP: 'Fecha Entrega Objetivo',
          DELIVERY_DATE: 'Fecha de Entrega',
          DELIVERY_DATE_TTIP: 'Fecha de Entrega',
          STATUS: 'Estado',
          VALUE: '$ Costo',
          DECISIONS: 'Decisión',
          SAVE_BTN: 'Guardar',
          HEADER: 'Cabecera',
          PRINT_TTIP: 'Imprimir',
          ADD_TTIP: 'Agregar Productos al pedido',
          COPY_TTIP: 'Agrega productos desde una lista copiada en tu portapapeles',
          CODE: 'Código',
          DESCRIPTION: 'Descripción',
          CATEGORY: 'Categoría',
          BRAND: 'Marca',
          APPROVE: 'Aprobar',
          REJECT: 'Rechazar',
          INFORM: 'Informar',
          TYPE_INPUT: 'Tipo de movimiento',
          CODE_INPUT: 'Código',
          ORIGIN_INPUT: 'Origen',
          DESTINATION_INPUT: 'Destino',
          STATE_INPUT: 'Estado',
          PRIORITY_INPUT: 'Prioridad',
          OBSERVATION_INPUT: 'Observación',
          CREATION_DATE_INPUT: 'Fecha de Pedido',
          PLANNING_DATE_INPUT: 'Fecha Entrega Objetivo',
          DELIVERY_DATE_INPUT: 'Fecha de Entrega',
          CANCEL_BTN: 'Cancelar',
          ORDER_VALUE: 'Costo del Pedido:',
          ADD_BTN: 'Agregar',
          ADD_PROD_TITLE_MODAL: 'Agregar productos al pedido',
          PRODUCT_INPUT: 'Producto',
          UDM_INPUT: 'Unidad de Medida',
          QUANTITY_INPUT: 'Cantidad',
          DELETE: 'Eliminar',
          DELETE_TTIP: 'Eliminar producto de la orden',
          SUGGESTED: '# Sugerida',
          SUGGESTED_TTIP: 'Cantidad Sugerida',
          TO_ASK: '# A pedir',
          TO_ASK_TTIP: 'Cantidad A pedir',
          TO_ASK_TTIP_ANEXO: '(Pallets)',
          UDM: 'UDM',
          EAN: 'EAN: {{val}}',
          SUPPLIER: 'Proveedor: {{val}}',
          STEP_BY_STEP: 'Paso a Paso',
          UNITS_INPUT: 'Unidades',
          NAME: 'Nombre',
          FORECASTED_SALE: 'Venta pronosticada',
          CURRENT_STOCK: 'Stock actual en Súper San Martín',
          CURRENT_STOCK2: 'Stock actual en Centro de Distribución Campana',
          TRANSIT_STOCK: 'Stock en tránsito hacia Super San Martín',
          MINIMUM_STOCK: 'Stock Mínimo',
          MAXIMUM_STOCK: 'Stock Máximo',
          AVAILABITY_DAYS_NOW: 'Días de Disponibilidad Ahora',
          AVAILABITY_DAYS_AFTER: 'Días de Disponibilidad Después',
          NEED: 'Necesidad',
          SUBCATEGORY: 'Subcategoría',
          EDIT_TTIP: 'Editar cantidad a pedir del producto',
          INVENTORY: 'Inventario',
        },
      },
    },
    ADMIN: {
      MASTER: {
        STORES: {
          LIST: {
            TYPE_FILTERS: 'Tipo',
            STATUS: 'Estado',
            STORE_TAGS: 'Etiquetas Puntos de Venta',
            NAME: 'Nombre',
            CODE: 'Código',
            BRAND: 'Bandera',
            STATE: 'Estado',
            TYPE: 'Tipo',
            AVAIBLE: 'Habilitado',
            ROUTE: 'Administración / Maestros',
            TITLE: 'Puntos de Venta',
            NEW_STORE_BTN: 'Nuevo Punto de Venta',
            FILTER_TTIP: 'Filtrar',
            FULLSCREEN_TTIP: 'Expandir',
            EXITFULLSCREEN_TTIP: 'Reducir',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Solo Tabla',
            VIEW_MAP: 'Solo mapa',
            VIEW_TABLE_MAP: 'Mapa y tabla',
            EDIT_ROW: 'Editar',
            COPY_ROW: 'Clonar',
            DELETE_ROW: 'Eliminar',
            INACTIVE: 'Inactiva',
            ACTIVE: 'Activa',
            CLONE_STORE_TITLE: 'Clonar Punto de Venta',
            CLONE_ITEM_CONTENT: 'Se creará un nuevo punto de venta a partir de los datos de la fila seleccionada',
            CANCEL_BTN: 'Cancelar',
            CREATE_BTN: 'Crear',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'Debe asignar a todas las clusterizaciones un cluster para poder guardar.',
          },
          PRICES: {
            BTN2_TITLE: 'Clonar precios de un punto de venta',
            BTN2_MSG: 'Elegir un punto de venta de referencia,  desde donde se van a copiar todos los precios.',
          },
          STORES_FILTER: 'Puntos de Venta',
          ROUTE: 'Administración / Maestros',
          TITLE: 'Puntos de Venta',
          NEW_STORE_BTN: 'Nuevo Punto de Venta',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          VIEW_TTIP: 'Vista',
          VIEW_TABLE: 'Solo Tabla',
          VIEW_MAP: 'Solo mapa',
          VIEW_TABLE_MAP: 'Mapa y tabla',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar puntos de venta seleccionadas',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          CODE: 'Código',
          BRAND: 'Bandera',
          TYPE: 'Tipo',
          STORE: 'Punto de Venta',
          STORES_V2: 'Punto de Venta',
          STATE: 'Estado',
          INACTIVE: 'Inactiva',
          ACTIVE: 'Activa',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTER: 'Estado',
          TYPE_FILTER: 'Tipo',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          TITLE_FORM: 'Nuevo Punto de Venta',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          LATITUDE_INPUT: 'Latitud',
          VALIDATION_LATITUDE1: 'Latitud debe ser menor o igual a 90',
          VALIDATION_LATITUDE2: 'Latitud debe ser mayor o igual a -90',
          LONGITUDE_INPUT: 'Longitud',
          VALIDATION_LONGITUDE1: 'Longitud debe ser menor o igual a 180',
          VALIDATION_LONGITUDE2: 'Longitud debe ser mayor o igual a -180',
          CODE_INPUT: 'Código',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'El campo debe ser mayor a 0',
          VALIDATION_CODE2: 'El código {{val}} ya existe.',
          NAME_INPUT: 'Nombre',
          BRAND_INPUT: 'Bandera',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Teléfono',
          OPENING_DATE_INPUT: 'Fecha de Apertura',
          ADRESS_INPUT: 'Dirección',
          ACTIVE_CHECK: 'Activa',
          ACTIVE_TTIP: 'Marcar como Activa la {{val}}',
          EXCEMPT_CHECK: 'Exento',
          EXCEMPT_TTIP: 'Marcar como exento',
          CLASSIFICATION_TAB: 'Clasificación',
          WITHOUT_SELECTION: 'Sin Selección...',
          ADD_ATRIBUTE_BTN: 'Agregar Atributos',
          QUERY_FILTER: 'Nombre',
          FEATURES_TAB: 'Características',
          STORE_TYPE_INPUT: 'Tipo de Punto de Venta',
          TERMINAL_INPUT: 'Centro de Distribución',
          TRADE_AREA_TAB: 'Trade Area',
          LEGEND_FILTERS: 'Filtre los competidores configurando el Trade Area',
          TRADE_AREA_TITLE: 'Distancia de Trade Area de competidores: ',
          SELECTED_COMPETITORS: 'Competidores Seleccionados',
          NO_SELECTED_COMPETITORS: 'No hay competidores seleccionados.',
          NO_SELECTED_COMPETITORS_TTIP: 'Refrescar listado',
          DELETE_BTN: 'Eliminar',
          DELETE_COMPETITOR_TTIP: 'Eliminar competidor',
          CODE_NAME: 'Cód | Nombre',
          MARKER: 'Marker',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          ADD_COMPETITORS_BTN: 'Agregar competidores',
          EMPTY_STATE: 'No hay competidores con el criterio de búsqueda.',
          ADD_BTN: 'Agregar',
          ADD_COMPETITOR_TTIP: 'Agregar competidor',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modificar Trade Area a: {{val}} Km.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL: 'Se perderán los competidores seleccionados y se modificarán con los del nuevo Trade Area.',
          EDIT_BTN: 'Modificar',
          SEARCH: 'Buscar ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Marcar como competidor principal para este Punto de Venta',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Desmarcar como competidor principal para este Punto de Venta',
          ASSORTMENT_TAB: 'Surtido',
          ASSORTMENT_TAB_ENABLED: 'Tab habilitado solo en estado modificación',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'Solo puede cargarse datos al editar el punto de venta previo a publicarse',
          ASSORTMENT_TITLE: 'Inicialización de Surtido',
          ASSORTMENT_SUBTITLE:
            'Seleccionar el cluster al que pertenecerá el nuevo Punto de Venta en cada una de las Clusterizaciones definidas en Prisma.',
          CLUSTERING: 'Clusterización',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Asignación de Cluster',
          CLONE_TTIP: 'Clonar de un Punto de Venta  existente.',
          CLONE_MODAL_TITLE: 'Clonar Surtido',
          CLONE_MODAL_SUBTITLE: 'Se clonarán los clusters para cada clusterización según el punto de venta seleccionado.',
          STORES: 'Punto de Venta',
          CANCEL_BTN: 'Cancelar',
          CLONE_BTN: 'Clonar',
          PRICING_TAB: 'Precios',
          PRICING_TITLE: 'Inicialización de Precios',
          PRICING_SUBTITLE: 'Elegir una opción de inicialización de precios',
          BTN1_TITLE: 'Completar precios en Excel',
          BTN1_MSG: 'Se deberá completar la columna price, de todo el surtido activo.',
          BTN2_TITLE: 'Clonar precios de un punto de venta',
          BTN2_MSG: 'Elegir un punto de venta de referencia,  desde donde se van a copiar todos los precios.',
          BTN3_TITLE: 'Reglas de Parentesco',
          BTN3_MSG: 'Se tomarán en cuenta las reglas de parentesco para el autocompletado de precios.',
          DOWLOAD_EXCEL: 'Descargar Excel',
          IMPORT_EXCEL_BTN: 'Importar precios desde el excel',
          CLONE_PRICE_MODAL_TITLE: 'Clonar Precios',
          CLONE_PRICE_MODAL_SUBTITLE: 'Se clonarán los precios según el punto de venta seleccionado.',
          EXCEL1: 'Completar-Precios.xlsx',
          EXCEL2: 'Clonar-Precios.xlsx',
          EXCEL3: 'Reglas-de-parentesco.xlsx',
          IMPORT_PRICES_TITLE: 'Importador de Precios',
          VENDORS: 'Proveedores',
          TABS: {
            VENDORS: {
              TITLE: 'Inicialización de proveedores',
              INTRO: 'Asigna qué proveedores abastece cada producto al punto de venta.',
              DISABLED_VENDORS_INPUT: 'Proveedores ya inicializados',
              BTN_IMPORT_SWITCH: 'Solucionar Conflictos',
              COMP: {
                TITLE: 'Selección Avanzada',
                INTRO: 'Selecciona los proveedores que van a abastecer al punto de venta.',
              },
            },
          },
        },
        STORESV2: {
          LIST: {
            TYPE_FILTERS: 'Tipo',
            STATUS: 'Estado',
            STORE_TAGS: 'Etiquetas Puntos de Venta',
            NAME: 'Nombre',
            CODE: 'Código',
            BRAND: 'Bandera',
            STATE: 'Estado',
            TYPE: 'Tipo',
            AVAIBLE: 'Habilitado',
            ROUTE: 'Administración / Maestros',
            TITLE: 'Puntos de Venta',
            NEW_STORE_BTN: 'Nuevo Punto de Venta',
            FILTER_TTIP: 'Filtrar',
            FULLSCREEN_TTIP: 'Expandir',
            EXITFULLSCREEN_TTIP: 'Reducir',
            VIEW_TTIP: 'Vista',
            VIEW_TABLE: 'Solo Tabla',
            VIEW_MAP: 'Solo mapa',
            VIEW_TABLE_MAP: 'Mapa y tabla',
            EDIT_ROW: 'Editar',
            EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
            COPY_ROW: 'Clonar',
            DISABLED_CLONE_TOOLTIP: 'Solo puede clonarse registros en estado publicado',
            DISABLED_DELETE_EQUIPMENT:
              'Existe equipamiento asignado, elimine o reubique todos los equipamiento para poder eliminar la tienda',
            DELETE_ROW: 'Inactivar',
            UNDELETE_ROW: 'Activar',
            INACTIVE: 'Inactiva',
            ACTIVE: 'Activa',
            CLONE_STORE_TITLE: 'Clonar Punto de Venta',
            CLONE_ITEM_CONTENT: 'Se creará un nuevo punto de venta a partir de los datos de la fila seleccionada',
            CANCEL_BTN: 'Cancelar',
            CREATE_BTN: 'Crear',
            SEE_DELETED_FILTERS: 'Ver Inactivos',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'Debe asignar a todas las clusterizaciones un cluster para poder guardar.',
          },
          PRICES: {
            BTN2_TITLE: 'Clonar precios de un punto de venta',
            BTN2_MSG: 'Elegir un punto de venta de referencia,  desde donde se van a copiar todos los precios.',
            REVIEW_CHANGES: 'Para consultar cambios de precios dirigirse a ',
            PRICE_DECISIONS: 'Decisiones de Precios',
            VIEW_ASOCIATED_RULES: 'VER REGLAS ASOCIADAS',
            MAIN_SAVE_WARNING: 'Para poder usar esta sección es necesario al menos tener los datos principales guardados',
          },
          EQUIPMENT: {
            ownerShip: 'Propietario',
            acquisitionDate: 'Fecha de adquisición',
            acquisitionValue: 'Valor de adquisición',
            adquisitionDate: 'Fecha de adquisición',
            adquisitionValue: 'Valor de adquisición',
            usefulLife: 'Vida útil (años)',
            EQUIPMENT_TYPE: 'Tipo de equipamiento',
            ADD_EQUIPMENT: 'Agregar Equipamiento',
            REMAINING_LIVE: 'Vida remanente',
            NAME: 'Nombre',
            ASSIGMENT_DATE: 'Fecha de Asignación',
            EDIT_EQUIPMENT: 'Editar Equipamiento',
            ADD_EQUIPMENT_TITLE: 'Agregar Equipamiento',
            AWARD_DATE: 'Fecha de Adjudicación',
            DELETE_TITLE: 'Inactivar equipamiento',
            DELETE_CONTENT: 'Esta acción no puede ser revertida. ¿Desea continuar?',
            NAME_ALREADY_EXIST: 'El nombre ya se encuentra en uso',
            DISABLE_REUBICATION: 'Debe guardarse la asignación para ser reubicada',
            REUBICATION_CONFIRM: 'Está seguro que desea reubicar este equipamiento al punto de venta {{val}} ?',
            REUBICATION: 'Reubicar en otro punto de venta',
            OWNER_OPTION: {
              Owner: 'Propio',
              Third: 'Tercero',
            },
          },
          COMPETITORS_SCOPE: 'Alcance de competidores',
          COMPETITORS_SCOPE_SUBTITLE: 'Seleccionar el alcance de competidores de este punto de venta',
          SELECTED_COMPETITOR_TTIP: 'Competidor Seleccionado',
          COMPETITOR_SELECTED: 'Competidores seleccionados',
          COMPETITOR_SELECTED_COUNT: 'Cantidad de Competidores seleccionados',
          COMPETITOR_SELECTED_TITLE: 'Seleccionar Competidores',
          COMPETITOR_SELECTED_CONTENT: 'Busque y agregue competidores por trade area y etiquetas',
          VIEW_ASOCIATED_RULES_TITLE: 'Sin reglas asociadas',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'No se puede consultar una tienda que no fue publicada',
          INFO_ASSORTMENT_STATUS:
            'El estado de surtido visualizado representa el estado una vez sea publicado, de igual modo todos los datos afectados por esta cambio surtirán efecto al encontrarse en ese estado.',
          IMPORT_ITEMS: 'Surtido por Producto',
          CODE_EXIST: 'El código {{val}} ya existe',
          ITEMS_ALLOWED: '# Productos propios permitidos',
          TIP_ITEMS_ALLOWED: 'Cantidad máxima de productos propios que esta tienda podrá crear.',
          NOT_ACCESS_PERMISSION: 'No tienes permisos para completar esta acción',
          NOTIFY_USER: 'NOTIFICAR AL USUARIO',
          NOTIFIED_USER: 'Usuario notificado',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_LINK_ITEMS: 'determinar-surtido.xlsx',
          IMPORT_FILE_NAME_BTN: 'Carga de surtido excepcional',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Podrá cargar excepciones de surtido por medio del importador o consultar el estado de surtido referente a este punto de venta',
          STORES_FILTER: 'Puntos de Venta',
          ROUTE: 'Administración / Maestros',
          TITLE: 'Puntos de Venta',
          NEW_STORE_BTN: 'Nuevo Punto de Venta',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          VIEW_TTIP: 'Vista',
          VIEW_TABLE: 'Solo Tabla',
          VIEW_MAP: 'Solo mapa',
          VIEW_TABLE_MAP: 'Mapa y tabla',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar puntos de venta seleccionadas',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          CODE: 'Código',
          BRAND: 'Bandera',
          TYPE: 'Tipo',
          STORE: 'Punto de Venta',
          STORES_V2: 'Punto de Venta',
          STATE: 'Estado',
          INACTIVE: 'Inactiva',
          ACTIVE: 'Activa',
          TITLE_FILTERS: 'Filtros',
          STATE_FILTER: 'Estado',
          TYPE_FILTER: 'Tipo',
          CLEAN_FILTER_BTN: 'Limpiar',
          APPLY_FILTER_BTN: 'Aplicar',
          TITLE_FORM: 'Nuevo Punto de Venta',
          SAVE_BTN: 'Guardar',
          BASIC_INFORMATION_TAB: 'Datos Principales',
          LATITUDE_INPUT: 'Latitud',
          VALIDATION_LATITUDE1: 'Latitud debe ser menor o igual a 90',
          VALIDATION_LATITUDE2: 'Latitud debe ser mayor o igual a -90',
          LONGITUDE_INPUT: 'Longitud',
          VALIDATION_LONGITUDE1: 'Longitud debe ser menor o igual a 180',
          VALIDATION_LONGITUDE2: 'Longitud debe ser mayor o igual a -180',
          CODE_INPUT: 'Código',
          EXTERNALCODE_INPUT: 'Código Externo',
          VALIDATION_CODE: 'El campo debe ser mayor a 0',
          VALIDATION_CODE2: 'El código {{val}} ya existe.',
          NAME_INPUT: 'Nombre',
          BRAND_INPUT: 'Bandera',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Teléfono',
          OPENING_DATE_INPUT: 'Fecha de Apertura',
          ADRESS_INPUT: 'Dirección',
          ACTIVE_CHECK: 'Activa',
          ACTIVE_TTIP: 'Marcar como Activa la {{val}}',
          EXCEMPT_CHECK: 'Exento',
          EXCEMPT_TTIP: 'Marcar como exento',
          CLASSIFICATION_TAB: 'Clasificación',
          WITHOUT_SELECTION: 'Sin Selección...',
          ADD_ATRIBUTE_BTN: 'Agregar Atributos',
          FEATURES_TAB: 'Características',
          STORE_TYPE_INPUT: 'Tipo de Punto de Venta',
          TERMINAL_INPUT: 'Centro de Distribución',
          TRADE_AREA_ALL_DISTANCE: 'Se buscarán todos los competidores sin tener en cuenta su distancia',
          GEOLOCATION_STORE: 'Coordenadas del punto de venta para Trade Area',
          TRADE_AREA_TAB: 'Trade Area',
          LEGEND_FILTERS: 'Filtre los competidores configurando el Trade Area',
          TRADE_AREA_TITLE: 'Distancia de Trade Area de competidores: ',
          SELECTED_COMPETITORS: 'Competidores Seleccionados',
          NO_SELECTED_COMPETITORS: 'No hay competidores seleccionados.',
          NO_SELECTED_COMPETITORS_TTIP: 'Refrescar listado',
          DELETE_BTN: 'Eliminar',
          DELETE_COMPETITOR_TTIP: 'Eliminar competidor',
          CODE_NAME: 'Cód | Nombre',
          MARKER: 'Marker',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          ADD_COMPETITORS_BTN: 'Agregar competidores',
          EMPTY_STATE: 'No hay competidores con el criterio de búsqueda.',
          ADD_BTN: 'Agregar',
          ADD_COMPETITOR_TTIP: 'Agregar competidor',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modificar Trade Area a: {{val}} Km.',
          CHANGE_TRADE_AREA_TITLE_MODAL_ALT: 'Modificar Localización',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL: 'Se perderán los competidores seleccionados y se modificarán con los del nuevo Trade Area.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL_ALT: 'Se perderán los competidores seleccionados y se modificarán con los del nuevo Trade Area.',
          EDIT_BTN: 'Modificar',
          SEARCH: 'Buscar ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Marcar como competidor principal para este Punto de Venta',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Desmarcar como competidor principal para este Punto de Venta',
          ASSORTMENT_TAB: 'Surtido',
          ASSORTMENT_TAB_ENABLED: 'Tab habilitado solo en estado modificación',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'Solo puede cargarse datos al editar el punto de venta previo a publicarse',
          ASSORTMENT_TITLE: 'Inicialización de Surtido',
          ASSORTMENT_SUBTITLE:
            'Seleccionar el cluster al que pertenecerá el nuevo Punto de Venta en cada una de las Clusterizaciones definidas en Prisma.',
          CLUSTERING: 'Clusterización',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Asignación de Cluster',
          CLONE_TTIP: 'Clonar de un Punto de Venta  existente.',
          CLONE_MODAL_TITLE: 'Clonar Surtido',
          CLONE_MODAL_SUBTITLE: 'Se clonarán los clusters para cada clusterización según el punto de venta seleccionado.',
          STORES: 'Punto de Venta',
          CANCEL_BTN: 'Cancelar',
          CLONE_BTN: 'Clonar',
          PRICING_TAB: 'Precios',
          PRICING_TITLE: 'Inicialización de Precios',
          PRICING_SUBTITLE: 'Elegir una opción de inicialización de precios',
          BTN1_TITLE: 'Completar precios en Excel',
          BTN1_MSG: 'Se deberá completar la columna price, de todo el surtido activo.',
          BTN2_TITLE: 'Clonar precios de un punto de venta',
          BTN2_MSG: 'Elegir un punto de venta de referencia,  desde donde se van a copiar todos los precios.',
          BTN3_TITLE: 'Reglas de Parentesco',
          BTN3_MSG: 'Se tomarán en cuenta las reglas de parentesco para el autocompletado de precios.',
          DOWLOAD_EXCEL: 'Descargar Excel',
          VIEW_ASSORTMENT_STATUS: 'Reporte de Surtido',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'Se requiere guardar los cambios realizados',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Se detectaron cambios que afectan al estado del Surtido, para poder acceder debe guardar los cambios realizados',
          IMPORT_EXCEL_BTN: 'Importar precios desde el excel',
          CLONE_PRICE_MODAL_TITLE: 'Clonar Precios',
          CLONE_PRICE_MODAL_SUBTITLE: 'Se clonarán los precios según el punto de venta seleccionado.',
          EXCEL1: 'Completar-Precios.xlsx',
          EXCEL2: 'Clonar-Precios.xlsx',
          EXCEL3: 'Reglas-de-parentesco.xlsx',
          IMPORT_PRICES_TITLE: 'Importador de Precios',
          VENDORS: 'Proveedores',
          IMPORT_EXCEL_ITEMS: 'IMPORTAR PDV DESDE EXCEL',
          IMPORT_EXCEL_ITEMS_EXCEPT: 'IMPORTAR SURTIDO DESDE EXCEL',
          IMPORT_TITLE_ITEMS: 'Importar Surtido',
          MAIN_SAVE_WARNING: 'Para poder usar esta sección es necesario al menos tener los datos principales guardados',
          IMPORT_PDV_OK: 'PUNTOS DE VENTA IMPORTADOS',
          NOVALID_PUBLISH_ADVICE: 'El siguiente tab no es válido:',
          TABS: {
            VENDORS: {
              TITLE: 'Inicialización de proveedores',
              INTRO: 'Asigna qué proveedores abastece cada producto al punto de venta.',
              DISABLED_VENDORS_INPUT: 'Proveedores ya inicializados',
              BTN_IMPORT_SWITCH: 'Solucionar Conflictos',
              COMP: {
                TITLE: 'Selección Avanzada',
                INTRO: 'Selecciona los proveedores que van a abastecer al punto de venta.',
              },
            },
          },
          ITEM: {
            SAVE: 'Guardar',
            PUBLISH: 'Publicar',
            REPORT: 'Reporte',
            REPORT_TOOLTIP: 'Se generará un reporte que podrá descargarse a través de las notificaciones.',
            REPORT_WARNING: 'Es necesario Publicar para descargar el reporte.',
            WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
            WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
            BASIC_INFORMATION_TAB: 'Datos Principales',
            CLASSIFICATION_TAB: 'Clasificación',
            PRICES_TAB: 'Precios',
            LOADING: 'Cargando...',
            NEW_ITEM_BTN: 'Nuevo Ítem',
            TRADE_AREA_TAB: 'Trade Area',
            COMPETITORS_SCOPE: 'Alcance de competidores',
            PUBLISH_CONFIRM:
              'El proceso de publicación incluye la creación del Surtido, Precios, Costos y otros registros relacionados al Punto de Venta.<br><br><b>Estás seguro que deseas Publicar?</b>',
            PUBLISH_CONFIRM_TITLE: 'Confirmación de Publicación',
            PUBLISHING_WARNING: 'Ítem en proceso de publicación, vuelva a intentar en unos minutos.',
            PUBLISH_LONG_TIME:
              'La publicación de este Punto de Venta puede demorar algunos minutos, cuando finalice serás avisado con una notificación.',
          },
        },
        SUPPLIER: {
          ROUTE: 'Administración / Maestros',
          TITLE: 'Proveedores',
          NEW_SUPPLIER_BTN: 'Nuevo Proveedor',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar proveedores seleccionados',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          CODE: 'Código',
          PHONE: 'Teléfono',
          DEFINE_SUPPLIER_STP1: 'Definir Proveedor',
          ITEMS_ALLOCATION_STP2: 'Asignación de Productos',
          EDIT_TITLE_FORM: 'Editar Proveedor',
          NEW_TITLE_FORM: 'Nuevo Proveedor',
          STP1: 'Paso 1 - Definir Proveedor',
          NAME_INPUT: 'Nombre',
          CODE_INPUT: 'Código',
          PHONE_INPUT: 'Teléfono',
          NEXT_BTN: 'Siguiente',
          STP2: 'Paso 2 - Asignación de Productos',
          ITEM: 'Producto',
          MAIN_STORES: '# Puntos de Venta Pral.',
          SECONDARY_STORES: '# Puntos de Venta Sec.',
          DELETE_ITEM_TTIP: 'Eliminar producto',
          ADD_ITEM_BTN: 'Agregar Producto',
          PREVIUS_BTN: 'Anterior',
          SAVE_BTN: 'Guardar',
          DELETE_TITLE_MODAL: 'Eliminar',
          DELETE_SUBTITLE_MODAL: '¿Desea continuar con la eliminación?',
          DELETE_SUBTITLE2_MODAL: 'Desea continuar con la eliminación de: {{val}}',
          DELETE_BTN: 'Eliminar',
          ALLOCATION_STORES: 'Asignación de Puntos de Venta',
          ITEM_INPUT: 'Producto',
          SEARCH_ITEM: 'Buscar Producto...',
          STORES_INPUT: 'Puntos de Ventas',
          ADD_STORES: 'Agregar más puntos de venta',
          SEARCH_STORES: 'Buscar Puntos de Venta...',
          ALL_CHECK: 'Todas',
          SECONDARY_STORES_INPUT: 'Puntos de Venta Secundarios',
          ADD_SECONDARY_STORE: 'Agregar más puntos de venta secundarios',
          SEARCH_SECONDARY_STORE: 'Buscar puntos de venta secundarios...',
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceptar',
        },
        SUPPLIERV2: {
          ROUTE: 'Administración / Maestros',
          TITLE: 'Proveedores',
          NEW_SUPPLIER_BTN: 'Nuevo Proveedor',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          DELETE_TTIP: 'Borrar proveedores seleccionados',
          DELETE: 'Eliminar',
          NAME: 'Nombre',
          CODE: 'Código',
          PHONE: 'Teléfono',
          STATUS: 'Estado',
          DEFINE_SUPPLIER_STP1: 'Definir Proveedor',
          CLASIFICATION: 'Clasificación',
          ITEMS_ALLOCATION_STP2: 'Asignación de Productos',
          EDIT_TITLE_FORM: 'Editar Proveedor',
          NEW_TITLE_FORM: 'Nuevo Proveedor',
          STP1: 'Paso 1 - Definir Proveedor',
          NAME_INPUT: 'Nombre',
          CODE_INPUT: 'Código',
          PHONE_INPUT: 'Teléfono',
          NEXT_BTN: 'Siguiente',
          STP2: 'Paso 2 - Asignación de Productos',
          ITEM: 'Producto',
          MAIN_STORES: '# Puntos de Venta Pral.',
          SECONDARY_STORES: '# Puntos de Venta Sec.',
          DELETE_ITEM_TTIP: 'Eliminar producto',
          ADD_ITEM_BTN: 'Agregar Producto',
          PREVIUS_BTN: 'Anterior',
          SAVE_BTN: 'Guardar',
          DELETE_TITLE_MODAL: 'Eliminar',
          DELETE_SUBTITLE_MODAL: '¿Desea continuar con la eliminación?',
          DELETE_SUBTITLE2_MODAL: 'Desea continuar con la eliminación de: {{val}}',
          DELETE_BTN: 'Eliminar',
          ALLOCATION_STORES: 'Asignación de Puntos de Venta',
          ITEM_INPUT: 'Producto',
          SEARCH_ITEM: 'Buscar Producto...',
          STORES_INPUT: 'Puntos de Ventas',
          ADD_STORES: 'Agregar más puntos de venta',
          SEARCH_STORES: 'Buscar Puntos de Venta...',
          ALL_CHECK: 'Todas',
          SECONDARY_STORES_INPUT: 'Puntos de Venta Secundarios',
          ADD_SECONDARY_STORE: 'Agregar más puntos de venta secundarios',
          SEARCH_SECONDARY_STORE: 'Buscar puntos de venta secundarios...',
          CANCEL_BTN: 'Cancelar',
          ACCEPT_BTN: 'Aceptar',
          SEGMENTATION: 'Segmentación',
          INTERNAL_CODE_INPUT: 'Código Interno',
          UNIQUE_CODE_INPUT: 'Código Único',
          ITEM_FORM: {
            SAVE: 'Guardar',
            PUBLISH: 'Publicar',
            WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
            WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
            LOADING: 'Cargando...',
            NEW_ITEM_BTN: 'Nuevo Ítem',
            PUBLISH_CONFIRM:
              'El proceso de publicación incluye la creación de costos en puntos de ventas relacionados al proveedor.<br><br><b>Estás seguro que deseas Publicar?</b>',
            PUBLISH_CONFIRM_TITLE: 'Confirmación de Publicación',
            NO_SEGMENT: 'Sin segmentar',
            PUBLISHING_WARNING: 'Ítem en proceso de publicación, vuelva a intentar en unos minutos.',
            PUBLISH_LONG_TIME:
              'La publicación de este Proveedor puede demorar algunos minutos, cuando finalice serás avisado con una notificación.',
            TIP_ATTRIBUTES_NO_SELECT: 'Los costos del proveedor aplicarán a toda la red.',
            TIP_ATTRIBUTES_SELECT: 'Los costos del proveedor aplicarán sólo a los puntos de venta etiquetados como:',
            TIP_USESOWNITEMNUMBERS:
              'Si se requiere Código Interno tanto la actualización de costos como los pedidos del módulo de abastecimiento se deberán completar con los códigos internos del proveedor.',
          },
        },
        INSIGHTS: {
          ROUTE: 'Administración / Maestros',
          TITLE: 'Insights',
          FILTER_TTIP: 'Filtrar',
          NAME: 'Nombre',
          DESCRIPTION: 'Descripción',
          MARGIN: 'Margen $',
          CREATE_DATE: 'Fecha',
          ACTIONS: 'Acciones',
          CONSULT_REPORTS: 'Consultar Reportes',
          REVIEW_PRICES: 'Revisar Precios',
          TOGGLE_FILTER: 'Solo mis Insights',
          ANTIQUITY_FILTER: 'Antigüedad',
          INSIGHTS_TYPE_FILTER: 'Tipo de Insight',
          STORES_FILTER: 'Puntos de Venta',
        },
        EQUIPMENTS: {
          ROUTE: 'Administración / Maestros',
          TITLE: 'Equipamientos',
          TITLE_FULL: 'Tipos de Equipamiento',
          FILTER_TTIP: 'Filtrar',
          NAME: 'Nombre',
          DESCRIPTION: 'Descripción',
          EDIT: 'Editar Tipo de Equipamiento',
          NEW: 'Nuevo Tipo de Equipamiento',
          DELETE: 'Eliminar Tipo de Equipamiento',
          DELETE_WARN: 'Este tipo de equipamiento se encuentra asignado, no es posible eliminarlo',
          ITEMS: 'Ítems',
          ATTR: 'atributos',
          ATTR_ALT: 'Atributos',
          REQUIRED: 'obligatorio',
          SAVE: 'Guardar',
          PUBLISH: 'Publicar',
          WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
          WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
          LOADING: 'Cargando...',
          ADD_ATTR: 'Agregar Atributo',
          EDIT_ATTR: 'Editar Atributo',
          TYPE_ATTR: 'Tipo de Atributo',
          NEW_ITEM: 'Nuevo Tipo de Equipamiento',
          VALUE_LIST: 'Listado de Valores:',
          ADD_VALUE: 'Agregar Valor',
          VALUE: 'Valor',
          VALIDATION_NAME_DUPLICATE: 'Este nombre ya existe',
          VALIDATION_ATTR_DUPLICATE: 'Hay atributos duplicados',
          ASSIGNED: 'Asignado',
          ATTR_REQUIRED: 'Atributo obligatorio',
          GENERAL: 'General',
          ASSIGN_REQUIRED_DISCLAIMER:
            'Este tipo de equipamiento ya tiene equipos asignados a tiendas, este cambio no será retroactivo para los equipos que ya fueron asignados.',
          REPORT: 'Descargar Reporte',
          TYPES: {
            NUMBER: 'Número',
            STRING: 'Texto',
            BOOLEAN: 'Booleano',
            LIST: 'Lista de valores',
            PRICE: 'Precio',
            DATETIME: 'Fecha',
          },
        },
        DYNAMIC_ENTITIES: {
          ROUTE: 'Administración / Maestros',
          TITLE: 'Equipamientos',
          TITLE_FULL: 'Entidades Dinámicas',
          FILTER_TTIP: 'Filtrar',
          NAME: 'Nombre',
          INSTANCES: 'Instancias',
          DESCRIPTION: 'Descripción',
          EDIT: 'Editar Tipo de Entidad',
          NEW: 'Nuevo Tipo de Entidad',
          DELETE: 'Eliminar Tipo de Entidad',
          DELETE_WARN: 'Este tipo de entidad se encuentra asignado, no es posible eliminarlo',
          ITEMS: 'Ítems',
          ATTR: 'atributos',
          ATTR_ALT: 'Atributos',
          REQUIRED: 'obligatorio',
          SAVE: 'Guardar',
          PUBLISH: 'Publicar',
          WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
          WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
          LOADING: 'Cargando...',
          ADD_ATTR: 'Agregar Atributo',
          EDIT_ATTR: 'Editar Atributo',
          TYPE_ATTR: 'Tipo de Atributo',
          NEW_ITEM: 'Nuevo Tipo de Entidad',
          VALUE_LIST: 'Listado de Valores:',
          ADD_VALUE: 'Agregar Valor',
          VALUE: 'Valor',
          VALIDATION_NAME_DUPLICATE: 'Este nombre ya existe',
          VALIDATION_ATTR_DUPLICATE: 'Hay atributos duplicados',
          ASSIGNED: 'Asignado',
          ATTR_REQUIRED: 'Atributo obligatorio',
          GENERAL: 'General',
          ASSIGN_REQUIRED_DISCLAIMER:
            'Este tipo de entidad ya tiene equipos asignados a tiendas, este cambio no será retroactivo para los equipos que ya fueron asignados.',
          REPORT: 'Descargar Reporte',
          TYPES: {
            NUMBER: 'Número',
            STRING: 'Texto',
            BOOLEAN: 'Booleano',
            LIST: 'Lista de valores',
            PRICE: 'Precio',
            DATETIME: 'Fecha',
          },
          NAME_ALREADY_EXISTS: 'El nombre ya existe',
          ADD_ENTITY_TITLE: 'Nueva entidad',
          ADD_INSTANCE_TITLE: 'Nueva instancia',
          EDIT_ENTITY: 'Modificar entidad',
          CODE: 'Código',
          TAGNAME: 'Nombre de etiqueta',
          ADD_INSTANCE: 'Agregar instancia',
          EDIT_INSTANCE: 'Modificar instancia',
          CANCEL_BTN: 'Cancelar',
          RELATION_WITH_ITEMS: 'Relación con Ítems',
          RELATION_WITH_STORES: 'Relación con Puntos de Venta',
          RELATION: 'Relación',
          DELETE_TITLE: 'Eliminar entidad',
          DELETE_CONTENT: 'Esta acción no puede ser revertida. ¿Desea continuar?',
          STORES: 'Puntos de venta',
          MASSIVE_IMPORT: 'Importación masiva',
          NEW_DYNAMIC_ENTITY: 'Entidad dinámica nueva, no es posible mostrar instancias.',
          IMPORT_SUCCESS: 'Importación exitosa',
        },
        TAGS: {
          ACCEPT: 'Aceptar',
          NAME_ALREADY_EXIST: 'El nombre ya se encuentra en uso',
          TYPES: {
            Item: 'Producto',
            Store: 'Punto de Venta',
            Competitor: 'Competidor',
            Brands: 'Bandera',
            Vendor: 'Proveedor',
          },
          DATA_TYPES: {
            Text: 'Texto',
            Number: 'Número',
            Boolean: 'Booleano',
            DynamicEntity: 'Entidad dinámica',
          },
          ENTITIES: {
            COMPETITORS: 'Competidores: {{val}}',
            ITEMS: 'Productos: {{val}}',
            ROUNDINGRULES: 'Reglas de redondeo: {{val}}',
            COMPETITIVELIMITRULES: 'Límite de competidores: {{val}}',
            PRICINGRULESITEMS: 'Reglas de parentesco de productos: {{val}}',
            PRICINGRULESSTORES: 'Reglas de parentesco de puntos de venta: {{val}}',
            STORES: 'Puntos de venta: {{val}}',
            VENDORS: 'Proveedores: {{val}}',
            VENDORCOSTS: 'Costos: {{val}}',
            ASSORTMENTASSIGNMENTS: 'Asignación de surtido: {{val}}',
            ASSORTMENTITEMEXCEPTIONS: 'Excepción de surtido por productos: {{val}}',
            ASSORTMENTSTOREEXCEPTIONS: 'Excepción de surtido por puntos de venta: {{val}}',
            ASSORTMENTCATEGORIES: 'Categorías de surtido: {{val}}',
          },
          DEFAULTVALUE: 'Iniciar con este valor por defecto',
          ISDEFAULTVALUE: 'Es Valor por defecto',
          TAG_IN_USE: 'No es posible eliminar una etiqueta que está en uso.',
          ENTITIES_IN_USE: 'Debe reasignar la siguiente cantidad de registros por cada entidad que utilizan la etiqueta a eliminar',
          CANCEL_BTN: 'Cancelar',
          NEW_TAG_VALUE_ADD_BTN: 'Crear nuevo valor de etiqueta',
          ROUTE: 'Administración / Maestros',
          ISDELETED: 'Eliminados',
          ISDELETED_POPOVER_TOOLTIP: 'Al habilitar solo se mostraran etiquetas eliminadas',
          USEFORREPORTS: 'Usados para reportes',
          USEFORREPORT: 'Usado para reportes',
          USEFORREPORTS_POPOVER_TOOLTIP: 'Al habilitar sólo se mostrarán las etiquetas asignadas para reportes',
          YES: 'Sí',
          NO: 'No',
          REQUIRED_CHECKBOX_POPOVER_TOOLTIP:
            'Hace obligatorio la carga de este campo para todos los "productos". Permite asignar valor por default si esta opción se encuentra activa.',
          REQUIRED_FOR_COMPETITOR_POPOVER_TOOLTIP:
            'Hace obligatorio la carga de este campo para todos los "competidores". Permite asignar valor por default si esta opción se encuentra activa.',
          REQUIRED_FOR_STORE_POPOVER_TOOLTIP:
            'Hace obligatorio la carga de este campo para todos los "puntos de venta". Permite asignar valor por default si esta opción se encuentra activa.',
          TERMINATION_SLIDE_POPOVER_TOOLTIP:
            'Puedes agregarle una terminación por default a los valores de la etiqueta. Ej: para Contenido Neto, "cc" o "cm3". Etiqueta: 250 cc',
          DEPENDENCIES_POPOVER_TOOLTIP:
            'Utilizalo en el caso de que esta etiqueta dependa de otra. Relación padre/hijo. Ej: "Ciudad" depende de "Estado"',
          SELECT_PARENT_TAG_POPOVER_TOOLTIP:
            'En caso de cambiar por otra etiqueta padre los valores de esta etiqueta serán eliminados. En caso de desasignarla los valores de esta etiqueta perderán relación con valores padres',
          SELECT_PARENT_TAG_WARNING:
            'Asegúrese de seleccionar la etiqueta padre correcta antes de cargar los valores padres, de cambiarse perderá los valores agregados',
          TITLE: 'Etiquetas',
          CANCEL: 'Cancelar',
          OK: 'Ok',
          NEW_STORE_BTN: 'Nueva Etiqueta',
          DELETE: 'Eliminar',
          DELETE_TTIP: 'Borrar Etiquetas Seleccionados',
          FILTER_TTIP: 'Filtrar',
          EXPORT_TTIP: 'Exportar',
          IMPORT_TTIP: 'Importar',
          PRINT_TTIP: 'Imprimir',
          NAME: 'Nombre',
          TYPE: 'Tipo',
          FIELD_TYPE: 'Tipo de Campo',
          REQUIRED: 'Obligatorio',
          QUANTITY_TAGS: '# Etiquetas',
          QUANTITY_TAGS_TTIP: 'Cantidad de Etiquetas que pertenecen a un grupo',
          DEFINE_TAG_STP1: 'Definir Etiqueta',
          ADMIN_TAG_STP2: 'Administrar Etiqueta',
          EDIT_TAG: 'Editar Etiqueta',
          DELETE_ROW: 'Eliminar Etiqueta',
          NEW_TAG: 'Nueva Etiqueta',
          STP1: 'Definir Etiqueta',
          NAME_INPUT: 'Nombre',
          TYPE_INPUT: 'Tipo de Etiqueta',
          DATATYPE_INPUT: 'Tipo de Datos',
          REQUIRED_CHECKBOX: 'Obligatoria para todos los productos',
          REQUIRED_FOR_COMPETITOR: 'Obligatoria para todos los Competidores',
          REQUIRED_FOR_STORE: 'Obligatoria para todos los Puntos de Venta Marca Propia',
          ADDITIONAL_SLIDE: 'Extras',
          COLOR_SLIDE: 'Color',
          ICON_SLIDE: 'Icono e Imagen',
          REQUIRED_SLIDE: 'Requiere Permiso Especial',
          TERMINATION_SLIDE: 'Terminación',
          TERMINATION_INPUT: 'Ingresar Terminación',
          DEPENDENCIES: 'Dependencias',
          DEPENDENCIES_INPUT: 'Seleccionar Etiqueta Padre',
          REQUIRED_WARNING_OFF_CONTENT: 'Si existe un valor de esta etiqueta como valor por defecto, este será desactivado',
          REQUIRED_WARNING_OFF_TITLE: 'Desea que esta etiqueta no sea obligatoria?',
          NEXT_BTN: 'Siguiente',
          STP2: 'Administrar Valores de la Etiqueta',
          NEW_TAG_INPUT: 'Añadir nueva etiqueta',
          DEPENCENCY_TAG_INPUT: 'Seleccionar valor de etiqueta Padre',
          PARENT: 'Padre',
          TYPE_CENTER: 'Tipo de Centro',
          COLOR: 'Color',
          ICON: 'Icono',
          TERMINATION: 'Terminación',
          PARENT_VALUE: 'Valor del Padre',
          ELEMENTS: '# Elementos',
          ELEMENTS_TTIP: 'Cantidad de Elementos',
          PREVIOUS_BTN: 'Anterior',
          SAVE_BTN: 'Guardar',
          ADD_BTN: 'Añadir',
          EDIT_BTN: 'Editar',
          TAG_VALUE_EXIST: 'Valor de etiqueta existente',
          DEFAULT_TTP: 'No puede asignarse valor por defecto si la etiqueta no es marcada como obligatoria',
          INVALID_TAG_VALUES_FORM: 'Todos los valores cargados deben contener los campos requeridos',
          OWN_STORES: 'puntos de venta',
          PRODUCTS: 'productos',
          COMPETITORS: 'competidores',
          REQUIRED_DEFAULT_TAG: 'Debe configurar un valor de etiqueta por defecto para poder Guardar',
          TAG_UPDATE_ALL_PRODUCTS: 'Esta etiqueta actualizará todos los Productos. ¿Desea continuar?',
          TAG_UPDATE_ALL_OWN_STORES: 'Esta etiqueta actualizará todos los Puntos de Venta Marca Propia. ¿Desea continuar?',
          TAG_UPDATE_ALL_COMPETITORS: 'Esta etiqueta actualizará todos los Competidores. ¿Desea continuar?',
        },
      },
      SECURITY: {
        USERS: {
          LIST: {
            TITLE: 'Usuarios',
            EDIT_ROW: 'Editar',
            USERNAME_INPUT: 'Nombre de usuario',
            EMAIL_INPUT: 'Email',
            ROLE_INPUT: 'Rol',
          },
          ITEM: {
            TAB1: 'Data General',
            USERNAME_INPUT: 'Nombre de usuario',
            FIRSTNAME_INPUT: 'Nombre',
            LASTNAME_INPUT: 'Apellido',
            EMAIL_INPUT: 'Email',
            PASSWORD_INPUT: 'Contraseña',
            PREV_PASSWORD_INPUT: 'Contraseña anterior',
            RESET_PASS: 'Blanquear Contraseña',
            REPEAT_PASSWORD_INPUT: 'Repetir Contraseña',
            PASSWORD_BTN: 'Ocultar contraseña',
            ROLES_INPUT: 'Rol',
            CATEGORIES: 'Categorías',
            STORES: 'Tiendas',
            EDIT_STORES: 'Editar Tiendas',
            CATEGORIES_ADVICE: 'En caso de dejar vacía esta selección, no se aplicaran restricciones por categorías para el usuario',
            STORES_ADVICE: 'En caso de dejar vacía esta selección, no se aplicaran restricciones por tienda para el usuario',
          },
          NEW_USER_BTN: 'Nuevo Usuario',
        },
        ROLES: {
          LIST: {
            TITLE: 'Roles',
            EDIT_ROW: 'Editar',
            NAME_INPUT: 'Nombre',
          },
          ITEM: {
            TAB1: 'Data General',
            NAME_INPUT: 'Nombre',
            NAME_ROLE: 'Nombre',
            DESCRIPTION_ROLE: 'Descripción',
            SELECTED: 'Seleccionado',
            SELECTED_PLURAL: 'Seleccionados',
            ROLE: {
              admin_view: 'Visualizar el menú de Administracion',
              itemmaster_view: 'Visualizar el menú de Ítem Master',
              prices_competitors_view: 'Visualizar el menú de banderas y competidores',
              prices_costsandtaxes: 'Importador de costos por tienda y costos por proveedor',
              prices_decisions: 'Cambiar precios, Aceptar e ignorar sugerencias. Solicitar aprobacion, e Importador de nuevos precios',
              prices_endorse: 'Permite aprobar precios y rechazar cambios.',
              prices_guidelineStrategy_view: 'Visualizar el menú de lineamientos y estratégias',
              prices_competitors_delete: 'Borrar un cometidor',
              prices_roundings_delete: 'Borrar un una regla de redondeo',
              prices_rules_delete: 'Borrar una regla',
              prices_sensitivities_delete: 'Borrar una sensibilidad',
              prices_decisions_prices_endorse: 'Aceptar una sugerencia (enviar a publicar)',
              assortment_clustering_save: 'Guardar una nueva clusterización',
              assortment_clustering_delete: 'Eliminar una clusterización',
              assortment_roles_save: 'Guardar cambios en un rol (o crear uno nuevo)',
              assortment_roles_delete: 'Eliminar un rol',
              assortment_strategies_save: 'Guardar cambios en una estrategia (o crear una nueva)',
              assortment_strategies_delete: 'Eliminar una estrategia',
              assortment_decisions_save: 'TBD',
              assortment_output: 'TBD',
              spaces_planograms_save: 'Guardar cambios en un planograma (o crear uno nuevo)',
              spaces_planograms_upload_audit_photo: 'Cargar fotos de auditoria de planogramas',
              spaces_planograms_approve: 'Accionar sobre el flujo de aprobación de planogramas (TBD)',
              spaces_planograms_request_approval: 'Accionar sobre el flujo de aprobación de planogramas (TBD)',
              spaces_planograms_inform: 'Accionar sobre el flujo de aprobación de planogramas (TBD)',
              spaces_planograms_messaging: 'TBD',
              spaces_planograms_pop_material_save: 'Guardar material POP estándar (TBD Promos)',
              spaces_layout_save: 'Guardar cambios en un layour (o crear uno nuevo)',
              spaces_layout_delete: 'Eliminar un layout',
              spaces_templates_save: 'Guardar una nueva configuración del Planogram Generator.',
              spaces_templates_delete: 'Eliminar una configuración del Planogram Generator.',
              equipments_save: 'Guardar cambios en un equipamiento (o crear uno nuevo)',
              equipments_delete: 'Eliminar un equipamiento',
              storemaster_save: 'Guardar cambios en un Punto de Venta (o crear uno nuevo).',
              storemaster_delete: 'Acceder al menú de Etiquetas.',
              tags_delete: 'Eliminar una etiqueta.',
              vendor_save: 'Guardar cambios en un Proveedor (o crear uno nuevo)',
              vendor_publish: 'Publicar un proveedor (TBD)',
              vendor_delete: 'Eliminar un proveedor.',
              general_importer_view: 'Acceder al importador general.',
              itemmaster_save: 'Guardar cambios en un item (o crear uno nuevo)',
              itemmaster_publish: 'Publicar un item.',
              itemmaster_delete: 'Eliminar un item.',
              update_custom_item_to_standard_item: 'Convertir un ítem propio en un ítem regular.',
              mysteryShopper_missions_save: 'Guardar cambios en una misión (o crear una nueva)',
              mysteryShopper_missions_delete: 'Eliminar una misión.',
              prices_output: 'Publicar decisiones de precios',
              prices_output_readonly: 'Visualizar el menú de Publicar decisiones y generar la vista previa',
              prices_roundings_view: 'Visualizar el menú de reglas de redondeo',
              prices_rules_view: 'Visualizar el menú de reglas de precios',
              prices_sensitivities_view: 'Visualizar el menú de sensibilidades',
              prices_view: 'Visualizar el menú de decisiones de precios',
              pricing_manual_suggestions: 'Permite generar sugerencias de precios manualmente',
              pricing_survey: 'Permite importar precios de la competencia',
              storemaster_view: 'Visualizar el menu de Puntos de venta',
              tags_view: 'Visualizar el menú de etiquetas',
              prices_competitors_save: 'Crear y actualizar banderas y competidores',
              prices_guidelineStrategy_save: 'Actualizaciones sobre lineamientos y estratégias',
              prices_roundings_save: 'Crear y actualizar reglas de redondeo',
              prices_rules_save: 'Crear y actualizar reglas de precios',
              prices_sensitivities_save: 'Crear y actualizar sensibilidades',
              tags_save: 'Crear y actualizar etiquetas',
              assortment_categoryTree_view: 'Visualizar el menu de Arbol de categorías y Configuración de categorias.',
              assortment_clustering_view: 'Visualizar el menu de clusterizaciones',
              assortment_decisions_view: 'Visualizar el menú de decisiones de surtido',
              assortment_output_readonly: 'Visualizar el menú de publicar decisiones de surtido y permitir generar la vista previa',
              assortment_roles_view: 'Visualizar el menú de roles',
              assortment_strategies_view: 'Visualizar el menú de Estrategias',
              assortment_view: 'Habilita el menú general de surtido',
              equipments_view: 'Visualizar el menú de equipamientos',
              category_tree_button_view: 'Visualizar el boton de reporte de árbol de categorías.',
              mysteryShopper_missions_view: 'Visualizar el menú de misiones',
              mysteryShopper_userAssigment_view: 'Visualizar el menú de asignacion de usuarios',
              mysteryShopper_view: 'Habilita el menú general de misiones',
              promotion_action_view: 'Visualizar el menú de Acciones',
              promotion_campaign_view: 'Visualizar el menu de Campañas',
              promotion_view: 'Habilita el menú general de promociones',
              promotion_calendar_view: 'Visualizar el menú de calendario',
              promotion_configuration_view: 'Visualizar el menú de configuraciones',
              promotion_reportes_view: 'Visualizar el menú de reportes',
              promotion_periods_view: 'Visualizar el menú de periodos promocionales',
              promotion_reportes_production_view: 'Visualizar listas de producción',
              promotion_reportes_logistic_view: 'Visualizar listas de logística',
              promotion_reportes_pos_view: 'Visualizar datos para POS',
              promotion_action_save: 'Crear y actualizar Acciones Promocionales',
              promotion_action_cancel: 'Eliminar acciones promocionales',
              promotion_action_publish: 'Publicar acciones promocionales',
              promotion_campaign_save: 'Crear y actualizar Campañas',
              promotion_reports_view: 'Visualizar reportes de promociones',
              promotion_can_edit_all_promotional_actions: 'Crear o editar promociones de períodos que ya han inicio/finalizado',
              promotion_promotional_process_view: 'Visualizar el menu de Procesos promocinoales',
              promotion_promotional_process_edit: 'Crear y actualizar procesos promocionales',
              spaces_layout_view: 'Visualizar el menu de Layout',
              spaces_planograms_pop_material_view: 'Visualizar el menu de Material Pop',
              spaces_planograms_view: 'Permite visualizar el menú de planogramas',
              spaces_view: 'Habilita el menú general de espacios',
              vendor_view: 'Visualizar el menú de vendedores',
              admin_security: 'Permisos en la gestión de Roles y Usuarios',
              spaces_planograms_delete: 'Habilita al usuario a inactivar todos los planogramas creados.',
            },
          },
          NEW_ROLE_BTN: 'Nuevo Rol',
        },
      },
      PROFILE: {
        CHANGE_PASS: 'Cambiar Contraseña',
        PROFILE_TITLE: 'Mi Perfil',
        PERMISSIONS: 'Permisos',
      },
    },
    PROMO: {
      CAMPAIGNS: {
        ROUTE: 'Promociones',
        TITLE: 'Campañas',
        NEW_CAMPAING_BTN: 'Nueva Campaña',
        NAME: 'Nombre',
        CODE: 'Código',
        ACTIONS: '# Acciones',
        SINCE: 'Desde',
        TO: 'Hasta',
        STATE: 'Estado',
        USER: 'Usuario',
        LAST_MOD: 'Ultima Modif.',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        EXPORT_TTIP: 'Exportar',
        IMPORT_TTIP: 'Importar',
        PRINT_TTIP: 'Imprimir',
        TITLE_FORM: 'Nueva Campaña',
        SAVE_BTN: 'Guardar',
        BASIC_INFORMATION_TAB: 'Datos Principales',
        CODE_INPUT: 'Código',
        NAME_INPUT: 'Nombre',
        SINCE_INPUT: 'Desde',
        TO_INPUT: 'Hasta',
        DESCRIPTION_INPUT: 'Descripción',
        STATUS_STORES: 'Todas',
        STATUS_HISTORY: 'Histórica',
        STATUS_VIGENT: 'Vigente',
        STATUS_FUTURE: 'Futura',
        STATUS_NOTVALID: 'No válido',
        STATUS_EMPTY: 'No válido',
        INVALID_TO_DATE: 'Debe ser mayor a la fecha Desde y encontrarse en el rango de fechas de la campaña',
        INVALID_FROM_DATE: 'Debe ser menor a la fecha Hasta y encontrarse en el rango de fechas de la campaña',
        INVALID_TO_DATE_CAMPAING: 'Debe ser mayor a la fecha Desde',
        INVALID_FROM_DATE_CAMPAING: 'Debe ser menor a la fecha Hasta',
        NAME_TTIP: 'Click aquí para ver acciones asociadas',
        ACTIONS_TTIP: 'Cantidad de Acciones',
        LAST_MOD_TTIP: 'Última Modificación',
      },
      ACTIONS: {
        ROUTE: 'Promociones',
        TITLE: 'Acciones',
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        NAME: 'Nombre',
        CODE: 'Código',
        TYPE: 'Tipo',
        STORES: '# Puntos de Venta',
        LIFT: 'Lift %',
        BRAND: 'Marca',
        NONE: 'Ninguna',
        SINCE: 'Desde',
        TO: 'Hasta',
        SUPPLIES_LAST: 'Hasta agotar stock',
        USER: 'Usuario',
        LAST_MOD: 'Ultima Modif.',
        STATE: 'Estado',
        TYPE_OP1: 'Lleva A paga B',
        TYPE_OP2: 'Descuento Directo',
        TYPE_OP3: 'Descuento por Cantidad',
        STATUS_STORES: 'Todas',
        STATUS_HISTORY: 'Histórica',
        STATUS_VIGENT: 'Vigente',
        STATUS_FUTURE: 'Futura',
        STATUS_NOTVALID: 'No válido',
        STATUS_EMPTY: 'No válido',
        STATUS_CANCELLED: 'Cancelada',
        INVALID_TO_TIME: 'Debe ser mayor a Horario Desde',
        INVALID_FROM_TIME: 'Debe ser menor a Horario Hasta',
        INVALID_TO_DATE: 'Debe ser mayor a fecha Desde y encontrarse en el rango de fechas de la campaña',
        INVALID_FROM_DATE: 'Debe ser menor a fecha Hasta y encontrarse en el rango de fechas de la campaña',
        INVALID_TO_DATE_CAMPAING: 'Debe ser mayor a fecha Desde',
        INVALID_FROM_DATE_CAMPAING: 'Debe ser menor a fecha Hasta',
        DEFINE_ACTION_STP1: 'Definir Acción',
        RESTRICTIONS_STP2: 'Restricciones',
        PRODUCTS_SCOPE_STP3: 'Alcance Productos',
        STORES_SCOPE_STP4: 'Alcance Puntos de Venta',
        OTHERS_TERMS_STP5: 'Otras Condiciones',
        TITLE_FORM_NEW: 'Nueva Acción - ',
        TITLE_FORM_EDIT: 'Editar Acción - ',
        STP1: 'Paso 1 - Definir Acción',
        CODE_INPUT: 'Código',
        PROMOTION_CODE_INPUT: 'Código Externo',
        NAME_INPUT: 'Nombre',
        TYPE_INPUT: 'Tipo',
        QUANTITY_BUY_INPUT: 'Cantidad a comprar',
        QUANTITY_PAY_INPUT: 'Cantidad a pagar',
        DISCOUNT_RATE_INPUT: 'Tipo de descuento',
        UNIT_DISCOUNT_INPUT: 'Descuento por unidad',
        DISCOUNT_VALUE_INPUT: 'Valor del descuento',
        QUANTITY_UNITS_INPUT: 'Cantidad de Unidades a llevar',
        UNIT_DISCOUNT_PER_INPUT: 'Descuento por unidad {{val}}',
        SINCE_INPUT: 'Desde',
        TO_INPUT: 'Hasta',
        SUPPLIES_LAST_CHECK: 'Hasta agotar stock',
        UNITS_AVAILABLE_INPUT: 'Unidades Disponibles',
        FRECUENCY_INPUT: 'Frecuencia',
        DAYS_INPUT: 'Días',
        TIME_FROM_INPUT: 'Horario Desde',
        TIME_TO_INPUT: 'Horario Hasta',
        TICKET_MESSAGE_INPUT: 'Mensaje del Ticket',
        OBSERVATION_INPUT: 'Observación',
        NEXT_BTN: 'Siguiente',
        MONDAY: 'Lunes',
        TUESDAY: 'Martes',
        WEDNESDAY: 'Miércoles',
        THURSDAY: 'Jueves',
        FRIDAY: 'Viernes',
        SATURDAY: 'Sábado',
        SUNDAY: 'Domingo',
        DAILY: 'Diario',
        WEEKLY: 'Semanal',
        BIWEEKLY: 'Quincenal',
        MONTHLY: 'Mensual',
        BIMONTHLY: 'Bimestral',
        CUARTERLY: 'Cuatrimestral',
        SEARCH_CONDITIONS: 'Buscar Otras Condiciones...',
        STP2: 'Paso 2 - Restricciones',
        UNIT_RESTRICTION: 'Restricción por Unidades',
        MINIMUM_AMOUNT_INPUT: 'Cantidad Mínima',
        MAXIMUM_AMOUNT_INPUT: 'Cantidad Máxima',
        AMOUNT_RESTRICTION: 'Restricción por Monto',
        OTHERS_INPUT: 'Otras Condiciones',
        PREVIOUS_BTN: 'Anterior',
        EQUAL_BRAND: 'Misma marca',
        EQUAL_PRICE: 'Mismo precio',
        EQUAL_CATEGORY: 'Misma categoría',
        EQUAL_DISCOUNT: 'Mismo descuento',
        EQUAL_PRODUCT: 'Mismo producto',
        STP3: 'Paso 3 - Alcance Productos',
        MANUAL_SELECTION_RADIO: 'Selección Manual',
        CATEGORY_INPUT: 'Categoría',
        SEARCH_CATEGORY: 'Buscar Categoría...',
        EXCLUDE_CATEGORY_INPUT: 'Categoría a excluir',
        SEARCH_EXCLUDE_CATEGORY: 'Buscar Categoría a excluir',
        SUBCATEGORY_INPUT: 'Subcategoría',
        SEARCH_SUBCATEGORY: 'Buscar Subcategoría',
        EXCLUDE_SUBCATEGORY_INPUT: 'Subcategoría a excluir',
        SEARCH_EXCLUDE_SUBCATEGORY: 'Buscar Subcategoría a excluir',
        SUPPLIER_INPUT: 'Proveedor',
        SEARCH_SUPPLIER: 'Buscar Proveedor',
        EXCLUDE_SUPPLIER_INPUT: 'Proveedor a excluir',
        SEARCH_EXCLUDE_SUPPLIER: 'Buscar Proveedor a excluir',
        BRAND_INPUT: 'Marca',
        SEARCH_BRAND: 'Buscar Marca',
        EXCLUDE_BRAND_INPUT: 'Marca a excluir',
        SEARCH_EXCLUDE_BRAND: 'Buscar Marca a excluir',
        TAGS_INPUT: 'Atributos',
        SEARCH_TAGS: 'Buscar Atributos',
        EXCLUDE_TAGS_INPUT: 'Atributos a excluir',
        SEARCH_EXCLUDE_TAGS: 'Buscar Atributos a excluir',
        ITEMS_INPUT: 'Productos',
        SEARCH_ITEMS: 'Buscar Productos',
        EXCLUDE_ITEMS_INPUT: 'Productos a excluir',
        SEARCH_EXCLUDE_ITEMS: 'Buscar Productos a excluir',
        MANUAL_LOAD_RADIO: 'Carga Manual',
        DRAG_FILE: 'Arrastrar el archivo aquí, o hacer click para buscar en tu PC',
        STP4: 'Paso 4 - Alcance Puntos de Venta',
        CLUSTER_INPUT: 'Cluster',
        SEARCH_CLUSTER: 'Buscar Cluster...',
        EXCLUDE_CLUSTER_INPUT: 'Cluster a excluir',
        SEARCH_EXCLUDE_CLUSTER: 'Buscar Cluster a excluir...',
        FORMAT_INPUT: 'Formato',
        SEARCH_FORMAT: 'Buscar Clientes / Comunidades...',
        EXCLUDE_FORMAT_INPUT: 'Formato a excluir',
        SEARCH_EXCLUDE_FORMAT: 'Buscar Formato a excluir...',
        STORE_INPUT: 'Punto de Venta',
        SEARCH_STORE: 'Buscar Punto de Venta...',
        EXCLUDE_STORE_INPUT: 'Punto de Venta a excluir',
        SEARCH_EXCLUDE_STORE: 'Buscar Punto de Venta a excluir...',
        STP5: 'Paso 5 - Otras Condiciones',
        CLIENTS_COMMUNITIES: 'Clientes / Comunidades',
        SEARCH_CLIENTS_COMMUNITIES: 'Buscar Clientes / Comunidades',
        PAYMENT_METHODS: 'Medios de Pago',
        SEARCH_PAYMENT_METHODS: 'Buscar Medios de Pago...',
        BANK_PROMOTIONS: 'Promociones bancarias',
        SEARCH_BANK_PROMOTIONS: 'Buscar Promociones bancarias...',
        CUMULATIVE: 'Acumulable con otras promociones',
        SAVE_BTN: 'Guardar',
        NEW_ACTION_TITLE: 'Nueva Acción Promocional',
        LEGEND_OP1:
          'Es el descuento donde A son las unidades a comprar por el cliente y B las unidades a pagar por el mismo. Se utiliza por ejemplo para indicar promociones del tipo 2x1, 3x2, 4x3',
        LEGEND_OP2:
          'Es el porcentaje o monto de descuento que aplicará sobre el precio vigente del producto. Se utiliza por ejemplo para promociones del tipo "15% de descuento" o "$10 pesos de descuento"',
        LEGEND_OP3:
          'Es el porcentaje de descuento que aplicará sobre el precio vigente del producto cuando se compra más de una unidad. se utiliza por ejemplo para promociones del tipo "2da al 80%".',
        CANCEL_BTN: 'Cancelar',
        CREATE_BTN: 'Crear',
        OVERLAP_TITLE: 'Hay Solapamiento',
        OVERLAP_SUBTITLE: 'Productos se solapan en diferentes promociones.',
        DATAIL_BTN: 'Ir al detalle',
        ACCEPT_BTN: 'Aceptar',
        ROUTE2: 'Promociones / Campaña',
        ROUTE2_PROMOTIONAL_PERIOD: 'Promociones / Períodos Promocionales',
        NEW_ACTION_BTN: 'Nueva Acción Promocional',
        ACTIONS_TITLE: 'Acciones',
        VIEW_TTIP: 'Vista',
        VIEW_TABLE: 'Solo Tabla',
        VIEW_CALENDAR: 'Solo Calendario',
        TITLE_FILTERS: 'Filtros',
        STATE_FILTER: 'Estado',
        STATE_OP1_FILTER: 'Histórica',
        STATE_OP2_FILTER: 'Vigente',
        STATE_OP3_FILTER: 'Futura',
        CAMPAINGS_FILTER: 'Campañas',
        CLEAN_FILTER_BTN: 'Limpiar',
        APPLY_FILTER_BTN: 'Aplicar',
        STORES_TTIP: 'Cantidad de Puntos de Venta',
        LAST_MOD_TTIP: 'Última Modificación',
        STP1_SUB: 'En este paso podrás definir los componentes principales del tipo de acción seleccionada.',
        STP2_SUB: 'En este paso podrás definir condiciones de surtido y restricciones mínimas y máximas para la acción.',
        STP3_SUB: 'En este paso podrás definir a qué productos y a cuales no alcanzará ésta acción.',
        STP4_SUB: 'En este paso podrás definir a qué puntos de venta y a cuales no alcanzará ésta acción.',
        STP5_SUB: 'En este paso podrás establecer condiciones que limitarán la aplicación de ésta acción.',
        STRATEGY: 'A continuación elegir la estrategia de selección de los productos hijos',
        STRATEGY_ATTR_TITLE: 'Selección por atributo (Dinámico)',
        STRATEGY_ATTR_MSG:
          'Se seleccionan atributos para definir el alcance de productos, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
        STRATEGY_STORE_ATTR_MSG: 'Se seleccionan atributos para definir el alcance de Puntos de Venta',
        STRATEGY_PROD_TITLE: 'Selección por producto',
        STRATEGY_STORE_TITLE: 'Selección por Puntos de Venta',
        STRATEGY_PROD_MSG: 'Se deberán elegir explícitamente los productos uno a uno',
        STRATEGY_STORE_MSG: 'Se deberán elegir explícitamente los puntos de venta una a una',
        GENERIC_MSG_ERRROR_EXECSUGGESTION: 'No se han podido generar sugerencias.',
        GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'No hay nuevas sugerencias.',
        GENERIC_ACTUAL_FILE: 'Archivo actual:',
      },
      HELP: {
        QUANTITY_BUY_INPUT:
          'Indica las unidades (A) que se llevará el cliente.  Ejemplo: Si la acción es "3 x 2" acá debemos ingresar "3".',
        QUANTITY_PAY_INPUT:
          'Indica las unidades (B) que pagará el cliente. Es un valor menor a "Cantidad a Comprar".  Ejemplo: Si la acción es "3 x 2" acá debemos ingresar "2".',
        FRECUENCY_INPUT: 'Indica la repetición de la acción a lo largo del tiempo.',
        DAYS_INPUT: 'Días de la semana en los que estará vigente la acción.',
        TIME_FROM_INPUT: 'Rango de horario en el que estará vigente la acción.',
        TIME_TO_INPUT: 'Rango de horario en el que estará vigente la acción.',
        TICKET_MESSAGE_INPUT: 'Descripción corta de la acción que se verá reflejado en el ticket factura.',
        OBSERVATION_INPUT: 'Permite ingresar algún tipo de información o detalle de la acción.',
        UNIT_DISCOUNT_INPUT: 'Indica el valor del descuento que se va a aplicar sobre cada unidad vendida.',
        DISCOUNT_RATE_INPUT: 'Indica si el descuento va a ser en porcentaje o en pesos.',
        SUPPLIES_LAST_CHECK: 'Mensaje informativo a efectos legales para el punto de venta.',
        UNITS_AVAILABLE_INPUT:
          'Indica las unidades del producto total compañía disponibles para la acción. Mensaje informativo a efectos legales para el punto de venta.',
        DISCOUNT_VALUE_INPUT:
          'Valor total del descuento que corresponde a la promoción. Ejemplo: Si la acción es "2da unidad al 30% de descuento", acá debemos ingresar el número "30".',
        UNIT_DISCOUNT_PER_INPUT:
          'Indica el valor del descuento que se va a aplicar sobre cada unidad vendida. Ejemplo: Si la acción es "2da unidad al 30% de descuento", acá debemos ingresar el número "15" que es el porcentaje que aplicará de descuento sobre cada unidad vendida.',
        QUANTITY_UNITS_INPUT:
          'Indica las unidades obligatorias que debe comprar el cliente para que aplique el descuento. Ejemplo: Si la acción es "2da unidad al 30% de descuento", acá debemos ingresar el número "2" que son las unidades necesarias para que aplique el descuento.',
        MINIMUM_AMOUNT_INPUT:
          'Indica las unidades mínimas que debe comprar el cliente para que aplique el descuento. Ejemplo: Si la acción es "15% de descuento llevando 3 unidades", acá debemos ingresar el número "3".',
        MAXIMUM_AMOUNT_INPUT:
          'Indica las unidades máximas que puede comprar el cliente con el descuento. A partir de este valor, las unidades no contarán con la promoción. Ejemplo: Si la acción es "15% de descuento hasta 6 unidades", acá debemos ingresar el número "6", en la unidad "7" o sucesivas no aplica el 15%.',
        MINIMUM2_AMOUNT_INPUT:
          'Indica valor monetario mínimo que debe comprar el cliente para que aplique la promoción. Ejemplo: Si la acción es "10% de descuento en compras superiores a $1.000", acá debemos ingresar el número "1.000". Si no se alcanza ese monto, no aplica el descuento.',
        MAXIMUM2_AMOUNT_INPUT:
          'Indica valor monetario máximo que puede comprar el cliente para que aplique la promoción. A partir de ese monto, el excedente de la compra no tendrá descuento. Ejemplo: Si la acción es “10% de descuento en compras hasta $1.000”, acá debemos ingresar el número “1.000”. Si la compra es de $1.500, los $500 no tienen el 10% de descuento.',
      },
    },
    PROMOV2: {
      CONFIGURATIONS: {
        LIST: {
          TITLE: 'Reglas de Promociones',
          NEW_RULE: 'Nueva regla',
          NAME: 'Nombre',
          CODE: 'Código',
          RULE_TYPE: 'Tipo de regla',
        },
        FORM: {
          NEW_ITEM_TITLE: 'Nueva regla de promociones',
          NAME: 'Nombre',
          CODE: 'Código',
          RULE_TYPE: 'Tipo de regla',
          PROMOTIONAL_PROCESS_TYPE: 'Tipo de proceso promocional',
          PROMOTION_TYPE: 'Mecánica',
          PROMOTION_RULE_TYPE: 'Tipo de Regla',
          LIMITS: 'Límites',
          PRICE_RULES_TITLE: 'El precio debe ser:',
          VALUE: 'Valor {{val}}',
          RULE_MESSAGES: {
            FIXPRICE_MINIMUM: 'Mi {{val}} precio promocional debe ser ${{val2}}.',
            FIXPRICE_MAXIMUM: 'Mi {{val}} precio promocional debe ser ${{val2}}.',
            REGULARPRICE_MINIMUM: 'Mi {{val}} precio promocional debe ser {{val3}}{{val2}} menor al precio regular.',
            REGULARPRICE_MAXIMUM: 'Mi {{val}} precio promocional debe ser {{val3}}{{val2}} sobre el precio regular.',
            TRUCKPRICE_MINIMUM: 'Mi {{val}} precio promocional debe ser {{val3}}{{val2}} menor al precio camión.',
            TRUCKPRICE_MAXIMUM: 'Mi {{val}} precio promocional debe ser {{val3}}{{val2}} sobre el precio camión.',
            SELECT_CONDITION: '(Elija una condición para la regla)',
            INSERT_VALUE: '(Inserte un valor)',
            SELECT_DISCOUNT_TYPE: '(Elija el tipo de descuento)',
          },
          REFERENCE_PRICE_TYPES: {
            PLACEHOLDER: 'Tipo de precio de referencia',
            FIXPRICE: 'Precio fijo',
            REGULARPRICE: 'Precio regular',
            TRUCKPRICE: 'Precio camión',
          },
          RULE_LIMIT_TYPES: {
            PLACEHOLDER: 'Condición',
            MINIMUM: 'Mínimo',
            MAXIMUM: 'Máximo',
          },
          PROMOTION_REGULAR_PRICE_CONDITION: {
            CURRENCY: '$',
            PERCENTAGE: '%',
          },
        },
        RULE_TYPES: {
          PRICE: 'Precio',
        },
        PROMOTIONAL_PROCESS: {
          LIST_TITLE: 'Procesos promocionales',
          NAME: 'Nombre',
          CODE: 'Código',
          TASK_QUANTITY: 'Cantidad de tareas',
          EDIT: 'Editar',
          CANT_EDIT_TOOLTIP: 'No se puede editar el proceso promocional ya que tiene periodos promocionales asignados',
          CANT_DELETE_TOOLTIP: 'No se puede eliminar el proceso promocional ya que tiene periodos promocionales asignados',
          NEW: 'Nuevo proceso promocional',
          MAIN_DATA_TAB: 'Datos principales',
          TASKS_TAB: 'Tareas',
          WITHOUT_PERMISSION: 'El usuario no tiene permiso para editar.',
          DELETE_ROW: 'Eliminar proceso promocional.',
          DELETE_MODAL_TITLE: '¿Desea eliminar ael proceso promocional{{val}}?',
          TASKS: {
            NAME: 'Nombre',
            STARTING_DAY: 'Día de inicio',
            BEFORE: 'Antes',
            AFTER: 'Después',
            TASK_OWNER: 'Responsable',
            CONSULTANTS: 'Consultores',
            DURATION_IN_DAYS: 'Duración',
            REQUIRES_COMPLETION: 'Requiere finalizar',
            ACTIONABLE: 'Accionable',
            NO_ACTIONABLES: 'No hay accionables disponibles',
            NEW: 'Agregar tarea',
            DELETE_MODAL_TITLE: '¿Desea eliminar la tarea{{val}}?',
            CLOSE_ALL: 'Cerrar Todo',
            EXPAND_ALL: 'Expandir Todo',
            ORDER: 'Ordenar',
            ORDER_TOOLTIP: 'Ordenar las tareas según el día de inicio',
            ACTIONABLES: {
              GeneratePOSAndPeriodReport: 'Envío automático de output', // Output
              GeneratePromotionReport: 'Envío automático de reporte de promociones', // Reporte Promociones
              GeneratePrintingAndLogisticsReport: 'Envío automático de listas de producción ', // Lista de Produccion
              PublishAllApprovedNewActions: 'Publicación automática', // Publicacion automática
            },
          },
        },
        MESSAGE_STRUCTURES: {
          TABS: {
            MAINDATA: 'Datos Principales',
            MESSAGESTRUCTURE: 'Estructura de mensaje',
            TAB_ERROR: 'El tab "{{val}}" contiene errores o campos incompletos.',
          },
          MESSAGE_STRUCTURE_WARNING:
            'Ya existe una estructura de mensaje por defecto, si desea editarla, se sobreescribirán los datos actuales.',
          MESSAGE_STRUCTURE_WARNING_TITLE: 'Estructura de mensaje por defecto',
          LIST: {
            NAME: 'Nombre',
            CODE: 'Código',
            PROMOTION_PROCESS: 'Proceso promocional',
            PROMOTION_TYPE: 'Mecánica',
            COMMUNICATION_TYPE: 'Tipo de comunicación',
            DEFAULT_STRUCTURE: 'Estructura por defecto',
            NEW_STRUCTURE: 'Nueva estructura',
            DELETE_STRUCTURE: 'Eliminar estructura',
            VIEW_ONLY_INACTIVES: 'Solo ver inactivos',
            DELETE_STRUCTURE_MESSAGE: '¿Está seguro que desea eliminar la estructura de mensaje{{val}}?',
            DELETE_STRUCTURE_IN_USE: 'No se puede eliminar una estructura en uso',
          },
          FORM: {
            NEW_STRUCTURE_TITLE: 'Nueva estructura de mensaje',
            NAME_INPUT: 'Nombre',
            CODE_INPUT: 'Código',
            COMMUNICATION_TYPE_INPUT: 'Tipo de comunicación',
            DEFAULT_MESSAGE_STRUCTURE_INPUT: 'Estructura de mensaje por defecto',
            MESSAGE_STRUCTURE_TAB: 'Estructura de mensaje',
            MESSAGE_STRUCTURE_TAB_ERROR: 'El tab "{{val}}" contiene errores o campos incompletos.',
            EXAMPLE: 'Ejemplo',
            DEFAULT_MESSAGE_STRUCTURE_INPUT_DISABLED:
              'Debe seleccionar un proceso promocional y una mecánica para seleccionar una estructura por defecto',
          },
        },
      },
      CAMPAIGNS_LIST: {
        ROUTE: 'Promociones',
        TITLE: 'Campañas',
        TITLE_PROMOTIONAL_PERIOD: 'Período Promocional',
        NEW_CAMPAING_BTN: 'Nueva Campaña',
        NEW_PROMOTIONAL_PERIOD: 'Nuevo Período Promocional',
        NAME: 'Nombre',
        CODE: 'Código',
        ACTIONS: '# Acciones',
        FROM: 'Desde',
        TO: 'Hasta',
        STATE: 'Estado',
        USER: 'Usuario',
        ACTIONSCOUNT: '# Acciones',
        LAST_MOD: 'Ultima Modif.',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        EXPORT_TTIP: 'Exportar',
        IMPORT_TTIP: 'Importar',
        PRINT_TTIP: 'Imprimir',
        TITLE_FORM: 'Nueva Campaña',
        SAVE_BTN: 'Guardar',
        BASIC_INFORMATION_TAB: 'Datos Principales',
        CODE_INPUT: 'Código',
        NAME_INPUT: 'Nombre',
        FROM_TTIP: 'Fecha desde',
        TO_TTIP: 'Fecha hasta',
        DESCRIPTION_INPUT: 'Descripción',
        STATUS_STORES: 'Todas',
        STATUS_HISTORY: 'Histórica',
        STATUS_VIGENT: 'Vigente',
        STATUS_FUTURE: 'Futura',
        STATUS_NOTVALID: 'No válido',
        STATUS_EMPTY: 'No válido',
        INVALID_TO_DATE: 'Debe ser mayor a la fecha Desde y encontrarse en el rango de fechas de la campaña',
        INVALID_FROM_DATE: 'Debe ser menor a la fecha Hasta y encontrarse en el rango de fechas de la campaña',
        INVALID_TO_DATE_PROMOTIONAL_PERIOD: 'Debe ser mayor a la fecha Desde y encontrarse en el rango de fechas del período promocional',
        INVALID_FROM_DATE_PROMOTIONAL_PERIOD: 'Debe ser menor a la fecha Hasta y encontrarse en el rango de fechas del período promocional',
        INVALID_TO_DATE_CAMPAING: 'Debe ser mayor a la fecha Desde',
        INVALID_FROM_DATE_CAMPAING: 'Debe ser menor a la fecha Hasta',
        NAME_TTIP: 'Click aquí para ver acciones asociadas',
        ACTIONS_TTIP: 'Cantidad de Acciones',
        LAST_MOD_TTIP: 'Última Modificación',
        SEE_ACTIONS: 'Acciones',
      },
      CAMPAIGNS_EDIT: {
        SAVE_BTN: 'Guardar',
        BASIC_INFORMATION_TAB: 'Datos Principales',
        CODE_INPUT: 'Código',
        NAME_INPUT: 'Nombre',
        FROM: 'Desde',
        INVALID_TO_DATE_CAMPAING: 'Debe ser mayor a la fecha Desde',
        INVALID_FROM_DATE_CAMPAING: 'Debe ser menor a la fecha Hasta',
        TO: 'Hasta',
        DESCRIPTION_INPUT: 'Descripción',
        TITLE_FORM: 'Nueva Campaña',
        TITLE_FORM_PROMOTIONAL_PERIOD: 'Nuevo Período Promocional',
      },
      ACTIONS_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE: 'eliminar',
        TITLE: 'Listado de acciones promocionales',
        NEW_ACTION: 'nueva acción Promocional',
        EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
        EDIT_ROW: 'Editar',
        DELETE_ROW: 'Eliminar',
        REVALIDATE_ROW: 'Revalidar',
        REVALIDATING_TOOLTIP: 'Revalidar',
        SEE_DELETED_FILTERS: 'Ver eliminados',
        SEE_CANCELLED_FILTERS: 'Ver Solo Cancelados',
        SEE_SUGGESTED_FILTERS: 'Ver Solo Sugerencias',
        SEE_WITHOUT_POP_MATERIAL: 'Ver Sin Material Promocional',
        SEE_SUGGESTED_FILTERS_TOOLTIP: 'Acción Sugerida',
        ACTION_STATUS: 'Estado de la Acción:',
        STATUS_VAL: 'Estado y Validación',
        STATUS: 'Estado',
        ACTION_STATUS_VALIDATION: 'Estado de Validación:',
        TYPE_FILTERS: 'Filtrar por tipo',
        CREATION_ON: 'Fecha de creación',
        STORES_FILTER: 'Puntos de Venta',
        ITEM_TAGS: 'Filtros para Productos',
        STORE_TAGS: 'Etiquetas Puntos de Ventas',
        ITEM_TYPE_INPUT: 'Tipo de Acción',
        ITEM_TYPE_INPUT_OXXO: 'Proceso promocional',
        ITEM_TYPE_MEC_INPUT: 'Mecánica',
        NEW_ITEM_MODAL: 'Nueva Acción Promocional',
        CLONE_ROW: 'Clonar registro',
        CANCEL_ROW: 'Cancelar registro',
        CREATE_BTN: 'Crear',
        COPY_ROW: 'Copiar registro',
        DISABLED_CLONE_TOOLTIP: 'Solo se puede clonar registros en estado publicado',
        REPORT_TOOLTIP: 'Descargar Reporte',
        REPORT_TOOLTIP_MORE_INFO:
          'Este informe genera registros basados en la configuración de la promoción. \nPara poder generar los registros, es necesario contar con los siguientes campos cargados: periodo, espacios, alcance de tiendas y productos, y configuración del descuento y la cobertura aprobada.',
        ACTIONS: {
          ROUTE: 'Promociones',
          TITLE: 'Acciones',
          FILTER_TTIP: 'Filtrar',
          FULLSCREEN_TTIP: 'Expandir',
          EXITFULLSCREEN_TTIP: 'Reducir',
          NAME: 'Nombre',
          CODE: 'Código',
          TYPE: 'Mecanica',
          LIFT: 'Lift %',
          BRAND: 'Marca',
          NONE: 'Ninguna',
          TO: 'Hasta',
          SUPPLIES_LAST: 'Hasta agotar stock',
          USER: 'Usuario',
          LAST_MOD: 'Ultima Modif.',
          STATE: 'Estado',
          PROMOTIONAL_SPACES: 'Espacios promocionales',
          PROMOTIONAL_MATERIAL: 'Material Promocional',
          EDIT_PROMOTIONAL_SPACES: 'Editar Material Promocional',
          EDIT_VALIDATIONS: 'Editar validaciones de negocio',
          LIST_PROMOTIONAL_SPACES: 'Espacios Promocionales seleccionados:',
          VALIDATION_STATE: 'Estado de Validación',
          VALID_TO_PUBLISH: 'Listo para publicar',
          TYPE_OP1: 'Lleva A paga B',
          TYPE_OP2: 'Descuento Directo',
          TYPE_OP3: 'Descuento por Cantidad',
          METHODTYPE: 'Tipo de método',
          DISCOUNTTYPE: 'Tipo de descuento',
          STOREQUANTITY: '# Puntos de venta',
          ESTIMATEDSALE: 'Venta estimada',
          FREQUENCYTYPE: 'Tipo de frecuencia',
          FROM: 'Desde',
          FROM_TTIP: 'Fecha desde',
          TO_TTIP: 'Fecha hasta',
          PROMOTINOAL_PERIOD: 'Periodo promocional',
          PROMOTIONAL_LOG: 'Bitácora de promociones',
          PROMOTIONAL_PERIOD_CODE: 'Código del periodo',
          EXTERNAL_CODE: 'Código externo',
          ONLY_ACTIVE_PERIODS: 'Solo períodos activos',
          NO_ACTIVE_PERIODS: 'No hay períodos activos.',
          NO_ACTIVE_PERIODS_IN_SELECTION:
            'No hay períodos activos en el año y proceso promocional seleccionado. Revisar en el calendario promocional la siguiente fecha para realizar este tipo de actividad.',
          NAVIGATE_TO_CALENDAR: 'Ir a Calendario',
          CANT_EDIT_VALIDATIONS: 'No pueden editarse las validaciones porque el período promocional ya ha inicio/finalizado.',
        },
        TYPE: {
          DISCOUNT: 'Descuento',
          BUYAPAYB: 'Lleva A paga B',
          BUYAGETDISCOUNTINXUNIT: 'Obtener descuento por unidad',
          SURCHARGE: 'Recargo',
          FIXPRICE: 'Precio Fijo',
          GIFT: 'Regalo',
          WHOLESALER: 'Mayorista',
          IMAGECOMMUNICATION: 'Comunicación de imagen',
        },
        TYPE_DEC: {
          DISCOUNT: 'Permite definir un valor monetario o porcentaje que se aplicará reduciendo el precio final que pagará el cliente.',
          BUYAPAYB: 'Descuento donde A es la cantidad de unidades a comprar por el cliente y B las unidades a pagar.',
          BUYAGETDISCOUNTINXUNIT: 'Se aplica descuento a un número de unidades dentro de una cantidad mínima a comprar del mismo producto.',
          SURCHARGE: 'Permite definir un valor monetario o porcentaje que se aplicará aumentando el precio final que pagará el cliente.',
          FIXPRICE: 'Establece el precio a pagar llevando un número de unidades.',
          GIFT: 'Permite definir un producto de regalo al comprar otros productos, un mínimo de compra y/o estableciendo precio del producto regalo.',
          WHOLESALER: 'Mayorista',
          IMAGECOMMUNICATION: 'Destaca productos nuevos o el precio regular tan atractivo que tiene',
        },
        METHODTYPE: {
          ACTIVATEONLYDURINGPROMOTION: 'Activo solo durante la promoción',
          ACTIVATEINDEFINITELY: 'Activo indefinidamente',
          DONOTACTIVATE: 'No activar productos',
        },
        DISCOUNTTYPE: {
          ABSOLUTEVALUE: '$',
          PERCENTAGE: '%',
        },
        FREQUENCYTYPE: {
          EVERYDAY: 'Todos los días',
          CERTAINDAYSOFTHEWEEK: 'Ciertos días de la semana',
          CERTAINDAYSOFTHEMONTH: 'Ciertos días del mes',
        },
        CLONE: {
          MODAL_CONTENT:
            'Definir nombre y código interno para la nueva promoción. Se clonaran de {{val}} ({{val2}}) todos los puntos ya existentes, teniendo la posibilidad de edición.',
          CREATE_BTN: 'Clonar',
          CANCEL: 'Cancelar',
        },
        CANCEL: {
          MODAL_CONTENT:
            'Se cancelara la promoción {{val}} ({{val2}}) todo su contenido será inhabilitado para modificaciones y la promoción no tendrá validez',
          CANCEL_REASON: 'Motivo de cancelación',
          CANCEL_OBSERVATION: 'Observación',
          CREATE_BTN: 'Aceptar',
          CANCEL: 'Cerrar',
        },
      },
      ACTIONS_TYPES: {
        Discount: 'Descuento Directo',
        BuyAPayB: 'Lleva A Paga B',
        BuyAGetDiscountInXUnit: 'Obtener descuento por unidad',
        Surcharge: 'Recargo',
        FixPrice: 'Precio Fijo',
        Gift: 'Regalo',
        Wholesaler: 'Mayorista',
        ImageCommunication: 'Comunicación de imagen',
      },
      ACTIONS_EDIT: {
        TEST: 'TEST',
        SAVE: 'Guardar',
        PUBLISH: 'Publicar',
        CANCEL_ACTION: 'Cancelar',
        OK: 'Ok',
        WARNING_DOWNLOAD: 'Se requiere tener un solo período promocional seleccionado en los filtros.',
        WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
        WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
        NOVALID_PUBLISH_ADVICE: 'El siguiente tab no es válido:',
        PROMOTION_EDIT_DISABLED: 'No se puede editar la promocion porque el período promocional seleccionado ya ha iniciado o finalizado.',
        BASIC_INFORMATION_TAB: 'Datos Principales',
        ACTIVATION_TAB: 'Activación',
        LOADING: 'Cargando...',
        SCOPE_OF_ITEMS: 'Alcance de Productos',
        PAYMENTMETHODS: 'Medios de Pago',
        CUSTOMFIELDS: 'Información Adicional',
        SCOPE_OF_STORES: 'Alcance de Puntos de Venta',
        GIFT_ITEM: 'Productos de Regalo',
        GIFT_ITEM_TAB: 'Alcance de Productos de Regalo',
        NEW_ITEM_BTN: 'Nueva Acción Promocional',
        PUBLISH_CONFIRM:
          'Hasta no finalizar el proceso de validación no se podra modificar la promoción de nuevo.<br><br><b>Estás seguro que deseas Publicar?</b>',
        PUBLISH_CONFIRM_TITLE: 'Confirmación de Publicación',
        PUBLISHING_WARNING: 'Ítem en proceso de publicación, vuelva a intentar en unos minutos.',
        PUBLISH_LONG_TIME:
          'La publicación de este Producto puede demorar algunos minutos, cuando finalice serás avisado con una notificación.',
        INVALID_TO_TIME: 'Debe ser mayor a Horario Desde',
        INVALID_FROM_TIME: 'Debe ser menor a Horario Hasta',
        STRATEGY_ATTR_TITLE: 'Selección por atributo (Dinámico)',
        STRATEGY_ATTR_MSG:
          'Se seleccionan atributos para definir el alcance de productos, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
        STRATEGY_PROD_TITLE: 'Selección por producto',
        STRATEGY_PROD_MSG: 'Se deberán elegir explícitamente los productos uno a uno',
        STRATEGY_ATTR_TITLE_STORE: 'Selección por atributo (Dinámico)',
        STRATEGY_ATTR_MSG_STORE:
          'Se seleccionan atributos para definir el alcance de puntos de venta, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
        STRATEGY_STORE_TITLE_STORE: 'Selección por puntos de venta',
        STRATEGY_STORE_MSG_STORE: 'Se deberán elegir explícitamente los puntos de venta uno a uno',
        CLIPBOARD_STORE_TITLE: 'Pegar puntos de venta desde portapapeles',
        CODE_AND_DESCRIPTION_INPUT: 'Código / Descripción',
        CLIPBOARD_PRODUCT_TITLE: 'Pegar productos desde portapapeles',
        DELETE_TIME_RANGE: 'Borrar Rango Horario',
        ADD_TIME_RANGE: 'Agregar Rango Horario',
        OVERLAP_TIME_RANGE: 'Rango Horario Solapado',
        SAVE_LONG_TIME: 'Confirmar Guardado',
        SAVE_OBSERVATION: 'Observaciones',
        SAVE_LONG_TIME_MSG_A:
          'Guardar esta promoción puede demorar algunos minutos, se validará y al finalizar serás avisado con una notificación.',
        SAVE_LONG_TIME_MSG_B:
          'Hasta no finalizar el proceso de validación no se podra modificar la promoción de nuevo.<br><br><b>Estás seguro que deseas Guardar?</b>',
        SAVE_LONG_TIME_MSG_C: 'Hasta no finalizar el proceso de validación no se podra modificar la promoción de nuevo.',
        SAVE_LONG_TIME_MSG_D: 'Estás seguro que deseas Guardar?',
        SAVE_PVD_MSG:
          'Estoy de acuerdo que mi acción promocional aplique a todos mis <br> productos y puntos de venta. (Estoy informado que esta situación <br>puede generar una mayor espera en el proceso de validaciones).',
        SAVE_STORE_SCOPE_PVD_MSG:
          'Estoy de acuerdo que mi acción promocional aplique a todos mis <br> puntos de venta. (Estoy informado que esta situación puede  <br> generar una mayor espera en el proceso de validaciones).',
        SAVE_ALL_ITEMS_SELECTED_WARNING:
          'El alcance de productos definido corresponde al 100% de los productos, defina un alcance específico para poder guardar.',
        PROCESS_WARNING_TITLE: 'No se puede completar la acción requerida',
        PROCESS_WARNING_MSGLONGTIME: 'No se puede realizar la publicación y/o guardado por los siguientes motivos:',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_1:
          'La promoción se encuentra en Proceso de Validación por lo cual no es posible guardar ni publicar hasta su finalización.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_2:
          'La promoción se encuentra en estado de Validación Rechazada, por lo cual no es posible publicar.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_3:
          'La promoción se encuentra en estado Validación Cancelada, por lo cual no es posible publicar.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_4:
          'La promoción se encuentra pendiente de Acción Requerida, por lo cual no es posible publicar hasta que no se resuelva dicha Acción.',
        COVERAGE_TITLE: 'Cobertura',
        MAIN_DATA: {
          NAME: 'Nombre',
          CODE: 'Código',
          EXTERNAL_CODE: 'Código Externo',
          MESSAGE: 'Mensaje del Ticket',
          REGENERATE: 'Regenerar',
          REGENERATE_TOOLTIP:
            'Se genera un mensaje con los primeros {{val}} caracteres del nombre de la acción promocional, excluyendo los precios.',
          INTERNAL_CODE: 'Código Interno',
          VALIDATION_CODE2: 'El código {{val}} ya existe.',
          SINCE_INPUT: 'Desde',
          TO_INPUT: 'Hasta',
          OBSERVATION: 'Observación',
          TITLE_FRECUENCY: 'Frecuencia',
          TIME_FROM: 'Horario desde',
          TIME_TO: 'Horario Hasta',
          CAMPAING: 'Campaña',
          PROMOTIONAL_PERIOD: 'Período Promocional',
          SET_PROMOTIONAL_PERIOD: 'Al seleccionar o modificar se cargaran las fechas del período promocional elegido',
          PROMOTIONAL_MATERIAL: 'Material Promocional',
          GIFT_ITEM: 'Producto de Regalo',
          ACCUMULATE_ACTIONS: 'Acumulable con otras acciones',
          GIFT_ITEM_POPOVER_MSG: 'Puede seleccionarse un único producto de regalo, existente en el módulo de Ítem Master',
          GIFT_ITEM_NEW: 'Nuevo Producto de Regalo',
          GIFT_ITEM_NEW_POPOVER_MSG:
            'Si el producto de regalo no debe ser administrado por el módulo Ítem Master, puede indicarse el nombre del mismo en este campo',
          SELECTTIMERANGE: 'Acción activa las 24hs. del día',
          SELECTTIMERANGE_TEXT:
            'Si esta opción está activa la acción promocional estará vigente las 24 horas del día, en caso contrario será necesario definir un rango horario diario para indicar el período en el que la acción promocional esté activa.',
          PLANOGRAMS_WARNING_PRODUCT_SCOPE: 'Seleccione el Alcance de Productos para poder editar.',
          PLANOGRAMS_WARNING_STORE_SCOPE: 'Seleccione el Alcance de Puntos de Venta para poder editar.',
          PLANOGRAMS_WARNING_SAVE: 'Guarde los cambios para poder editar.',
          PLANOGRAMS_WARNING_NO_PLANOGRAMS: 'No hay planogramas en el alcance de tiendas seleccionado.',
          PLANOGRAMS_WARNING_ALL_ITEMS: 'Defina un alcance de productos específico para obtener el material promocional disponible.',
          PLANOGRAMS_WARNING_MAIN_DATA: 'Complete los campos obligatorios para poder guardar los cambios y editar.',
          PLANOGRAMS_WITHOUT_POP_MATERIAL: 'Sin material promocional',
          PLANOGRAMS_DELETED: '{{val}} materiales promocionales previamente seleccionados ya no están disponibles.',
          PERIOD_WARNING_INVALID_FROM_TODAY: 'La Fecha de comienzo es anterior a la fecha actual',
          PERIOD_WARNING_INVALID_FROM_TASK: 'La primer tarea de planificación para el periodo seleccionado deberia haber comenzado el',
          PERIOD_WARNING_INVALID_FROM_TO: 'La Fecha de comienzo no puede ser posterior a la de finalización',
          ESTIMATEDSALE: 'Venta estimada',
          CHANGE_REASON: 'Motivo de Cambio',
          TICKET_MESSAGE: {
            MODAL_TITLE: '¿Desea regenerar el mensaje del ticket?',
            PRICE: 'No se permiten precios en el mensaje.',
            SPECIALCHARACTERS: 'No se permiten caracteres especiales en el mensaje.',
            DOUBLESPACES: 'No se permiten espacios dobles en el mensaje.',
            MAXCHARACTER: 'Alcanzó el máximo de caracteres permitido para el mensaje.',
          },
        },
        VALIDATIONS: {
          VALIDATIONS_TITLE: 'Validaciones',
          COVERAGE_TOOLTIP:
            'Para mejorar esta validación sugerimos ajustar el alcance de tiendas o productos, previamente chequeando la cobertura por medio de etiquetas y analizar el estado actual de cobertura por diferentes conjuntos de puntos de venta.',
          MAX_SLOTS_TOOLTIP:
            'Para mejorar esta validación sugerimos ajustar el alcance de tiendas o modificar los materiales promocionales utilizados.',
          FIX_AND_REGULAR_PRICE_TOOLTIP:
            'Para mejorar los resultados de esta validación sugerimos ajustar el precio promocional desde el tab de activación.',
          STORE_AND_PROMOTIONAL_SPACE:
            'Para mejorar esta validación sugerimos ajustar el alcance de tiendas o modificar los materiales promocionales utilizados.',
          FIX_PRICE_EXCEPTIONS:
            'Para mejorar esta validación sugerimos ajustar las excepciones de precios en el importador de excepciones.',
          REFERENCE_PRICE: 'Para mejorar esta validación sugerimos ajustar el precio promocional desde el tab de activación.',
          ACTION_BUTTON_DISABLED_TOOLTIP: 'Solo esta permitido modificar el estado si la acción promocional se encuentra publicada',
          TAB_TITLE: 'Validaciones de negocio',
          TAB_DESCRIPTION:
            'Las validaciones de negocio, se utilizan para controlar que la acción promocional cumpla con los criterios necesarios para su ejecución, en caso que una validación presente errores, podrá aprobarse o rechazarse al momento de publicar o preventivamente modificar los datos involucrados para obtener nuevos resultados. Por medio de los reportes podemos obtener información detallada de los fallos y en caso de no tener todos los datos necesarios para realizar la validación se informara como incompleta. Es necesario contar con todas las validaciones aprobadas y validadas para obtener reportes productivos.',
          storesAndPromotionalSpace: {
            VALIDATION_NAME: 'Alcance de tiendas vs Material Promocional',
            VALIDATION_DESCRIPTION:
              'Valida si alguna tienda configurada para esta promoción no cuenta con los materiales promocionales seleccionados.',
            VALIDATION_ERROR:
              'Se detectaron {{val}} tiendas configuradas para esta promoción que no cuentan con alguno de los materiales promocionales seleccionados.',
            VALIDATION_SUCCESS: 'Todos los materiales promocionales seleccionados están disponibles en las tiendas de la promoción.',
            VALIDATION_INCOMPLETE:
              'No se puede determinar el impacto de tiendas porque no fueron seleccionados los materiales promocionales o el alcance de tiendas para esta promoción.',
          },
          MaxSlots: {
            VALIDATION_NAME: 'Cantidad Máxima de promociones por Material Promocional',
            VALIDATION_DESCRIPTION:
              'Valida si se superó el límite máximo de promociones soportadas en alguno de los materiales promocionales seleccionados.',
            VALIDATION_ERROR: 'Esta promoción supera la capacidad máxima soportada por {{val}} espacio-tiendas.',
            VALIDATION_SUCCESS: 'La promoción se pudo asignar correctamente en los materiales promocionales seleccionados.',
            VALIDATION_INCOMPLETE: 'No fue calculada la cantidad máxima de promociones porque faltan seleccionar materiales promocionales.',
          },
          FixAndRegularPrice: {
            VALIDATION_NAME: 'Precio promocional vs Precio regular',
            VALIDATION_DESCRIPTION:
              'Valida que el precio promocional fijado sea menor al precio regular en esa tienda, controlando que se cumpla para todas las tiendas en que aplique esta promoción.',
            VALIDATION_ERROR: 'Se detectó que el precio promocional es mayor o igual al precio regular en {{val}} productos-tiendas.',
            VALIDATION_SUCCESS: 'El precio promocional es menor al precio regular en todos los productos-tiendas de esta promoción.',
            VALIDATION_INCOMPLETE:
              'No se puede determinar si el precio promocional es correcto porque no se seleccionaron productos o tiendas en sus respectivos alcances.',
          },
          FixPriceExceptions: {
            VALIDATION_NAME: 'Excepción de precio vs precio regular',
            VALIDATION_DESCRIPTION:
              'Valida que el precio promocional fijado en el importador de excepciones de precio, sea menor al precio regular para cada tienda del alcance de puntos de venta definidos en el importador.',
            VALIDATION_ERROR:
              'El precio promocional es mayor o igual al precio regular en {{val}} productos-tiendas en las excepciones de esta promoción.',
            VALIDATION_SUCCESS: 'El precio promocional es menor al precio regular en todos los productos-tiendas de esta promoción.',
            VALIDATION_INCOMPLETE:
              'Para validar esta acción promocional es necesario definir alcance de productos, alcance de tiendas y al menos importar 1 excepción de precio correctamente.',
          },
          Coverage: {
            VALIDATION_NAME: 'Cobertura Mínima',
            VALIDATION_DESCRIPTION: 'Valida que se alcance la cobertura mínima.',
            VALIDATION_ERROR: 'No se cumple con la cobertura mínima definida del {{val}}%.',
            VALIDATION_SUCCESS: 'Se cumple con la cobertura mínima definida del {{val}}%.',
            VALIDATION_INCOMPLETE:
              'La cobertura no fue calculada porque no se seleccionaron productos o tiendas en sus respectivos alcances.',
          },
          ReferencePrice: {
            VALIDATION_NAME: 'Precio promocional vs Precio camión',
            VALIDATION_DESCRIPTION:
              'Valida que el precio promocional cumpla con la regla de precios definida, controlando que se cumpla para todas las tiendas en que aplique esta promoción.',
            VALIDATION_ERROR:
              'El precio promocional no cumple con la regla de precio camión en todos los productos-tiendas de esta promoción.',
            VALIDATION_SUCCESS:
              'El precio promocional cumple con la regla de precio camion en todos los productos-tiendas de esta promoción.',
            VALIDATION_INCOMPLETE:
              'No se puede determinar si el precio promocional es correcto porque requiere ser cargado en tab de activación.',
          },
        },
        DOWNLOAD_VALIDATION_REPORT: 'Descargar Reporte de Validaciones',
        VALIDATION_NO_DATA: 'No hay datos suficientes para validar',
        ACTIVATION: {
          ASSORTMENTMETHOD: 'Comportamiento de Surtido',
          CHANNELCODES: 'Canales de Venta',
          LOYALTYPROGRAMCODES: 'Programas de Lealtad',
          LOYALTYPROGRAMCODES_GLOBAL: 'Restricción para Programas de Lealtad',
          LOYALTYPROGRAMCODE: 'Programa de Lealtad',
          LOYALTY_PROGRAM_CODE_EXC: 'Programa de Lealtad Excepcional',
          LOYALTY_PROGRAM_MODAL_ADVICE_TITLE: '¿Desea eliminar las excepciones de precio cargadas?',
          LOYALTY_PROGRAM_MODAL_ADVICE_SUBTITLE:
            'La acción promocional tiene excepciones de precio que serán eliminadas en caso de aplicar restricciones para programa de lealtad.',
          BUY_A_QUANT: 'Lleva',
          BUY_B_QUANT: 'Paga',
          BUY_B_QUANT_DESC: 'Unidades a Descontar',
          MIN_QUANT: 'Cantidad',
          SAMEITEM: 'Mismo Producto',
          UNTILSTOCKLAST: 'Hasta Agotar Stock',
          MIN_VALUE: 'Valor Mínimo $',
          MIN_VALUE_TICKET: 'Valor Mínimo Total Ticket $',
          DISCOUNT: 'Descuento',
          LOYALTY_DISCOUNT: 'Descuento Excepcional por Lealtad',
          LOYALTY_FIXED_PRICE: 'Precio Fijo Excepcional por Lealtad',
          SURCHARGE: 'Recargo',
          DISCOUNTTYPE: 'Tipo de Descuento',
          SURCHARGETYPE: 'Tipo de Recargo',
          FIXPRICE: 'Precio Fijo $',
          FIXPRICE_TOOLTIP:
            'Indica el precio promocional por la cantidad total de productos cargados. Ejemplo: Si la acción es "3 x $40", la Cantidad Mínima de productos es 3 y el precio fijo a cargar es el final, en este caso $40.',
          SHIPPINGDISCOUNT: 'Descuento en Envío %',
          MAXIMUMDISCOUNTVALUE: 'Tope de Descuento $',
          COMMUNICATIONTYPE: 'Tipo de comunicación',
          COMMUNICATIONDISPLAY: 'Orden de comunicación',
          COMMUNICATIONDISPLAY_TOOLTIP:
            'Orden de comunicación: Permite definir el orden en el que se genera el texto para la lista de producción.',
          EXCEPTIONAL_FIXED_PRICE_TITLE: 'Carga de Precio fijo Excepcional',
          EXCEPTIONAL_DISCOUNT_TITLE: 'Carga de Descuento Excepcional',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE: 'Podrá cargar excepciones de precios por medio del importador',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID:
            'Podrá cargar excepciones de precios por medio del importador, Se requiere guardar los cambios actuales.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID_ALT:
            'Podrá cargar excepciones de precios por medio del importador. Permitiendo cargar excepciones a nivel tienda o etiqueta (solo de puede utilizar uno de los dos nivel para todo el importador). Cada importación remplaza la importación anterior. Se requiere guardar los cambios actuales.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE: 'Podrá cargar excepciones de descuentos por medio del importador',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_ALT:
            'Podrá cargar excepciones de precios por medio del importador. Permitiendo cargar excepciones a nivel tienda o etiqueta (solo de puede utilizar uno de los dos nivel para todo el importador). Cada importación remplaza la importación anterior.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_ALT:
            'Podrá cargar excepciones de precios por medio del importador. Permitiendo cargar excepciones a nivel tienda o etiqueta (solo de puede utilizar uno de los dos nivel para todo el importador). Cada importación remplaza la importación anterior.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_NO_ID:
            'Podrá cargar excepciones de descuentos por medio del importador, Se requiere guardar los cambios actuales.',
          LOYALTYPROGRAMCODES_GLOBAL_TOOLTIP:
            'Permite opcionalmente restringir la ejecución de la activación para el/los programas de lealtad seleccionados.',
          COMMUNICATIONTYPE_TOOLTIP:
            'En caso de seleccionar <b>"Comunicar Descuento"</b> se indicará el porcentaje de descuento que se aplicará al producto por su compra.<br>Si seleccionas <b>"Comunicar Precio"</b> obtendrás el precio final de la promoción en los informes de promociones e impresión.<br><br><b>Ejemplo de informe:</b><br><b>Promoción 1</b> Coca Cola 600ml con un descuento del 10% cada una.<br><b>Promoción 2</b> Coca Cola 600ml a solo $10 cada una en la tienda A y Coca Cola 600ml a solo $9 cada una en la tienda B, considerando que los precios regulares pueden ser diferentes en cada tienda.',
          LOYALTY_DISCOUNT_TOOLTIP:
            'Permite opcionalmente otorgar un valor de descuento diferente y exclusivo para para el programa de lealtad seleccionado.',
          LOYALTY_FIXED_PRICE_TOOLTIP:
            'Permite opcionalmente otorgar un valor de precio fijo diferente y exclusivo para para el programa de lealtad seleccionado.',
          LOYALTY_FIXED_PRICE_TOOLTIP_ALT:
            'Indica el precio promocional para el programa de lealtad seleccionado, respetando la cantidad total de productos cargados. Ejemplo: Si la acción es "3 x $30 para programa de lealtad", la Cantidad Mínima de productos es 3 y el precio fijo excepcional por lealtad a cargar es el final, en este caso $30. Este precio aplica para todas las tiendas sin excepción.',
          ActionsV2_communicationType: {
            CommunicateDiscount: 'Comunicar Descuento',
            CommunicatePrice: 'Comunicar Precio',
          },
          ActionsV2_communicationDisplay: {
            CommunicateFirstValue: 'Valor promocional + Nombre de producto',
            CommunicateFirstName: 'Nombre de producto + Valor promocional',
          },
          COMMUNICATION: {
            PROMOTIONAL_MESSAGE: 'Mensaje promocional',
            PROMOTIONAL_MESSAGE_TOOLTIP:
              'Este campo permite visualizar de manera agil un ejemplo del mensaje promocional que se obtiene en los reportes productivos',
            PROMOTIONAL_MESSAGE_WARNING:
              'La acción promocional no presenta los datos suficientes para generar el mensaje promocional, complete todos los datos requeridos',
            SUPPORT_TEXT: 'Texto de apoyo',
            SUPPORT_TEXT_TOOLTIP: '',
          },
          IMPORT_LIST: {
            valuePrice: 'Precio Fijo $',
            valueDiscount: 'Descuento',
            tagValueId: 'Etiqueta {{val}}',
            storeId: 'Tienda',
            storeName: 'Tienda',
            storeScope: 'Alcance de puntos de venta',
            regularPrice: 'Precio Regular $',
            discount: 'Descuento Estimado %',
            comboId: 'Combo ID',
          },
          COMBOS: {
            DELETE_BLOCK: 'Eliminar bloque',
            EDIT_ITEMS: 'Editar productos',
            EDIT_NAME_STAGGERED: 'Al editar el nombre de este combo se aplicará el cambio a todos los combos.',
            EDIT_NAME_MIXED: 'Editar nombre',
            EDIT_QUANTITY: 'Editar catidad',
            ADD_COMBO: 'Agregar combo',
            ADD_STAGGERED_COMBO: 'Agregar combo escalonado',
            COMBO_ITEMS: 'Productos{{val}}',
            COMBO_ITEMS_EDIT: 'Editar productos',
            ADD_BLOCK: 'Agregar bloque',
            COPY_IDS: 'Copiar IDS',
            COPY_ID: 'Copiar ID',
            EDIT_STAGGERED_COMBO: 'Editar combo escalonado',
            EDIT_COMBO: 'Editar combo',
            DELETE_COMBO: 'Desea eliminar el combo?',
            CREATE_COMBO: 'Crear combo',
            CREATE_STAGGERED_COMBO: 'Crear combo escalonado',
            CLONE_COMBO: 'Clonar combo',
            DELETE_BLOCK_TITLE: '¿Desea eliminar el bloque{{val}}?',
            NAME_INPUT_VALIDATION_MESSAGES: {
              DUPLICATE_NAMES: 'No puede haber bloques con el mismo nombre.',
              PRICE: 'No se permiten precios en el nombre.',
              SPECIALCHARACTERS: 'No se permiten caracteres especiales en el nombre.',
              DOUBLESPACES: 'No se permiten espacios dobles en el nombre.',
              MAXCHARACTER: 'Alcanzó el máximo de caracteres permitido para el nombre.',
              DUPLICATE_ITEMS: 'No se permiten items duplicados entre bloques.',
            },
          },
          DISCOUNT_AND_REGULAR_PRICE: 'Precio regular ${{val}}. Descuento estimado {{val2}}%.',
          DISCOUNT_TOOLTIP_TITLE: 'Descuento estimado sobre precio fijo',
          DISCOUNT_TOOLTIP_DESCRIPTION:
            'Se define un descuento estimado, al comparar el precio regular y el precio fijo. El precio regular se define como la moda, el precio más repetido, existente en el alcance de tiendas para esta promoción.<br><br> En caso que la promoción tenga más de un producto o un combo tenga más de un producto en cada bloque, se utiliza el menor precio moda entre los productos participantes de la acción promocional o bloque.',
          STORES_WITHOUT_REGULAR_PRICE: 'No hay precio regular en las tiendas seleccionadas para calcular el descuento.',
          DISCOUNT_WARNING_MESSAGE: 'Para ver el nuevo descuento aplicado, es necesario guardar.',
          WITHOUT_POP_MATERIAL_TOOLTIP_TITLE: 'Sin material promocional',
          WITHOUT_POP_MATERIAL_TOOLTIP_DESCRIPTION:
            'Activo: No requiere seleccionar un material promocional para poder publicar la acción promocional, y las validaciones que dependan de este dato serán automáticamente validadas. El reporte de promociones no informará espacio publicitario, y esta acción promocional no formará parte de los resultados de los reportes relacionados con el material promocional.<br><br> No activo: Requiere seleccionar al menos un material promocional. La acción promocional formará parte de los reportes relacionados con el material promocional, y las validaciones que dependan de este dato deberán ser verificadas.',
        },
        SCOPE_ITEM: {
          IMPORT: 'Importar',
          RADIO_SIMPLE_PRODUCT: 'Producto simple',
          RADIO_MULTIPLE_PRODUCT: 'Productos multiples',
          RADIO_STAGGERED_PRODUCT: 'Promociones escalonadas',
          DESCRIPTION_SIMPLE_PRODUCT:
            'Selecciona los productos que participan de la promoción. Lleva un solo tipo o combínalos como quieras. Todos los productos tendrán el mismo precio promocional.',
          DESCRIPTION_MULTIPLE_PRODUCT:
            'Crea uno o más conjuntos de productos que participan de la promoción. Define la cantidad y los productos que forman parte de cada componente.',
          DESCRIPTION_STAGGERED_PRODUCT:
            'Crea un conjunto de productos para definir la promoción base y variantes diferenciando únicamente la cantidad de sus componentes. Cada conjunto tendrá un precio promocional diferente.',
          WARNING_MESSAGE:
            'Al modificar el alcance de productos, se eliminará el Material Promocional asignado a esta acción promocional. Deberás reasignarlos después de guardar los cambios.',
          WARNING_IMPORTANT_MESSAGE: 'Aviso importante:',
        },
        SCOPE_STORE: {
          IMPORT: 'Importar',
          WARNING_MESSAGE:
            'Al modificar el alcance de tiendas, se eliminará el Material Promocional asignado a esta acción promocional. Deberás reasignarlos después de guardar los cambios.',
          WARNING_IMPORTANT_MESSAGE: 'Aviso importante:',
        },
        ActionsV2_FrequencyType: {
          EveryDay: 'Todos los días',
          CertainDaysOfTheWeek: 'Ciertos días de la semana',
          CertainDaysOfTheMonth: 'Ciertos días del mes',
        },
        ActionsV2_AssortmentType: {
          ActivateOnlyDuringPromotion: 'Activo solo durante la promoción',
          ActivateIndefinitely: 'Activo indefinidamente',
          DoNotActivate: 'No activar productos',
        },
        ActionsV2_DiscountType: {
          AbsoluteValue: '$',
          Percentage: '%',
        },
        PAYMENTS: {
          ADD_BLOCK: 'Agregar Medio de Pago',
          PAYMENT_BLOCK: 'Medio de Pago',
          FINANCIAL_INSTITUTION: 'Entidad financiera',
          CARD_TYPE: 'Tipo de tarjeta',
          PAYMENT_BLOCK_OPT: 'Medio de Pago particular (opcional)',
          BANK: 'Banco',
          INSTALLMENTS: 'Cuotas',
          LOYALTYPROGRAMPOINTS: 'Puntos de Lealtad',
          LOYALTYPROGRAMPOINTSEQUIVALENCEINCASH: 'Equivalencia Puntos de Lealtad',
          SPONSORSHIP: 'Porcentaje de Sponsoreo %',
          PAYMENT_BLOCK_COMBO: 'Combinación de medios de pago',
          VALUE: 'Valor',
          ADDSUBCOMBINATION: 'Agregar tipo de Medio de Pago',
          REMOVECOMBINATION: 'Eliminar Combinación',
          REMOVESUBCOMBINATION: 'Eliminar tipo de Medio de Pago',
          TYPE: 'Tipo de Medio de Pago',
          ALL: 'Todos',
          DEBIT: 'Débito',
          CREDIT: 'Crédito',
          NONE: 'Ninguno',
          ADD_COMBINATION_BLOCK: 'Agregar Combinación',
          HELP_PAYMENT1:
            'Si no se selecciona medio de pagos, se aplicarán todos los medios de pagos. En caso de seleccionarse medios de pagos, estos medios de pagos serán requisitos necesarios para que se active la promoción',
          DUPLICATE_WARNING: 'Medio de pago duplicado',
          DUPLICATE_COMB_WARNING: 'Combinación de Medio de pago duplicada',
          PAYMENT_ALL: 'Todos los medios de pago',
          PAYMENT_NO_COMB: 'Selección de Medios de Pago',
          PAYMENT_COMB: 'Precio del Producto Regalo',
          PAYMENT_ALL_DESC: 'La acción promocional estará activa para cualquier medio de pago.',
          PAYMENT_NO_COMB_DESC:
            'En caso de seleccionarse medios de pagos, los mismos serán requisitos necesarios para que se active la promoción',
          PAYMENT_COMB_DESC:
            'Sólo aplica si el Producto Regalo tiene un precio adicional (ej: "llevate un brownie por $50 adicionales a tu café"). Admite una o varias combinaciones de uno o más medios de pago.',
          NO_ITEMS: 'Debe al menos seleccionar una opción como medio de pago',
          NO_ITEMS_COMB: 'Debe al menos seleccionar una combinación de medios de pago',
        },
        GIFT_ITEMS: {
          SIZE: 'Cantidad de Productos',
          SIZE_HELP: 'Cantidad de productos que serán regalados si se cumple la promoción.',
          GIF_SELECTION_NO_MANUAL_ITEMS_SELECTED: 'No hay ítems seleccionados',
        },
        PUBLISH_CONTENT: 'Desea publicar los siguientes {{val}} registros seleccionados?',
      },
      ACTIONS_STATUS: {
        NEW: 'Nuevo',
        ENDORSED: 'Endorsado',
        INFORMED: 'Informado',
        DELETED: 'Eliminado',
        MODIFIED: 'Modificado',
        CURRENT: 'Publicado',
        CANCELLED: 'Cancelado',
      },
      ACTIONS_VALIDATION_STATUS: {
        APPROVED: 'Aprobado',
        VALIDATING: 'Validando',
        ACTIONREQUIRED: 'Acción Requerida',
        REJECTED: 'Rechazado',
        PENDING: 'Pendiente',
      },
      HELP: {
        QUANTITY_BUY_INPUT:
          'Indica las unidades (A) que se llevará el cliente.  Ejemplo: Si la acción es “3 x 2” acá debemos ingresar “3”.',
        QUANTITY_PAY_INPUT:
          'Indica las unidades (B) que pagará el cliente. Es un valor menor a “Cantidad a Comprar”.  Ejemplo: Si la acción es “3 x 2” acá debemos ingresar “2”.',
        FRECUENCY_INPUT: 'Indica la repetición de la acción a lo largo del tiempo.',
        DAYS_INPUT: 'Días de la semana en los que estará vigente la acción.',
        DATE_FROM_INPUT: 'Fecha de inicio de vigencia de la acción.',
        DATE_TO_INPUT: 'Fecha de culminación de vigencia de la acción.',
        TIME_FROM_INPUT: 'Inicio de rango de horario diario de vigencia de la acción.',
        TIME_TO_INPUT: 'Culminación de rango de horario diario de vigencia de la acción.',
        TICKET_MESSAGE_INPUT: 'Descripción corta de la acción que se verá reflejado en el ticket factura.',
        OBSERVATION_INPUT: 'Permite ingresar algún tipo de información o detalle de la acción.',
        UNIT_DISCOUNT_INPUT:
          'Indica el valor del descuento a aplicar sobre el precio regular total de la promoción. Ejemplo: $5 de descuento llevando 2 productos A, si cada producto tiene un precio regular de $10. (2x$10) -$5 de descuento. Precio promocional $15 final.',
        DISCOUNT_RATE_INPUT: 'Indica si el descuento va a ser en porcentaje o en pesos.',
        SUPPLIES_LAST_CHECK: 'Mensaje informativo a efectos legales para el punto de venta.',
        UNITS_AVAILABLE_INPUT:
          'Indica las unidades del producto total compañía disponibles para la acción. Mensaje informativo a efectos legales para el punto de venta.',
        DISCOUNT_VALUE_INPUT:
          'Valor total del descuento que corresponde a la promoción. Ejemplo: Si la acción es “2da unidad al 30% de descuento”, acá debemos ingresar el número “30”.',
        UNIT_DISCOUNT_PER_INPUT:
          'Indica el valor del descuento que se va a aplicar sobre cada unidad vendida. Ejemplo: Si la acción es “2da unidad al 30% de descuento”, acá debemos ingresar el número “15” que es el porcentaje que aplicará de descuento sobre cada unidad vendida.',
        QUANTITY_UNITS_INPUT:
          'Indica las unidades obligatorias que debe comprar el cliente para que aplique el descuento. Ejemplo: Si la acción es “2da unidad al 30% de descuento”, acá debemos ingresar el número “2” que son las unidades necesarias para que aplique el descuento.',
        MINIMUM_AMOUNT_INPUT:
          'Indica las unidades mínimas que debe comprar el cliente para que aplique el descuento. Ejemplo: Si la acción es “15% de descuento llevando 3 unidades”, acá debemos ingresar el número “3”.',
        MAXIMUM_AMOUNT_INPUT:
          'Indica las unidades máximas que puede comprar el cliente con el descuento. A partir de este valor, las unidades no contarán con la promoción. Ejemplo: Si la acción es “15% de descuento hasta 6 unidades”, acá debemos ingresar el número “6”, en la unidad “7” o sucesivas no aplica el 15%.',
        MINIMUM2_AMOUNT_INPUT:
          'Indica valor monetario mínimo que debe comprar el cliente (sobre el total del ticket) para que aplique la promoción. Ejemplo: Si la acción es “10% de descuento en compras superiores a $1.000”, acá debemos ingresar el número “1.000”. Si no se alcanza ese monto, no aplica el descuento.',
        MAXIMUM2_AMOUNT_INPUT:
          'Indica valor monetario máximo que puede comprar el cliente para que aplique la promoción. A partir de ese monto, el excedente de la compra no tendrá descuento. Ejemplo: Si la acción es “10% de descuento en compras hasta $1.000”, acá debemos ingresar el número “1.000”. Si la compra es de $1.500, los $500 no tienen el 10% de descuento.',
        ASSORTMENTMETHOD: 'Indica el comportamiento de Surtido',
        MINIMUM_AMOUNT_INPUT_GROUP:
          'Indica valor monetario mínimo que debe comprar el cliente (sobre los productos alcanzados) para que aplique la promoción. Ejemplo: Si la acción es “10% de descuento en gaseosas a partir de $1.000”, acá debemos ingresar el número “1.000”. Si no se alcanza ese monto, no aplica el descuento.',
        SAME_ITEM: 'El descuento se calculará agrupando mismos productos',
        COMPLETE_COVERAGE:
          'Activar cobertura completa genera cambios en el cálculo del mismo, indicando que la tienda cumple con la cobertura solo si todos los productos del alcance de productos de esta promoción se encuentran activos en el surtido de esa tienda. En caso de estar desactivado con al menos un producto que se encuentre activo en la tienda, se cumplirá con la cobertura de la misma. <br><br>Si el producto es tipo combo, esté activo o no esté campo, se requiere tener al menos 1 variante de cada componente del combo activo para que la tienda cumpla con la cobertura.',
        COVERAGE_BASED_COMMUNICATION:
          'Activo: los mensajes promocionales difieren con cada cambio en la cobertura de la acción promocional en las tiendas del scope. <br><br> No activo: independientemente de la cobertura, todas las tiendas del scope reciben el mismo mensaje promocional.',
        COMMUNICATE_REGULAR_PRICE:
          'Si está encendido, los reportes calcularán el precio regular del producto para cada tienda dentro del alcance definido. <br> Si está apagado, el mensaje a comunicar será sin precio.',
      },
      PAY_METHODS: {
        PAY_METHOD_Money: 'Dinero',
        PAY_METHOD_LoyaltyPoints: 'Programa de Lealtad',
      },
      PRODUCT_LIST: {
        TITLE: 'Reporte de imprenta',
        DOWNLOAD: 'Descargar reporte',
        GENERATE: 'Generar reporte',
        LAST: 'Ultimo reporte generado',
        PERIODS: 'Períodos',
        VENDORS: 'Imprentas',
        ERROR_REPORT: 'El reporte no pudo generarse correctamente',
      },
      PROMOTION_OUTPUT: {
        TITLE: 'Datos para POS',
        DOWNLOAD: 'Generar datos',
        GENERATE: 'Generar datos',
        LAST: 'Ultimo reporte generado',
        PERIODS: 'Períodos',
        ERROR_REPORT: 'El reporte no pudo generarse correctamente',
      },
      LOGISTIC_REPORT: {
        TITLE: 'Reporte de logística',
        DOWNLOAD: 'Descargar reporte',
        GENERATE: 'Generar reporte',
        LAST: 'Ultimo reporte generado',
        PERIODS: 'Períodos',
        VENDORS: 'Imprentas',
        ERROR_REPORT: 'El reporte no pudo generarse correctamente',
      },
      PROMOTION_REPORT: {
        TITLE: 'Reporte de Promociones',
        DOWNLOAD: 'Generar reporte',
        LAST: 'Ultimo reporte generado',
        PERIODS: 'Períodos',
        VENDORS: 'Imprentas',
        ERROR_REPORT: 'El reporte no pudo generarse correctamente',
      },
      PROMOTION_LOG: {
        TITLE: 'Bitacora de Promociones',
        DOWNLOAD: 'Generar reporte',
        LAST: 'Ultimo reporte generado',
        ERROR_REPORT: 'El reporte no pudo generarse correctamente',
      },
      MARKETPLACE_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE: 'eliminar',
        DELETE_ROW: 'Eliminar',
        EDIT_ROW: 'Editar',
        TITLE: 'Marketplace',
        PROMOTION_TYPE: 'Tipo de Promoción',
        YEAR: 'Año',
        CLONE_ALL_PERIODS: 'Clonar periodos',
        CLONE_CONTENT: 'Clonar todos los periodos del primer campo Tipo de Promoción hacia el segundo para el año indicado',
        CLONE_TITLE: 'Clonar periodos por tipo de promoción',
        PROMOTION_TYPE_FROM: 'Clonar periodos de tipo de promocion desde',
        PROMOTION_TYPE_TO: 'Clonar periodos de tipo de promocion hacia',
        NEW_PERIOD: 'Nuevo',
        EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
        SEE_DELETED_FILTERS: 'Ver eliminados',
        CREATION_ON: 'Fecha de creación',
        CLONE: 'Clonar Períodos',
        COPY: 'Copiar Períodos',
        NAME: 'Nombre',
        ROUTE: 'Marketplace',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        STORENAME: 'Punto de venta',
        CODE: 'Código',
        STATUS: 'Estado',
        VIEW_MATERIAL_POP: 'Ver material promocional',
        DISABLE_STATUS: 'Filtro deshabilitado por utilizar filtro `Contratos proximos a vencer`',
        STATUS_AVAILABLE: 'Disponible',
        STATUS_RESERVED: 'Reservado',
        STATUS_RENTED: 'Rentado',
        RESERV: 'Reservar',
        RENT: 'Rentar',
        POPMATERIAL: 'Material Promocional',
        CADUCATE: 'Contratos proximos a vencer',
        CADUCATE_1MONTH: 'Dentro de 1 Mes',
        CADUCATE_2MONTH: 'Dentro de 2 Mes',
        CADUCATE_3MONTH: 'Dentro de 3 Mes',
        ADVANCED_SEARCH: 'Filtros Avanzados',
        INTERNAL_USE: 'Uso interno',
        CONTRACT: 'Gestionar Contrato',
        CONTRACT_CONTENT: 'Reservar o Rentar {{val}}. Asignando fechas y precio para el proveedor seleccionado',
        VENDOR: 'Imprenta',
        PRICE: 'Precio',
        STORECOUNT: '# Puntos de venta',
        OCUPATION: 'Ocupación',
        SPACE: 'Espacio',
        VALIDFROM: 'Fecha inicio',
        VALIDTO: 'Fecha fin',
      },
      PERIODS_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        CANT_DELETE_TTIP: 'El periodo promocional no puede ser eliminado porque existen acciones promocionales que lo utilizan',
        DELETE_MODAL_TITLE: '¿Desea eliminar el período promocional{{val}}?',
        DELETE: 'eliminar',
        DELETE_ROW: 'Eliminar',
        IS_CANCELING: 'eliminando',
        EDIT_ROW: 'Editar',
        TITLE: 'Períodos Promocionales',
        PROMOTION_TYPE: 'Tipo de Promoción',
        PROMOTION_TYPE_PROCESS: 'Proceso promocional',
        FOLIO: 'Folio Promocional',
        YEAR: 'Año',
        YEAR_FROM: 'Año Desde',
        YEAR_TO: 'Año Hacia',
        CLONE_ALL_PERIODS: 'Clonar periodos',
        CLONE_CONTENT: 'Clonar todos los periodos del primer campo Tipo de Promoción hacia el segundo para el año indicado',
        CLONE_TITLE: 'Clonar periodos por tipo de promoción',
        PROMOTION_TYPE_FROM: 'Clonar periodos de proceso promocional desde',
        PROMOTION_TYPE_TO: 'Clonar periodos de proceso promocional hacia',
        NEW_PERIOD: 'Nuevo período promocional',
        EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
        SEE_DELETED_FILTERS: 'Ver eliminados',
        CREATION_ON: 'Fecha de creación',
        CLONE: 'Clonar Períodos',
        COPY: 'Copiar Períodos',
        ROUTE: 'Períodos Promocionales',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        NAME: 'Nombre',
        CODE: 'Código',
        RESPONSIBLEROLEID: 'Responsable',
        OBSERVATIONS: 'Observaciones',
        CONSULTEDROLEID: 'Consultor',
        STARTINGDAYOFWEEK: 'Días de inicio',
        DURATIONINDAYS: 'Duración en días',
        ORDER: 'Orden',
        VALIDFROM: 'Fecha inicio',
        VALIDTO: 'Fecha fin',
        EXTRAPLANNINGDAYS: 'Días extras de planificación',
        ISSPECIALEVENT: 'Evento especial',
        VALIDATION_CODE: 'El código {{val}} ya existe',
        EDIT_ERROR: 'Error al editar: {{val}}',
      },
      CALENDAR_LIST: {
        EXPORT_TTIP: 'exportar',
        FILTER_TTIP: 'Filtrar',
        DELETE_TTIP: 'eliminar',
        DELETE: 'eliminar',
        TITLE: 'Calendario',
        MODIFY: 'Modificar',
        CHANGE_DATE_TITLE: 'Modificar Fecha',
        CHANGE_DATE_CONTENT: 'Modificar la fecha de esta actividad',
        EDIT_ROW_DISABLED_TOOLTIP: 'No se puede editar un registro en proceso de publicación',
        ONLY_CURRENT_PERIODS: 'Solo períodos activos.',
        ACTIONS: {
          NAME: 'Descripción',
          RESPONSIBLEROLEID: 'Responsable',
          CONSULTEDROLEID: 'Consultor',
          OBSERVATIONS: 'Observaciones',
          STARTTASKXDAYSBEFORE: 'Día de inicio',
          DURATIONINDAYS: 'Duración en días',
        },
      },
      PERIODS_EDIT: {
        SAVE: 'Guardar',
        PUBLISH: 'Publicar',
        WARNING_SAVE: 'Se requiere guardar los cambios actuales.',
        WARNING_PUBLISH: 'Es necesario republicar para que los cambios guardados tomen efecto.',
        NOVALID_PUBLISH_ADVICE: 'El siguiente tab no es válido:',
        BASIC_INFORMATION_TAB: 'Datos Principales',
        ACTIVATION_TAB: 'Activación',
        LOADING: 'Cargando...',
        NEW_ITEM_BTN: 'Nueva Acción Promocional',
        PUBLISH_CONFIRM:
          'El proceso de publicación incluye la creación del Surtido, Precios, Costos y otros registros relacionados a esta acción.<br><br><b>Estás seguro que deseas Publicar?</b>',
        PUBLISH_CONFIRM_TITLE: 'Confirmación de Publicación',
        PUBLISHING_WARNING: 'Ítem en proceso de publicación, vuelva a intentar en unos minutos.',
        PUBLISH_LONG_TIME:
          'La publicación de este Producto puede demorar algunos minutos, cuando finalice serás avisado con una notificación.',
        MAIN_DATA: {
          VALID_DATE: 'Fecha de vigencia',
          ORDER_WARNING:
            'Elegir un orden menor al maximo recalculará las fechas de los periodos posteriores, excluyendo eventos especiales',
          VALIDFROMEXCEEED: 'La fecha de inicio excede el año vigente',
          PERIOD_IN_USE: 'No se puede editar el periodo porque se encuentra en uso.',
        },
      },
      COVERAGE: {
        WARNING_IS_NEW: 'Necesita guardar los cambios para consultar la cobertura.',
        WARNING_STORE_AND_ITEM_SCOPE:
          'Al modificar el alcance de productos y puntos de venta es necesario guardar los cambios para consultar la cobertura.',
        COVERAGE_BASED_COMMUNICATION_TITLE: 'Comunicación según cobertura',
        COMPLETE_COVERAGE_TITLE: 'Cobertura Completa',
        COMMUNICATION_REGULAR_PRICE_TITLE: 'Comunicar precio regular',
      },
      REPORTS: {
        AVAILABLE_SPACES: {
          REGION: 'Región',
          AVAILABLE_SLOTS_PERCENTAGE: '% Total disponibles',
          AVAILABLE_SLOTS: 'Cantidad total disponibles',
          SLOTS_TOTAL: 'Cantidad total de slots',
          SLOTS_TOTAL_IN_USE: 'Cantidad total en uso',
          SLOTS_PERCENTAGE_IN_USE: '% Total en uso',
          SLOTS_EXCEEDED: 'Cantidad de slots excedidos',
        },
        TAG_VALUES: 'Descargar reporte de etiquetas',
      },
    },
    M_SHOPPER: {
      MISSIONS: {
        CHECK_RULE: {
          MODAL_TITLE: '¿Desea cambiar la selección?',
          MODAL_CONTENT_FIRST_STEP: 'Si confirma, los siguientes pasos copiaran la configuración de la regla de competencia',
          MODAL_CONTENT:
            'Si confirma, las misiones afectadas serán reemplazadas por las modificaciones realizadas en base a la regla de competencia del primer paso',
          MODAL_BTN_CLOSE: 'Cancelar',
          MODAL_BTN_ACCEPT: 'Confirmar',
          CHECK: 'No volver a preguntar.',
        },
        TYPE_OPTION: {
          PriceSurvey: 'Encuesta de precios',
          ExhibitionSurvey: 'Encuesta de exposición',
        },
        FRECUENCY_OPTION: {
          OneTime: 'Una vez',
          Daily: 'Diario',
          Weekly: 'Semanal',
          Biweekly: 'Quincenal',
          Monthly: 'Mensual',
          Biannual: 'Semestral',
          Annual: 'Anual',
        },
        AUDYT_OPTION: {
          Internal: 'Interna',
          External: 'Externa',
        },
        METHODS_OPTION: {
          Strip: 'Tira',
          Ticket: 'Ticket',
          Catalog: 'Catálogo',
          Totem: 'Tótem',
        },
        ACTION_OPTION: {
          PriceLabelScan: 'Escaneo de etiqueta de precio',
          VoiceCommand: 'Comando de voz',
          ManualEntry: 'Entrada manual',
          Indistinct: 'Indistinto',
        },
        CADUCATE: 'Vencido',
        TODAY: 'Hoy',
        DAYTOEXPIRE: 'Días para vencer',
        ROUTE: 'Mystery Shopper',
        TITLE: 'Misiones',
        SUBTITLE:
          'En esta pantalla podrás ver todas las misiones creadas, con su porcentaje de avance, su frecuencia y si tienen o no reglas de competencia asociadas. Dichas misiones van a ser ejecutadas por el shopper mediante la app Mystery Shopper de Prisma, si existen relaciones entre puntos de venta propios o de la competencia con el shopper, se disponibilizará la misión a ese usuario, con el fin de relevar precios propios o de la competencia.',
        NEW_MISSION_BTN: 'Nueva Misión',
        DAILYREPORT: 'Reporte Diario',
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        EXPORT_TTIP: 'Exportar',
        IMPORT_TTIP: 'Importar',
        PRINT_TTIP: 'Imprimir',
        DELETE_TTIP: 'Borrar misiones seleccionadas',
        DELETE: 'Eliminar',
        EDIT: 'Editar',
        NAME: 'Nombre',
        TYPE: 'Tipo',
        AUDIT_METHOD: 'Mtd. Auditoría',
        AUDIT_METHOD_TTIP: 'Método de Auditoría',
        START_DATE: 'F. de Inicio',
        START_DATE_TTIP: 'Fecha de Inicio de la Misión',
        FINISH_DATE: 'F. de Fin',
        FINISH_DATE_TTIP: 'Fecha de Fin de la Misión',
        FRECUENCY: 'Frecuencia',
        ADVANCED: 'Avance %',
        SURVEYED_STATUS: 'Estado de relevamiento',
        SURVEYED_STATUS_NOTAVAIBLE_TOOLTIP:
          'La misión no se encuentra disponible para los usuarios por no estar asignados o no tener competidores',
        SURVEYED_STATUS_AVAIBLE_TOOLTIP: 'La misión se encuentra disponible para los usuarios',
        SURVEYED_STATUS_TAKENBYME_TOOLTIP: 'Un usuario acepto la misión y se encargará de relevarla',
        SURVEYED_STATUS_COMPLETED_TOOLTIP: 'La misión ya fue relevada, descargar el reporte diario para obtener los resultados',
        SURVEYED_STATUS_NOTSURVEY_TOOLTIP: 'La misión no fue relevada y la fecha de vigencia a finalizado',
        SURVEYED_STATUS_NOTAVAIBLE: 'No Disponible',
        SURVEYED_STATUS_AVAIBLE: 'Disponible',
        SURVEYED_TAKENBYME: 'En curso',
        SURVEYED_STATUS_COMPLETED: 'Completada',
        SURVEYED_STATUS_NOTSURVEY: 'Sin relevar',
        DAYS_TO_EXPIRE: 'Días por Vencer',
        SKUS: '# Productos',
        SKUS_TTIP: 'Cantidad de productos',
        SITES: '# Puntos de Venta',
        SITES_TTIP: 'Cantidad de puntos de venta',
        USERS_TTIP: 'Cantidad de usuarios',
        USERS: '# Usuarios',
        RULE: 'Regla',
        RULE_TTIP: 'Misión asociada a una regla de competencia',
        YES: 'Si',
        NO: 'No',
        STATUS: 'Estado',
        DATE: 'Fecha',
        STATUS_ACTIVE: 'Misión Vigente',
        STATUS_OVERDUE: 'Misión Vencida',
        STORES_FILTER: 'Puntos de venta',
        DEFINE_MISSION_STP1: 'Definir Misión',
        COMPETITORS_SCOPE_STP2: 'Alcance Competidores',
        PRODUCT_SCOPE_STP3: 'Alcance Productos',
        STORE_SCOPE_STP4: 'Alcance Puntos de Venta',
        RESPONSIBLE_STP5: 'Responsables',
        NEW_TITLE_FORM: 'Nueva Misión',
        EDIT_TITLE_FORM: 'Editar Misión',
        STP1: 'Paso 1 - Definir Misión',
        STP1_SUBTITLE: 'En este paso podrás definir los componentes principales de una Misión',
        NAME_INPUT: 'Nombre',
        TYPE_INPUT: 'Tipo',
        AUDIT_METHOD_INPUT: 'Método de Auditoría',
        FRECUENCY_INPUT: 'Frecuencia',
        ACTION_TYPE_INPUT: 'Tipo de Acción',
        AUDIT_TYPE_INPUT: 'Tipo de Auditoría',
        VALIDATE_LOCATION_TOOLTIP: 'La misión debe estar dentro del rango de distancia permitido',
        REQUEST_EVIDENCE_TOOLTIP: 'Será de carácter obligatorio la carga de una imagen de evidencia del producto relevado',
        REQUEST_EVIDENCE: 'requerir evidencia fotográfica',
        VALIDATE_LOCATION: 'validar ubicación',
        SURVEYSAMOUNT: '#Competidores',
        SURVEYSAMOUNT_TTIP: 'Cantidad de competidores',
        STARTS_INPUT: 'Comienza el',
        FINISHED_INPUT: 'Finaliza el',
        VALIDATE_PROMOTION_CHECK: 'Validar Promoción',
        VALIDATE_DATE_START: 'La fecha de comienzo debe ser menor o igual a la de finalización',
        VALIDATE_DATE_END: 'La fecha para finalizar debe ser mayor o igual a la de comienzo',
        VALIDATE_STOCK_CHECK: 'Validar Stock',
        COMPETITIVE_RULE_INPUT: 'Regla',
        COMPETITIVE_RULE_INPUT_IN_USE: 'Esta regla se encuentra aplicada en una misión',
        NEXT_BTN: 'Siguiente',
        STP2: 'Paso 2 - Alcance Competidores',
        LEGEND_SKIP_STEP: 'Este Paso no es necesario si el tipo de Auditoría es Interna',
        SKIP_BTN: 'Omitir',
        PREVIUS_BTN: 'Anterior',
        STP3: 'Paso 3 - Alcance Productos',
        STP3_SUBTITLE:
          'En este paso podrás definir a qué productos y a cuales no alcanzará esta Misión. Puedes elegirlos mediante selección por atributo, o selección individual.',
        STP3_TOGGLE: 'Permitir capturar precios de productos por fuera de la misión',
        STP3_TOGGLE_TOOLTIP:
          'Se tendrá disponible un lote de productos genéricos para relevar productos encontrados que no se encuentren configurados en la misión.',
        VIEW_PRODUCTS_BTN: 'Ver Productos',
        REFRESH_PRODUCTS_BTN: 'Refrescar Productos',
        STP4: 'Paso 4 - Alcance Puntos de Venta',
        STP4_SUBTITLE:
          'En este paso podrás definir a qué puntos de venta y a cuales no alcanzará esta Misión. Puedes elegirlos mediante selección por atributo, o selección individual.',
        AUTOMATIC_ASIGNATION_CHECK: 'Asignación Automática',
        ADD_RESPONSIBLE_BTN: 'Agregar Responsable',
        SAVE_BTN: 'Guardar',
        ADD_RESPONSIBLE_TITLE: 'Agregar Responsable',
        COMPETITOR_INPUT: 'Competidor',
        SHOPPER_INPUT: 'Shopper',
        ACCEPT_BTN: 'Aceptar',
        CANCEL_BTN: 'Cancelar',
        ADD_BTN: 'Agregar',
        STRATEGY_ATTR_TITLE: 'Selección por atributo (Dinámico)',
        STRATEGY_ATTR_MSG:
          'Se seleccionan atributos para definir el alcance de productos, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
        STRATEGY_PROD_TITLE: 'Selección por producto',
        STRATEGY_PROD_MSG: 'Se deberán elegir explícitamente los productos uno a uno',
        STRATEGY_ATTR_TITLE_STORE: 'Selección por atributo (Dinámico)',
        STRATEGY_ATTR_MSG_STORE:
          'Se seleccionan atributos para definir el alcance de puntos de venta, si el alcance sufre cambios, el impacto que tiene esta configuración se realiza automáticamente y no es necesario modificarlo',
        STRATEGY_STORE_TITLE_STORE: 'Selección por puntos de venta',
        STRATEGY_STORE_MSG_STORE: 'Se deberán elegir explícitamente los puntos de venta uno a uno',
        VIEW_STORES_BTN: 'Ver Puntos de Venta',
        REFRESH_STORES_BTN: 'Refrescar P. Venta',
        STP5: 'Paso 5 - Responsable de la Misión',
        LEGEND_RESPONSIBLE_STEP:
          'La asignación de usuarios a la misión se realizará de forma automática en función de las relaciones cargadas en la pantalla Asignación de Usuarios. La misión se disponibilizará en MS App, a todos los usuarios relacionados con los puntos de venta y/o competidores alcanzados, y el primer usuario en tomar la misión será a quien le quede asignada la misma',
        PASTE: 'Pegar',
        PASTE_TTIP: 'Agrega puntos de Venta desde una lista copiada en tu portapapeles.',
        SEARCH: 'Buscar',
        CODE_AND_DESCRIPTION_INPUT: 'Código / Descripción',
        CLIPBOARD_TITLE: 'Pegar puntos de venta desde portapapeles',
        CLIPBOARD_STEP1: '1) Define qué tipo de códigos estás usando',
        TYPE_CODE: 'Tipo de Código',
        INTERNAL_CODE_INPUT: 'Código Interno',
        EXTERNAL_CODE_INPUT: 'Código Externo',
        NOT_FOUND_CODE_ITEM: 'Este código de producto no fue encontrado en Ítem Master',
        NOT_FOUND_CODE_STORE: 'Este código de punto de venta no fue encontrado en Maestro de Puntos de Venta',
        CLIPBOARD_STEP2:
          '2) Selecciona y copia los códigos de una hoja de cálculo o texto. (Ctrl + C) como se muestra en la imagen de abajo. También pueden cargarse delimitando cada código con los siguientes caracteres "," ";" "|" "/" "\\\\" (tab) (enter) (espacio) ',
        CLIPBOARD_STEP3: '3) Presiona aquí y pegue los códigos (Ctrl + V)',
        CLIPBOARD_MAX: 'La selección individual admite hasta {{val}} productos.',
        CLIPBOARD_COUNT: 'Códigos han sido cargados',
        CLIPBOARD_TITLE_DEFUALT: 'Presiona aquí y pegue los códigos (Ctrl + V)',
        CLIPBOARD_NONE: 'Ningún código fue cargado',
        CLIPBOARD_ACTION: 'Pegar',
        PASTE_PRODUCT_TTIP: 'Agrega productos desde una lista copiada en tu portapapeles.',
        CLIPBOARD_PRODUCT_TITLE: 'Pegar productos desde portapapeles',
        CLIPBOARD_STORE_TITLE: 'Pegar puntos de venta desde portapapeles',
        FILTER_PROD: 'Productos Filtrados',
        SELECT_PROD: 'Productos Seleccionados',
        ADD_PROD: 'Agregue Productos al listado',
        FILTER_STORE: 'Puntos de Venta Filtrados',
        SELECT_STORE: 'Puntos de Venta Seleccionados',
        ADD_STORE: 'Agregue Puntos de Venta al listado',
        PROVINCE_CHIP: 'Provincia:',
        COUNTRY_CHIP: 'País:',
        PRICE_CHIP: 'Precio:',
        COST_CHIP: 'Costo:',
        LEGEND_SKIP_STEP4: 'Este Paso no es necesario ya que en el Alcance Competidores se eligió la opción Selección de Competidores',
        STP2_COMP_BUTTON_TITLE: 'Selecciona la manera en que desea compararse contra sus competidores.',
        MAINMARKET_TITLE: 'Competidor Principal',
        ONLY_MARKERS_TITLE: 'Solo seleccionar Markers',
        MAINMARKET_MSG: 'Buscará el precio del Competidor Principal de cada Punto de Venta.',
        USE_TRADE_AREA_TITLE: 'Trade Area',
        USE_TRADE_AREA_MSG: 'Buscará el precio de los Competidores asociados al Trade Area de cada Punto de Venta.',
        SELECT_COMPETITORS_TITLE: 'Selección de Competidores',
        SELECT_COMPETITORS_MSG: 'Buscará a los competidores que cumplan con los criterios a definir a continuación.',
        HELP: {
          TYPE_INPUT:
            'Encuesta de Precios:  Enviarás al shopper a auditar los precios de la competencia y dentro de tus puntos de venta, para informarte o asegurar que se cumplan tus políticas.',
          TYPE_INPUT2:
            'Encuesta de Exhibición: Enviarás al shopper a auditar como están exhibidos los productos en tus puntos de venta o en puntos de venta de la competencia.',
          AUDIT_METHOD_INPUT: 'Fleje:  Se deberá auditar el precio que figura en la góndola donde se ubica el producto. ',
          AUDIT_METHOD_INPUT2: 'Ticket: Se auditará el precio que figura en el ticket o factura luego de comprar los productos.',
          AUDIT_METHOD_INPUT3:
            'Catálogo: Se auditarán los precios que figuren en un catálogo, revista, volante  o cualquier publicación impresa de productos.',
          AUDIT_METHOD_INPUT4: 'Totem: Se deberán auditar los precios en tótem y/o cartelera de productos.',
          STARTS_INPUT: 'A partir de esta fecha se da inicio a la misión.',
          FINISHED_INPUT:
            'A partir de esta fecha Mystery Shopper APP no solicitará a los shoppers relevar la misión. La misma quedará pausada.',
          VALIDATE_PROMOTION_CHECK: 'Se deberá auditar también los productos seleccionados estén en promoción o no.',
          VALIDATE_STOCK_CHECK: 'Se deberá confirmar si había o no stock disponible del producto al momento de auditarlo.',
          FRECUENCY_INPUT: 'Determina con qué frecuencia se repetirá la auditoría hasta la fecha de finalización.',
          ACTION_TYPE_INPUT:
            'Determina si requieres auditar de alguna forma específica, Prisma Mystery Shopper APP permitirá al shopper auditar sólo mediante el uso la metodología seleccionada.',
          AUDIT_TYPE_INPUT: 'Interna: Se auditará en tus establecimientos.',
          AUDIT_TYPE_INPUT2: 'Externa: Se auditará e los establecimiento de la competencia.',
          COMPETITIVE_RULE_INPUT:
            'Al elegir una regla de competencia se autocompletarán los siguientes pasos por lo ya definido anteriormente en la creación de a regla.',
        },
        CHANGES_REFLECTED_NEXT_SURVEY: 'Los cambios realizados se verán reflejados a partir del próximo relevamiento.',
      },
      USER_ASSIGNMENT: {
        TITLE: 'Asignación de Usuarios',
        STATUS: {
          ASSIGNED: 'Asignado',
          NOTASSIGNED: 'Sin Asignar',
        },
        CLONATION: {
          TITLE: 'Clonar configuración del usuario',
          CONTENT: 'Se asignará la configuración de {{val}} para los usuarios seleccionados',
          CLIPBOARD: 'Importar Usuarios',
          USER_SELECTED: 'Usuarios Seleccionados',
          USER_FOUND: 'Usuarios Filtrados',
          ADD_USERS: 'Agregar Usuarios',
          PASTE_USERS: 'Pegar Usuarios al portapapeles',
          USER_NOTFOUND: 'Usuario no encontrado',
          HAS_ASSIGNED:
            'Se encontraron usuarios actualmente asignados, si desea continuar se sobreescribiran con las asignaciones del usuario desde donde se desea clonara. Caso contrario cancelar y quitar los usuarios asignados que no desee sobrescribir.',
          CLIPBOARD_SELECT_STEP:
            'Selecciona y copia los códigos de una hoja de cálculo o texto. (Ctrl + C) como se muestra en la imagen debajo. También pueden cargarse delimitando cada código con los siguientes caracteres "," ";" "|" "/" "\\" (tab) (enter) (espacio) ',
          CLIPBOARD_PASTE_STEP: 'Presiona aquí y pegue los códigos (Ctrl + V)',
          SUCCESSFULL: 'La clonación fue completada satisfactoriamente',
          FAILED: 'Ha ocurrido un error y la clonación ha falló',
        },
        UNASSIGNED: {
          TITLE_ONE: 'Desea desasignar a {{val}}?',
          CONTENT:
            'La acción desasignará la configuración de los puntos de venta propios a los que pertenece y los competidores que tiene asignados',
          TITLE_MULTIPLE: 'Desea desasignar a los usuarios seleccionados ({{val}})?',
        },
        LIST: {
          NAME: 'Nombre',
          EMAIL: 'Email',
          CODE: 'Código',
          STORES_COUNT: '# Puntos de venta propios',
          STORES: 'Puntos de venta propios',
          COMPETITORS_COUNT: '# Competidores',
          STATUS: 'Estado',
          UNASSIGNED_ROW: 'Desasignar',
          FILTER: 'Filtrar',
        },
        EDIT: {
          SAVE_WITH_CURRENT_COMPETITORS: 'Guardar con el Alcance de Competidores actual',
          WARNING_SAVE:
            'Por el cambio realizado en los Alcances de Puntos de ventas hay nuevos Competidores por Trade Area disponibles, si desea utilizarlos debe dirigirse al Alcance de Competidores antes de guardar. De lo contrario se mantendrán los competidores actualmente seleccionados',
          WARNING_COMPETITORS: 'Nuevos Competidores por Trade Area',
          STRATEGY_ATTR_TITLE_STORE: 'Selección por atributo (Estático)',
          STRATEGY_ATTR_MSG_STORE:
            'Se seleccionan atributos para definir el alcance de puntos de venta, si el alcance sufre cambios, no impactaran en esta configuración, si desea que impacte debe modificar este alcance y volver a guardarlo',
          STRATEGY_STORE_TITLE_STORE: 'Selección por puntos de venta',
          STRATEGY_STORE_MSG_STORE: 'Se deberán elegir explícitamente los puntos de venta uno a uno',
          CLIPBOARD_COMPETITORS_TITLE: 'Pegar competidores desde portapapeles',
          STORESCOPE: 'Alcance de Puntos de ventas',
          COMPETITORSSCOPE: 'Alcance de competidores',
          CHANGE_STORE_SCOPE_TITLE: 'Desea modificar el alcance de puntos de venta?',
          CHANGE_STORE_SCOPE_DESCRIPTION:
            'Si cancela volverá a la configuración previa. Si confirma modificará el alcance de puntos de venta, solo los competidores seleccionados marcados como trade area se sobreescribiran',
          STORE_SCOPE: {
            SUBTITLE:
              'En este paso podrás definir qué puntos de venta propios pertenece este usuario. Puedes elegirlos mediante selección por atributo o selección manual y también importarlos',
            VIEW_STORES_BTN: 'Ver puntos de venta',
            CODE_AND_DESCRIPTION_INPUT: 'Buscar por nombre o código',
            PASTE: 'Importar Puntos de venta',
          },
          COMPETITORS_SCOPE: {
            SUBTITLE:
              'En este paso podrás definir a qué competidores el usuario podrá relevar (tendrá acceso a las misiones disponibles que le corresponda según competidor). Inicialmente se asignarán los competidores dentro del trade area de los puntos de venta propios, con la posibilidad de quitar o agregar nuevos competidores',
            SEARCH_COMPETITORS: 'Buscar por nombre o código',
            PASTE: 'Importar Competidores',
            NOTFOUND: 'No encontrado',
            SAVE_FIRST: 'Debe guardar los cambios para poder configurar el Alcance de Competidores',
          },
        },
      },
    },
    COMP: {
      GENERIC_TABLE: {
        COMPETITORS: {
          COMPETITORS_FOUND: 'Competidores filtrados',
          COMPETITORS_SELECTED: 'Competidores seleccionados',
          ADD_COMPETITORS: 'Agregar Competidores',
        },
      },
      PRISMA_TABLE: {
        CONFIG_COLUMNS: 'Configurar columnas',
        DELETE_ACTION: {
          DELETE: 'Inactivar',
          DELETE_TTIP: 'Desactiva el registro',
          UNDELETE: 'Activar',
          UNDELETE_TTIP: 'Activar el registro',
          DELETE_ROW: 'Inactivar Registro',
          DELETE_ROWS: 'Inactivar Registros',
          DELETE_ROW_CONTENT: 'Seguro que desea inactivar {{val}}?',
          DELETE_ROWS_CONTENT: 'Seguro que desea inactivar {{val}} registros?',
          UNDELETE_ROW: 'Activar Registro',
          UNDELETE_ROWS: 'Activar Registros',
          UNDELETE_ROW_CONTENT: 'Seguro que desea activar {{val}}? al ser activado será iniciado con el estado nuevo',
          UNDELETE_ROWS_CONTENT: 'Seguro que desea activar {{val}} registros? al ser activados los mismos iniciarán con el estado nuevo',
          ACTION_ROW_CONTENT_SUCCESS: 'Acción completada correctamente',
          ACTION_ROW_CONTENT_FAILED: 'La acción no puede llevarse a cabo correctamente',
        },
        ENTITIES: {
          REPORT: 'Reporte completo aquí',
          APPLY: 'Debido a encontrarse en uso en las siguientes entidades:',
          ITEM_NOT_FOUND: 'Producto/s no encontrados',
          USEDINCOMBOS: 'Combos: {{val}}',
          USEDINMISSIONS: 'Misiones: {{val}}',
          USEDINPARENTRULES: 'Reglas de precios: {{val}}',
          USEDINPLANOGRAMS: 'Planogramas: {{val}}',
          USEDINPROMOTIONS: 'Promociones: {{val}}',
          USEDINRECIPES: 'Recetas: {{val}}',
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: 'El inconveniente fue producido por ',
          TYPE: 'Tipo: {{val}}',
          KEYWORD: 'Palabra clave: {{val}}',
          VALUES: 'Valores: {{val}}',
          WARNING: 'Alerta',
          ERROR: 'Error',
          ENTITYNOTFOUND: 'Entidad no encontrada',
        },
      },
      INSIGHT: {
        CONTEXT_MENU: {
          SUBSCRIBED: 'Suscripto',
          UNSUBSCRIBED: 'Desuscripto',
          SUBSCRIBE: 'Suscribir a todos los insights',
          UNSUBSCRIBE: 'Desuscribir a todos los insights',
          TYPE: 'de {{val}}',
          CATEGORY: 'de la Categoría {{val}}',
          STORE: 'del Punto de Venta {{val}}',
          STORETAG: 'de la etiqueta {{val}}',
          CONCATENATE_MESSAGE: '{{val}} {{val2}}',
        },
      },
      MODAL: {
        IMPORT_VENDORS: {
          TITLE: 'Solucionar conflictos de Proveedores',
          CLOSE: 'Cerrar',
        },
        ADD_MODAL_VERIFY: {
          SELECT_ALL: 'Seleccionar todos',
          SELECT_OPTIONS_MSG: 'Seleccione Opciones',
          CURRENT_SELECTION: 'Selección Actual',
          VALIDATE_BTN: 'Validar',
          VALIDATION_TITLE: 'Validación',
          PLACEHOLDER: 'Buscar {{val}}',
          TITLE_EMPTY: 'No hay {{val}} para validar',
          VALIDATION_MESSAGE_OK: 'Se configuraron correctamente todos los proveedores para el punto de venta.',
          VALIDATION_MESSAGE_WARNING:
            'Los {{val}} seleccionados no cubren el 100% del surtido activo. No se encontraron proveedores para los siguientes productos',
          VALIDATION_MESSAGE_WARNING_FOOTER: 'Seleccione {{val}} hasta cubrir todo el surtido.',
          VALIDATION_TITLE_WARNING: 'Advertencia',
          CLOSE: 'Cerrar',
          VALIDATION_MESSAGE_ERROR: 'Más de un proveedor tiene el mismo producto',
          VALIDATION_TITLE_ERROR: 'Conflictos ',
          SOLVE: 'Solucionar',
          DOWNLOAD_XLSX: 'Descargar excel',
          XLSX: 'proveedor.xlsx',
          BTN_TOOLTIP_ERROR: 'Descargar excel, y completar con una X la columna IsPrimary con el proveedor principal',
          INIT_VENDORS: 'Inicializar proveedores',
          SURE_TO_SAVE:
            'Este proceso es único y luego no podrá ser modificado en esta pantalla, asegúrese que los proveedores seleccionados sean los correctos',
          CANCEL: 'Cancelar',
          SAVE: 'Guardar',
        },
        COPY_VENDORS_BY_STORE: {
          INTRO: 'Se clonarán los proveedores según el punto de venta seleccionado.',
          PLACEHOLDER: 'Punto de Venta',
          SAVE: 'Guardar',
          CLOSE: 'Cerrar',
        },
      },
      PICK_COLOR: {
        SELECTION: 'Seleccionar',
        CANCEL: 'Cancelar',
        CLEAR: 'Borrar',
        SELECTION_TITLE: 'Seleccionar un color',
      },
      KPIS: {
        DOUBLE_KPI_SUGGESTION: 'SUGERENCIAS ACEPTADAS',
      },
      MULTIPLE_GREEN_BUTTON: {
        MODAL_TITLE: '¿Desea cambiar la selección?',
        MODAL_CONTENT: 'Si confirma, los datos relacionados a la selección pueden ser eliminados de la carga actual',
        MODAL_BTN_CLOSE: 'Cancelar',
        MODAL_BTN_ACCEPT: 'Confirmar',
        CHECK: 'No volver a preguntar.',
      },
      ALERT_AND_CAP: {
        ALERTACOMPETENCIA:
          'El precio sugerido de ${{val}} es {{val2}} al límite por Competidor {{val3}}. Tu precio debería estar entre ${{val4}} y ${{val5}}',
        ALERTACOMPETENCIA_NO_MIN:
          'El precio sugerido de ${{val}} es {{val2}} al límite por Competidor {{val3}}. Tu precio debería ser como mínimo ${{val5}}',
        ALERTACOMPETENCIA_NO_MAX:
          'El precio sugerido de ${{val}} es {{val2}} al límite por Competidor {{val3}}. Tu precio debería ser como máximo ${{val4}}',
        ALERTACOMPETENCIA_NO_RANGE: 'El precio sugerido de ${{val}} es {{val2}} al límite por Competidor {{val3}}.',
        ALERTACOMPETENCIAMISSING: 'No se pudo chequear el límite por competidor por no contar con precios de mercado vigentes para {{val}}',
        ALERTAMARGEN: 'El precio sugerido de ${{val}} es {{val2}} al {{val3}} {{val4}} según límite de margen.',
        ALERTAMARGENMISSING: 'No se pudo chequear el límite de margen por no contar con información de costos suficiente.',
        ALERTAVARIACION: 'El precio sugerido de ${{val}} es {{val2}} al {{val3}} {{val4}} según límite de variación de precios.',
        CAPCOMPETENCIA:
          'El precio sugerido de ${{val}} se cambió a ${{val2}} por ser {{val3}} al límite por Competidor {{val4}} que implicaría estar entre {{val5}} y {{val6}}',
        CAPCOMPETENCIAMISSING: 'No se pudo chequear el límite por competidor por no contar con precios de mercado vigentes para {{val}}',
        CAPMARGE:
          'El precio sugerido de ${{val}} se cambió a ${{val2}} por ser {{val3}} al {{val4}} {{val7}} según límite de margen. Debe estar entre {{val5}} y {{val6}}',
        CAPMARGEMISSING: 'No se pudo chequear el límite de margen por no contar con información de costos suficiente.',
        CAPVARIACION:
          'El precio sugerido de ${{val}} se cambió a ${{val2}} por ser {{val3}} al {{val4}} {{val7}} según límite de variación. Debe estar entre {{val5}} y {{val6}}',
        MINIMOMAXIMO: '{{val}} de ${{val2}}',
        MINIMUM: 'mínimo',
        MAXIMUM: 'máximo',
        GREATER: 'mayor',
        ABOVE: 'encima',
        BELOW: 'debajo',
        ALERT_MARGEN_LIMIT: 'El precio sugerido esta por {{val}} del {{val2}} del límite de margen requerido.',
        ALERT_VARIATION_LIMIT: 'El precio sugerido esta por {{val}} del {{val2}} del límite de variación requerido.',
        OF: 'de',
        LOWER: 'menor',
        FOR: 'para {{val}}',
      },
      DEFAULT_TBAR: {
        SEARCH: 'Buscar...',
        SEARCH_ITEM: 'Buscar Ítem...',
        SEARCH_STORE: 'Buscar Punto de Venta...',
      },
      ACTION_TBAR: {
        ACTIONS: 'Acciones',
        SELECTED_SINGULAR: 'Seleccionado',
        SELECTED_PLURAL: 'Seleccionados',
      },
      ITEMS_NO_FOUND: {
        NO_RESULTS_FILTERS: 'No se encontraron resultados con los filtros seleccionados.',
        NO_RESULTS_FILTERS_MANDATORY:
          'No se encontraron resultados con los filtros seleccionados. Por favor, completar los filtros mandatorios.',
        NO_RESULTS: 'No se encontraron resultados.',
        NO_RESULTS_REPORTS: 'No se encontraron reportes.',
      },
      ERROR_MESSAGE: {
        TITLE: 'Ha ocurrido un error',
        SUBTITLE: 'Comuníquese con el equipo de soporte.',
        ACCEPT_BTN: 'Aceptar',
        DOWNLOAD_LOG: 'Descargar error-log',
      },
      DINAMIC_TAGS: {
        ADD_ATTRIBUTE_BTN: '+',
        WITHOUT_SELECTION: 'Sin Selección...',
        SEARCH: 'Buscar ',
        ELEMENTS_NOTFOUND: 'No se encontraron etiquetas',
        NO_ELEMENTS_SELECTED: 'No se seleccionaron etiquetas',
        SELECTION_ALL: 'Seleccionar todas',
      },
      FILTER_CHARGE_COMBO: {
        OTHERS: ', Otros',
        OTHER: ', Otro',
        SELECTED: 'Seleccionados',
        RESULT: 'Resultados Encontrados',
        TOOLTIP: 'Opciones seleccionadas: ',
      },
      ADD_ATTRIBUTE: {
        TITLE_MODAL: 'Nuevo Atributo',
        SELECT_ATTRIBUTE: 'Seleccionar Atributo',
        SEARCH_ATTRIBUTE: 'Buscar Atributo',
        SELECT_ALL: 'Seleccionar todos',
        CANCEL_BTN: 'Cancelar',
        ADD_BTN: 'Agregar',
        EXCLUDED: 'Excluido',
      },
      ERASURE_CONFIRMATION: {
        TITLE: 'Eliminar',
        SUBTITLE: '¿Desea continuar con la eliminación?',
        CANCEL_BTN: 'Cancelar',
        DELETE_BTN: 'Eliminar',
      },
      DRAG_DROP: {
        TITLE: 'Arrastrar la imagen aquí o hacer click para buscar en tu PC',
        SUBTITLE: 'Extensión no permitida: ',
        SUBTITLE_TTIP: 'Extensiones permitidas: "jpeg", "jpg", "png", "ico"',
        SUBTITLE_TTIP_EXCEL: 'Extensiones permitidas: "xls", "xlsx"',
        SUBTITLE_TTIP_ONLY: 'Extensiones permitidas:',
      },
      OPERATION_SUCCESS: {
        TITLE: 'Operación Exitosa',
        SUBTITLE: 'La operación se completo satisfactoriamente',
        ACCEPT_BTN: 'Aceptar',
        TITLE_EMPTY: 'Operación Sin Modificaciones',
        SUBTITLE_EMPTY: 'La operación se completo satisfactoriamente, no se encontraron cambios pendientes a informar',
        SUBTITLE_WITHOUT_RESULT: 'Sin resultados',
      },
      IAN_IMPORTER: {
        BTN_IMPORT_SWITCH: 'Terminar',
        CLOSE: 'Cerrar',
        UPLOAD_FILES_STP1: 'Subir archivos',
        VERIFY_FORMAT_STP2: 'Verificar Formato',
        VALIDATE_INFORMATION_STP3: 'Validar Información',
        UPDATE_TABLES_STP4: 'Actualizar tablas',
        UPDATE_TABLES_STP_END: 'Finalizar',
        RESTART_TTIP: 'Reiniciar importador para subir nuevos archivos',
        STP1: 'Paso 1 - Subir archivo',
        DRAG_FILE: 'Arrastrar el archivo aquí, o hacer click para buscar en tu PC',
        STP2: 'Paso 2 - Verificar formato',
        CHECKING_FORMAT: 'Verificando el formato y la extensión del archivo a subir al servidor',
        STP3: 'Paso 3 - Validar información',
        VALIDATING_DATA: 'Validando los datos del archivo en búsqueda de inconsistencias',
        STP4: 'Paso 4 - Actualizar tablas',
        STP_END: 'Paso 2 - Finalizar',
        INCORPORATING_INFORMATION: 'Se está incorporando la información procesada a Prisma',
        CORRECT_FILE: 'El formato y la extensión del archivo es correcto',
        WITHOUT_ERRORS: 'La información ha sido incorporada al sistema correctamente sin errores pero con',
        WARNINGS: 'alertas',
        ERRORS: 'La información no ha sido incorporada al sistema y se encontraron los siguientes errores o alertas',
        WITHOUT_ERRORS_WARNINGS: 'La información ha sido incorporada al sistema correctamente y sin errores o alertas',
        DOWNLOAD_TEMPLATE: 'Descargar Template',
        TOTAL_ROWS_REGISTRED: 'Total de registros cargados',
        TOTAL_ROWS_REGISTRED_WITH_WARNINGS: 'Total de registros no cargados con alertas',
      },
      GEN_CATEGORYS: {
        CATEGORIES_INPUT: 'Categoría',
        CATEGORIES_INPUT_EXC: 'Categoría Excluida',
        CATEGORIES_TTIP: 'Deberá al menos seleccionar una categoría, para seleccionar las subcategorías',
        SEARCH_CATEGORIES: 'Buscar Categorías',
        SUBCATEGORIES_INPUT: 'Subcategoría',
        SUBCATEGORIES_INPUT_EXC: 'Subcategoría Excluida',
        DEPT_INPUT: 'Departamento',
        DEPT_INPUT_EXC: 'Departamento Excluido',
        SUBCATEGORIES_TTIP: 'Deberá al menos seleccionar una subcategoría por categoría seleccionada',
        SEARCH_SUBCATEGORIES: 'Buscar Subcategorías',
        SELECT_ALL: 'Seleccionar Todos',
        SELECT_ALL_SUBCATEGORY: 'Seleccionar todas las Subcategorías',
        SEARCH_SUBCATEGORIES_FILTER: 'Buscar Subcategorías',
        SELECT_ALL_CATEGORY: 'Seleccionar todas las Categorías',
      },
      GEN_ITEM_LIST: {
        NAME: 'Nombre',
        CODE: 'Código',
        BRAND: 'Marca',
        MANUFACTURER: 'Fabricante',
        PRICE: 'Precio $',
        COST: 'Costo $',
      },
      GEN_ITEM_STORE: {
        NAME: 'Nombre',
        CODE: 'Código',
        BRAND: 'Marca',
      },
      GEN_TAGS: {
        FILTER_TAG_VALUES: 'Filtrar opciones que estén relacionadas con los resultados del listado',
        ADD_ATRIBUTE_BTN: 'Agregar Atributos',
        NO_TAGS: 'Sin Atributos para agregar',
        NONE: 'Ningún',
        ALL: 'Todos',
        EXCLUDED: 'excluido',
        SEARCH: 'Buscar',
      },
    },
    MAT_PAGINATOR: {
      QUANTITY: 'Ítems por página',
      NEXT: 'Página siguiente',
      PREVIUS: 'Página anterior',
      FIRST: 'Primera página',
      LAST: 'Última página',
    },
    SERVICES: {
      CAN_DEACTIVATE: {
        TITLE_MODAL: 'Cambios sin guardar',
        SUBTITLE_MODAL: '¿Desea salir de todos modos?',
        GO_OUT_BTN: 'Salir',
        FOLLOW_BTN: 'Seguir en el formulario',
      },
    },
    SPACES: {
      COMMON: {
        SEE_MORE: 'Ver más',
        STORES_LIST_TITLE: 'Puntos de venta',
        STORES_TABLE_NAME: 'Nombre',
        STORES_TABLE_ACTION: 'Acciones',
      },
      LIST: {
        ENTITIES: {
          REPORT: 'Reporte completo aquí',
          APPLY: 'Debido a encontrarse en uso en las siguientes entidades:',
          CONTENT_DELETE_LAYOUT:
            'La acción no pudo llevarse acabo correctamente, el planograma se encuentra asociado a al menos un layout, presionar continuar para eliminarlo y quitarlo de los layouts correspondientes',
          ITEM_NOT_FOUND: 'Sin permisos suficientes sobre todos los puntos de ventas vinculadas a los planogramas solicitados',
          USEDINLAYAOUTS: 'Layouts en uso: {{val}}',
          IMPEDIMENT: {
            USEDINLAYAOUTS: 'Layouts en uso: {{val}}',
          },
        },
        STATUS: {
          New: 'Nuevo',
          PendingEndorsement: 'Pendiente de Aprobación',
          Current: 'Publicado',
          Endorsed: 'Aprobado',
          Deleting: 'Eliminando',
          Draft: 'Preview',
        },
        TITLE_STRUCTURE: 'Estructuras',
        BTN_STRUCTURE: 'Nueva Estructura',
        TITLE_GENERIC_SPACE: 'Espacios Genéricos',
        BTN_GENERIC_SPACE: 'Nuevo Espacio Genérico',
        TITLE: 'Planogramas',
        POPOVER_TEXT:
          'Sólo se muestran aquellos planogramas cuyos Puntos de Venta están disponibles para el usuario. Si no se muestan uno o más planogramas, por favor comunicarse con el administrador del sistema informando los planogramas ausentes y solicitando verificar los PDV asociados al usuario.',
        ROUTE: 'Espacios',
        BTN: 'Nuevo Planograma',
        ADMIN: 'Administrar Planogramas',
        EXPORT_TTIP: 'Exportar',
        FILTER_TTIP: 'Filtrar',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        PRINT_TTIP: 'Imprimir',
        DELETE: 'Eliminar',
        DELETE_TTIP: 'Borrar planogramas seleccionados',
        RECOVER: 'Recuperar',
        RECOVER_TTIP: 'Recuperar',
        CLONE_STRUCTURE: 'Clonar solo estructura',
        CLONE_STRUCTURE_AND_PRODUCTS: 'Clonar estructura y productos',
        SURE_CLONE:
          'Está seguro que desea clonar este ítem? solo se clonara la estructura del planograma y no la configuración con todos sus productos aplicados',
        SURE_CLONE_COMPLETE:
          'Está seguro que desea clonar este ítem? se clonara la estructura del planograma y la configuración con todos sus productos aplicados',
        EDIT: 'Editar',
        STATUS_NEW: 'Nuevo',
        STATUS_EDITED: 'Editado',
        STATUS_ACTUAL: 'Actual',
        STATUS_APPROVED: 'Aprobado',
        STATUS_PENDING_APPROVAL: 'Pend. de Aprob.',
        STATUS_PEVIEW: 'Preview',
        STATUS_INFORMED: 'Informado',
        STATUS_INFORM: 'Informar',
        REQUEST_APPROVAL: 'Solicitar Aprobación',
        REQUEST_APPROVAL_SMALL: 'Solicitar Aprob.',
        APPROVE: 'Aprobar',
        COLUMN: {
          NAME: 'Nombre',
          CODE: 'Código',
          TYPE: 'Tipo',
          LAST_MODIF: 'Ult Modif.',
          STATUS: 'Estado',
          CAT: '# Categorías',
          SUBCAT: '# Subcat.',
          GENERATE: 'Generación',
          CHARGE: 'Carga (UN)',
          FRONT: 'Largo Frente',
          LINEAL_SIZE: 'Espacio lineal',
          LAYOUT: '# Layouts',
          STORES: '# Puntos de Venta',
          SKU: '# Productos',
          SALES: 'Vtas mes/ espacio lineal',
          VAR: 'Var. Mes anterior',
          PLANOGRAMTYPE: 'Tipo',
          STRUCTUREONLY: 'Estructura',
          STRUCTUREONLY_Structure: 'Estructura',
          STRUCTUREONLY_Planogram: 'Planograma',
          STRUCTUREONLY_Generic: 'Espacio Genérico',
          FILTER_PREVIEW: 'Ver Previews',
          FILTER_TEMPLATE_GENERATE: 'Generados por:',
          FILTER_TEMPLATE_GENERATE_FOR: 'Generación:',
          MASIVE_ACTIONS: 'Acciones Masivas:',
          request_approval_msg: '¿Estás seguro de solicitar la aprobación de {{val}} registros?',
          approve_msg: '¿Estás seguro de aprobar {{val}} registros?',
          inform_msg: '¿Estás seguro de informar {{val}} registros?',
          APPROVE_ALERT: '“Aprobación enviada. Recibirá una notificación cuando el proceso se haya completado.',
          REQUEST_APPROVAL_ALERT: 'Solicitud de Aprobación enviada. Recibirá una notificación cuando el proceso se haya completado.',
          INFORM_ALERT: 'Informando planogramas. Recibirá una notificación cuando el proceso se haya completado.',
          INACTIVATE_ALERT: 'Eliminando planogramas. Recibirá una notificación cuando el proceso se haya completado.',
          request_approval: 'Solicitar Aprobación',
          approve: 'Aprobar',
          inform: 'Informar',
          delete: 'Inactivar',
          NO_PERMISSIONS: 'No tienes permisos.',
          AUTOMATIC: 'Automática',
          MANUAL: 'Manual',
        },
        SNACKBAR_REPORT: 'Se está generando el reporte',
        SNACKBAR_CLOSE: 'Cerrar',
        PLANOGRAM_TYPES: {
          All: 'Todos',
          Structure: 'Estructuras',
          Planogram: 'Planogramas',
          GenericSpace: 'Espacio Genérico',
          isGenericSpace: 'Espacio Genérico',
        },
        PLANOGRAM_TYPE: {
          Structure: 'Estructura',
          Planogram: 'Planograma',
          GenericSpace: 'Espacio Genérico',
          isGenericSpace: 'Espacio Genérico',
        },
      },
      TABS: {
        TAB1_ICON: 'settings',
        TAB1: 'General',
      },
      GENERAL: {
        TITLE_NEW: 'Nuevo planograma',
        TITLE_NEW_TEMPLATE: 'Nueva Estructura',
        TITLE_NEW_GENERICSPACE: 'Nuevo Espacio Genérico',
        TITLE_EDIT: 'Editar planograma',
        TITLE_EDIT_TEMPLATE: 'Editar Estructura',
        TITLE_EDIT_GENERICSPACE: 'Editar Espacio Genérico',
        SUBTITLE: 'Estás creando un nuevo planograma, selecciona el Nombre, los puntos de venta o la categoría',
        SAVE_BTN: 'Guardar',
        NAME_INPUT: 'Nombre',
        OBSERVATIONS_INPUT: 'Observaciones',
        ADVANCED_SEARCH: 'Búsqueda avanzada',
        UPDATE: 'Actualizar',
        UPDATE_STRUCTURES: 'Debe actualizarse el listado de estructuras para ver los cambios realizados.',
        CODE_SEARCH: 'Buscar por códigos',
        VALIDATION_CODE: 'El código {{val}} ya existe y se encuentra en un planograma en uso o inactivo',
        STORES: 'Puntos de Venta',
        CATEGORIES: 'Categorías',
        DELETE_ALL: 'Eliminar todos',
        CONFIRM_DELETE: '¿Está seguro que desea eliminar el punto de venta?',
        CONFIRM_DELETE_ALL: '¿Está seguro que desea eliminar todos?',
      },
      STRUCTURE: {
        HIDE_PANEL: 'Ocultar Panel',
        OPEN_L_PANEL: 'Abrir panel izquierdo',
        OPEN_R_PANEL: 'Abrir panel derecho',
      },
      PRODUCTS: {
        TURN_OFF_INDICATOR: 'Apagar indicador',
        FILTER_TITLE: 'Filtros',
      },
      CONFIRMATION: {
        TITLE: '¡Atención!',
        SUBTITLE: '¿Desea agregarlo a lo existente o reemplazarlo por completo?',
        REPLACE_BTN: 'Reemplazar',
        ADD_BTN: 'Agregar',
      },
      EXTRAS: {
        CATEGORY_TITLE: 'Subcategorías',
        CATEGORY_DESC: 'Asigna las subcategorías de los productos que incorporarás al planograma. Luego podrás modificarlas.',
        STORE_TITLE: 'Puntos de Venta',
        STORE_DESC:
          'Asigna los Puntos de Venta de forma individual o masiva a las cuales pertenecerá. Luego podrás modificarlas. Usa (Ctrl + P) para pegar de un Excel.',
      },
      MATERIAL_POP: {
        TITLE: 'Material POP',
        ROUTE: 'Espacios',
        NEW_BTN: 'Nuevo Material Pop',
        FULLSCREEN_TTIP: 'Expandir',
        EXITFULLSCREEN_TTIP: 'Reducir',
        NAME: 'Nombre',
        CODE: 'Código',
        TYPE: 'Tipo',
        LAST_MODIF: 'Última Modif.',
        OBSERVATION: 'Observación',
        CHARGE_UNITS: 'Carga (UN)',
        LAYOUTS: '# Layouts',
        STP1_INITIAL_DATA: 'Datos Iniciales',
        STP2_ASSEMBLY: 'Armado',
        STP1: 'Paso 1 - Datos iniciales',
        BTN_NEXT: 'Siguiente',
        STP2: 'Paso 2 - Armado',
        DISTANCE: 'Distancia entre Productos (cm.)',
        PREVIOUS_BTN: 'Anterior',
        SAVE_BTN: 'Guardar',
        MOVE_UP: 'Mover el producto hacia arriba',
        MOVE_DOWN: 'Mover el producto hacia abajo',
        DELETE: 'Eliminar el producto',
        SEARCH_PRODUCT: 'Buscar por producto o código',
        TITLE_FORM: 'Nuevo Material POP',
        POP_MATERIAL: 'Material POP / ',
        EMTY_MSG: 'Arrastre los productos aquí',
        VALIDATION_CODE: 'El código {{val}} ya existe y se encuentra en un Material POP en uso o inactivo',
        PARENT_MATERIAL: 'Material POP Padre',
        LIST: {
          DELETE_ROW: 'Inactivar registro',
          DELETE_ROWS: 'Inactivar registros',
          DELETE_ROW_IN_PROCESS: 'Solicitud de inactivación en proceso. Te notificaremos una vez completada.',
          CONTENT_DELETE_PLANOGRAM:
            'La acción no pudo llevarse acabo correctamente, el Material POP se encuentra asociado a al menos un planograma.',
        },
      },
      EDITOR: {
        IS_PREVIEW: 'Es un Preview',
        PREVIEW: 'Previsualizar',
        SAVE_FOR_PREVIEW: 'Deben guardarse los cambios para generar una vista previa.',
        DELETE: 'Quitar preview',
        OPACITY: 'Opacidad',
        NEW_STRUCTURE: 'Nueva estructura',
        NEW_GENERIC_SPACE: 'Nuevo espacio genérico',
        DOWNLOAD_EXCEL: 'Descargar reporte',
        LOADING_POG: 'Cargando planograma...',
        NEW_POG: 'Nuevo planograma',
        SAVE: 'Guardar',
        VISUALIZATION: 'Visualización',
        NULL_SPACES: 'Espacio vacío',
        NULL_SPACES_LEGEND: '',
        CLEAN_NI: 'Limpiar espacios vacíos',
        CLEAN_POG: 'Limpiar planograma',
        NEW_BLOCK_SPACES: 'Agregar Área',
        EDIT_BLOCK_SPACES: 'Editar Área',
        DELETE_ALL_BLOCK_SPACES: 'Limpiar Áreas',
        DELETE_ALL_WARNING: 'Eliminar todas las Áreas',
        DELETE_ALL_MSG: 'Esta acción no puede ser revertida. ¿Desea continuar?',
        MIRROR_POG: 'Espejar planograma',
        CLEAN_MOD: 'Limpiar módulo',
        MIRROR_MOD: 'Espejar módulo',
        CLEAN_SHELF: 'Limpiar estante',
        MIRROR_SHELF: 'Espejar estante',
        AUTOCOMPLETE_SHELF: 'Autocompletar estante',
        SHELF_AVOID_CHECK_SPACE: 'No validar tamaños',
        AUTOCOMPLETE_MODULE: 'Autocompletar módulo',
        TITLE_POG: 'Planograma',
        TITLE_LAYERS: 'Capas',
        TITLE_HEATMAP: 'Mapas de Calor',
        TITLE_HEATMAP_SING: 'Mapa de Calor',
        TITLE_VARIABLE: 'Variable',
        TITLE_TIMELINE: 'Línea de Tiempo',
        TITLE_VARIATIONS: 'Variaciones',
        TITLE_VIS_ATTRS: 'Visualizar atributos',
        TITLE_VIS_ATTRS_SING: 'Visualizar atributo',
        TITLE_VIS_ATTRS_CAT: 'Ranking por Categoría',
        TITLE_VIS_ATTRS_DEP: 'Ranking por Departamento',
        TITLE_STORES: 'Tiendas',
        TITLE_FILTERS: 'Filtros',
        TITLE_SEARCH_BTN: 'Agregar productos a la Tira de Impulso',
        TITLE_TYPE: 'Tipo',
        TITLE_SHELF: 'Estante',
        TITLE_BLOCK_SPACE: 'Área Bloqueada',
        NAME_BLOCK_SPACE: 'Nombre',
        COUNT_SHELFS: 'Estantes seleccionados:',
        WIDTH_BLOCK_SPACE: 'Ancho (cm.):',
        DST_X_BLOCK_SPACE: 'Dist. inicio mueble (cm.):',
        TITLE_OWN: 'Propia',
        TITLE_CAPACITY: 'Capacidad',
        TITLE_CAPACITY_LIN: 'Cap. lineal',
        TITLE_CAPACITY_LIN_FREE: 'Cap. lin. libre',
        TITLE_ASSIGNED_TO: 'Asignado a',
        TITLE_PRODS: 'Productos',
        TITLE_FACINGS: 'Frentes',
        TITLE_LEVELS: 'Niveles',
        TITLE_LOAD: 'Carga',
        TITLE_STOCK_VALUE: 'Stock $',
        TITLE_PERFORMANCE: 'Performance',
        TITLE_ACTIVES: 'Activos',
        TITLE_NO_ACTIVES: 'No activos',
        TITLE_ACTIVE: 'Activo',
        TITLE_NO_ACTIVE: 'Activo en algunas tiendas',
        TITLE_NO_ACTIVE_ALL_STORES: 'Inactivo en todas las tiendas',
        TITLE_IS_BLOCKED: 'Disponible para la Venta en algunas tiendas',
        TITLE_NO_BLOCKED: 'Disponible para la Venta',
        TITLE_BLOCKED_ALL_STORES: 'Bloqueado para la Venta',
        TITLE_SEARCH: 'Buscar',
        TITLE_EXHIBITED: 'Exhibidos',
        TITLE_EXHIBITED_SING: 'Exhibido',
        TITLE_NO_EXHIBITED: 'Sin Exhibir',
        TITLE_NEW_ONLY: 'Solo Nuevos',
        TITLE_BLOCKEDFORSALES: 'Bloqueado para la Venta',
        TITLE_BLOCKEDFORSALES_NO: 'Disponible para la Venta',
        TITLE_ADVANCED_FILTERS: 'Filtros Avanzados',
        TITLE_CLEAN_FILTERS: 'Limpiar Filtros',
        TITLE_SMALL_COD: 'Cod.',
        TITLE_SMALL_UNIT_MED: 'UN med',
        TITLE_EAN: 'Ean',
        TITLE_BRAND: 'Marca',
        TITLE_SUPLIER: 'Proveedor',
        TITLE_CAN_STACK: 'Apilable',
        TITLE_CAN_LAYDOWN: 'Tumbable',
        TITLE_CAN_ROTATE: 'Rotable',
        TITLE_SALES_PART_PER: 'Part. % Ventas',
        TITLE_SALES_PART_LIN_ESP: 'Part. % Esp. (m/lin)',
        TITLE_EXH_DAYS: 'Días exhib.',
        TITLE_EXH_STOCK: 'Días stock',
        TITLE_STOCK: 'Stock',
        TITLE_STOCK_UN: 'Stock UN',
        TITLE_DATE: 'Fecha',
        TITLE_USER: 'Usuario',
        TITLE_ANALISYS_TYPE: 'Tipo de análisis',
        TITLE_LOAD_LIMIT: 'Límite de carga',
        TITLE_STACK_LIMIT: 'Límite de apilado',
        TITLE_LIMIT_PLACEHOLDER: 'lím.',
        TITLE_IS_PALLET: 'es Tarima',
        TITLE_IS_PALLET_PLACEHOLDER: 'es Tarima',
        FILTER_POPMATERIALESPECIAL: 'Material POP Especial',
        FREE_AREA: 'Área Libre',
        TOTAL_AREA: 'Área Total',
        TOTAL_PLANOGRAMS: 'Planogramas',
        YES: 'Sí',
        NO: 'No',
        TTIP_UNDO: 'Deshacer último cambio [ctrl+z]',
        TTIP_REDO: 'Rehacer último cambio [ctrl+y]',
        TTIP_DRAG: 'Arrastrar [spacebar+mouse]',
        TTIP_FIT: 'Recentrar contenido [ctrl+0]',
        TTIP_ZOOM_IN: 'Acercar [+]',
        TTIP_ZOOM_OUT: 'Alejar [-]',
        TTIP_FULL_SCREEN: 'Maximizar pantalla',
        TTIP_FULL_PRINT: 'Imprimir [ctrl+p]',
        TTIP_OPTIONS: 'Opciones',
        TTIP_DEL: 'Eliminar [del]',
        TTIP_CLONE: 'Clonar Módulo',
        TTIP_CLONE_ALT: 'Clonar',
        TTIP_COMMENTS: 'Comentarios',
        TTIP_STACK_WARING: 'Este producto no es apilable',
        PRINT_NAME: 'Producto',
        PRINT_POSITION: 'Posición',
        PRINT_NAME_CAT: 'Categoría',
        PRINT_NAME_SUBCAT: 'Subcategoria',
        PRINT_UNIT_MESURE: 'UOM',
        PRINT_BRAND: 'Marca',
        PRINT_MANUFACTURE: 'Fabricante',
        PRINT_WIDTH: 'Ancho (cm.)',
        PRINT_HEIGHT: 'Alto (cm.)',
        PRINT_DEPTH: 'Profundidad (cm.)',
        PRINT_FACES: 'Frentes',
        PRINT_LOAD: 'Carga Max.',
        PRINT_LINEAL_SPACE: 'Espacio Lineal (cm.)',
        PRINT_ROTATION: 'Rotación',
        PRINT_STACK: 'Niveles',
        MSG_SHELF_SHOULD_EMPTY: 'El estante debe estar vacío.',
        MSG_CONFIRM_SAVE: '¿Confirma que desea guardar el planograma?',
        MSG_CONFIRM_SAVE_TEMPLATE: '¿Confirma que desea guardar la estructura?',
        MSG_CONFIRM_SAVE_SPACE: '¿Confirma que desea guardar el espacio genérico?',
        MSG_WIDTH_NI: 'Debe asignar el ancho del espacio vacío',
        MSG_CONFIRM_DEL_PRODS: '¿Desea continuar con la eliminación de todos los productos del planograma?',
        MSG_CONFIRM_DEL_NI_PRODS: '¿Desea continuar con la eliminación de todos los espacios vacíos del planograma?',
        MSG_CONFIRM_MIRROR_PRODS: '¿Desea continuar con el espejado del planograma?',
        MSG_CONFIRM_DEL_SHELF_PRODS: '¿Desea continuar con la eliminación de todos los productos del estante?',
        MSG_CONFIRM_MIRROR_SHELF_PRODS: '¿Desea continuar con el espejado de todos los productos del estante?',
        MSG_CONFIRM_DEL_PRODS_MOD: '¿Desea continuar con la eliminación de todos los productos del módulo?',
        MSG_CONFIRM_MIRROR_PRODS_MOD: '¿Desea continuar con el espejado de de todos los productos del módulo?',
        MSG_CONFIRM_DEL_PRODS_MOD_PRD: '¿Desea continuar con la eliminación del módulo?<br><b>Se eliminarán todos los productos.</b>',
        MSG_CONFIRM_DEL_PRODS_SHELF_PRD: '¿Desea continuar con la eliminación del estante?<br><b>Se eliminarán todos los productos.</b>',
        MSG_CONFIRM_DEL: 'Confirmación de eliminación.',
        MSG_CONFIRM_MIRROR: 'Confirmación de espejado.',
        MSG_MODULE_WIDTH_ALERT: 'Hay productos en el módulo que no entran en el nuevo ancho.',
        MSG_MODULE_DEPTH_ALERT: 'Hay productos en el módulo que no entran en la nueva profundidad.',
        MSG_PRD_NO_ENOUGH_SPACE: 'No hay suficiente espacio.',
        MSG_PRD_NO_ENOUGH_SPACE_LIMIT: 'Carga máxima superada.',
        MSG_PRD_CATN_ROTATE: 'El producto seleccionado no se puede rotar.',
        MSG_PRD_CATN_LAYDOWN: 'El producto seleccionado no se puede tumbar.',
        MSG_PRD_CATN_ROTATE_LAYDOWN: 'Los productos tumbados no se puede rotar.',
        MSG_PRD_CATN_ROTATE_ROTATEY: 'Los productos girados no se puede rotar.',
        MSG_PRD_CATN_PALLET: 'El producto elegido no se puede colocar en modo de tarima por falta de espacio.',
        MSG_SHELF_SHOULD_EMPTY_TYPE: 'El estante debe estar vacío para cambiar el tipo.',
        MSG_SHELF_PRD_HEIGTH_WARNING: 'Existen productos con una altura superior a la del estante.',
        MSG_SHELF_PRD_DEPTH_WARNING: 'Existen productos con una profundidad superior a la del estante.',
        BTN_CANCEL: 'Cancelar',
        BTN_DEL: 'Eliminar',
        BTN_ACEPT: 'Aceptar',
        BTN_PRINT: 'Imprimir',
        BTN_CLEAN: 'Limpiar',
        BTN_APPLY: 'Aplicar',
        BTN_SAVE: 'Guardar',
        BTN_CLOSE: 'Cerrar',
        ACTION_REORDER_LEFT: 'Reordenar a la Izquierda',
        ACTION_REORDER_RIGHT: 'Reordenar a la Derecha',
        ACTION_ADD_SHELF: 'Agregar Estante',
        ACTION_DEL_PRODS: 'Eliminar productos',
        ACTION_PRD_ADD_FACING: 'Agregar un frente [ctrl+right]',
        ACTION_PRD_REMOVE_FACING: 'Remover un frente [ctrl+left]',
        ACTION_PRD_STACKSLYINGDOWN: 'No tumbar frente',
        ACTION_PRD_NO_STACKSLYINGDOWN: 'Tumbar frentes',
        ACTION_PRD_ROTATE_CW_NORMAL: 'Rotar hacia la derecha [r]',
        ACTION_PRD_ROTATE_NOCW: 'Rotar hacia la izquierda',
        ACTION_PRD_ROTATE_CW: 'Acostar a la derecha [r]',
        ACTION_PRD_ROTATE_UP_CW: 'Parar el producto [r]',
        ACTION_PRD_ROTATE_REV: 'Acostar a la izquierda',
        ACTION_PRD_ROTATE_REV_Y: 'Ver de perfil',
        ACTION_PRD_ROTATE_REV_Y2: 'Ver de frente',
        ACTION_SHELF_MOVEUP: 'Mover arriba',
        ACTION_SHELF_MOVEDOWN: 'Mover abajo',
        ACTION_SHELF_ADD_UP: 'Agregar estante encima',
        ACTION_SHELF_ADD_DOWN: 'Agregar estante debajo',
        ACTION_SHELF_DEL_PRODS: 'Eliminar productos',
        ACTION_LAYER_TOGGLE_MODS: 'Oculta/Prende Módulos',
        ACTION_LAYER_HIDE_MODS: 'Ocultar módulos',
        ACTION_LAYER_SHOW_MODS: 'Mostrar módulos',
        ACTION_LAYER_HIDE_PRODS: 'Ocultar productos',
        ACTION_LAYER_HIDE_IMG: 'Ocultar imágenes',
        ACTION_LAYER_SHOW_IMG: 'Mostrar imágenes',
        ACTION_LAYER_SHOW_PRODS: 'Mostrar productos',
        ACTION_LAYER_HIDE_POP: 'Ocultar material POP',
        ACTION_LAYER_SHOW_POP: 'Mostrar material POP',
        ACTION_LAYER_HIDE_PITSHELF: 'Mostrar pozos de Frente',
        ACTION_LAYER_SHOW_PITSHELF: 'Mostrar pozos en planta',
        ACTION_ADD_CONSTRAINTS: 'Crear Regla',
        SHELF: 'Estante',
        SHELF_FRONT_VIEW: 'Pozo Vista de frente',
        SHELF_NORMAL_TYPE_SHELF: 'Estanterías',
        SHELF_NORMAL_TYPE_REFRIGERATOR: 'Heladeras',
        SHELF_NORMAL_TYPE_SIMPLEPEG: 'Gancheras',
        SHELF_NORMAL_TYPE_PITSHELF: 'Pozo / Freezer',
        SHELF_NORMAL_TYPE_PALLET: 'Tarima',
        SHELF_CANT_LEVELS: 'Cant. de Niveles',
        MOD_TITLE: 'Módulo',
        BARTAB_GENERAL: 'Datos Principales',
        BARTAB_MODULES: 'Estructura',
        BARTAB_BLOCK_SPACES: 'Áreas Bloqueables',
        BARTAB_PROMOTION_MATERIAL: 'Material Promocional',
        BARTAB_SCOPES: 'Alcance de productos',
        BARTAB_MODULES_PL: 'Estructuras',
        BARTAB_PRODS: 'Productos',
        BARTAB_OTHERS: 'Otros',
        BARTAB_POPMAT: 'Material POP',
        BARTAB_ANALISYS: 'Análisis',
        BARTAB_CONSTRAINTS: 'Reglas',
        BARTAB_AUDIT: 'Auditoría',
        BARTAB_MAX_QUANT: 'Cantidad Máxima de Promociones',
        IND_SALES: 'Ventas',
        IND_SALES_MAG: 'Ventas $',
        IND_SALES_UINIT: 'Ventas UN.',
        IND_SALES_UINIT_PER: 'Ventas UN. %',
        IND_SALES_PER: 'Ventas %',
        IND_MARGIN: 'Margen',
        IND_MARGIN_UNIT: 'Margen UN.',
        IND_MARGIN_MAG: 'Margen $',
        IND_MARGIN_PER: 'Margen %',
        IND_MARGIN_TOT: 'Margen Tot.',
        IND_ESP_MT_LIN: 'Esp. (m/lin) %',
        IND_ESP_MT2_LIN: 'Esp. m2',
        IND_SPACE_SUB: 'Subespaciado',
        IND_SPACE_OK: 'Balanceado',
        IND_SPACE_HI: 'Sobreespaciado',
        SPACE_WARNING: 'Alerta de Espacio',
        IND_SPACE_SUB_SMALL: 'Sub.',
        IND_SPACE_OK_SMALL: 'Bal.',
        IND_SPACE_HI_SMALL: 'Sobr.',
        IND_SPACE_GMROI: 'Gmroi',
        IND_SPACE_GMROS: 'Gmros',
        IND_SPACE_PRICE: 'Precio',
        IND_SPACE_NONE: 'Ninguno',
        IND_SPACE_DAYS_EXH: 'Días Exhib.',
        IND_SPACE_DAYS_EXH_FULL: 'Días Exhibición',
        IND_SALES_PER_PROD: 'Ventas $ p prod.',
        IND_SALES_PER_FACING: 'Ventas $ p fte.',
        IND_SALES_MTS_LIN_RATIO: 'Ventas $/ esp.',
        IND_SALES_MTS_LIN_RATIO_USED: 'Ventas $/ esp us.',
        IND_SALES_PRICE_PROM: 'Precio Prom. $',
        IND_SALES_MARGIN_PROM: 'Margen Prom. $',
        IND_SALES_MARGIN_PROM_PER: 'Margen Prom. %',
        IND_SALES_MONTH_UNITS: 'Ventas Mes UN',
        OPEN_IN_ITEM_MASTER: 'Abrir en Ítem Master',
        IND_SALES_MONTH: 'Ventas Mes',
        IND_SALES_UNITS_FACINGS: 'Ventas UN/Fte.',
        IND_MARGIN_MONTH: 'Margen Mes',
        IND_SPACE_ASSIGN: 'Asignación de Esp.',
        IND_SPACE_ASSIGN_FULL: 'Asignación de Espacio',
        IND_LOAD: 'Carga',
        IND_STOCK_DAYS: 'Días Stock',
        IND_STOCK_DAYS_FULL: 'Días de Stock',
        IND_ATTRS: 'Atributos',
        IND_NONE: 'Ninguno',
        IND_DAYS: 'días',
        IND_MIN_SMALL: 'Mín.',
        IND_MAX_SMALL: 'Máx.',
        IND_NORMAL: 'Normal',
        IND_BIGGER_THAN_DAYS: 'Mayor a {{val}} días',
        IND_SMALER_THAN_DAYS: 'Menor a {{val}} días',
        MAG_DEFAULT: 'cm.',
        MAG_VOL_DEFAULT: 'm³',
        MAG_HEIGHT_WITH_MAG: 'Alto (cm.)',
        MAG_HEIGHT_WITH_MAG_ALLMOD: 'Altura (cm.)',
        MAG_WIDTH_WITH_MAG: 'Ancho (cm.)',
        MAG_DEPTH_WITH_MAG: 'Profundidad (cm.)',
        MARGIN_LEFT: 'Margen Izq. (cm.)',
        MARGIN_RIGHT: 'Margen Der. (cm.)',
        MAG_GUIDES: 'Distancia guias (cm.)',
        MAG_HEIGHT: 'Alto',
        MAG_WIDTH: 'Ancho',
        MAG_DEPTH: 'Prof.',
        MAG_VOL: 'Vol.',
        PRINT_TITLE_DIALOG: 'Imprimir Planograma',
        PRINT_MODS_SELECTION:
          'Elija los módulos a imprimir,\r\n si deja el rango vacío se imprimirán todos los módulos.\r\n (Es posible usar rangos, ej: 2-4)',
        PRINT_MODS_TO_PRINT: 'Módulos a imprimir',
        PRINT_PRINT_TYPES: 'Tipos de impresión',
        PRINT_PRINT_TYPE_IMAGES: 'Imágenes',
        PRINT_PRINT_TYPE_GROUP: 'Códigos Grupales',
        PRINT_PRINT_TYPE_INDIV: 'Códigos Individuales',
        PRINT_PRINT_TYPE_ALL_PLANOGRAM_IMAGE: 'Planograma Completo',
        PRINT_PRINT_TYPE_POP: 'Material POP',
        PRINT_REPORT: 'Listado de Reporte',
        PRINT_WARN_NO_RANGE: 'No hay módulos para imprimir con el rango seleccionado.',
        AUDIT_STATUS: 'Estado',
        AUDIT_LAST_PUBLISH: 'Ult. Pub.',
        AUDIT_NO_IMAGE: 'Sin imagen',
        AUDIT_IMG_UPLOAD: 'Subir imagen',
        AUDIT_IMG_VIEW: 'Ver imagen',
        OPEN_PRISMA_VISION: 'Compliance',
        AUDIT_TITLE: 'Auditoría',
        AUDIT_WARNING_NO_SAVED: 'Para cambiar el estado del planograma no debe haber cambios sin guardar.',
        AUDIT_AUDIT_IMG: 'Imagen de auditoría',
        AUDIT_NEW_AUDIT_IMG: 'Nueva imagen de auditoría',
        AUDIT_NEW_AUDIT_IMG_OK: 'Imagen guardada correctamente',
        CONSTRAINTS_TITLE: 'Reglas',
        TLINE_TITLE: 'Línea de tiempo',
        TLINE_ACTUAL_TIME: 'Actual (Hoy)',
        TLINE_TOTAL_MARG: 'Marg. Tot.',
        TLINE_SALES_UNITS: 'Ventas UN',
        TLINE_LAST_PUB: 'Publicación Anterior [ctrl+left]',
        TLINE_NEXT_PUB: 'Publicación Siguiente [ctrl+right]',
        TLINE_CLOSE_TL: 'Cerrar línea de tiempo',
        TLINE_OPEN_TL: 'Abrir línea de tiempo',
        TYPES: {
          'normalShelf-module': 'Estantería',
          'simplePeg-module': 'Ganchera',
          'pitShelf-module': 'Pozo',
          'refrigerator-module': 'Heladera',
          'normalShelf-level': 'Estantería',
          'simplePeg-level': 'Ganchera',
          'pitShelf-level': 'Pozo',
          'float-pop-impulse-strip-simple': 'Tira de impulso',
        },
        COMM_TITLE: 'Comentarios',
        COMM_DATE: 'Fecha',
        COMM_COMMENT: 'Comentario',
        COMM_NEW_COMMENT: 'Nuevo comentario',
        COMM_READ_MORE: '[Leer más]',
        NEW_NORMALSHELF: 'Nueva Estantería',
        NEW_REFRIGERATOR: 'Nueva Heladera',
        NEW_SIMPLEPEG: 'Nueva Ganchera',
        NEW_PITSHELF: 'Nuevo Pozo / Freezer',
        CONSTRAINS_TYPES: {
          MIN_FACINGS: 'Mínimo de frentes por producto',
          MAX_FACINGS: 'Máximo de frentes por producto',
          MIN_LINE_SPACE: 'Mínimo de espacio lineal',
          MAX_LINE_SPACE: 'Máximo de espacio lineal',
          MIN_SHARE_SPACE: 'Mínimo de share de espacio',
          MAX_SHARE_SPACE: 'Máximo de share de espacio',
        },
        CONSTRAINS_NAME_TITLE: 'Nombre',
        CONSTRAINS_APPLY_TO: 'Aplica a:',
        CONSTRAINS_ALL_THE_PLANOGRAM: 'Todo el planograma',
        CONSTRAINS_ALL_THE_PLANOGRAM_TTIP: 'Esta regla va aplicar a todo el planograma',
        CONSTRAINS_ALL_SHELF_TTIP: 'Esta regla va aplicar a todo el estante',
        CONSTRAINS_ALL_SHELF_MODULE_TTIP: 'Esta regla va aplicar a todo el módulo',
        CONSTRAINS_SHELF_SELECTED: 'Estante',
        CONSTRAINS_SHELF_MODULE: 'Módulo',
        CONSTRAINS_CONDITION: 'Condición',
        CONSTRAINS_FRONTS_VALUE: 'Frentes',
        CONSTRAINS_LINE_SPACE_VALUE: 'Espacio lineal (cm.)',
        CONSTRAINS_SHARE_SPACE_VALUE: '% de espacio',
        CONSTRAINS_GROUP_EAN: 'Agrupado por ean',
        CONSTRAINS_ALL_ITEMS: 'Todos los ítems',
        CONSTRAINS_WARNINGS: 'alertas',
        CONSTRAINS_ONLY_THIS: 'Ver alertas',
        CONSTRAINS_WARNINGS_SIZE: 'Cantidad de alertas',
        DELETE_DELETE_ITEM: 'Borrar ítem',
        DELETE_DELETE_ALL_EANS: 'Borrar todos los ítems agrupados por EAN',
        CLONE_STRUCTURE: 'Clonar solo estructura',
      },
      SPACES_TEMPLATES: {
        GENERATION_PLANOGRAMS:
          'Se ha encolado la solicitud para la generación de Planograms. Vas a recibir una notificación cuando finalice.',
        GENERATION_PLANOGRAMS_ALT:
          'Se ha guardado y encolado la solicitud para la generación de Planograms. Vas a recibir una notificación cuando finalice.',
        TITLE: 'Templates',
        TITLE_NEW: 'Nuevo Template',
        TAB_GENERAL: 'General',
        TAB_BLOCKS: 'Configuración de Bloques',
        TAB_BLOCKS_TT: 'Define las reglas de ordenamiento y condiciones comerciales para la generación de planogramas.',
        TAB_PLANOGRAMS: 'Planogramas',
        TAB_STRUCTURES: 'Estructuras',
        TAB_GENERATE: 'Generación',
        DEMO_TITLE_EDIT: 'Template para YOGHURT',
        DEMO_TITLE_CODE_EDIT: 'YOG1',
        PREVIEW: 'Previsualización',
        CLOSE_PREVIEW: 'Cerrar Previsualización',
        NEW_DIVISION: 'Nueva División',
        DIVISION: 'División',
        LEVELS: 'Niveles',
        LEVEL_BLOCK: 'Bloque Nivel',
        TABLE: {
          NAME: 'Nombre',
          FRONT: 'Ancho',
          HEIGHT: 'Alto',
          SHELVES: 'Estantes',
          STORES: 'Puntos de Venta',
          BLOCK_AREAS: 'Asignar Espacios Bloqueados',
          STORES_VIEW: 'Ver Puntos de Venta',
          STRUCTURE_VIEW: 'Ver Estructura',
          DELETE: 'Eliminar',
          SAVED_AREAS: 'Espacios Bloqueados',
        },
        LIST: {
          TITLE: 'Listado de Templates',
          PLANOGRAMS: 'Planogramas',
          STRUCTURES: 'Estructuras',
          GenericSpace: 'GenericSpace',
          DIVISIONS: 'Divisiones',
          NO_DIVISIONS: 'Agrega divisiones para comenzar a definir tus bloques.',
          NO_BLOCKS: 'Actualmente no hay productos disponibles para la configuración seleccionada.',
          TAG_SCOPE: 'Etiquetas Alcanzadas',
          CATEGORY_SCOPE: 'Categorías Alcanzadas',
          EXCEED_TOLERANCE: 'Exceso tolerado',
          MIN_LINEAR_SPACE: 'Mínimo espacio lineal',
          MAX_LINEAR_SPACE: 'Máximo espacio lineal',
          LINEAR_SPACE_CALC_TYPE: 'Orden de Bloques',
          LEVELS: 'Niveles',
          CODE: 'Código',
          NAME: 'Nombre',
          EDIT_ROW: 'Editar',
          COPY_ROW: 'Clonar',
          DELETE_ROW: 'Eliminar',
          ITEM_TAGS: 'Etiquetas de Producto',
          NEW_ITEM: 'Nuevo Template',
          DELETE: 'Eliminar',
          DELETE_TTIP: 'Eliminar un template',
          FILTER: 'Filtro avanzado',
          FILTER_TTIP: 'Activar o desactivar el Filtro avanzado',
          EXPORT: 'Exportar',
          EXPORT_TTIP: 'Exportar un template',
          DELETE_ROW_IN_PROCESS: 'Solicitud de inactivación en proceso. Te notificaremos una vez completada.',
          DELETE_ROWS: 'Inactivar registros',
        },
        PLANOGRAMS: {
          TITLE: 'Estructuras Asociadas',
          INTRO: 'Asigna las estructuras para vincularlas con el template',
          PLANOGRAMS: 'Planogramas',
          STRUCTURES: 'Estructuras',
        },
        LINEAR_SPACES: {
          SALES: 'Ventas $',
          UNITS: 'Ventas en Unidades',
          MARGIN: 'Margen $',
        },
        MAIN_TAB: {
          TITLE: 'Datos Generales',
          CODE: 'Código',
          CODE_EXIST: 'Código ya existe',
          NAME: 'Nombre',
          LEVELS: 'Niveles',
          LINEAR_SPACE_CALC: 'Asignación de frentes proporcional a',
          MIN_LINEAR_SPACE: 'Cantidad mínima de frentes por estante',
          MAX_LINEAR_SPACE: 'Cantidad máxima de frentes por estante',
          LEVELS_PER_PRODUCT: 'Niveles por producto',
          ORDER_TYPE: 'Tipo de ordenamiento',
          FILL_SPACES: 'Optimizar espacio',
          FACINGS_LIMIT: 'Utilizar límites por ítem',
          FILL_SPACES_MSG:
            'Al habilitar esta opción, se rellenarán automáticamente los espacios vacíos en los planogramas, maximizando el aprovechamiento del lugar disponible y evitando la pérdida de oportunidades de negocio',
          FACINGS_LIMIT_MSG:
            'Al habilitar esta opción, se aplicarán los límites mínimos y máximos configurados para cada SKU, garantizando visibilidad y evitando exceso de stock',
          EXCEED_BLOCK_TOLERANCE: 'Tolerancia a Exceder al ancho del bloque',
          SCOPE_ITEMS: 'Alcance de Productos',
          SCOPE_ITEMS_COUNT: 'Productos seleccionados',
        },
        GENERATE: {
          TITLE: 'Generación de Planogramas',
          INTRO:
            'Selecciona el criterio para generar tus planogramas de manera eficiente. Optimiza la presentación de tus productos en los puntos de venta según tus necesidades específicas.',
          BTN1_TITLE: 'Generación por Estructura',
          BTN1_MSG:
            'Desarrolla un planograma por cada estructura, utilizando el promedio de datos de todas las tiendas con esa estructura.',
          BTN2_TITLE: 'Generación por Punto de Venta',
          BTN2_MSG: 'Crea un planograma específico para cada tienda, utilizando datos detallados de cada punto de venta.',
          BTN3_TITLE: 'Generación por Cluster',
          BTN3_MSG:
            'Genera planogramas para clusters de tiendas que comparten comportamientos similares, promediando los datos de cada grupo.',
          TEXT1:
            'Se generará un planograma por cada estructura asociada al Template; la performance de cada producto será el promedio de los puntos de venta asociadas a la estructura.',
          TEXT2:
            'Se generará un planograma por cada Punto de Venta asociado a las Estructuras del Template; la performance de cada producto será la correspondiente a cada Punto de Venta. Importante: Si uno o más de los Puntos de Venta está presente en más de una estructura, entonces se generará un planograma por cada dupla Estructura- Punto de Venta.',
          TEXT3:
            'Se generará un planograma por cada Cluster seleccionado; la performance de cada producto será el promedio en cada Cluster. Importante: Si hay múltiples estructuras, estas están a su vez asociadas Puntos de Venta de un mismo Cluster, entonces se generarán planogramas para cada dupla Cluster-Estructura.',
          INTRO2: 'Este proceso puede demorar varios minutos',
          PLANOGRAM: 'Generar Planogramas',
          PREVIEW: 'Generar Preview',
          MODAL_TITLE_PREVIEW: 'Generar Previsualización',
          MODAL_INTRO_PREVIEW: 'Seleccione el punto de venta para generar la previsualización',
          MODAL_TITLE_GENERATE: 'Generar Planogramas',
          MODAL_INTRO_GENERATE: 'Seleccione el criterio por el cual se generarán los planogramas para este template:',
          SELECT_PLANOGRAM: 'Planogramas',
          SELECT_STORE: 'Puntos de venta',
          SELECT_AREA: 'Áreas Bloqueadas',
          SELECT_STRUCTURE: 'Estructuras',
          SELECT_CLUSTER: 'Cluster',
          ACCEPT: 'Aceptar',
          CANCEL: 'Cancelar',
          ALERT: 'Guardar cambios para poder generar planogramas',
          WARNING: 'Debe guardar los cambios para generar planogramas con las nuevas modificaciones del tab estructuras',
          WARNING_ALT: 'Se detectaron cambios sin guardar, los cambios no guardados se guardarán automáticamente al generar planogramas.',
          WARNING_ALT_PDV: 'Debe guardar los cambios para ver los puntos de venta.',
          CREATEVERSIONFOR: 'Generar Planogramas para:',
          PREVIEW_MODE: 'Generar planogramas como Borrador (Preview)',
          CREATEVERSIONFOR_OPTIONS: {
            PerStructure: 'Estructuras asociadas al Template',
            PerStore: 'Selección Manual de Puntos de Venta',
            PerClusterStructure: 'Selección Manual por Cluster',
          },
          NAME_COLUMN: 'Nombre',
          PLANOGRAM_EXIST_TITLE: 'Planogramas Existentes',
          PLANOGRAM_EXIST_SUBTITLE:
            '¿Está seguro de que desea continuar con la generación de planogramas? Este proceso sobreescribirá los elementos de la siguiente lista:',
        },
        BLOCKS: {
          SELECT_PARENT: 'Seleccionar Bloque Padre',
          SELECT_VARIABLE: 'Seleccionar Variable',
          SELECT_VARIABLE_OPTS: {
            Department: 'Por Departamento',
            Category: 'Por Categoría',
            SubCategory: 'Por Subcategoría',
            Tag: 'Por Etiqueta',
            Price: 'Por Precio',
            Size: 'Por Tamaño',
            category: 'Categoría',
            subcategory: 'Subcategoría',
            tag: 'Etiqueta',
            price: 'Precio',
            size: 'Tamaño',
          },
          SELECT_ORIENTATION_OPTS_NEW: {
            Vertical: 'Vertical',
            Horizontal: 'Horizontal',
          },
          SELECT_CALCULATIONTYPE: {
            Sales: 'Ventas',
            Units: 'Unidades',
            Margin: 'Margen',
            Price: 'Precio',
            Size: 'Tamaño',
            Custom: 'Custom',
          },
          SELECT_ORDERTYPE: {
            Descending: 'Descendente',
            Ascending: 'Ascendente',
            Custom: 'Custom',
          },
          COLOR: 'Color',
          SELECT_DIVISIONTYPE_ROOT: 'Tipo de división',
          SELECT_DIVISIONTYPE: 'Cálculo de División de Bloques',
          SELECT_DIVISIONTYPE_ITEM: 'Cálculo de División de Ítems',
          SELECT_ORDER_TYPE: 'Orden de Ítems',
          SELECT_ORDER_TYPE_ITEMS: 'Orden de Ítems',
          SELECT_ORDER_TYPE_BLOCKS: 'Orden de Bloques',
          SELECT_DIVISIONTYPE_OPTS: {
            Horizontal: 'Horizontal',
            Vertical: 'Vertical',
          },
          MAX_DIVISIONS: 'Máximo de divisiones',
          MAX_DIVISIONS_ALT: 'Máximo',
          MIN_DIVISIONS_ALT: 'Mínimo',
          DIVISION_INDEX: 'División Nro',
          DIVISION_WIDTH_ALT: 'Ancho (%)',
          ALLOCATION_CRITERIA: 'Criterio para asignación de espacio',
          ALLOCATION_CRITERIA_OPTS: {
            '0': 'Prop. a la venta $',
            '1': 'Venta Un.',
            '2': 'Margen $',
          },
          ORDER: 'Orden',
          ORDER_OPTS: {
            '0': 'Ascendente',
            '1': 'Descendente',
            '2': 'Custom',
          },
          ADD_DIVITION: 'Agregar División',
          PRODUCTS: 'Productos',
          UNITS: 'Unidades',
          MARGIN: 'Margen',
          SALES: 'Ventas',
          ADD_DIVITION_EXCEPTION: 'Orden Custom',
          ADD_DIVITION_EXCEPTION_SIZE: 'Tamaño Custom',
          DIVISION_WIDTH: 'Tamaño (%)',
          DIVISION: 'División',
          DIVISIONS: 'Divisiones',
          DEMO_BRAND: 'Marca',
          DEMO_FLAVOR: 'Sabor',
          DEMO_SIZE: 'Tamaño',
          DEMO_PRESENTATION: 'Presentación',
          SELECT_ORIENTATION: 'Orientación',
          SELECT_ORIENTATION_OPTS: {
            horizontal: 'Horizontal',
            vertical: 'Vertical',
          },
          VALIDATIONS: {
            blockCustomSize_invalidWidth: 'Tamaño del bloque inválido',
            blockCustomSize_invalidWidth_max: 'Tamaño del bloque inválido',
            blockCustomSize_invalidtagValueOrCategoryId: 'Falta de categoría o etiqueta',
            blockCustomOrder_invalidtagValueOrCategoryId: 'Falta de categoría o etiqueta',
            blockCustomOrder_invalidOrder: 'Orden inválido',
            blockCustomSize_invalidtagValueOrCategoryId_dup: 'Categoría o etiqueta duplicada',
            blockCustomOrder_invalidtagValueOrCategoryId_dup: 'Categoría o etiqueta duplicada',
            blockCustomDivisionType_invalidHorizontal: 'Tipo de división inválido en el primer nivel',
            divisionVariable_duplicate: 'Variable duplicada',
            tagId_duplicate: 'Etiqueta duplicada',
          },
        },
      },
      FLOOR_PLANS: {
        LIST: {
          NEW_ITEM: 'Crear Layout',
          TITLE: 'Listado de Layouts',
          NAME: 'Nombre',
          CODE: 'Código',
          SIZE_PLANOGRAMS: 'Nro Planogramas',
          SIZE_LEVELS: 'Niveles',
          EDIT_ROW: 'Editar',
          COPY_ROW: 'Clonar',
          DELETE_ROW: 'Eliminar',
          ITEM_TAGS: 'Etiquetas de Producto',
          DELETE: 'Eliminar',
          DELETE_TTIP: 'Eliminar un template',
          FILTER: 'Filtro avanzado',
          FILTER_TTIP: 'Activar o desactivar el Filtro avanzado',
          EXPORT: 'Exportar',
          EXPORT_TTIP: 'Exportar un template',
          DELETE_CONFIRM: '¿Desea continuar con la eliminación del Layout? ',
        },
        EDITOR: {
          TAB_GENERAL: 'General',
          TAB_LAYOUTS: 'Layouts',
          TAB_ANALISYS: 'Análisis',
          TITLE_EDIT: 'Layouts',
          TAB_LEVELS: 'Niveles',
          LEVEL: 'Nivel',
          LEVEL_LABEL_NAME: 'Nivel',
          TITLE_NEW: 'Nuevo Layout',
          SUBTITLE: 'Estás creando un nuevo Layout, selecciona el Nombre, los puntos de venta o la categoría',
          BACK_OPACITY: 'Opacidad del Fondo',
          ROTATION: 'Ángulo de Rotación',
          BACK_BW: 'Imagen Monocromática',
          DELETE_ALL_MODULES: 'Limpiar Módulos',
          OPEN_PLANOGRAM: 'Abrir Planograma',
          MSG_CONFIRM_DEL_MODULES: '¿Desea continuar con la eliminación de todos los planogramas del layout?',
          ADD_LEVEL: 'Agregar Nivel',
          EDIT_LEVEL: 'Editar Nivel',
          NEW_LEVEL: 'Nuevo Nivel',
          WIDTH: 'Ancho (m.)',
          DEPTH: 'Profundidad (m.)',
          TOTALSPACE: 'Área total (m².)',
          SELLINGAREASPACE: 'Área de ventas (m².)',
          BLUEPRINT_PHOTO: 'Imágen de referencia del Plano',
          DELETE_LEVEL: 'Eliminar Nivel',
          MOVELEVELTOP: 'Mover arriba (reordenar)',
          MOVELEVELBOTTOM: 'Mover abajo (reordenar)',
          MSG_CONFIRM_DEL_LEVEL: '¿Desea continuar con la eliminación del nivel {{val}}?',
          NAME: 'Nombre',
          CODE: 'Código',
          CATEGORY: 'Categoría',
          NO_LEVELS: 'Sin Niveles',
          PLANOGRAMS: 'Planogramas',
        },
      },
    },
    TAGS_MODAL: {
      TITLE: 'Selección Avanzada',
      DESC: 'Aplica filtros a tu búsqueda para poder llegar el resultado deseado. Esta opción anulará la selección por filtros.',
      FIRST_TABLE_BTN: 'Aplicar',
      FIRST_TABLE_HEADER: 'Filtros',
      SECOND_TABLE_BTN: 'Seleccionar',
      THIRD_TABLE_HEADER: 'Selección Actual',
      THIRD_TABLE_EMPTY: 'Seleccione opciones',
      CANCEL: 'Cancelar',
      APPLY: 'Aplicar',
    },
    REPORTS: {
      GENERAL_TITLE: 'Reportes Generales',
      PREFIX_TITLE: 'Reportes: ',
      TYPE_FILTERS: 'Tipo de Reporte',
      DOWNLOAD: 'Generar y descargar Reporte',
      GENERATE: 'Generar Reporte',
      FORMAT: 'Formato',
      GENERATE_FORMAT: 'Formato',
      GO_TO_REPORT_LIST: 'Ir al Listado de Reportes',
      OPEN_REPORT_LIST: 'Abrir Listado de Reportes',
      ASYNC_START_MSG: 'La solicitud para generar el reporte ha sido encolada. Recibirás una notificación cuando se complete.',
      FILTERS_TITLE: 'Selección de Filtros',
      TABLE: {
        filename: 'Nombre de Archivo',
        lastModified: 'Últ. Modificación',
        reportType: 'Tipo de Reporte',
        username: 'Nombre Usuario',
      },
      REPORT_TYPE_KEYS: {
        COMMENTARIO_DOCU: 'Se usan/Usar: GENERIC_COMP.IMPORTERV2.TYPES',
      },
      LABEL_KEYS: {
        INCLUDEISDELETED: 'Incluir eliminados',
        SEARCH: 'Búsqueda',
        PRICE: 'Precio',
        PERCENT: 'Porcentaje',
        DATE: 'Fecha',
        DATEFROM: 'Fecha Desde',
        DATETO: 'Fecha Hasta',
        FROMTODATES: 'Fechas (Desde/Hasta)',
        ITEMTAGVALUES: 'Atributos de Ítem',
        STORETAGVALUES: 'Atributos de PDVs',
        CATEGORYVALUES: 'Categorías',
      },
    },
    SELLING: {
      PRICING: {
        TITLE: 'Precios',
        SUBTITLE: 'Obtén el precio correcto con Inteligencia Artificial.',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Alcanza el posicionamiento competitivo deseado',
        FEATURE1_SUBTITLE:
          'Nuestros algoritmos identifican tus competidores en cada zona de precios y te ayudan a ajustar tus precios al posicionamiento buscado.',
        FEATURE2_TITLE: 'Analiza el impacto de tus decisiones',
        FEATURE2_SUBTITLE: 'Prisma estima el impacto en margen e imagen de precios para ayudarte en tu proceso de toma de decisión.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
      CTA_MODAL: {
        TITLE: 'Contáctenos para más información',
        NAME_INPUT: 'Nombre',
        COMPANY_INPUT: 'Empresa',
        PHONE_INPUT: 'Teléfono',
        SEND_BTN: 'Enviar',
      },
      ASSORTMENT: {
        TITLE: 'Surtidos',
        SUBTITLE: 'Optimiza tu mix de productos y maximiza tus resultados.',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Catalogación',
        FEATURE1_SUBTITLE:
          'Asigna productos a tus puntos de venta: * Asigna por segmento y clasifica tus productos. * Asigna por segmento y clasifica tus productos. ',
        FEATURE2_TITLE: 'Cuida tus recursos y tu margen',
        FEATURE2_SUBTITLE:
          'Reglas de Pareto + Inteligencia Artificial: Tu negocio puede trabajar con Inteligencia artificial para tener en tus puntos de venta los productos que tus clientes  necesitan. Incorpora productos que son un éxito en el mercado u otros puntos de venta. Remueve productos que no están funcionando como esperabas.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
      PROMOTIONS: {
        TITLE: 'Promociones',
        SUBTITLE: 'Potencia tu inteligencia promocional.',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Diseña promociones sorprendentes',
        FEATURE1_SUBTITLE: 'Sorprende a tus clientes implementando una variedad de dinámicas promocionales.',
        FEATURE2_TITLE: 'Mide los resultados en detalles',
        FEATURE2_SUBTITLE:
          'Toma en cuenta el efecto canibalización, las ventas pre y post promoción y la inversión en marketing para medir la rentabilidad incremental por promoción.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
      ITEM_MASTER: {
        TITLE: 'Ítem Master',
        SUBTITLE: 'Simplifica tu proceso de gestión de productos con Inteligencia Artificial.',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Matching de Productos con IA',
        FEATURE1_SUBTITLE: 'Encuentra tus productos en tus competidores para comparar precios, surtidos y detectar tendencias.',
        FEATURE2_TITLE: 'Mejora contínua de tu árbol de categorías',
        FEATURE2_SUBTITLE: 'Aumenta tu precisión y velocidad en el proceso de clasificación de productos.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
      SPACES: {
        TITLE: 'Espacios',
        SUBTITLE: 'Ubica tus productos de una manera eficiente, optimizada y visualmente atractiva para los clientes.',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Llenado Inteligente de Góndola',
        FEATURE1_SUBTITLE:
          'Utiliza la función de autocompletado con días de exhibición objetivo y Prisma te va a sugerir cantidad de frentes para minimizar los quiebres de stock.',
        FEATURE2_TITLE: 'Usa elementos móviles y distintos tipos de góndolas y módulos',
        FEATURE2_SUBTITLE:
          'Agrega elementos como ganchos, colgantes a las góndolas. Combina con freezers para productos congelados o lácteos. Utiliza mesas libres o canastos para ubicar tus promociones.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
      SUPPLY: {
        TITLE: 'Abastecimiento',
        SUBTITLE: 'INTEGRE TODOS SUS DATOS A SU CADENA DE ABASTECIMIENTO',
        SEE_MORE_BTN: 'Ver Más',
        FEATURE1_TITLE: 'Reduzca sus stocks y días de inventario',
        FEATURE1_SUBTITLE:
          'Estime el comportamiento de cada SKU para cada tienda y determine las necesidades de distribución. Se alimenta de información histórica de ventas, precios y promociones para ajustar los estimados.',
        FEATURE2_TITLE: 'Defina su Cadena de abastecimiento',
        FEATURE2_SUBTITLE:
          'Permite definir qué centros abastecen a cada tienda y diseñar una red de abastecimiento omnicanal donde intervienen proveedores y centros de aprovisionamiento en cada parte de la cadena.',
        CTA_TITLE: 'Conoce cómo podemos ayudarte!',
        CTA_BTN: 'Hablemos',
      },
    },
    GLOBAL_WARNINGS: {
      MODAL_WARNING_TITLE: 'Alerta de Publicación - Confirmación',
      MODAL_WARNING_TITLE_ONLY_ALERT: 'Alerta de Publicación',
      MODAL_FILE_REPORT_TITLE: 'Descargar Reporte',
      TEST: 'Se encontraron datos inconsistentes en la publicación.\r\n¿Desea publicar de todas formas?',
      ITEM_UNCOVERED_COST:
        'El ítem no resuelve los costos para todos los puntos de venta seleccionados por surtido.\r\n¿Desea publicar de todas formas?',
      STORE_UNCOVERED_COST:
        'El punto de venta no resuelve los costos para todos los ítems seleccionados por surtido.\r\n¿Desea publicar de todas formas?',
      ASSORTMENT_UNCOVERED_COST:
        'Algunos puntos de venta e ítems no cubren los costos según esta decisión de surtido, puede descargar el reporte para ver el detalle.\r\n¿Desea publicar de todas formas?',
      CLUSTER_UNCOVERED_COST_ONLYALERT:
        'Los cambios realizados en la clusterización afectan a la cobertura de costos por proveedor. Por favor revise el reporte de cobertura o revierta su última acción.',
    },
    INTERFACE_LOG_MSG_KEYS: {
      PromotionNotFound: 'Promoción no encontrada',
      ItemNotFound: 'Ítem no encontrado',
      ItemDoesNotExists: 'Ítem inexistente',
      UomNotFound: 'Unidad de Medida no encontrada',
      NoDataProcessed: 'Sin datos procesados',
      HeaderIsInvalid: 'Encabezado no válido',
      ItemCodeMustNotBeNull: 'El Código del Ítem no puede ser "null"',
      ItemCostDoesNotBeNull: 'El Costo del Ítem no puede ser "null"',
      ItemCostGretherThanZero: 'El Costo del Ítem debe ser mayor que 0.',
      DiscountGretherThanZero: 'El Descuento del Ítem debe ser mayor que 0.',
      Cost2GretherThanZero: 'El Costo 2 del Ítem debe ser mayor que 0.',
      Cost3GretherThanZero: 'El Costo 3 del Ítem debe ser mayor que 0.',
      TaxGretherThanZero: 'El Impuesto del Ítem debe ser mayor que 0.',
      Tax2GretherThanZero: 'El Impuesto 2 del Ítem debe ser mayor que 0.',
      Tax3GretherThanZero: 'El Impuesto 3 del Ítem debe ser mayor que 0.',
      StoreCodeMustNotBeNull: 'El código del punto de venta no puede ser nulo',
      StoreDoesNotExists: 'El punto de venta no existe',
      TagValueDoesNotExists: 'El valor de la etiqueta no existe',
      VendorDoesNotExists: 'El Proveedor no existe',
      ItemCannotBeSupply: 'Ítem no puede ser del tipo Supply',
      ItemCannotBeIngredient: 'Ítem no puede ser del tipo Ingrediente',
      ItemCannotBeNew: 'Ítem no puede ser estar en estado nuevo',
      ItemBaseOnComponents: 'No se pueden importar precios de combos con precios basados en sus componentes',
      ItemPriceGretherThanZero: 'El precio debe sea mayor a cero',
      ItemPriceStoreAndSegmentationSameTime: 'Punto de venta y segmentación no pueden estar informados al mismo tiempo',
      ItemAndSegmentationSameTime: 'Producto y segmentación no pueden estar informados al mismo tiempo',
      ValidFromGreaterThanTodayAndLessThen: 'La fecha debe ser mayor a hoy y como máximo 30 días a futuro',
      DiscountGretherThanOrEqualZero: 'El Descuento del Ítem debe ser mayor o igual que 0.',
      DiscountLowerThanToItemCost: 'El descuento debe ser menor al costo del ítem',
      keyword: 'Mensaje',
      type: 'Tipo de mensaje',
      values: 'Valores',
      'GatewayTime-out': 'Tiempo de espera vencido',
      NULL: 'Error no especificado',
      ItemNameMustNotBeNull: 'El nombre del Ítem no debe ser nulo',
      SalesTaxRateGreaterThanOrEqualToZero: 'El IVA de venta debe ser mayor o igual a cero',
      CategoryCodeMustNotBeNull: 'El código de la categoría no debe ser nulo',
      CategoryDoesNotExists: 'El código de la categoría no existe',
      CompetitorCodeMustNotBeNull: 'El código del competitor no debe ser nulo',
      WrongAssortmentBehavior: 'Comportamiento de surtido incorrecto',
      StoreAssortmentGeneratedByPromotion: 'Surtido por punto de venta generado por promoción',
      StoreAssortmentNotFound: 'No se encontró el surtido por punto de venta',
      ItemCodeTypeNotFound: 'No se encontró el ItemCodeType',
      DuplicateItemCode: 'Se encontró un código de ítem duplicado',
      ItemCostGretherThanRegulated: 'El costo de un ítem supera al costo regulado',
      InvalidUnits: 'Las unidades de medidas no están correctamente completadas',
      InvalidDate: 'Fecha inválida',
      MonthlySaleAlreadyExist: 'El registro de venta ya existe',
      TagsRequiredNotCompleted: 'Debe completar las etiquetas requeridas.',
      InvalidVendors: 'Debe completar la solapa proveedores',
      WrongItemCode: 'Debe completar los códigos obligatorios.',
      InvalidCombo: 'Por favor verifique la solapa componentes.',
      InvalidRecipe: 'Por favor verifique la solapa ingredientes.',
      ItemCannotPublish: 'El ítem no puede publicarse automáticamente.',
      ItemCostGreaterThanRegulated: 'El costo de un ítem supera al costo regulado.',
      DiscountGreaterThanOrEqualZero: 'El Descuento del Ítem debe ser mayor o igual que 0',
      ItemPriceGreaterThanZero: 'El precio debe sea mayor a cero',
      ItemCostGreaterThanZero: 'El Costo del ítem debe ser mayor que 0',
      DiscountGreaterThanZero: 'El Descuento del ítem debe ser mayor que 0',
      Cost2GreaterThanZero: 'El Costo 2 del Ítem debe ser mayor que 0',
      Cost3GreaterThanZero: 'El Costo 3 del Ítem debe ser mayor que 0',
      TaxGreaterThanZero: 'El Impuesto del Ítem debe ser mayor que 0.',
      Tax2GreaterThanZero: 'El Impuesto 2 del Ítem debe ser mayor que 0.',
      Tax3GreaterThanZero: 'El Impuesto 3 del Ítem debe ser mayor que 0.',
      ValidFromGreaterThanToday: 'La fecha debe ser mayor a la fecha actual',
      DuplicateRecord: 'Registro duplicado',
      CostCodeDoesNotExists: 'No existe el código de costo',
      TaxCodeDoesNotExists: 'No existe el código de impuesto',
      VendorItemDoesNotExist: 'La relación ítem y proveedor no existe',
      VendorCodeMustNotBeNull: 'El Código del proveedor no puede ser "null"',
      TaxGreaterThanOrEqualZero: 'El Impuesto del ítem debe ser mayor o igual a 0',
      Tax2GreaterThanOrEqualZero: 'El Impuesto 2 del ítem debe ser mayor o igual a 0',
      Tax3GreaterThanOrEqualZero: 'El Impuesto 3 del ítem debe ser mayor o igual a 0',
      ItemCostGreatherThanRegulated: 'El costo de un ítem supera al costo regulado',
      InvalidSalesUnits: 'Las unidades de medidas de venta no están correctamente completadas',
      ItemHasNotSellingUnit: 'El ítem no tiene unidad de medida de venta',
      InvalidCompetitorPriceSource: 'Fuente de precio de competidor inválida',
      CodeMustNotEmpty: 'El código no debe estar vacío',
      NameMustNotEmpty: 'El nombre no debe estar vacío',
      ItemIsDeleted: 'El ítem está borrado',
      StoreCostValueGreaterThanZero: 'El costo de un ítem-tienda debe ser mayor a cero',
      WrongStoreCode: 'Código de tienda incorrecto',
      InvalidSaleUnits: 'Unidades de venta inválidas',
      ValueShouldBeGreaterThanZero: 'El valor debe ser mayor a cero',
      MalFormedFieldTagValues: 'El campo TagValues está mal construido',
      TagNotFound: 'Etiqueta no encontrada',
      TaskFailedAfterRetries: 'La tarea falló luego de varios reintentos',
      UnitsLowerOrEqualToZero: 'Unidades menores o iguales a cero',
      DateTimeGreaterThanTodayAndLessThen: 'La fecha es mayor a la fecha actual',
      DailySaleAlreadyExist: 'La venta diaria ya existe',
      InvalidVendorSegmentation: 'Segmentación del proveedor inválida',
      DocumentTypeDoesNotExists: 'El tipo de documento no existe',
      DailyInventoryAlreadyExist: 'Inventario diario ya existe',
      InvalidMonth: 'Mes incorrecto',
      DuplicateTagValueForOneOrMoreStores: 'Valor de etiqueta con una o más tiendas asignadas',
      SalesTotalCannotBeEmpty: 'Venta total no puede estar vacío',
      NetSalesCannotBeEmpty: 'Venta neta no puede estar vacío',
      SalesTotalCannotBeEmptyOrEqualToZero: 'Venta total no puede estar vacía o ser menor a cero',
      NetSalesCannotBeEmptyOrEqualToZero: 'Venta neta no puede estar vacía o ser menor a cero',
      ComboCodeDoesNotExists: 'El código del combo no existe',
      TagValueWrongType: 'El tipo de dato del valor de etiqueta es incorrecto',
      QuantityGreaterThanOrEqualOne: 'La cantidad es mayor o igual a uno',
      QuantityMustBeGreaterThanOrEqualOne: 'La cantidad debe ser mayor o igual a uno',
    },
    CUSTOM_ERRORS: {
      '1': 'Test Custom Error Trad ES val: {{val}}',
      BaseUnitOfMeasureNotFound: 'No se encontró unidad de medida base para este producto. Se utilizará 1 como conversión.',
      UnitOfMeasureNotFound: 'No se encontró la unidad de medida',
      CalculationTypeCostNotFound:
        'No se encontró {{val}} de referencia en el Punto de Venta {{val2}} para poder calcular el costo o impuesto del producto {{val3}} ',
      MissingInitialPriceMethod:
        'No se seleccionó una metodología para crear los precios. Indicar un Precio Inicial para todas las unidades de medida de venta y una metodología para inicializar los precios en el Tab Precios.',
      MissingSellingUnitOfMeasure: 'Debes crear al menos una unidad de medida de venta para este tipo de producto',
      NotGeneratedPriceMissingSellingUom: 'No se generaron precios por no contar con una unidad de medida de venta',
      CannotDeleteRegistryInUse: 'El registro se encuentra en uso',
      NoCodeSpecified: 'Registro no encontrado',
      ItemNotFound: 'Ítem no encontrado: {{val}}',
      ItemIsPublishingCannotUpdate: 'No se puede editar este ítem porque se está publicando.',
      CodeAlreadyExists: 'El código ya existe {{val}}.',
      CLONED_PROCESS_FAILURE_TITLE: 'El Proceso de clonación fallo',
      CLONED_PROCESS_FAILURE_MSG: 'La Clonación no fue realizada o fue realizada parcialmente sin surtido.',
      InvalidCodeTypes: 'El tipo de código no existe {{val}}.',
      ParamCannotBeNull: 'El parámetro {{val}} no puede ser nulo.',
      ParentCannotBeUse:
        'No se puede usar el padre seleccionado para la regla porque ya fue utilizado como hijo en otra regla de parentesco',
      AtLeastOneChildWasFound: 'Se ha encontrado al menos un producto hijo que ya fue usado como hijo en otra regla.',
      NoNeedToRepublish: 'Este ítem no necesita republicarse.',
      ItemHasNotStoreAssortment:
        'La publicación no fue llevada a cabo. Excepción por surtido se encuentra activo y no se cargó correctamente, se debe cargar en el importador al menos un producto activo y no tener bloqueo para la venta',
      StoreIsPublishingCannotUpdate: 'No se puede editar este punto de venta porque se está publicando.',
      UNAUTHORIZED_ACCESS: 'No tiene permisos suficientes para realizar esta acción.',
      INVALID_STORE_SCOPE: 'No se encuentran Puntos de Venta para el alcance de productos seleccionado.',
      INVALID_MANUAL_STORE_SCOPES: 'Los siguientes Puntos de Venta seleccionados no son válidos para el alcance de productos elegido',
      AnyRoleInUse: 'No es posible eliminar Roles que están en uso.',
      NoRecordSelected: 'No hay elementos seleccionados para borrar.',
      EntityCreated: 'Registro creado correctamente',
      EntityUpdated: 'Registro actualizado correctamente',
      CodeCompetitorCannotBeNull: 'El código de competidor no puede estar vacío.',
      CompetitorBadGeoData: 'Los datos de latitud o longitud son incorrectos.',
      BrandCompetitorCannotBeNull: 'La bandera no puede estar vacía.',
      NameCompetitorCannotBeNull: 'El nombre del competidor no puede estar vacío.',
      FileIsTooBig: 'El tamaño del archivo es demasiado grande.',
      ItemForecastDataIsNull: 'Datos de Forecast del Ítem Nulos',
      ReferencedItemIsAlreadyInUse: 'El Ítem referenciado ya está en uso.',
      ErrorOnAddingItemForecast: 'Error al Agregar el Forecast del Ítem',
      ErrorOnRemovingItemForecast: 'Error al Eliminar el Forecast del Ítem',
      ReferencedNotFound: 'Referencia No Encontrada',
      ExpiredPromotion: 'La Fecha de inicio de la acción promocional y del periodo promocional es anterior a la fecha actual.',
      CategoriesWithoutPermission: 'Sin permisos a las siguientes categorías: {{val}}',
    },
  },
};
